import globals from "@/globals";
import moment from "moment";
//import jsencrypt from "jsencrypt";
import { security, utils } from "@/helpers";
import { Buffer } from 'buffer';

const decodeBase64 = (data) => {
  return Buffer.from(data, 'base64').toString('utf-8');
}
export default {
  create(response) {
    const GIVENAME =
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname";
    const NAME = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
    const NameIdentifier = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
    const holderId = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/holderId";

    var token = {
      hash: response.data.token,
      expiration: response.data.expiration,
    };

    var jwt = this.decode(token.hash);
    var auth = {
      username: jwt[NAME],
      name: jwt[GIVENAME],
      id: eval(jwt[NameIdentifier]),
      holderId: jwt[holderId],
      language: jwt.language,
      token,
      sessionId: jwt.jti,
      orignalExpiration: response.data.expiration,
      //profile: eval(jwt.profile),
      profiles: eval(jwt.profiles),
      firstTime: eval(jwt.firstTime)
    };

    return auth;
  },
  decode:  function(jwt) {
    if(typeof jwt !== 'string' & !(jwt instanceof String)) return null

    let splitted = jwt.split('.')
    if(splitted.length < 2) return null

    let obj1 = JSON.parse(decodeBase64(splitted[0]))
    let obj2 = JSON.parse(decodeBase64(splitted[1]))

    return Object.assign({}, obj1, obj2)
  },
  get(key) {
    if (!key) key = globals.AUTH;

    var json = localStorage.getItem(key);

    if (!json) return null;

    //Decode base64
    const decoded = security.decryptMessage(json);
    var auth = JSON.parse(decoded);
    var currentDate = moment();
    var expirationDate = moment(auth.token.expiration);

    auth.expired = expirationDate < currentDate;

    return auth;
  },
  set(auth) {
    const key = globals.AUTH;
    if (auth) {
      var aux = JSON.stringify(auth);
      //Encode base64
      const encoded = security.encryptMessage(aux);
      localStorage.setItem(key, encoded);
    } else localStorage.removeItem(globals.AUTH);
  },
  // resetTimeout(auth) {
  //   var momentDate = moment(auth.token.expiration);
  //   var date = momentDate.add(15, "minutes");
  //   auth.token.expiration = date.toDate();
  //   var aux = JSON.stringify(auth);
  //   const encoded = security.encryptMessage(aux);
  //   localStorage.setItem(globals.AUTH, encoded);
  // },
  redirectToLogin(url) {
    utils.setUrlRedirect(url);
    window.location.href = "/#/login?expired=true";
  },
};
