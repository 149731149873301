<template>
  <div class="row pl-5 pr-5">
    <div class="col-12 d-f">
      <p class="pageTitle" id="table">
        <span class="btnAlike mr-1" @click="goBack">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="9.5"
              fill="white"
              stroke="#007AC4"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.7438 19.8659C11.565 20.0401 11.4893 20.2711 11.5012 20.4996C11.4893 20.728 11.565 20.9599 11.7438 21.1341L16.8347 25.2549C17.1692 25.5817 17.7122 25.5817 18.0467 25.2549C18.3812 24.928 18.3812 24.3977 18.0467 24.0708L14.682 21.3467H27.6488C28.1195 21.3467 28.5 20.9732 28.5 20.5129C28.5 20.0527 28.1195 19.6791 27.6488 19.6791H14.6497L18.0467 16.9292C18.3812 16.6023 18.3812 16.072 18.0467 15.7451C17.7122 15.4183 17.1692 15.4183 16.8347 15.7451L11.7438 19.8659V19.8659Z"
              fill="#007AC4"
            />
          </svg>
        </span>
        {{ declaracaoTitle }}
      </p>
      <DeclaracaoUnicaProgressBar
        :text="selectedLang.progress"
        :progress="progress"
      />
    </div>
  </div>

  <CustomCard class="mb-5" v-if="!loading">
    <DeclaracaoUnica
      :declaracaoUnicaProp="declaracao.json"
      :declaration="declaracao"
    />
    <DeclaracaoUnicaSubmitModal :declaracaoUnicaProp="declaracao.json" />
  </CustomCard>
</template>

<script>
import DeclaracaoUnicaProgressBar from "./DeclaracaoUnicaProgressBar.vue";
import DeclaracaoUnica from "./index.vue";
import DeclaracaoUnicaSubmitModal from "./DeclaracaoUnicaSubmitModal.vue";

export default {
  components: {
    DeclaracaoUnicaProgressBar,
    DeclaracaoUnica,
    DeclaracaoUnicaSubmitModal,
  },
  props: {
    declarationType: [Number, String],
  },
  data() {
    return {
      selectedTab: 1,
      loading: true,
      progress: 0,
    };
  },

  mounted() {
    this.emitter.on("selectTab", (tabId) => {
      this.selectedTab = tabId;
    });

    var progressCount = 0;
    var progressTotalCountBaseLine = 19;
    var progressTotalCount = progressTotalCountBaseLine;

    this.emitter.on("DeclarationProgress:Update", (InputTab) => {
      progressTotalCount = progressTotalCountBaseLine;
      progressCount = 0;
      var InputTabJson = InputTab.json;
      var natureza = InputTab.natureza;
      InputTab = InputTab.json.value[1].value[0];

      if (
        InputTab == null ||
        !this.declaracaoUnicaHelper.canEditDeclaration()
      ) {
        return;
      }
      progressCount +=
        this.validateProgressDropdownB(InputTab.value[6].value) ||
        this.validateProgressGeral(InputTab.value[1].value); // Entidade
      progressCount +=
        this.validateProgressDropdownB(InputTab.value[7].value) ||
        this.validateProgressGeral(InputTab.value[0].value); // Orgão
      progressCount +=
        this.validateProgressDropdownB(InputTab.value[8].value) ||
        this.validateProgressGeral(InputTab.value[2].value); //Cargo/Função
      progressCount +=
        this.validateProgressDropdownB(InputTab.value[4].value) ||
        this.validateProgressGeral(InputTab.value[5].value); // Titular
      progressCount += this.validateProgressGeral(InputTab.value[9].value); // Data de início de funções/recondução/ reeleição

      if (natureza == 4 || natureza == 8) {
        //cessão ou final
        progressTotalCount++;
        progressCount += this.validateProgressGeral(
          this.declaracaoUnicaHelper.getFieldByKey(
            InputTabJson,
            "5783eba6-4662-4e01-a1ab-14dbe7bb693c"
          ).value
        );
      }

      //card C01025P002S00-1440
      //é para deixar de validar
      progressCount += this.validateProgressDropdownB(InputTab.value[11].value); // Exercício de funções em regime de exclusividade
      // var exerciciosFuncoes = InputTab.value[11].value;
      // progressCount += this.validateProgressRadio(exerciciosFuncoes); // Exercício de funções em regime de exclusividade
      // if (exerciciosFuncoes != "0" && exerciciosFuncoes != null) {
      //   progressTotalCount++;
      //   var dadosRelativosAtividades = this.declaracaoUnicaHelper.getFieldByKey(
      //     this.declaracao.json,
      //     "2c6d9562-dea5-488b-9afe-42519c636daf"
      //   ).value;
      //   progressCount += dadosRelativosAtividades.length > 0;
      // }

      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b"
        ).value
      ); //nome  x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "54854c64-5845-40bb-a536-8c03da55420a"
        ).value
      ); //sexo x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e"
        ).value
      ); //NIC x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "d155af2b-21e9-475f-be81-8e42bc5a5641"
        ).value
      ); //NIF x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "a5bf96eb-8af0-4c05-bb76-a15f57f8490d"
        ).value
      ); //Naturalidade x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "25fa81a1-70d2-4fca-8292-4717ba2e8cdb"
        ).value
      ); //data nascimento x
      var estadoCivil = this.declaracaoUnicaHelper.getFieldByKey(
        InputTabJson,
        "ed9a8413-b9f0-4d32-89bd-03593c826211"
      ).value;
      if (estadoCivil == 1 || estadoCivil == 4 || estadoCivil == 5) {
        //casado x
        if (estadoCivil == 1) {
          progressTotalCount = progressTotalCount + 1;
          progressCount += this.validateProgressDropdownB(
            this.declaracaoUnicaHelper.getFieldByKey(
              InputTabJson,
              "ed9a8413-b9f0-4d32-89bd-03593c826212"
            ).value
          ); //propertyRegime x
        }
        progressCount += this.validateProgressGeral(
          this.declaracaoUnicaHelper.getFieldByKey(
            InputTabJson,
            "ed9a8413-b9f0-4d32-89bd-03593c826213"
          ).value
        ); //spouseName x
      } else {
        progressCount += this.validateProgressDropdownB(estadoCivil); //estado civil
      }
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "37b963d3-b73a-4da7-9a78-cb417f92214a"
        ).value
      ); //morada x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "38cffadd-0893-47ef-b261-84007c381639"
        ).value
      ); //codigoPostal x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "308ccc8a-452a-4ed7-94e5-5ca17a1667fe"
        ).value
      ); //localidade x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "77902677-0143-437c-ae9b-b3e54642a080"
        ).value
      ); // freguesia  x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "2b75bfd8-392e-49d7-866f-1353a5e765c3"
        ).value
      ); //concelho x
      progressCount += this.validateProgressGeral(
        this.declaracaoUnicaHelper.getFieldByKey(
          InputTabJson,
          "d3fd512b-65e8-4550-90d9-018a5ef91c0d"
        ).value
      ); //email x
      // progressCount += this.validateProgressGeral(
      //   this.declaracaoUnicaHelper.getFieldByKey(
      //     InputTabJson,
      //     "8b022aca-fdf8-4391-8d49-73abc4e63128"
      //   ).value
      // ); //telemovel x
      // progressCount += this.validateProgressGeral(     this.declaracaoUnicaHelper.getFieldByKey(
      //   InputTabJson,
      //   "67d71fd5-100a-42dd-a6a8-44d40ce2ba50"
      // ).value);//telefone x

      this.progress = Math.round((progressCount * 100) / progressTotalCount);
    });

    if (this.declaracao.draftId == null) {
      this.$router.push({ name: "ConsultarDeclaracoes" });
    } else {
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.$store.state.declaracao.json.value,
        "f649defc-e001-4866-9c2b-01ed9fa80eef",
        this.selectedLang.declarationNatureTypeEnum[this.declaracao.natureza]
      );

      this.loading = false;
    }
  },
  computed: {
    showTab1() {
      return this.selectedTab == 1;
    },
    showTab2() {
      return this.selectedTab == 2;
    },
    showTab3() {
      return this.selectedTab == 3;
    },
    showTab4() {
      return this.selectedTab == 4;
    },
    declaracaoTitle() {
      return `${this.selectedLang.declaration} ${
        this.selectedLang.declarationNatureTypeTitleEnum[
          this.$store.state.declaracao.natureza
        ]
      }`;
    },
  },
  methods: {
    async novaDeclaracao() {
      this.$store.state.declaracao.json =
        this.declaracaoUnicaHelper.newDeclaracao();
      this.$store.state.declaracao.estado = 1;
    },
    validateProgressDropdown(field) {
      return field != null && field > 1 ? 1 : 0;
    },
    validateProgressDropdownB(field) {
      return field != null && field >= 0 ? 1 : 0;
    },
    validateProgressGeral(field) {
      return field != null && field != "" ? 1 : 0;
    },
    validateProgressRadio(field) {
      return (field != null && (field == 1 || field == 0)) ||
        field == "1" ||
        field == "0"
        ? 1
        : 0;
    },
    goBack() {
      this.$router.push({ name: "ConsultarDeclaracoes" });
    },
    validateTab1() {},
    validateTab2() {},
  },
};
</script>

<style></style>
