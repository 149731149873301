<template>
  <div class="comment p-4">
    <div class="d-f mb-1">
      <IconPerson class="mr-1" />
      <div>
        {{ name }}
      </div>
      <div class="ml-auto">
        {{ $filters.formatDateTime(date) }}
      </div>
    </div>
    <div class="vHtml taj" v-html="props.text"></div>

    <div v-for="(attachment, index) in props.attachments" :key="index">
      <template v-if="attachment != null">
        <CustomInput
          @click="downloadFile(attachment.id)"
          :tooltipInfo="
            selectedLang.permitedFileType +
            '.doc,.docx,.pdf,.xlxs,.xls' +
            selectedLang.permitedFileSize
          "
          type="File"
          :label="null"
          :modelValue="props.attachments[index]"
          :showErrorMsg="false"
          :dashed="true"
          :disabled="true"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { defineProps } from "vue";
import { authentication } from "@/helpers";
import { attachmentService } from "@/services";
import { utils } from "@/helpers";

var props = defineProps({
  name: String,
  date: [Date, String],
  text: String,
  attachments: Array,
  attachmentFunction: Function,
});

var name = computed({
  get() {
    if (props.name == null) {
      let auth = authentication.get();
      return auth.name;
    } else return props.name;
  },
});
var date = computed({
  get() {
    if (props.date == null) {
      return new Date();
    } else return props.date;
  },
});

async function downloadFile(id) {
  if (props.attachmentFunction != null) {
    props.attachmentFunction(id);
    return;
  }
  var response = await attachmentService.get(id);

  if (response.code == 0) {
    utils.downloadFile(response.data);
  } else {
    utils.errorToast("error");
  }
}
</script>

<style scoped>
.taj {
  text-align: justify;
}
</style>
