<template>
  <div>
    <div class="row mb-2">
      <div class="col-12 ta-last-left">
        <span class="title">{{ selectedLang.assets }}</span>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <PatrimonioImobiliarioPortugal
          v-if="isPortugal"
          :guid="PatrimonioImobiliarioGuid"
          :declaracaoUnicaProp="PatrimonioImobiliario"
        />
        <PatrimonioImobiliarioEstrangeiro
          v-else
          :guid="PatrimonioImobiliarioGuid"
          :declaracaoUnicaProp="PatrimonioImobiliario"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <QuotasAcoesPortugal
          v-if="isPortugal"
          :guid="QuotasAcoesGuid"
          :declaracaoUnicaProp="QuotasAcoes"
        />
        <QuotasAcoesEstrangeiro
          v-else
          :guid="QuotasAcoesGuid"
          :declaracaoUnicaProp="QuotasAcoes"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <DireitosSobreVeiculosPortugal
          v-if="isPortugal"
          :guid="DireitosSobreVeiculosGuid"
          :declaracaoUnicaProp="DireitosSobreVeiculos"
        />
        <DireitosSobreVeiculosEstrangeiro
          v-else
          :guid="DireitosSobreVeiculosGuid"
          :declaracaoUnicaProp="DireitosSobreVeiculos"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <AplicacoesFinanceirasEquivalentes
          :guid="AplicacoesFinanceirasEquivalentesGuid"
          :declaracaoUnicaProp="AplicacoesFinanceirasEquivalentes"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <ContasBancariasAOrdem
          :guid="ContasBancariasAOrdemGuid"
          :declaracaoUnicaProp="ContasBancariasAOrdem"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <OutrosElementosPatrimonial
          :guid="OutrosElementosPatrimonialGuid"
          :declaracaoUnicaProp="OutrosElementosPatrimonial"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PatrimonioImobiliarioEstrangeiro from "./PatrimonioImobiliario-Estrangeiro.vue";
import PatrimonioImobiliarioPortugal from "./PatrimonioImobiliario-Portugal.vue";
import QuotasAcoesPortugal from "./QuotasAcoes-Portugal.vue";
import QuotasAcoesEstrangeiro from "./QuotasAcoes-Estrangeiro.vue";
import DireitosSobreVeiculosPortugal from "./DireitosSobreVeiculos-Portugal.vue";
import DireitosSobreVeiculosEstrangeiro from "./DireitosSobreVeiculos-Estrangeiro.vue";
import AplicacoesFinanceirasEquivalentes from "./AplicacoesFinanceirasEquivalentes.vue";
import ContasBancariasAOrdem from "./ContasBancariasAOrdem.vue";
import OutrosElementosPatrimonial from "./OutrosElementosPatrimonial.vue";

export default {
  components: {
    QuotasAcoesPortugal,
    QuotasAcoesEstrangeiro,
    DireitosSobreVeiculosPortugal,
    DireitosSobreVeiculosEstrangeiro,
    AplicacoesFinanceirasEquivalentes,
    ContasBancariasAOrdem,
    OutrosElementosPatrimonial,
    PatrimonioImobiliarioPortugal,
    PatrimonioImobiliarioEstrangeiro,
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  computed: {
    isPortugal() {
      return (
        this.declaracaoUnicaProp.key == "888d8680-77d1-40ef-8fac-d69586bf37ba"
      );
    },
    PatrimonioImobiliarioGuid() {
      return this.declaracaoUnicaProp.key ==
        "888d8680-77d1-40ef-8fac-d69586bf37ba"
        ? "112c6561-4a51-4f79-b637-bbd8d2c0c74c"
        : "252ba9e4-bbba-4660-be9e-470b263c4832";
    },
    QuotasAcoesGuid() {
      return this.declaracaoUnicaProp.key ==
        "888d8680-77d1-40ef-8fac-d69586bf37ba"
        ? "70fdd906-58d5-4682-967d-90ae90805436"
        : "cdeffda1-efd5-44c7-b256-1822dd5bafe0";
    },
    DireitosSobreVeiculosGuid() {
      return this.declaracaoUnicaProp.key ==
        "888d8680-77d1-40ef-8fac-d69586bf37ba"
        ? "53168cfb-433e-41e6-9b23-33c1eac7fa6f"
        : "05d989a9-7824-459d-aeb3-cc13f1f70c00";
    },
    AplicacoesFinanceirasEquivalentesGuid() {
      return this.declaracaoUnicaProp.key ==
        "888d8680-77d1-40ef-8fac-d69586bf37ba"
        ? "05d711d3-08cd-4944-8be9-dc5f40a201fc"
        : "79a46f97-b0e6-4ea7-a85a-e6430c625257";
    },
    ContasBancariasAOrdemGuid() {
      return this.declaracaoUnicaProp.key ==
        "888d8680-77d1-40ef-8fac-d69586bf37ba"
        ? "879401c9-2273-465a-91c7-7d8f080da762"
        : "35d426ec-2cac-440b-9482-9681c2eae8d4";
    },
    OutrosElementosPatrimonialGuid() {
      return this.declaracaoUnicaProp.key ==
        "888d8680-77d1-40ef-8fac-d69586bf37ba"
        ? "8a7cfdae-1d47-41f9-a366-5ba020c1b90f"
        : "ea846529-712c-4b47-953f-5b39015c4f17";
    },

    PatrimonioImobiliario: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.PatrimonioImobiliarioGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.PatrimonioImobiliarioGuid,
          newValue
        );
      },
    },
    DireitosSobreVeiculos: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.DireitosSobreVeiculosGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.DireitosSobreVeiculosGuid,
          newValue
        );
      },
    },
    AplicacoesFinanceirasEquivalentes: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.AplicacoesFinanceirasEquivalentesGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.AplicacoesFinanceirasEquivalentesGuid,
          newValue
        );
      },
    },
    QuotasAcoes: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.QuotasAcoesGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.QuotasAcoesGuid,
          newValue
        );
      },
    },
    ContasBancariasAOrdem: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasAOrdemGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasAOrdemGuid,
          newValue
        );
      },
    },
    OutrosElementosPatrimonial: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.OutrosElementosPatrimonialGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.OutrosElementosPatrimonialGuid,
          newValue
        );
      },
    },
  },
  mounted() {},
};
</script>
