export default {

  errorCode:{
    "-41":"O utilizador está desactivo",
    "-43":"O email já se encontra em uso.",
    "-50":"Erro ao atualizar o perfil.",
    "-80":"Titular não encontrado.",
    "-91":"Documento não encontrado",
    "-100": "Declaração não encontrada",
    "-101": "Declaração já existe",
    "-103": "Estado da declaração inválido",
    "-172": "O ficheiro já foi submetido.",
    "-173": "Existem erros no ficheiro, proceda à correção para efetuar o carregamento corretamente.",
    "-500": "Acesso negado",
    "-501": "Erro no recaptcha",
  },

  genericErrorMsg:"Ocorreu um erro. Por favor, entre em contato com o suporte técnico para obter assistência ou tente novamente mais tarde."  ,

  homePage: "Página Inicial",
  homePageWarningTxt: "A Plataforma Eletrónica da Entidade para a Transparência entrou em funcionamento recentemente. A informação disponível poderá ser limitada, enquanto decorrerem os prazos para submissão das declarações em formato eletrónico (conforme disposto no n.º 3 do artigo 25.º da Lei n.º 52/2019, de 31 de julho).",
  siteTitle: "Plataforma Eletrónica da Entidade para a Transparência",
  entityToTransparency: "Entidade para a Transparência",
  siteSubTitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus imperdiet sed id elementum.",
  siteFooter: "@2024",
  footerTitularMessage: "Pode consultar toda a informação sobre o processamento de dados pessoais pela Entidade para a Transparência",
  privateArea: "Área privada dos Titulares",
  privateAreaCrumb: "Área privado do titular",
  holders: "Titulares",
  commission: "Comissão",
  parliamentaryCommittee: "Comissão Parlamentar",
  email: "E-mail",
  add: "Adicione",
  password: "Palavra-passe",
  login: "Entrar",
  recoverPassword: "Recuperar palavra-passe",
  orTxt: "ou",
  atAuth: "AUTENTICAÇÃO AT",
  govAuth: "AUTENTICAÇÃO.GOV",
  serviceTerms: "Ao entrar, concordo com os ",
  serviceTermsHref: "Termos de Serviço.",
  passwordRecoverTitle: "Repor a palavra-passe",
  passwordRecoverBtn: "Enviar um e-mail de reposição da palavra-passe",
  homePageCard1Title: "Área privada",
  areaprivada: "Acesso à área privada",
  homePageCard1Txt:
    "Reservado a:\n   Titulares de cargos políticos, altos cargos públicos e equiparados sujeitos às obrigações declarativas constantes da Lei n.º 52/2019, de 31 de julho\n    Membros da Comissão Parlamentar da Transparência e Estatuto dos Deputados",
  homePageCard1Txtpt1:
    "Reservado a:",
  homePageCard1Txtpt2:
    "Titulares de cargos políticos, altos cargos públicos e equiparados sujeitos às obrigações declarativas constantes da Lei n.º 52/2019, de 31 de julho",
  homePageCard1Txtpt3:
    "Membros da Comissão Parlamentar da Transparência e Estatuto dos Deputados",
  homePageCard2Title: "Área privada do titular",
  homePageCard2Txt:
    "Reservado aos requerentes da consulta remota das declarações únicas",
  homePageTableTile: "Lista de titulares por entidade e cargo",
  publicConsult: "Acesso Público",
  publicConsultTxt: "Publicidade dos elementos relativos aos registos de interesses constantes das declarações únicas",
  filters: "Filtros",
  selectTxt: "Selecione",
  entityTxt: "Entidade/Órgão",
  organTxt: "Órgão",
  entity: "Entidade",
  role: "Cargo",
  entityPrompt: "Introduza a Entidade ou Orgão",
  organPrompt: "Introduza o Orgão",
  entityInsertPrompt:"Introduza a Entidade ",
  officeTxt: "Cargo",
  officeStart: "Início do Cargo",
  officeEnd: "Fim do Cargo",
  officePrompt: "Introduza o Cargo do titular",
  titleTxt: "Titular",
  titlePrompt: "Introduza o nome do titular",
  titleName: "Nome do titular",
  clear: "Limpar",
  apply: "Aplicar",
  noResultsText: "Não foram encontrados resultados",
  searchTxt: "Pesquisar",
  personalData: "Dados Pessoais",
  save: "Guardar",
  completeName: "Nome Completo",
  name: "Nome",
  sexTxt: "Sexo",
  femenine: "Feminino",
  masculine: "Masculino",
  perfil: "Perfil",

  datePickerSelectText: "Selecione",
  datePickerNowButtonLabel: "Hoje",
  datePickerLocale: "pt",
  datePickerPlaceholder: "dd-mm-aaaa",

  username: "Utilizador",
  NIC: "Número de Identificação Civil (NIC)",
  NIF: "Número de Identificação Fiscal (NIF)",
  naturalness: "Naturalidade",
  birthdate: "Data nascimento",
  household: "Morada",
  postalCode: "Código postal",
  location: "Localidade",
  parish: "Freguesia",
  county: "Concelho",
  mobilePhone: "Telemóvel",
  cellPhone: "Telefone",
  contacts: "Contactos",
  confirmPassword: "Confirmar a palavra-passe",
  maritalStatus: "Estado Civil",

  invalidValue: "Valor Inválido",

  consultDeclarations:"Consulta de declarações",

  declarationsTxt: "Declarações",
  newDeclarations: "Entregar Declaração",
  declarationId: "ID Declaração",
  nature: "Natureza",
  deliveryYear: "Ano de Entrega",
  state: "Estado",
  orderType: "Tipo de pedido",
  natureOptions: {
    0: "Inicial",
    1: "Alteração",
    2: "Cessação",
    3: "Final",
  },
  stateOptions: {
    0: "Rascunho",
    1: "Pendente de publicação",
    2: "Em curso de publicação",
    3: "Pendente de esclarecimento por parte do Titular",
    4: "Publicada",
    5: "Inválida",
    6: "Anulada",
  },
  declarationStateOptions: {
    0: "Rascunho",
    1: "Não publicada",
    2: "Publicada",
    4: "Pendente de publicação",
    8: "Anulada",
  },
  clarificationStateEnum: {
    0: "Rascunho",
    1: "Pendente",
    2: "Em curso",
    3: "Pendente",
    4: "Publicada",
    5: "Inválida",
    6: "Anulada",
  },
  declarationStateTypeEnum: {
    1: "Rascunho",
    2: "Pendente de Verificação",
    4: "Não publicada",
    8: "Publicada",
    16: "Anulada",
    32: "Alterada",
    64: "Substituída",
  },
  declarationNatureTypeEnum: {
    1: "Inicial",
    2: "Alteração",
    4: "Cessação",
    8: "Final",
    16: "Substituição",
  },
  orderTypeOptions: {
    0: "Pedido de oposição",
    1: "Pedido de esclarecimento",
    2: "Ambos",
  },
  declarations: "Declarações",
  deliverDate: "Data de entrega",
  relatedStatementId: "ID declaração relacionada",
  opposition: "Oposição",
  clarification: "Esclarecimento",

  toReplace: "Substituir",
  generateChangeStatement: "Gerar declaração de alteração",
  generateTerminationStatement: "Gerar declaração cessação",
  generateFinalStatement: "Gerar declaração final",
  singleDeclarationInitial: "Declaração única - Inicial",
  singleDeclaration: "Declaração única",

  progress: "Progresso",
  validate: "Validar",
  previous: "Anterior",
  continue: "Continuar",

  determiningFactDeclaration: "Facto determinante da declaração",

  declarationNature: "Natureza da declaração",
  category: "Categoria",
  organ: "Órgão",
  functionExercised: "Cargo/Função a exercer",
  functionInicialDate: "Data de início de funções/recondução/ reeleição",
  functionFinalDate: "Data de cessação de funções",
  functionYesNo: "Exercício de funções em regime de exclusividade",

  yes: "Sim",
  no: "Não",

  incomeHeritage: "Rendimentos e Património",
  workDependent: "Trabalho dependente",
  payingEntity: "Entidade pagadora",
  adding: "Adicionar",
  monetaryPrompt: "0,00€",
  percentPrompt: "0,00%",
  percent: " %",
  currency: " €",

  independentWork: "Trabalho independente",
  commercialIndustrial: "Comerciais e industriais",
  agricultural: "Agrícolas",
  capitals: "Capitais",
  building: "Prediais",
  gains: "Mais-valias",
  pensions: "Pensões",
  otherIncome: "Outros rendimentos",

  eliminateDependentWork: "Eliminar trabalho dependente",
  addDependentWork: "Adicionar trabalho dependente",
  editDependentWork: "Editar trabalho dependente",
  eliminate: "Eliminar",
  cancel: "Cancelar",

  assets: "Ativo Patrimonial",
  assetsPortugal: "Ativo Patrimonial Portugal",
  assetsAbroad: "Ativo Patrimonial Estrangeiro",
  realEstate: "Património Imobiliário",

  landRegistrationNumber: "Número de registo predial",
  goodSituation: "Situação do bem",
  matrizNumber: "Inscrição matricial (nº da matriz)",
  equityValue: "Valor patrimonial",
  equityValueEuro: "Valor patrimonial (em euros)",
  summaryDescription: "Descrição sumária",

  urban: "Urbana",
  rustic: "Rústica",
  mixed: "Mista",

  realEstateNatureEnum: {
    0: "Urbana",
    1: "Rústica",
    2: "Mista",
  },

  speciesAndTypeEnum: {
    5: "Obrigações",
    1: "Títulos do tesouro",
    2: "Certificados de aforro",
  },

  quotasShares:
    "Quotas, ações, participações ou outras partes sociais do capital de sociedades civis ou comerciais",
  quantity: "Quantidade",
  nominalValue: "Valor nominal",
  nominalValueEuro: "Valor nominal (em euros)",
  civilOrCommercialSociety: "Sociedade civil ou comercial",
  companyOrCorporateName: "Firma ou denominação social",
  nipc: "NIPC",
  headquarters: "Sede",
  establishmentDate: "Data de constituição",
  irregularSociety: "Sociedades irregulares",

  vehicleRights: "Direitos sobre barcos, aeronaves ou veículos automóveis",
  goodType: "Tipo do bem",
  registration: "Matrícula",
  brand: "Marca",
  class: "Classe",
  type: "Tipo",
  model: "Modelo",
  registrationNumber: "Número do registo",
  registrationYear: "Ano da Matrícula",
  engineCapacity: "Cilindrada",

  goodTypeEnum: {
    0: "Barco",
    1: "Aeronave",
    2: "Veículo automóvel",
  },

  equivalentFinancialInvestments:
    "Carteiras de títulos, Contas bancárias a prazo e Aplicações financeiras equivalentes",

  identificationOfTitles: "Identificação dos títulos",
  speciesAndType: "Espécie e tipo",
  issuingEntity: "Entidade emitente",
  stipulatedInterest: "Juro estipulado",
  financialInstitution: "Instituição financeira",
  walletNumber: "Número da carteira",

  amount: "Montante",
  amountEuro: "Montante (em euros)",
  depositaryEntity: "Entidade depositária",
  accountNumber: "Número da conta",
  depositDate: "Data do depósito",
  openDate: "Data de abertura",
  depositTerm: "Prazo do depósito",

  designation: "Designação",
  date: "Data",
  otherElements: "Outros elementos",

  currentBankAccounts:
    "Contas bancárias à ordem e direitos de crédito, de valor superior a 50 salários mínimos",

  debtorEntity: "Entidade devedora",
  dueDate: "Data do vencimento",

  securitiesPortfolios: "Carteiras de títulos",
  amountsDepositedAccounts: "Contas bancárias a prazo",
  financialInvestments: "Aplicações financeiras",
  currentBankAccountsTitle: "Contas bancárias à ordem",
  creditRights: "Direitos de crédito",

  otherElementsEquityAsset: "Outros elementos do ativo patrimonial",

  passive: "Passivo",
  passivePortugal: "Passivo Portugal",
  passiveAbroad: "Passivo Estrangeiro",
  propertyGuarantees: "Garantias patrimoniais",
  typeGuarantees: "Tipo/Natureza da garantia",

  creditorIdentification: "Identificação do credor",
  debtAmount: "Montante do débito",
  debtAmountEuro: "Montante do débito (em euros)",
  //dueDate:"Data do vencimento",
  share: "Quota-parte do Montante do débito",
  natureAndWarranty: "Débito a que se encontra associada",

  promiseEquityAdvantage: "Promessa de vantagem patrimonial futura",
  equity: "Patrimonial",

  promiseDate: "Data da promessa",
  expectedDateCompletion: "Data previsível da concretização",
  indicationTxt:
    "Indicação do facto que originou a alteração patrimonial, quando de valor superior a 50 vezes o salário mínimo nacional em vigor à data da declaração (campo de preenchimento obrigatório sempre que se verifique uma alteração com o impacto descrito)",

  otherStatements: "Outras declarações",
  otherStatementsFormField:
    "Área disponível para prosseguir, se for o caso, declarações relativas a qualquer dos campos anteriores",

  registrationInterests: "Registo de interesses",

  dataRelatedToActivities:
    "Dados relativos a atividades profissionais, cargos públicos, privados e sociais, e outras funções e atividades exercidos nos últimos três anos e/ou a exercer em acumulação ou exercidos até três anos após a cessação de funções",

  positionFunctionActivity: "Cargo/função/atividade",
  natureAndArea: "Natureza e área de atuação da entidade",
  headquartersLocation: "Local da sede",
  remunerated: "Remuneração",
  inicialDate: "Data de início",
  expiryDate: "Data de termo",

  membershipData:
    "Dados relativos a Filiação, participação ou desempenho de quaisquer funções em entidades de natureza associativa, exercidas nos últimos três anos e/ou a exercer em acumulação ou exercidos até três anos após a cessação de funções",

  supportBenefits: "Apoios ou benefícios",
  //entity:"Entidade",
  natureAreaActivityEntity: "Natureza e área de atuação da entidade",
  natureAreaActivitySupportBenefit:
    "Natureza e área de atuação do apoio ou benefício",

  servicesProvided: "Serviços prestados",

  serviceProvided: "Serviço prestado",

  societies: "Sociedades",
  society: "Firma ou denominação social da sociedade",
  socialParticipationValue: "Valor da participação social (em euros)",
  socialParticipationPercentage: "Percentagem da participação social",

  otherSituations: "Outras situações",
  description: "Descrição",
  remuneration: "Remuneração",
  comments: "Observações",

  otherSituationsDescription: {
    0: "Participação em conselhos consultivos, comissões de fiscalização ou outros organismos colegiais, quando previstos na lei ou no exercício de fiscalização e controlo de dinheiros públicos",
    1: "Realização de conferências, palestras, ações de formação de curta duração e outras atividades de idêntica natureza",
    2: "Participação em comissões de ou grupos de trabalho pela qual aufiram remuneração",
    3: "Participação em entidades sem fins lucrativos beneficiárias de recursos públicos",
    4: "Participação em associações profissionais ou representativas de interesses",
  },

  portugal: "Portugal",
  abroad: "Estrangeiro",

  newDeclaration: "Nova declaração",
  mustfill: "Deve preencher",
  newDeclarationQuestion1: "Iniciou agora funções numa nova entidade/órgão?",
  newDeclarationQuestion2: "Foi reconduzido no cargo que já empenhava?",
  newDeclarationQuestion3:"Ocorreu alguma alteração de património e/ou atos ou atividades que pretenda declarar?",
  newDeclarationQuestion4: "O seu cargo terminou",
  newDeclarationQuestion4Answer1: "recentemente?",
  newDeclarationQuestion4Answer2: "há mais de 3 anos?",

  attachments: "Anexos",
  attachmentsNotFound: "Não existem anexos.",
  attachmentsOptions: {
    0: "Facto determinante da declaração",
    1: "Dados pessoais, morada e contactos",
    2: "Rendimentos",
    3: "Ativo patrimonial",
    4: "Passivo",
    5: "Promessa de vantagem patrimonial",
    6: "Registo de interesses",
    7: "Outra",
  },
  maritalStatusOptions: {
    0: "Solteiro(a)",
    1: "Casado(a)",
    4: "Casado(a), mas separado(a) de pessoas e bens",
    5: "Unido(a) de facto",
    2: "Divorciado(a)",
    3: "Viúvo(a)",
  },

  informationBlock: "Bloco de informação",
  //comments:"Observações",
  attachment: "Anexo",
  fileName: "Nome do Ficheiro",
  size: "Tamanho",
  format: "Formato",
  records: "Registos",
  record: "Registo",
  requiredField: "Campo obrigatório",
  requiredFields: "Existem campos obrigatórios por preencher",
  invalidEmail: "E-mail inválido",
  invalidPassword: "Palavra-passe inválida",
  diferentPasswords: "Palavra-passe diferente da inserida anteriormente",

  optionsSelectedTxt: "opções selecionadas",
  optionSelectedTxt: "opção selecionada",

  deleteQuestion: "Tem a certeza que pretende eliminar?",
  deleteQuestionAnex: "Tem a certeza que pretende eliminar o anexo ",

  attachmentsModalAdd: "Adicionar Anexo",
  attachmentsModalEdit: "Editar Anexo",
  attachmentsModalDelete: "Eliminar Anexo",

  workDependentModalAdd: "Adicionar Trabalho dependente",
  workDependentModalDetail: "Detalhe Trabalho dependente",
  workDependentModalEdit: "Editar Trabalho dependente",
  workDependentModalDelete: "Eliminar Trabalho dependente",

  realEstateModalAdd: "Adicionar Património imobiliário",
  realEstateModalDetail: "Detalhe Património imobiliário",
  realEstateModalEdit: "Editar Património imobiliário",
  realEstateModalDelete: "Eliminar Património imobiliário",

  quotasSharesModalAdd: "Adicionar Quotas, ações ou outros",
  quotasSharesModalDetail: "Detalhe Quotas, ações ou outros",
  quotasSharesModalEdit: "Editar Quotas, ações ou outros",
  quotasSharesModalDelete: "Eliminar Quotas, ações ou outros",

  vehicleRightsModalAdd: "Adicionar Barco, aeronave ou veículo automóvel",
  vehicleRightsModalDetail: "Detalhe Barco, aeronave ou veículo automóvel",
  vehicleRightsModalEdit: "Editar Barco, aeronave ou veículo automóvel",
  vehicleRightsModalDelete: "Eliminar Barco, aeronave ou veículo automóvel",

  securitiesPortfoliosModalAdd: "Adicionar Carteira de título",
  securitiesPortfoliosModalDetail: "Detalhe Carteira de título",
  securitiesPortfoliosModalEdit: "Editar Carteira de título",
  securitiesPortfoliosModalDelete: "Eliminar Carteira de título",

  amountsDepositedModalAdd: "Adicionar Contas bancárias a prazo",
  amountsDepositedModalDetail: "Detalhe Contas bancárias a prazo",
  amountsDepositedModalEdit: "Editar Contas bancárias a prazo",
  amountsDepositedModalDelete: "Eliminar Contas bancárias a prazo",

  financialInvestmentsModalAdd: "Adicionar Aplicação financeira",
  financialInvestmentsModalDetail: "Detalhe Aplicação financeira",
  financialInvestmentsModalEdit: "Editar Aplicação financeira",
  financialInvestmentsModalDelete: "Eliminar Aplicação financeira",

  currentBankAccountsModalAdd: "Adicionar Conta bancária à ordem",
  currentBankAccountsModalDetail: "Detalhe Conta bancária à ordem",
  currentBankAccountsModalEdit: "Editar Conta bancária à ordem",
  currentBankAccountsModalDelete: "Eliminar Conta bancária à ordem",

  creditRightsModalAdd: "Adicionar Direitos de crédito",
  creditRightsModalDetail: "Detalhe Direitos de crédito",
  creditRightsModalEdit: "Editar Direitos de crédito",
  creditRightsModalDelete: "Eliminar Direitos de crédito",

  otherElementsTheEquityAssetModalAdd:
    "Adicionar Outros elementos do ativo patrimonial",
  otherElementsTheEquityAssetModalDetail:
    "Detalhe Outros elementos do ativo patrimonial",
  otherElementsTheEquityAssetModalEdit:
    "Editar Outros elementos do ativo patrimonial",
  otherElementsTheEquityAssetModalDelete:
    "Eliminar Outros elementos do ativo patrimonial",

  passiveModalAdd: "Adicionar Passivo",
  passiveModalDetail: "Detalhe Passivo",
  passiveModalEdit: "Editar Passivo",
  passiveModalDelete: "Eliminar Passivo",

  propertyGuaranteesModalAdd: "Adicionar Garantia patrimonial",
  propertyGuaranteesModalDetail: "Detalhe Garantia patrimonial",
  propertyGuaranteesModalEdit: "Editar Garantia patrimonial",
  propertyGuaranteesModalDelete: "Eliminar Garantia patrimonial",

  equityModalAdd: "Adicionar promessa de vantagem patrimonial",
  equityModalDetail: "Detalhe promessa de vantagem patrimonial",
  equityModalEdit: "Editar promessa de vantagem patrimonial",
  equityModalDelete: "Eliminar promessa de vantagem patrimonial",

  otherStatementsModalAdd: "Adicionar Outra declaração",
  otherStatementsModalEdit: "Editar Outra declaração",
  otherStatementsModalDelete: "Eliminar Outra declaração",

  dataRelatedProfessionalActivitiesModalAdd:
    "Adicionar dados relativos a atividades profissionais",
  dataRelatedProfessionalActivitiesModalDetail:
    "Detalhe dados relativos a atividades profissionais",
  dataRelatedProfessionalActivitiesModalEdit:
    "Editar Dados relativos a atividades profissionais",
  dataRelatedProfessionalActivitiesModalDelete:
    "Eliminar Dados relativos a atividades profissionais",

  membershipDataModalAdd: "Adicionar Dados relativos a filiação",
  membershipDataModalDetail: "Detalhe Dados relativos a filiação",
  membershipDataModalEdit: "Editar Dados relativos a filiação",
  membershipDataModalDelete: "Eliminar Dados relativos a filiação",

  supportBenefitsModalAdd: "Adicionar Apoio ou benefício",
  supportBenefitsModalDetail: "Detalhe Apoio ou benefício",
  supportBenefitsModalEdit: "Editar Apoio ou benefício",
  supportBenefitsModalDelete: "Eliminar Apoio ou benefício",

  servicesProvidedModalAdd: "Adicionar Serviço prestado",
  servicesProvidedModalDetail: "Detalhe Serviço prestado",
  servicesProvidedModalEdit: "Editar Serviço prestado",
  servicesProvidedModalDelete: "Eliminar Serviço prestado",

  companiesModalAdd: "Adicionar Sociedade",
  companiesModalDetail: "Detalhe Sociedade",
  companiesModalEdit: "Editar Sociedade",
  companiesModalDelete: "Eliminar Sociedade",

  otherSituationsModalAdd: "Adicionar Outra situação",
  otherSituationsModalDetail: "Detalhe Outra situação",
  otherSituationsModalEdit: "Editar Outra situação",
  otherSituationsModalDelete: "Eliminar Outra situação",

  declarationTypeEnum: {
    1: "Nova declaração",
    2: "Declaração de alteração",
    4: "Declaração de cessação",
    8: "Declaração final",
    16: "Declaração de substituição",
  },
  filePlaceholder: "Clique aqui para descarregar o ficheiro",

  listing: "Listagem",
  declarationListing: "Lista das Declarações",
  holderDetail: "Detalhe de titular",
  makeRequest: "Efetuar requerimento",
  tooltipMakeRequest: "Para solicitar o acesso à(s) declaração(ões) dos titulares é necessário que estas sejam previamente selecionadas",
  entityOther: "Outra Entidade",
  roleOther: "Outro Cargo/Função",
  boardOther: "Outro Órgão",
  holderOther: "Outro Titular",

  invalidFileFormat:
    "Formato inválido. Adicione ficheiros do tipo .xls, .xlsx, .doc, .docx ou .pdf.",
  submit: "Submeter",
  seclaracaoUnicaSubmitModalWarning:
    "Ao submeter a declaração confirma que todas as secções não preenchidas, serão marcadas como “Nada a declarar”.",

  sectionList: "Lista de secções",
  nothingDeclare: "Nada a declarar",
  blockedContent:
    "É necessário pedir um requerimento para consultar esta informação.",
  blockedData: "Dados não passíveis de consulta",

  roleInicialDate: "Início do Cargo",
  roleFinalDate: "Fim do Cargo",
  invalidDeclaration: "Existem campos inválidos ou por preencher.",
  invalidDeclarationTitle: "Declaração inválida",

  LoginFailed: "Utilizador ou palavra-passe inválido.",
  LoginUserBlocked: "Excedeu o número de tentativas de login. Faça recuperação de password.",
  HolderNotFound: "Titular não encontrado.",
  DeclarationErrorOnDeserialize: "Error ao tentar ler a declaração.",
  propertyRegime: "Regime de Bens",
  spouseName: "Nome do cônjuge ou unido/a de facto",
  InternalServerError: "Ocorreu um erro interno.",
  ERR_NETWORK: "Não foi possível fazer a ligação à API de serviços.",
  declarationOpositionListing: "Listagem de Pedidos",
  newRequest: "Novo pedido",
  requestDate: "Data do pedido",
  declarationOppositionRequestStatusEnum: {
    1: "Em apreciação",
    2: "Deferido",
    3: "Indeferido",
    4: "Deferido parcialmente",
    5: "À espera de resposta por parte do titular",
  },
  reason: "Motivo",
  declaration: "Declaração",
  oppositionRequest: "Pedido de oposição",
  requestForClarification: "Pedido de esclarecimento pela EpT",
  newDeclarationOppositionRequestCreated: "Pedido de oposição {0} enviado com sucesso.",
  requestForClarificationResponse: "Resposta ao pedido de esclarecimento",
  oppositionRequestSectionItemNotSelected: "É necessário selecionar pelo menos um item ou mais itens das secções. ",
  requestForClarificationDontExist: "Não existem pedidos de oposição associados a esta declaração.",
  newDeclarationOppositionRequestUploadError: "Não foi possível criar o Pedido de oposição, ocorreu um erro ao tentar guardar o anexo.",
  newDeclarationRequestUploadError: "Não foi possível criar o rascunho da Declaração, ocorreu um erro ao tentar guardar o anexo.",
  invalidJsonDeclaration: "A declaração está inválida ou corrompida.",


  declarationNatureTypeTitleEnum: {
    1: "inicial",
    2: "de alteração",
    4: "de cessação",
    8: "final",
    16: "de substituição",
  },

  declarationDetail:"Detalhe da declaração",
  resultsNotFound:"Não encontrámos resultados para a sua pesquisa",
  resultsNotFoundTitularDetails:"A Plataforma Eletrónica da Entidade para a Transparência contém declarações que foram submetidas em formato eletrónico através deste sistema (após a sua entrada em funcionamento)",
  pedidoOpsicaoEmpty:"Não existem pedidos de oposição nesta declaração",
  pedidoOpsicaoEmptyMsg:'Se deseja fazer um pedido de oposição, clique no botão "Pedido de oposição".',
  pedidoEsclarecimentoEmpty:"Não existem pedidos de esclarecimento nesta declaração",
  hidedHomePageTable:"Efetue a sua pesquisa utilizando os filtros disponíveis",
  newOppositionRequest: "Novo Pedido de Oposição",
  
  clarificationResponse:"Resposta de esclarecimento",
  clarificationDetail:"Detalhe do pedido",
  EpTUser:"Utilizador da EpT",
  commentstxt:"Comentários",
  newComments:"nova resposta",
  
  title:"Título",

  declarationDraftSaved: "O rascunho da declaração foi guardado.",
  oppositionReqeuestDetail: "Detalhe do Pedido de Oposição",
  electronicEmail: "Correio eletrónico",
  accessCode: "Chave de consulta",
  electronicEmailOrQueryKeyInvalid: "E-mail e/ou chave de consulta inválidos.",
  infoRequest: "Informações sobre o requerimento",
  fundamentation: "Fundamentação",
  requirementCodeExpired: "Período de consulta para a chave {0} expirado.",
  novaDeclaracaoCessacaoText: "Outras não apresentadas na plataforma",
  relatedDocument: "Declaração Relacionada",

  requirementDeclarationStateTypeEnum: {
    1: "Em apreciação",
    2: "Deferido",
    3: "Concluído",
    4: "Indeferido",
    5: "Aguardar pronúncia do requerente",
  },
  send: "Enviar",
  consultHolders: "Consultar Titulares",
  results: "Resultados",
  sendRequest: "Enviar Pedido",
  validationCode: "Código de Validação",
  bank: "Banco",
  validationCodeTitle: "Por favor, verifique as suas mensagens e insira o código.",
  validationCodeSubtitle: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  code: "Código",
  holdersCommunication: "Comunicação de Titulares",
  loadFilledElectronicForm: "Carregar formulário eletrónico preenchido",
  clickHereDownloadFile: "Clique aqui para descarregar o ficheiro",
  electronicForm: "Formulário eletrónico",
  close: "Fechar",
  attachmentList: "Lista de Ficheiros",
  consultRequirement: "Consultar pedidos de requerimento",
  yearDelivery: "Ano da entrega",
  requirementRequestList: "Lista de pedidos de requerimento",
  requirementCode: "Código Requerimento",
  requirementDate: "Data do requerimento",
  requirementFundamentation: "Fundamentação do requerimento",
  requirementRequests: "Pedidos de Requerimento",
  requirementRequest: "Pedido de Requerimento",
  requirementList: "Lista de Requerimentos",
  
  selectedDeclarationsResume: "Resumo das declarações selecionadas",
  selectedDeclarations: "Declarações selecionadas",

  fundamentationRequirement: "Fundamentação do requerimento",
  requirementDescription: "Fundamentação do requerimento",
  
  justifyDocumentList: "Lista de documentos justificativos",
  makeRequestErrorDocuments: "Erro ao tentar fazer o upload dos anexos",
  makeRequestError: "Erro ao tentar submeter o requerimento",
  makeRequestDocumentsRequiredMsg: "É necessário adicionar pelo menos um documento",
  makeRequestSucessPt1: "Pedido requerimento ",
  makeRequestSucessPt2: " enviado com sucesso. Consulte o e-mail para mais informação.",
  verificationCode: "Código de verificação",
  resend: "Reenviar",
  RequirementNotFound:"Código Inválido",
  accountEmailNotFoundTitular: "O e-mail indicado não é válido. Efetue o registo via Autenticação.gov, Autoridade Tributária ou solicite o mesmo junto da EpT.",
  accountEmailNotFoundCommissioner: "O e-mail não é válido. Solicite o registo do mesmo junto da Entidade para a Transparência.",
  accountEmailSended: "Foi enviado um e-mail para a alteração da palavra-passe.",
  loginPage:"Login",
  cantConsultDeclaration:"Não é possível consultar esta declaração",
  defineNewPassword: "Escolha uma nova palavra-passe",
  actualPassword: "Palavra-passe atual",
  newPassword:"Insira uma nova palavra-passe",
  invalidConfirmPassword: "Palavras-passe diferem entre si.",
  userResetTokenNotFound: "Token inválido",
  userResetTokenExpired: "O token já expirou.",
  updatePassword: "Alterar palavra-passe",
  updatedPassword: "Palavra-passe alterada com sucesso.",
  passwordNotRequirements: "Palavra-passe não cumpre os requisitos.",
  tooltipOppositionRequest : "Foi solicitado um pedido de oposição à consulta pública por parte do titular que, de momento, está em análise por parte da Ept. Por este motivo, a declaração não poderá ser consultada.",
  observations: "Observações",
  observation: "Observação",
  deleteDeclaration: "Eliminar declaração",
  activationCommunicationArea: "Ativação da área de comunicação",
  entityCollaborationNotFound: "Código de ativação inválido.",
  entityCollaborationInvalidState: "O ficheiro já submetido.",
  entityCollaborationHolderListInvalid: "Existem erros no ficheiro, proceda à correção para efetuar o carregamento corretamente.",
  fileSentSuccessfully:"Ficheiro submetido com sucesso.",
  DeclarationClarificationRequestNotFound: "Código inválido",
  emailResendSucssess: "Email reenviado",
  emailResendError: "Erro ao enviar email",
  deleteDeclarationTooltip:"Ao clicar, a declaração será removida do requerimento",
  notificationsNotFound: "Ainda não existem notificações.",
  notificationReadSuccess: "Notificação marcada como lida.",
  notificationDeleteSuccess: "Notificação eliminada com sucesso.",
  invalidFileFormatForCodeValidation:"Formato de ficheiro inválido. Deverá carregar um ficheiro do tipo .xlsx.",
  invalidFileSize: "O ficheiro selecionado ultrapassa o tamanho máximo (5 Mb).",
  system:"Sistema",
  emailAndSystem: "E-mail/Sistema",
  configurations: "Configurações",
  application: "Aplicação",
  savedConfigurations: "Configurações guardadas com sucesso.",
  notifications: "Notificações",
  notification: "Notificação",
  preferredCommunicationChannel: "Canal de comunicação preferencial",
  requiredPreferredCommunicationChannel: "Selecione, pelo menos, um canal de comunicação para cada notificação",
  notificationNotFound: "Notificação não encontrada.",
  read: "Lida",
  unread: "Não lida",
  submitDeclarationSucssess: "Declaração submetida com sucesso",
  submitDeclarationError: "Erro ao submeter declaração",
  declarationAlreadyExists: "Declaração já existe",
  form: "Formulário",
  attachedSupportingDocuments: "Documentos anexos justificativos",
  notExistsFile: "Não existem ficheiros.",
  passwordRequirements: "A nova palavra-passe deverá ter no mínimo 8 caracteres e conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 símbolo e 1 número.",
  wrongPassword: "A palavra-passe atual é inválida.",
  invalidVerificationCode:"Código inválido. Tente novamente.",
  sender: "Remetente",
  newPasswordLabel: "Nova palavra-passe",
  delete: "Eliminar",
  actions: "Ações",
  notificationSettings: "Definições das notificações",
  invalidFileFormatForCodeValidationWordPdf:"Formato do ficheiro inválido. Adicione ficheiros do tipo .docx ou .pdf.",
  invalidFileFormatForCodeValidationExcel:"Formato do ficheiro inválido. Adicione ficheiros do tipo .xlsx.",

  declarationClarificationRequestStatusEnum: {
    1: "Aguardar resposta do titular",
    2: "Em validação",
    3: "Fechado",
    4: "Fechado",
  },

  fileUploadPlaceholder: "Clique aqui para fazer upload do ficheiro",

  fileUploadPlaceholderShort: "Clique aqui ou arraste",

  markAsRead:"Marcar como lida",
  newPasswordBtn:"Definir nova palavra-passe",
  perfilUpdatedError:"Erro ao atualizar os Dados pessoais",
  perfilUpdated:"Dados pessoais atualizados com sucesso",
  perfilCreated:"Dados pessoais criados com sucesso",
  passwordUpdated:"Palavra-passe atualizada com sucesso",
  passwordUpdatedError:"Erro ao atualizar a palavra-passe",
  userAlreadyExists: "O utilizador já se encontra em uso.",
  UserAlreadyExists: "O utilizador já se encontra em uso.",
  decrarationRestrictReasonEnum:{
    '-1':"É necessário pedir um requerimento para consultar esta informação.",
    0: "Dados não passíveis de consulta",
    1: "Dados cujo acesso pressupõe a apresentação e o deferimento de requerimento de consulta",
    2: "Dados indisponíveis por pedido de oposição por parte do titular",
    3: "Dados indisponíveis devido a sigilo profissional",
    4: "Dados cujo acesso pressupõe a apresentação e o deferimento de requerimento de consulta",
  },
  userPrivateDataError: "Erro ao tentar carregar as informações privadas do utilizador.",
  submitDeclaration: "Submeter declaração",
  noSectionFill: "Nenhuma secção por preencher",
  loginNotEnabledTitle: "Acesso temporariamente indisponível",
  loginNotEnabledTxt: "Caro Utilizador,\nPor via de um processo de manutenção de sistema, a funcionalidade de login encontra-se inibida. Por favor, tente mais tarde.\n\nObrigado,\nEntidade para a Transparência",
  UserNotActive: "Utilizador não ativo",
  detail: "Detalhe",
  perfilCreatedError: "Erro ao tentar criar o utilizador",
  decision: "Decisão",

  propertyRegimeEnum:{
    0:"Regime da comunhão de adquiridos",
    1:"Regime de Separação",
    2:"Regime da comunhão geral",
  },

  invalidActivities: 'Ao ter selecionado "Exercício de funções em regime de exclusividade" = Sim deverá, obrigatoriamente, preencher a primeira tabela do separador n.º 4 "Registo de interesses".',
  validateAndSaveDraf: "Declaração guardada e validada com sucesso.", 
  saveDraft: "Guardar rascunho",
  codeAccessEmailSended: "Foi enviado um e-mail com o código de verificação.",
  getLastQuention: "Pretende pré-preencher a declaração com os dados da última declaração entregue?",
  requirementId: "Requerimento",
  validationCodeAccordionTitle: "Passos para criação de titulares por parte da Entidade",
  validationCodeAccordionText: "Este link foi criado para que as Entidades onde estão inseridos Titulares possam, através da submissão de um ficheiro excel, solicitar a criação de novos Titulares. Desta forma, os passos para que o façam são os seguintes:\n\b\b\b\b\b1. Na página de validação (próxima) introduzir o código de validação fornecido pela EpT e clicar em Validar.\n\b\b\b\b\b2. No ecrã seguinte, terá a possibilidade de exportar o template que servirá de base para a adição de Titulares em massa.\n\b\b\b\b\b3. Depois de preenchido o ficheiro corretamente* poderá efetuar o carregamento do ficheiro no campo de upload (localizado mais à direita no ecrã).\n\b\b\b\b\b4. Para concluir, o utilizador deverá clicar em \"Submeter\".\n\nPosteriormente ao envio, a EpT irá validar os respetivos titulares.",
  expiredActivationCode: "Código de ativação expirado",
  location2: "Localização",
  update: "Atualizar",
  updateDeclaration: "Atualizar declaração",
  updateQuestion: "A declaração irá ser atualizada com base nas alterações que está prestes a submeter. Pretende continuar? ",
  
  declarativeMomentTypeIdEnum:{
    1:"Inicial",
    4:"Cessação",
    8:"Final",
  },
  annulDeclaration: "Anular a declaração",
  annulDeclarationSucess: "Declaração anulada com sucesso",
  annul: "Anular",
  annulDeclarationQuestion: "Tem a certeza que pretende anular a declaração?",
  legalNotice:"AVISO LEGAL:",
  declarationLegalNotice:"a consulta de elementos relativos a rendimentos e património ou passivo dos declarantes encontra-se sujeita a regras legais cuja inobservância pode gerar responsabilidade civil ou criminal, encontrando-se vedada, nomeadamente, a reprodução desses elementos",
  professionalSecrecy:"Sigilo profissional",
  RecaptchaFailed:"Erro no recaptcha",
  euroInformation:"Devem ser utilizadas as cotações oficiais publicadas pelo Banco de Portugal (e disponíveis na respetiva página oficial na Internet), vigentes à data do facto determinante da entrega da declaração",
  NIFNIPC:"NIF/NIPC",
  invalidNIF:"NIF/NIPC inválido",
  incomeHeritageOposition: "Rendimentos brutos constantes da última declaração apresentada para efeitos da liquidação do imposto sobre o rendimento das pessoas singulares",
  PatrimonioImobiliario:"Património imobiliário",
  OutrosElementosAtivoPatrimonial:"Outros elementos do ativo patrimonial",
  ContasBancariasOrdemDireitosCredito:"Contas bancárias à ordem e direitos de crédito, de valor superior a 50 salários mínimos",
  CarteirasTitulosContasBancariasPrazo:"Carteiras de títulos, contas bancárias a prazo e aplicações financeiras equivalentes",
  DireitosSobreBarcosAeronavesVeiculosAutomoveis:"Direitos sobre barcos, aeronaves ou veículos automóveis",
  QuotasAcoesParticipacoesOutras:"Quotas, ações, participações ou outras partes sociais de sociedades civis ou comerciais",
  designationPayingEntity:"Designação da entidade pagadora",
  countryISOCode:"Estado/País (nome e Código ISO 3166-1 alfa3)",
  incomeEarnedAbroad:"Rendimento obtido no estrangeiro?",
  taxIdentification:"Tax identification number ou equivalente",
  district:"Distrito",
  registrationNumberEquivalent:"Número de registo ou equivalente",
  assetLocation:"Localização do bem [Estado/País (nome e Código ISO 3166-1 alfa3), região e cidade]",
  landRegistry:"Conservatória de Registo Predial",
  landRegistryPlaceholder:"Insira nome da Conservátória",
  underRegistrationNumber:"Número de registo",
  matrizNumberTooltip:"Tratando-se de um prédio rústico, deverá adicionalmente identificar, a respetiva Secção.",
  summaryDescriptionTooltip: "Tratando-se de um prédio rústico, a descrição sumária deverá conter as confrontações, as parcelas e a área total (em ha). Tratando-se de um prédio urbano, a descrição sumária deve conter a afetação, a tipologia, a área do terreno integrante, a área bruta privativa e a área bruta dependente (se e quando aplicáveis) e a morada. Nos casos em que o imóvel coincida com a residência indicada no separador 1 da presente declaração, o declarante não deve incluir, neste campo, a morada, considerando que o mesmo será disponibilizado aquando de uma consulta.",
  summaryDescriptionTooltipEstrangeiro: "A descrição sumária deve conter a tipologia, a afetação, a área, e a morada ou as confrontações.",
  
  socialParticipationDesignation:"Designação da participação social (na língua original)",
  societyTypology:"Tipologia da sociedade (na língua original)",
  shareCapitalPercentage:"Percentagem do capital social",
  shareAssets:"Quota do património",
  //companyOrCorporateName: "Firma ou denominação social",
  HeadquartersISO:"Sede [Estado/País (nome e Código ISO 3166-1 alfa3)]",
  declarantIsTheHolder:"O declarante é titular:",
  declarantIsTheHolderOption1:"por si",
  declarantIsTheHolderOption2:"por interposta pessoa coletiva ou singular (incluindo situações em que a/o declarante é apenas beneficiário efetivo)",
  legaOrNaturalPerson:"Indique a pessoa coletiva ou singular",
  oneOrOnotherInfo:"Pelo menos um dos 2 campos 'Percentagem do capital social' ou 'Quota do património' é de preenchimento obrigatório",
  
  monetaryValue:"Valor monetário",
  
  buildingNature:"Natureza do direito incidente sobre o prédio",
  buildingNatureOption1:"Propriedade",
  buildingNatureOption2:"Usufruto",
  buildingNatureOption3:"Direito de superfície",
  buildingNatureOption4:"Direito real de habitação duradoura",
  buildingNatureOption5:"Direito real de habitação periódica",
  buildingNatureOption6:"Direito emergente de contrato de arrendamento",
  buildingNatureOption7:"Direito emergente de comodato",
  buildingNatureOption8:"Posse",
  buildingNatureOption9:"Detenção",
  buildingNatureOption10:"Uso e Habitação",
  buildingOwnership:"Titularidade do direito incidente sobre o prédio",
  buildingOwnershipOption1:"Titular único",
  buildingOwnershipOption2:"Cotitular",
  depositaryEntityNIF:"NIF/NIPC da Entidade depositária",
  debtorEntityNIF:"NIF/NIPC da Entidade devedora",
  passiveInfo:"Devem ser registados nesta rubrica todos os débitos em relação ao Estado ou quaisquer pessoas singulares ou coletivas, nomeadamente a instituições de crédito e a quaisquer empresas, públicas ou privadas",
  creditorDesignation:"Designação do credor",
  creditorNIF:"NIF/NIPC do credor",
  indicationOfTheFact:"Indicação do facto que originou a alteração patrimonial, quando de valor superior a 50 vezes o salário mínimo nacional em vigor à data da declaração (campo de preenchimento obrigatório sempre que se verifique uma alteração com o impacto descrito)",
  supportBenefitsInfo:"Devem ser registados nesta rubrica todos e quaisquer apoios financeiros ou materiais recebidos para o exercício das atividades, inclusivamente de entidades estrangeiras, designadamente senhas de presença e ajudas de custo (e que não correspondam a remuneração, visto que, a existir, esta é identificada numa das duas rubricas anteriores)",
  
  recipientSupportBenefit:"Destinatário do apoio ou benefício",
  recipientSupportBenefitOption1:"Declarante",
  recipientSupportBenefitOption2:"Cônjuge",
  recipientSupportBenefitOption3:"Pessoa com quem viva em união de facto",
  recipientSupportBenefitOption4:"Sociedade em cujo capital participem o declarante, ou o cônjuge, a pessoa com quem o declarante vive em união de facto",
  companyName:"Denominação social ou firma da sociedade",
  servicosPrestadosInfo:"Consideram-se abrangidas nesta rubrica as entidades, e respetiva área de atuação, a quem a/o declarante preste pessoalmente serviços remunerados de qualquer natureza, com caráter de permanência ou mesmo pontualmente, desde que suscetíveis de gerarem conflitos de interesses",
  
  natureOption1:"Quotas",
  natureOption2:"Ações",
  natureOption3:"Outras partes sociais",
  natureOptionEnum:{
    1:"Quotas",
    2:"Ações",
    3:"Outras partes sociais",
  },
  outrasSituacoesInfo:"Devem ser registados nesta rubrica as situações que, não se integrando em qualquer das rubricas anteriores, sejam suscetíveis de gerar incompatibilidades ou impedimentos previstos na lei",
  
  societyType:"Tipo sociedade",
  societyTypeOption1:"Sociedade em nome coletivo",
  societyTypeOption2:"Sociedade em comandita simples",
  societyTypeOption3:"Sociedade em comandita por ações",
  societyTypeOption4:"Sociedade civil sem forma comercial",
  companyFirm:"Firma da sociedade",
  companyNIF:"NIF/NIPC da sociedade",
  societyTypeTypeOption1:"Ações",
  societyTypeTypeOption2:"Outras participações sociais",
  sharesNumber:"Quantidade de participações sociais",
  corporateName:"Denominação social",

  registerNumber:"Número de registo",
  shipName:"Nome do navio ou embarcação",
  manufacturerBrand:"Fabricante/marca",
  intendedPurpose:"Fim a que se destina",
  constructionYear:"Ano da construção",
  mainDimensions:"Dimensões principais",
  engineCharacteristics:"Características do motor propulsor",
  grossTonnage:"Arqueação bruta",
  netTonnage:"Arqueação líquida",
  motivePower:"Potência motriz",
  aircraftType:"Tipo de aeronave",
  carRegistrationNumber:"Número da matrícula",
  maximumWeight:"Peso máximo autorizado à descolagem, tal como constante do certificado de aeronavegabilidade",
  enrollmentYear:"Ano de matrícula",
  cisplacement:"Cilindrada (em cm3)",
  maximumUsefulPower:"Potência útil máxima (em kW)",
  nationalCategory:"Categoria nacional",
  vehicleType:"Tipo de veículo",
  manufactureYear:"Ano de fabrico",
  
  natureApplicableGood:"Natureza do direito incidente sobre o bem",
  natureApplicableGoodOptions:{
    0:"Propriedade",
    1:"Usufruto",
    2:"Direito emergente de contrato de locação",
    3:"Direito emergente de comodato",
    4:"Posse",
    5:"Detenção",
  },
  buildingOwnershipRight:"Titularidade do direito incidente sobre o prédio",
  buildingOwnershipRightOptions:{
    0:"Titular único",
    1:"Cotitular",
  },
  flagState:"Estado da bandeira (nome e Código ISO 3166-1 alfa3)",
  enginePropulsion:"Caraterísticas do motor propulsor",
  registrationCountry:"Estado/País da matrícula (nome e Código ISO 3166-1 alfa3)",
  takeOffMaximumWeight:"Peso máximo autorizado à descolagem",
  typeOrNature:"Tipo ou natureza dos elementos do ativo patrimonial",
  valueAtTheDate:"Valor à data da declaração (em euros)",

  portfolioDesignation:"Designação da carteira",
  portfolioType:"Espécie e tipo dos títulos constitutivos da carteira",
  portfolioValue:"Valor da carteira à data da declaração (em euros)",
  financialInstitutionName:"Designação da instituição financeira associada",
  financialInstitutionNIF:"NIF/NIPC da instituição financeira associada",
  securitiesPortfolioNumber:"Número da carteira de títulos (se aplicável)",
  ownership:"Titularidade",
  ownershipOptions:{
    1:"Titular único",
    2:"Cotitular",
  },
  financialInvestmentValue:"Valor da aplicação financeira à data da declaração (em euros)",
  
  societiesNatureOptions:{
    1:"Sociedade civil",
    2:"Sociedade comercial",
  },
  occupationArea:"Área de atuação",
  buildingOwnershipOptions:{
    1:"Titular único",
    2:"Cotitular",
  },
  dataRelatedToActivitiesInfo:"Devem ser registados nesta rubrica:\nToda e qualquer atividade pública ou privada que a/o declarante exerça, ou tenha exercido nos últimos três anos e/ou que venha a exercer em acumulação com o mandato, ou que tenha exercido até três anos após a cessação de funções, incluindo atividades profissionais subordinadas, comerciais ou empresariais, exercício de profissão liberal, desempenho de funções eletivas;Desempenho de cargos sociais que a/o declarante exerça, ou tenha exercido nos últimos três anos e/ou que venha a exercer em acumulação com o mandato, ou que tenha exercido até três anos após a cessação de funções, designadamente a discriminação dos cargos de administrador, gerente, gestor, diretor, membro de comissão administrativa, conselho fiscal e comissão de fiscalização, membro de mesa de assembleia-geral ou de órgãos ou cargos análogos, de quaisquer sociedades comerciais, sociedades civis sob forma comercial, cooperativas, e também de associações, fundações, instituições particulares de solidariedade social, misericórdias e semelhantes, tanto nacionais como estrangeiras",
  membershipDataInfo:"Devem ser registados nesta rubrica a filiação, participação ou desempenho de quaisquer funções em quaisquer entidades de natureza associativa, exercidas nos últimos três anos ou a exercer cumulativamente com o mandato ou exercidos até três anos após a cessação de funções. É facultativa a menção da filiação, participação ou desempenho de quaisquer funções em quaisquer entidades de natureza associativa, quando seja suscetível de revelar dados constitucionalmente protegidos, como sejam os relativos à saúde, orientação sexual, filiação sindical ou convicções religiosas ou políticas",
  declarationNumber:"Número",
  permitedFileType:"formatos válidos: ",
  permitedFileSize:". Tamanho máximo de 5MB.",
  monetaryValueEuro:"Valor monetário (em euros)",
  monetaryValueInfo:"Devem ser utilizadas as cotações oficiais publicadas pelo Banco de Portugal (e disponíveis na respetiva página oficial na Internet), vigentes à data do facto determinante da entrega da declaração",
  projectDecision:"Projeto de decisão",
  pronunciation:"Pronúncia do titular em sede de audiência prévia",
  pronunciationRequiremente:"Pronúncia do requerente em sede de audiência prévia",
  issuePronunciation:"Emitir pronúncia", 

  pronunciationTxt:"Pronúncia",
  requestPronunciationSucess:"Pronúncia em sede de audiência prévia enviada para a Entidade para a Transparência", 
  grossIncome:"Rendimentos brutos constantes da última declaração apresentada para efeitos da liquidação do imposto sobre o rendimento das pessoas singulares",
  advantagePromiseIndicationTxt:
    "Indicação do facto relativo ao aumento da vantagem patrimonial, quando de valor superior a 50 vezes o salário mínimo nacional em vigor à data da declaração (campo de preenchimento obrigatório sempre que se verifique uma alteração com o impacto descrito)",

  natureApplicableBuilding:"Natureza do direito incidente sobre o bem",
  natureApplicableBuildingOptions:{
    0:"Propriedade",
    1:"Outro direito",
  },
  indicatetheRight:"Indique qual o direito",
  emptyPronouncement:"O titular não se pronunciou",

  yesNoEnum:{
    1:"Sim",
    0:"Não"
  },
  declarantIsTheHolderOptions:{
    1:"por si",
    2:"por interposta pessoa coletiva ou singular (incluindo situações em que a/o declarante é apenas beneficiário efetivo)",
  },
  
  societyTypeOptions:{
    1:"Sociedade em nome coletivo",
    2:"Sociedade em comandita simples",
    3:"Sociedade em comandita por ações",
    4:"Sociedade civil sem forma comercial",
  },

  recipientSupportBenefitOptions:{
    0:"Declarante",
    1:"Cônjuge",
    2:"Pessoa com quem viva em união de facto",
    3:"Sociedade em cujo capital participem o declarante, ou o cônjuge, a pessoa com quem o declarante vive em união de facto",
  },

  exportDeclaration:"Exportar declaração",
  export:"Exportar",
  exportDeclarationQuestion:"Tem a certeza que pretende exportar a declaração [natureza] submetida a [data]?",
  goodOwnershipRight:"Titularidade do direito incidente sobre o bem",
  goodOwnershipRightOptions:{
    0:"Titular único",
    1:"Cotitular",
  },
  societyTypeTypeOptions:{
    1:"Ações",
    2:"Outras participações sociais",
  },
  sexEnum:{
    F: "Feminino",
    M: "Masculino",
  },
  ownershipOptions1:{
    0:"Titular único",
    1:"Cotitular",
  },

  nifTax:"NIF/NIPC/Tax identification number ou equivalente",
  exportChronology:"Exportar cronologia",

  
  requirementStatusTypeEnum:{
    1: "Em apreciação",
    2: "Em apreciação",
    3: "Concluído",
    4: "Aguardar pronúncia do requerente",
  },

  savedDdeclaration:"Declaração guardada com sucesso.",
  
  mimes:{
    "pdf":".pdf",
    ".pdf":".pdf",
    "xlsx": ".xlsx",
    ".xlsx": ".xlsx",
    "doc": ".doc",
    ".doc": ".doc",
    "docx": ".docx",
    ".docx": ".docx",
    "png": ".png",
    ".png": ".png",
    "jpeg": ".jpeg",
    ".jpeg": ".jpeg",
    "jpg": ".jpg",
    ".jpg": ".jpg",
  },
  shareIsMoreThanDebtAmount:"A quota-parte do montante do débito não pode ser superior ao montante do débito",
  declarationInitialWrongDates:"Para a declaração inicial, a data de submissão deverá ser posterior à data de início de funções/recondução/reeleição.",
  declarationCessationWrongDates:"Para a declaração de cessação, a data de submissão deverá ser posterior à data de cessação de funções.",
  declarationFinalWrongDates:"Para a declaração final, a data de submissão deverá distar, pelo menos, três anos a data de cessação de funções.",
  
  participationHolder:"Titular da participação",
  participationHolderOptions:{
    1:"Declarante titular único",
    2:"Declarante cotitular",
    3:"Cônjuge",
    4:"Pessoa com quem viva em união de facto",
  },
  invalidOtherHolderName:"O nome do titular do 1.º separador não coincide com o nome completo presente no 2.º separador. Para submeter, corrija os dados presentes no 1.º separador.",
  nifEquivalent:"Número de identificação fiscal (NIF) ou equivalente",
  tooltipRequirementDisabled:"A solicitação de requerimento apenas está disponível para declarações no estado Publicada",
  emptyPronouncementJumped:"A audiência prévia foi dispensada pela EpT",
  requirementStateError:"O estado desta declaração foi alterado. Contacte a Entidade para a Transparência para o endereço de correio eletrónico geral@entidadetransparencia.pt. Obrigado.",

};
