<!-- <script src="https://unpkg.com/vue-demi@0.12.1/lib/index.iife.js"></script>
<script src="https://unpkg.com/vue-recaptcha@^2/dist/vue-recaptcha.js"></script>
<script src="https://unpkg.com/vue-recaptcha@^2/dist/vue-recaptcha.min.js"></script> -->

<template>
  <vue-recaptcha
    :sitekey="sitekey"
    @verify="verifyMethod"
    @expired="expiredMethod"
    @render="renderMethod"
    @error="errorMethod"
  >
  </vue-recaptcha>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { VueRecaptcha } from "vue-recaptcha";

const sitekey = process.env.VUE_APP_RECAPTCHAKEY_SITE;
const emit = defineEmits(["update:modelValue"]);

var props = defineProps({
  modelValue: String,
});

const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

function verifyMethod(token) {
  localValue.value = token;
}
function expiredMethod() {
  localValue.value = null;
}
function renderMethod() {
  console.log("renderMethod");
}
function errorMethod() {
  localValue.value = null;
}
</script>

<style scoped></style>
