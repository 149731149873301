<template>
  <div class="row mb-1">
    <div class="col-12 ta-last-left">
      <span class="title">{{ props.title }}</span>
    </div>
    <div class="col-12">
      <hr class="rounded" />
    </div>
    <h1 class="ta-last-left">
      {{ props.subTitle }}
    </h1>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

var props = defineProps({
  title: String,
  subTitle: String,
});
</script>

<style scoped></style>
