//import { devtools } from 'vue';

export default {
    //: devtools ? 'https://api.FO.pdur.pt:443' : 'https://api.FO.pdur.pt:443',/*GON�ALO*/
    //apiURL: devtools ? 'https://localhost:7236' : 'http://api.pdur.pt',/*GON�ALO*/
    apiURL: 'https://localhost:7236',
    //apiURL: 'http://api.pdur.pt',
    URLREDIRECT: 'fb58e25a-f0d1-421c-8714-17227e1bed38',
    AUTH: '9davvc96-9d8b-430b-aa92-06b43cc28ee0',
    AUTHREFRESH: '50057282-f9cc-4d15-b37d-fbbe15d79444',
    AUTHGOVREDIRECT: "http://www.pdur.pt/govauthredirect"
}

