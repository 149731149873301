<template>
    <div class="container">
    <h2>{{toastTitle}}</h2>
    <span class="descriptionHtml" v-html="toastText"></span>
    </div>
  </template>
  
  <script>
  export default {
      props: {
          toastTitle: String,
          toastText: String,
      },
  };
  </script>