<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5601 16.3L14.8901 3.58C14.58 3.09481 14.1527 2.69552 13.6476 2.41894C13.1425 2.14235 12.5759 1.99738 12.0001 1.99738C11.4243 1.99738 10.8577 2.14235 10.3526 2.41894C9.84755 2.69552 9.42024 3.09481 9.1101 3.58L1.4401 16.3C1.16915 16.7517 1.02189 17.2667 1.01311 17.7933C1.00434 18.3199 1.13436 18.8396 1.3901 19.3C1.68578 19.8183 2.11376 20.2487 2.6303 20.5474C3.14684 20.8461 3.73343 21.0023 4.3301 21H19.6701C20.2629 21.0063 20.8468 20.8562 21.363 20.5647C21.8792 20.2733 22.3093 19.8508 22.6101 19.34C22.8734 18.8748 23.0075 18.3477 22.9987 17.8132C22.9899 17.2787 22.8385 16.7563 22.5601 16.3ZM20.8601 18.35C20.7399 18.5566 20.5655 18.7264 20.3557 18.8409C20.146 18.9555 19.9089 19.0105 19.6701 19H4.3301C4.09132 19.0105 3.85422 18.9555 3.64446 18.8409C3.4347 18.7264 3.26029 18.5566 3.1401 18.35C3.05233 18.198 3.00613 18.0255 3.00613 17.85C3.00613 17.6745 3.05233 17.502 3.1401 17.35L10.8201 4.62C10.9581 4.4377 11.1365 4.28986 11.3412 4.18807C11.5459 4.08628 11.7715 4.03331 12.0001 4.03331C12.2287 4.03331 12.4543 4.08628 12.659 4.18807C12.8637 4.28986 13.0421 4.4377 13.1801 4.62L20.8501 17.34C20.9413 17.4923 20.9902 17.6661 20.992 17.8436C20.9937 18.0211 20.9482 18.1959 20.8601 18.35Z"
      fill="black"
    />
    <path
      d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
      fill="black"
    />
    <path
      d="M12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
      fill="black"
    />
  </svg>
</template>
