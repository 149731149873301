<template>
  <div class="row">
    <div class="col-12">
      <p class="pageTitle pl-5" id="table">
        {{ selectedLang.publicConsult }}
      </p>
    </div>
  </div>
  <CustomCard class="mb-5">
    <div class="p-5">
      <div class="row mb-5">
        <div class="col-12">
          <p class="">
            {{ selectedLang.publicConsultTxt }}
          </p>
          <HomePageForm />
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12 ta-last-left">
          <span class="title">{{ selectedLang.homePageTableTile }}</span>
          <hr class="rounded" />
        </div>
      </div>
      <HomePageTable />
    </div>
  </CustomCard>
</template>

<script>
import HomePageTable from "./HomePageTable.vue";
import HomePageForm from "./HomePageForm.vue";

export default {
  name: "HomePage",
  components: { HomePageTable, HomePageForm },
  mounted() {
    this.emitter.on("scrollTo", (value) => {
      this.scrollPageTo(value);
    });
  },
  methods: {
    scrollPageTo(navEl) {
      let element = document.querySelector(`#${navEl}`);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
