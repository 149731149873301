import service from "./service-base";

export default {
    add: (model) => {
        return service.post("/attachment/add", model);
    },
    addAnonymous: (model) => {
        return service.post("/attachment/addAnonymous", model);
    },
    get: (id) => {
        let model = {
            id:id,
            originType:124
        }
        return service.post("/attachment/get", model);
    },
};