import IconArrowLeft from "./arrow-left";
import IconArrowRight from "./arrow-right";
import IconArrowUp from "./arrow-up";
import IconArrowDown from "./arrow-down";
import IconEye from "./eye";
import IconBell from "./bell";
import IconHistory from "./history";
import IconForward from "./forward";
import IconVerification from "./verification";
import IconAttach from "./attach";
import IconTrash from "./trash";
import IconCircleCross from "./circle-cross";
import IconCircleAprove from "./circle-aprove";
import IconSaveDraft from "./save-draft";
import IconAttachment from "./attachment";
import IconDraft from "./draft";
import IconComment from "./comment";
import IconSearch from "./search";
import IconSearchSmall from "./search-small";
import IconWord from "./word";
import IconExcel from "./excel";
import IconPdf from "./pdf";
import IconPlus from "./plus";
import IconFile from "./file";
import IconPerson from "./person";
import IconIrregularity from "./irregularity";
import IconAlert from "./alert";
import IconHome from "./home";
import IconBack from "./back";
import IconSettings from "./settings";
import IconOpposition from "./opposition";
import IconClarification from "./clarification";
import IconSubmenu from "./submenu";
import IconFolder from "./folder";
import IconDownload from "./download";
import IconNote from "./note";
import IconWallet from "./wallet";
import IconBarcode from "./barcode";
import IconEntities from "./entities";
import IconMapping from "./mapping";
import IconUpdateCircle from "./update-circle";
import IconPlusCircle from "./plus-circle";
import IconCrossCircle from "./cross-circle";
import IconEdit from "./edit";
import IconVerticalSeparator from "./vertical-separator";
import IconEyeClosed from "./eye-closed";
import IconInformation from "./information";

export default {
    IconArrowLeft,
    IconArrowRight,
    IconEye,
    IconBell,
    IconHistory,
    IconForward,
    IconVerification,
    IconAttach,
    IconTrash,
    IconCircleCross,
    IconCircleAprove,
    IconSaveDraft,
    IconAttachment,
    IconDraft,
    IconComment,
    IconSearch,
    IconSearchSmall,
    IconWord,
    IconExcel,
    IconPdf,
    IconPlus,
    IconFile,
    IconPerson,
    IconIrregularity,
    IconAlert,
    IconHome,
    IconBack,
    IconSettings,
    IconOpposition,
    IconClarification,
    IconSubmenu,
    IconFolder,
    IconDownload,
    IconNote,
    IconWallet,
    IconBarcode,
    IconEntities,
    IconMapping,
    IconArrowUp,
    IconArrowDown,
    IconUpdateCircle,
    IconPlusCircle,
    IconCrossCircle,
    IconEdit,
    IconVerticalSeparator,
    IconEyeClosed,
    IconInformation,
}