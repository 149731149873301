<template>
  <svg
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.7269 16.9919V18.1341C6.7269 18.5842 6.81578 19.0298 6.98846 19.4455C7.16114 19.8613 7.41425 20.2391 7.73332 20.5573C8.0524 20.8755 8.4312 21.1279 8.84809 21.3001C9.26498 21.4723 9.7118 21.561 10.163 21.561C10.6143 21.561 11.0611 21.4723 11.478 21.3001C11.8949 21.1279 12.2737 20.8755 12.5928 20.5573C12.9118 20.2391 13.1649 19.8613 13.3376 19.4455C13.5103 19.0298 13.5992 18.5842 13.5992 18.1341V16.9919M17.0353 7.85366C17.0353 11.2805 19.3261 16.9919 19.3261 16.9919H1C1 16.9919 3.29076 12.4227 3.29076 7.85366C3.29076 4.1207 6.41994 1 10.163 1C13.9061 1 17.0353 4.1207 17.0353 7.85366Z"
      stroke="#102E40"
      stroke-width="1.56442"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
