<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.03341 2.33337H6.03288C5.53677 2.33364 5.05057 2.47229 4.62894 2.73375C4.2078 2.9949 3.86776 3.36824 3.64697 3.81185L3.64622 3.81337L0.781575 9.53435C0.708252 9.67343 0.666748 9.83189 0.666748 10V15C0.666748 15.7073 0.9477 16.3856 1.4478 16.8857C1.94789 17.3858 2.62617 17.6667 3.33341 17.6667H16.6667C17.374 17.6667 18.0523 17.3858 18.5524 16.8857C19.0525 16.3856 19.3334 15.7073 19.3334 15V10C19.3334 9.9873 19.3332 9.97457 19.3327 9.96187C19.3269 9.80791 19.2863 9.66285 19.2186 9.53434L16.3539 3.81337L16.3533 3.81203C16.1325 3.36834 15.7924 2.99494 15.3712 2.73375C14.9496 2.47229 14.4634 2.33364 13.9673 2.33337H13.9667H6.03341ZM16.7143 9.00004L14.5642 4.70611L14.5629 4.70337C14.5077 4.5923 14.4226 4.49883 14.3172 4.43347C14.2118 4.36813 14.0904 4.33347 13.9664 4.33337H6.03377C5.9098 4.33347 5.78832 4.36813 5.68296 4.43347C5.57755 4.49883 5.49247 4.5923 5.43728 4.70337L4.54174 4.25837L5.43592 4.70611L3.28583 9.00004H6.66675C7.0011 9.00004 7.31333 9.16714 7.4988 9.44534L8.8686 11.5H11.1316L12.5014 9.44534C12.6868 9.16714 12.9991 9.00004 13.3334 9.00004H16.7143ZM2.66675 11V15C2.66675 15.1769 2.73699 15.3464 2.86201 15.4714C2.98703 15.5965 3.1566 15.6667 3.33341 15.6667H16.6667C16.8436 15.6667 17.0131 15.5965 17.1382 15.4714C17.2632 15.3464 17.3334 15.1769 17.3334 15V11H13.8686L12.4988 13.0547C12.3133 13.3329 12.0011 13.5 11.6667 13.5H8.33342C7.99906 13.5 7.68683 13.3329 7.50136 13.0547L6.13156 11H2.66675Z"
      :fill="props.color"
    />
  </svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#102E40",
  },
});
</script>
