<template>
  <div>
    <DeclarationSectionTitle
      :title="selectedLang.incomeHeritage"
      :subTitle="selectedLang.grossIncome"
    />
    <div class="row mb-5" v-if="showTrabalhoDependenteTable">
      <div class="col-12">
        <h1 class="">
          {{ selectedLang.workDependent }}
        </h1>
        <TrabalhoDependenteTable :declaracaoUnicaProp="declaracaoUnicaProp" />
      </div>
      <OutrosRendimentosForm
        class="mb-5"
        :declaracaoUnicaProp="declaracaoUnicaProp"
      />
    </div>
    <DeclarationRestrictData
      :reason="showTrabalhoDependenteTableReason"
      v-if="!showTrabalhoDependenteTable"
    />

    <div class="mb-5" v-if="showAtivoPatrimonial">
      <TabMenu>
        <TabItem
          tabId="ativoPatrimonialPortugal"
          group="ativoPatrimonial"
          :tabTittle="selectedLang.portugal"
          :initialIsActive="true"
        ></TabItem>
        <TabItem
          tabId="ativoPatrimonialEstrangeiro"
          group="ativoPatrimonial"
          :tabTittle="selectedLang.abroad"
        ></TabItem>
      </TabMenu>
      <AtivoPatrimonial
        v-if="ativoPatrimonial"
        :declaracaoUnicaProp="AtivoPatrimonialPortugal"
      />
      <AtivoPatrimonial
        v-if="!ativoPatrimonial"
        :declaracaoUnicaProp="AtivoPatrimonialEstrangeiro"
      />
    </div>
    <div class="mb-5" v-if="!showAtivoPatrimonial">
      <DeclarationSectionTitle :title="selectedLang.assets" />
      <DeclarationRestrictData :reason="showAtivoPatrimonialReason" />
    </div>

    <div class="mb-5" v-if="showPassivo">
      <TabMenu>
        <TabItem
          tabId="passivoSectionPortugal"
          group="passivoSection"
          :tabTittle="selectedLang.portugal"
          :initialIsActive="true"
        ></TabItem>
        <TabItem
          tabId="passivoSectionEstrangeiro"
          group="passivoSection"
          :tabTittle="selectedLang.abroad"
        ></TabItem>
      </TabMenu>
      <PassivoSection
        v-if="passivoSection"
        :declaracaoUnicaProp="PassivoPortugal"
      />
      <PassivoSection
        v-if="!passivoSection"
        :declaracaoUnicaProp="PassivoEstrangeiro"
      />
    </div>
    <div class="mb-5" v-if="!showPassivo">
      <DeclarationSectionTitle :title="selectedLang.passive" />
      <DeclarationRestrictData :reason="showPassivoReason" />
    </div>

    <div class="mb-5" v-if="showPromessaVantagemPatrimonial">
      <PromessaVantagemPatrimonial :declaracaoUnicaProp="declaracaoUnicaProp" />
    </div>
    <div class="mb-5" v-if="!showPromessaVantagemPatrimonial">
      <DeclarationSectionTitle :title="selectedLang.promiseEquityAdvantage" />
      <DeclarationRestrictData
        :reason="showPromessaVantagemPatrimonialReason"
      />
    </div>

    <div class="mb-5" v-if="showOutrasDeclaracoes">
      <OutrasDeclaracoes :declaracaoUnicaProp="declaracaoUnicaProp" />
    </div>
    <div class="mb-5" v-if="!showOutrasDeclaracoes">
      <DeclarationSectionTitle :title="selectedLang.otherStatements" />
      <DeclarationRestrictData :reason="showOutrasDeclaracoesReason" />
    </div>
  </div>
</template>

<script>
import TrabalhoDependenteTable from "./declaracaoUnicaTab3Aux/TrabalhoDependenteTable.vue";
import OutrosRendimentosForm from "./declaracaoUnicaTab3Aux/OutrosRendimentosForm.vue";
import AtivoPatrimonial from "./declaracaoUnicaTab3Aux/ativoPatrimonial/AtivoPatrimonial.vue";
import PassivoSection from "./declaracaoUnicaTab3Aux/passivo/PassivoSection.vue";
import PromessaVantagemPatrimonial from "./declaracaoUnicaTab3Aux/PromessaVantagemPatrimonial.vue";
import OutrasDeclaracoes from "./declaracaoUnicaTab3Aux/OutrasDeclaracoes.vue";

export default {
  components: {
    TrabalhoDependenteTable,
    OutrosRendimentosForm,
    AtivoPatrimonial,
    PassivoSection,
    PromessaVantagemPatrimonial,
    OutrasDeclaracoes,
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {
      form: {
        teste: null,
      },
      ativoPatrimonial: true,
      passivoSection: true,
      promessaVantagemPatrimonial: true,
    };
  },
  methods: {
    selectTab(emitterItem) {
      switch (emitterItem.group) {
        case "ativoPatrimonial":
          this.ativoPatrimonial =
            emitterItem.tabId == "ativoPatrimonialPortugal";
          break;
        case "passivoSection":
          this.passivoSection = emitterItem.tabId == "passivoSectionPortugal";
          break;

        default:
          break;
      }
    },
  },

  mounted() {
    this.emitter.on("selectTabMenuItem", (emitterItem) => {
      this.selectTab(emitterItem);
    });

    this.emitter.on("validateForm", () => {
      this.emitter.emit("DeclaracaoUnicaTabHeader:UpdateDraft", 3);
    });
  },
  computed: {
    AtivoPatrimonialPortugal: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "888d8680-77d1-40ef-8fac-d69586bf37ba"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "888d8680-77d1-40ef-8fac-d69586bf37ba",
          newValue
        );
      },
    },
    AtivoPatrimonialEstrangeiro: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "90df628b-64cd-46db-bd1e-427897977b63"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "90df628b-64cd-46db-bd1e-427897977b63",
          newValue
        );
      },
    },

    PassivoPortugal: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "bf6dcb0a-19de-4c77-8ff6-76e57f93ceef"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "bf6dcb0a-19de-4c77-8ff6-76e57f93ceef",
          newValue
        );
      },
    },
    PassivoEstrangeiro: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "142f6497-9e25-44e9-a79a-f19acade29b9"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "142f6497-9e25-44e9-a79a-f19acade29b9",
          newValue
        );
      },
    },
    showTrabalhoDependenteTable() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "45b8a3d2-374f-4531-b167-ac62b950ad6b"
      ).isVisible;
    },
    showTrabalhoDependenteTableReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "45b8a3d2-374f-4531-b167-ac62b950ad6b"
      ).reason;
    },
    showAtivoPatrimonial() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "1a24058c-9ebe-4c14-9876-e5181310fb19"
      ).isVisible;
    },
    showAtivoPatrimonialReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "1a24058c-9ebe-4c14-9876-e5181310fb19"
      ).reason;
    },
    showPassivo() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "ba17cce1-b542-42a8-a22a-d6a541428858"
      ).isVisible;
    },
    showPassivoReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "ba17cce1-b542-42a8-a22a-d6a541428858"
      ).reason;
    },
    showPromessaVantagemPatrimonial() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "2a52ac1f-dac3-4d82-83cb-18d2a4b98be2"
      ).isVisible;
    },
    showPromessaVantagemPatrimonialReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "2a52ac1f-dac3-4d82-83cb-18d2a4b98be2"
      ).reason;
    },
    showOutrasDeclaracoes() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "97941562-f066-4520-bf07-fc3330832c68"
      ).isVisible;
    },
    showOutrasDeclaracoesReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "97941562-f066-4520-bf07-fc3330832c68"
      ).reason;
    },
  },
};
</script>
