import service from "./service-base";

export default {
    search: (pageNumber, sortBy, declarationId) => {
        return service.post("/declaration-opposition-request/search", {
            declarationId: declarationId,
            sortBy: sortBy,
            pageNumber: pageNumber
          });
    },
    create: (declarationId, reason, oppositionKeys, attachments) => {
        var oppositionKeysModel = oppositionKeys.map(o => {
            return {
                Key:o,
                Validated:true
            }
        })
        let model = {
            declarationId: declarationId,
            reason: reason,
            oppositionKeys: oppositionKeysModel,
            attachments: attachments
          };
          
        return service.post("/declaration-opposition-request/create", model);
    },
    getOppositionKeys: () => {
        return service.get("/declaration-opposition-request/oppositionkeys");
    },
    getAllAttachments:(declarationId) => {
        return service.get(`/declaration-opposition-request/${declarationId}/attachments`);
    },
    get:(declarationId, declarationOppositionRequestId) => {
        return service.post(`/declaration-opposition-request/get`, {
            declarationId,
            declarationOppositionRequestId
        });
    },
    getAllByOppositionRequest:(declarationOppositionRequestId) => {
        return service.get(`/declaration-opposition-request/${declarationOppositionRequestId}/attachments`);
    },
    dowloadAttachment:(declarationOppositionRequestId, attachmentId) => {
        return service.post(`/declaration-opposition-request/attachment`, {
            declarationOppositionRequestId,
            attachmentId
        });
    },
    createpronunciation:(oppositionRequestId, pronunciation,attachments) => {
        return service.put(`/declaration-opposition-request/createpronunciation`, {
            oppositionRequestId:oppositionRequestId,
            declarationOppositionRequestId:oppositionRequestId,
            pronunciation:pronunciation,
            attachments:attachments
        });
    },
};
