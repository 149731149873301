<template>
  <div id="capture">
    <div class="row mb-1">
      <div class="col-12 ta-last-left">
        <span class="title">{{ selectedLang.determiningFactDeclaration }}</span>
      </div>
      <div class="col-12">
        <hr class="rounded" />
      </div>
    </div>
    <div class="row" v-if="showFactoDeterminanteDeclaracao">
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="f649defc-e001-4866-9c2b-01ed9fa80eef"
          type="Text"
          :label="selectedLang.declarationNature"
          :required="false"
          :maxlength="60"
          :disabled="true"
        />
      </div>
      <div
        class="col-12 col-md-6 col-lg-4 mb-4"
        v-if="declaracao.RelatedDeclarationId != null"
      >
        <CustomInput
          type="Text"
          :label="selectedLang.relatedDocument"
          v-model="declaracao.RelatedDeclarationId"
          :required="false"
          :maxlength="60"
          :disabled="true"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="c83b94a8-5d7a-4061-a001-0c3522d2e533"
          :validateForm="validateForm"
          type="Multiselect"
          :label="selectedLang.entity"
          :required="true"
          :options="entityOptions"
          :loading="entityOptionsLoading"
          @select="setEntity"
          :searchable="true"
          @clear="clearEntidade"
          @search-change="entitySearchChange"
          :showOptions="showEntityOptions"
          :key="entityMultiselectKey"
          :showOtherOption="true"
          :otherOptionValue="{
            value: -1,
            label: 'Outra entidade',
          }"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="showEntidadeOutra == -1">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="c83b94a9-5d7a-4061-a001-0c3522d2e533"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.entityOther"
          v-model="declaracao.EntityName"
          :required="true"
          :maxlength="150"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :disabled="disabledBoard"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="21cdd43f-aebd-43c1-8b47-0eafedb644c8"
          :validateForm="validateForm"
          type="Multiselect"
          :label="selectedLang.organ"
          :required="true"
          :options="boardOptions"
          :loading="boardOptionsLoading"
          :searchable="true"
          @select="setBoard"
          @clear="clearOrgao"
          @search-change="boardSearchChange"
          :showOptions="showBoardOptions"
          :showOtherOption="true"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="showOrgaoOutro == -1">
        <DeclaracaoUnicaInput
          :disabled="disabledBoard"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="22cdd43f-aebd-43c1-8b47-0eafedb644c8"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.boardOther"
          v-model="declaracao.BoardName"
          :required="true"
          :maxlength="150"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :disabled="disabledRole"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67"
          :validateForm="validateForm"
          type="Multiselect"
          :label="selectedLang.functionExercised"
          :required="true"
          :options="roleOptions"
          :loading="roleOptionsLoading"
          @select="setRole"
          :searchable="true"
          @clear="clearCargo"
          @search-change="roleSearchChange"
          :showOptions="showRoleOptions"
          :showOtherOption="true"
          :otherOptionValue="{
            value: -1,
            label: 'Outra entidade',
          }"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="showCargoOutro == -1">
        <DeclaracaoUnicaInput
          :disabled="disabledRole"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.roleOther"
          v-model="declaracao.RoleName"
          :required="true"
          :maxlength="150"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :disabled="disabledHolder"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="d34934dc-dc8f-4515-abc3-eb2a16d58ed1"
          :validateForm="validateForm"
          type="Multiselect"
          :label="selectedLang.titleTxt"
          :required="true"
          :options="holderOptions"
          :loading="holderOptionsLoading"
          :searchable="true"
          @select="setHolder"
          @clear="clearTitular"
          @search-change="holderSearchChange"
          :showOptions="true"
          :showOtherOption="true"
          :otherOptionValue="{
            value: -1,
            label: 'Outro titular',
          }"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="showTitularOutro == -1">
        <DeclaracaoUnicaInput
          :disabled="disabledHolder"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="d34723dc-dc8f-4515-abc3-eb2a16d58ed1"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.holderOther"
          v-model="declaracao.HolderName"
          :required="true"
          :maxlength="150"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :disabled="declaracao.natureza == 16"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="418cdc5e-7f77-4922-b0b7-3ab6b2f84c6f"
          :validateForm="validateForm"
          type="DatePicker"
          :label="selectedLang.functionInicialDate"
          :required="true"
          :maxDate="new Date()"
          @update="clearFinalDate"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :disabled="declaracao.natureza == 16"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="5783eba6-4662-4e01-a1ab-14dbe7bb693c"
          :validateForm="validateForm"
          type="DatePicker"
          :minDate="minDate"
          :label="selectedLang.functionFinalDate"
          :required="declaracao.natureza == 4 || declaracao.natureza == 8"
        />
      </div>
      <div class="col-12 col-xl-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="b8de313d-cc0d-445d-b211-368794d7a2df"
          :validateForm="validateForm"
          type="RadioBtn"
          :label="selectedLang.functionYesNo"
          :required="true"
          :disabled="loading"
          :options="formExercicioFuncoesOptions"
        />
      </div>
    </div>
    <DeclarationRestrictData
      v-else
      :reason="showFactoDeterminanteDeclaracaoReason"
    />
  </div>
</template>

<script>
import publicQueryService from "@/services/publicQuery.service";
import { utils } from "@/helpers";
export default {
  data() {
    return {
      loading: false,
      validateForm: false,
      options: [
        {
          value: 0,
          label: "Outra",
        },
      ],
      roleOptions: [],
      roleOptionsLoading: true,
      entityOptions: [],
      entityOptionsLoading: true,
      entityMultiselectKey: 0,
      holderOptions: [],
      holderOptionsLoading: true,
      boardOptions: [],
      boardOptionsLoading: true,
      entitySearchValue: "",
      roleSearchValue: "",
      holderSearchValue: "",
      boardSearchValue: "",
      minDate: new Date(),
    };
  },
  props: {
    declaracaoUnicaProp: Object,
  },
  watch: {
    declaracao: {
      handler(newValue) {
        this.emitter.emit("DeclarationProgress:Update", newValue);
      },
      deep: true,
    },
  },
  computed: {
    declaracao() {
      return this.$store.state.declaracao;
    },
    showEntidadeOutra() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "c83b94a8-5d7a-4061-a001-0c3522d2e533" //entidade
      ).value;
    },
    showOrgaoOutro() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "21cdd43f-aebd-43c1-8b47-0eafedb644c8" //orgao
      ).value;
    },
    showCargoOutro() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67" //cargo
      ).value;
    },
    showTitularOutro() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "d34934dc-dc8f-4515-abc3-eb2a16d58ed1" //titular
      ).value;
    },
    showFactoDeterminanteDeclaracao() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "29e3c9b0-8881-4dbd-b96b-6ab967edbb7c"
      ).isVisible;
    },
    showFactoDeterminanteDeclaracaoReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "29e3c9b0-8881-4dbd-b96b-6ab967edbb7c"
      ).reason;
    },
    disabledBoard() {
      return this.showEntidadeOutra == null;
    },
    disabledRole() {
      return this.showEntidadeOutra == null || this.showOrgaoOutro == null;
    },
    disabledHolder() {
      return (
        this.showEntidadeOutra == null ||
        this.showCargoOutro == null ||
        this.showOrgaoOutro == null
      );
    },

    formExercicioFuncoesOptions() {
      return [
        {
          value: 1,
          label: this.selectedLang.yes,
        },
        {
          value: 0,
          label: this.selectedLang.no,
        },
      ];
    },
    showEntityOptions() {
      return !!this.entitySearchValue;
    },
    showRoleOptions() {
      return !!this.roleSearchValue;
    },
    showHolderOptions() {
      return !!this.holderSearchValue;
    },
    showBoardOptions() {
      return !!this.boardSearchValue;
    },
  },
  methods: {
    clearEntidade() {
      this.$store.state.declaracao.entidade = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "c83b94a8-5d7a-4061-a001-0c3522d2e533", //entidade
        null
      );
      this.$store.state.declaracao.EntityName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "c83b94a9-5d7a-4061-a001-0c3522d2e533", //outra entidade
        null
      );
      this.$store.state.declaracao.orgao = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "21cdd43f-aebd-43c1-8b47-0eafedb644c8", //orgao
        null
      );
      this.$store.state.declaracao.BoardName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "22cdd43f-aebd-43c1-8b47-0eafedb644c8", //outro orgao
        null
      );
      this.$store.state.declaracao.cargo = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //cargo
        null
      );
      this.$store.state.declaracao.RoleName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //outro cargo
        null
      );
      this.$store.state.declaracao.titular = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34934dc-dc8f-4515-abc3-eb2a16d58ed1", //titular
        null
      );
      this.$store.state.declaracao.HolderName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34723dc-dc8f-4515-abc3-eb2a16d58ed1", //outro titular
        null
      );
      this.getFilters();
    },
    clearOrgao() {
      this.$store.state.declaracao.orgao = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "21cdd43f-aebd-43c1-8b47-0eafedb644c8", //orgao
        null
      );
      this.$store.state.declaracao.BoardName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "22cdd43f-aebd-43c1-8b47-0eafedb644c8", //outro orgao
        null
      );
      this.$store.state.declaracao.cargo = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //cargo
        null
      );
      this.$store.state.declaracao.RoleName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //outro cargo
        null
      );
      this.$store.state.declaracao.titular = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34934dc-dc8f-4515-abc3-eb2a16d58ed1", //titular
        null
      );
      this.$store.state.declaracao.HolderName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34723dc-dc8f-4515-abc3-eb2a16d58ed1", //outro titular
        null
      );
      this.getFilters();
    },
    clearCargo() {
      this.$store.state.declaracao.cargo = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //cargo
        null
      );
      this.$store.state.declaracao.RoleName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //outro cargo
        null
      );
      this.$store.state.declaracao.titular = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34934dc-dc8f-4515-abc3-eb2a16d58ed1", //titular
        null
      );
      this.$store.state.declaracao.HolderName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34723dc-dc8f-4515-abc3-eb2a16d58ed1", //outro titular
        null
      );
      this.getFilters();
    },
    clearTitular() {
      this.$store.state.declaracao.titular = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34934dc-dc8f-4515-abc3-eb2a16d58ed1", //titular
        null
      );
      this.$store.state.declaracao.HolderName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34723dc-dc8f-4515-abc3-eb2a16d58ed1", //outro titular
        null
      );
      this.getFilters();
    },
    getRoles(filter) {
      publicQueryService
        .getAllRoles(filter)
        .then((response) => {
          if (response.data.length) {
            this.roleOptions = response.data;

            let filter2 = this.roleOptions.filter(
              (x) => x.value == this.declaracao.cargo
            );
            if (
              utils.isNullOrEmpty(filter2) &&
              this.declaracao.cargo != -1 &&
              this.declaracao.cargo != null
            ) {
              this.roleOptions.push({
                value: this.declaracao.cargo,
                label: this.declaracao.RoleName,
              });
            }
          } else {
            this.roleOptions = [
              {
                value: this.declaracao.cargo,
                label: this.declaracao.RoleName,
              },
            ];
          }
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.InternalServerError);
        })
        .finally(() => {
          this.addOtheRole();
          this.roleOptionsLoading = false;
        });
    },
    getEntities(filter) {
      publicQueryService
        .getAllEntities(filter)
        .then((response) => {
          if (response.code == 0) {
            if (response.data.length) {
              this.entityOptions = response.data;
              let filter2 = this.entityOptions.filter(
                (x) => x.value == this.declaracao.entidade
              );
              if (
                utils.isNullOrEmpty(filter2) &&
                this.declaracao.entidade != -1 &&
                this.declaracao.entidade != null
              ) {
                this.entityOptions.push({
                  value: this.declaracao.entidade,
                  label: this.declaracao.EntityName,
                });
              }
            } else {
              this.entityOptions = [
                {
                  value: this.declaracao.entidade,
                  label: this.declaracao.EntityName,
                },
              ];
            }
          }
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.InternalServerError);
        })
        .finally(() => {
          this.addOtheEntity();
          this.entityOptionsLoading = false;
          this.entityMultiselectKey++;
        });
    },
    getHolders(filter) {
      publicQueryService
        .getAllHolders(filter)
        .then((response) => {
          if (response.data.length) {
            this.holderOptions = response.data;

            let filter2 = this.holderOptions.filter(
              (x) => x.value == this.declaracao.titular
            );

            if (
              utils.isNullOrEmpty(filter2) &&
              this.declaracao.titular != -1 &&
              this.declaracao.titular != null
            ) {
              this.holderOptions.push({
                value: this.declaracao.titular,
                label: this.declaracao.HolderName,
              });
            }
          } else {
            console.log("!!!");
            this.holderOptions = [
              {
                value: this.declaracao.titular,
                label: this.declaracao.HolderName,
              },
            ];
          }
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.InternalServerError);
        })
        .finally(() => {
          this.addOtheHolder();
        });
    },
    getBoards(filter) {
      publicQueryService
        .getallboards(filter)
        .then((response) => {
          if (response.data.length) {
            this.boardOptions = response.data;

            let filter2 = this.boardOptions.filter(
              (x) => x.value == this.declaracao.orgao
            );
            if (
              utils.isNullOrEmpty(filter2) &&
              this.declaracao.orgao != -1 &&
              this.declaracao.orgao != null
            ) {
              this.boardOptions.push({
                value: this.declaracao.orgao,
                label: this.declaracao.BoardName,
              });
            }
          } else {
            this.boardOptions = [
              {
                value: this.declaracao.orgao,
                label: this.declaracao.BoardName,
              },
            ];
          }
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.InternalServerError);
        })
        .finally(() => {
          this.addOtheBoard();
        });
    },

    setRole(role) {
      this.$store.state.declaracao.cargo = role;
      this.$store.state.declaracao.RoleName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //outro cargo
        null
      );
      this.getFilters();
    },
    setEntity(entity) {
      this.$store.state.declaracao.entidade = entity;
      this.$store.state.declaracao.EntityName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "c83b94a9-5d7a-4061-a001-0c3522d2e533", //outra entidade
        null
      );
      this.getFilters();
    },
    setHolder(holder) {
      let holderFound = this.holderOptions.filter((x) => x.value == holder);

      if (holderFound.length > 0) {
        this.$store.state.declaracao.HolderName = holderFound[0].label;
      } else {
        this.$store.state.declaracao.HolderName = null;
      }
      this.$store.state.declaracao.titular = holder;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "d34723dc-dc8f-4515-abc3-eb2a16d58ed1", //outro titular
        null
      );
      this.getFilters();
    },
    setBoard(board) {
      this.$store.state.declaracao.orgao = board;
      this.$store.state.declaracao.BoardName = null;
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //outro cargo
        null
      );
      this.getFilters();
    },
    addOtheRole() {
      this.roleOptions.push({
        value: -1,
        label: this.selectedLang.roleOther,
      });
      this.roleOptionsLoading = false;
    },
    addOtheEntity() {
      this.entityOptions.push({
        value: -1,
        label: this.selectedLang.entityOther,
      });
      this.entityOptionsLoading = false;
    },
    addOtheHolder() {
      this.holderOptions.push({
        value: -1,
        label: this.selectedLang.holderOther,
      });
      this.holderOptionsLoading = false;
    },
    addOtheBoard() {
      this.boardOptions.push({
        value: -1,
        label: this.selectedLang.boardOther,
      });
      this.boardOptionsLoading = false;
    },
    getFilters() {
      this.getRoles({
        EntityId: this.checkFielIdToSearch(
          this.$store.state.declaracao.entidade
        ),
        HolderId: this.checkFielIdToSearch(
          this.$store.state.declaracao.titular
        ),
        BoardId: this.checkFielIdToSearch(this.$store.state.declaracao.orgao),
      });
      this.getEntities({
        RoleId: this.checkFielIdToSearch(this.$store.state.declaracao.cargo),
        HolderId: this.checkFielIdToSearch(
          this.$store.state.declaracao.titular
        ),
        BoardId: this.checkFielIdToSearch(this.$store.state.declaracao.orgao),
      });
      this.getHolders({
        RoleId: this.checkFielIdToSearch(this.$store.state.declaracao.cargo),
        EntityId: this.checkFielIdToSearch(
          this.$store.state.declaracao.entidade
        ),
        BoardId: this.checkFielIdToSearch(this.$store.state.declaracao.orgao),
      });
      this.getBoards({
        RoleId: this.checkFielIdToSearch(this.$store.state.declaracao.cargo),
        EntityId: this.checkFielIdToSearch(
          this.$store.state.declaracao.entidade
        ),
        HolderId: this.checkFielIdToSearch(
          this.$store.state.declaracao.titular
        ),
      });
    },
    showProgress() {
      this.emitter.emit(
        "DeclarationProgress:Update",
        this.$store.state.declaracao
      );
    },
    entitySearchChange(query) {
      this.entitySearchValue = query;
    },
    roleSearchChange(query) {
      this.roleSearchValue = query;
    },
    holderSearchChange(query) {
      this.holderSearchValue = query;
    },
    boardSearchChange(query) {
      this.boardSearchValue = query;
    },

    checkFielIdToSearch(id) {
      return id > 0 ? id : null;
    },

    clearOther() {},
    clearFinalDate() {
      this.declaracaoUnicaHelper.setFieldValueByKey(
        this.declaracaoUnicaProp,
        "5783eba6-4662-4e01-a1ab-14dbe7bb693c",
        null
      );

      let val = this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "418cdc5e-7f77-4922-b0b7-3ab6b2f84c6f" //entidade
      );
      if (val != null) {
        this.minDate = val.value;
      }
    },
  },
  mounted() {
    this.emitter.on("ValidateForm", () => {
      this.validateForm = true;

      // const isValid = declaracaoUnicaHelper.isValidTab1Declaration(this.$store.state.declaracao.json);
      // if(!isValid){
      //   utils.warningToast(this.selectedLang.invalidDeclaration);
      // }else{
      //   this.emitter.emit("DeclaracaoUnicaTabHeader:UpdateDraft", 1);
      // }
    });

    this.getFilters();
    this.showProgress();

    let val = this.declaracaoUnicaHelper.getFieldByKey(
      this.declaracaoUnicaProp,
      "418cdc5e-7f77-4922-b0b7-3ab6b2f84c6f" //entidade
    );
    if (val != null) {
      this.minDate = val.value;
    }
  },
};
</script>
