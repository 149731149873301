<template>
  <div class="progress declaracaoUnicaTabHeaderBar">
    <div
      class="progress-bar bg-dpur progress-bar-animated"
      role="progressbar"
      :style="{ width: progress + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    tabId: Number,
    initialProgress: {
      Number,
      default: 0,
    },
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {
      progress: this.initialProgress,
    };
  },
  methods: {
    selectTab(tabId) {
      if (tabId < this.tabId) {
        this.progress = 0;
      }
      if (tabId == this.tabId) {
        this.progress = 50;
      }
      if (tabId > this.tabId) {
        this.progress = 100;
      }
    },
  },
  mounted() {
    this.emitter.on("selectTab", (tabId) => {
      this.selectTab(tabId);
    });
  },
};
</script>
