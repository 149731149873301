import service from "./service-base";

export default {
  getByCode: (code) => {
    return service.get(`/entitycollaboration/getaccess/${code}`);
  },
  getTemplate: () => {
    return service.get("/entitycollaboration/getholderlistformtemplate");
  },
  getForm: (id, code) => {
    return service.get(`/entitycollaboration/getform/${id}/${code}`);
  },
  addAttachment: (entityCollaborationId, attachment) => {
    return service.post("/entitycollaboration/attachment/create", {
      entityCollaborationId,
        ...attachment      
    });
  },
  getAttachment: (entityCollaborationId, attachmentId) => {
    return service.post("/entitycollaboration/attachment/get", {
      entityCollaborationId,

      attachmentId});
  },
  submitForm: (entityCollaborationId) => {
    return service.post("/entitycollaboration/submitform", {entityCollaborationId});
  },
  deleteAttachment: (attachmentId) => {
    return service.delete(`/entitycollaboration/attachment/delete/${attachmentId}`);
  },
};