<template>
  <svg
    width="1"
    height="40"
    viewBox="0 0 1 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0.5" y1="2.18557e-08" x2="0.499998" y2="40" stroke="#D8D8D8" />
  </svg>
</template>
