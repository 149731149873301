import service from "./service-base";

export default {
    search: (pageNumber, sortBy, state) => {
        let model = {
            pageNumber:pageNumber,
            sortBy:sortBy,
            stateId:state
        }
        return service.post(`/notification/search`, model);
    },
    markAsRead: (id) => {
        return service.put(`/notification/markasread`, {
            id
        });
    },
    delete: (id) => {
        return service.post(`/notification/delete`, {
            id
        });
    },
    unreadsCount: () => {
        return service.post(`/notification/count`, {
          stateId: 1,
        });
      },
      readsCount: () => {
        return service.post(`/notification/count`, {
          stateId: 2,
        });
      },
};
