<template>
    <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="39"
              height="39"
              rx="9.5"
              fill="white"
              stroke="#007AC4"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.7438 19.8659C11.565 20.0401 11.4893 20.2711 11.5012 20.4996C11.4893 20.728 11.565 20.9599 11.7438 21.1341L16.8347 25.2549C17.1692 25.5817 17.7122 25.5817 18.0467 25.2549C18.3812 24.928 18.3812 24.3977 18.0467 24.0708L14.682 21.3467H27.6488C28.1195 21.3467 28.5 20.9732 28.5 20.5129C28.5 20.0527 28.1195 19.6791 27.6488 19.6791H14.6497L18.0467 16.9292C18.3812 16.6023 18.3812 16.072 18.0467 15.7451C17.7122 15.4183 17.1692 15.4183 16.8347 15.7451L11.7438 19.8659V19.8659Z"
              fill="#007AC4"
            />
          </svg>
</template>