import store from "@/store";
import { utils,authentication} from "@/helpers";
import resources from "@/resources/index";
import { isProxy, toRaw } from "vue";

export default {
  version(){
    return 1
  },
  newDeclaracao() {
    return {
      key: "dab5b2ca-921a-4c8a-950a-5413e46625ab",
      value: [
        {
          key: "dab5b2ca-921a-4c8a-950a-5413e46625aa",
          value: [],
          isVisible: true,
        },
        {
          key: "358af850-b811-494a-8a38-81eadaf9d695",
          value: [
            {
              key: "29e3c9b0-8881-4dbd-b96b-6ab967edbb7c",
              value: [
                {
                  key: "22cdd43f-aebd-43c1-8b47-0eafedb644c8",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "c83b94a9-5d7a-4061-a001-0c3522d2e533",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "f649defc-e001-4866-9c2b-01ed9fa80eef",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "d34934dc-dc8f-4515-abc3-eb2a16d58ed1",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "d34723dc-dc8f-4515-abc3-eb2a16d58ed1",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "c83b94a8-5d7a-4061-a001-0c3522d2e533",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "21cdd43f-aebd-43c1-8b47-0eafedb644c8",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "418cdc5e-7f77-4922-b0b7-3ab6b2f84c6f",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "5783eba6-4662-4e01-a1ab-14dbe7bb693c",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "b8de313d-cc0d-445d-b211-368794d7a2df",
                  value: null,
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
          ],
          isVisible: true,
        },
        {
          key: "7df770a3-e06b-4448-b5ac-c1b162c80fcc",
          value: [
            {
              key: "56786e7c-a8bb-43aa-9250-eaf8fa82002f",
              value: [
                {
                  key: "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "54854c64-5845-40bb-a536-8c03da55420a",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "d155af2b-21e9-475f-be81-8e42bc5a5641",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "a5bf96eb-8af0-4c05-bb76-a15f57f8490d",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "25fa81a1-70d2-4fca-8292-4717ba2e8cdb",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "ed9a8413-b9f0-4d32-89bd-03593c826211",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "ed9a8413-b9f0-4d32-89bd-03593c826212",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "ed9a8413-b9f0-4d32-89bd-03593c826213",
                  value: null,
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
            {
              key: "6a3c614b-2c9e-42f3-b5af-49cc8b078204",
              value: [
                {
                  key: "37b963d3-b73a-4da7-9a78-cb417f92214a",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "38cffadd-0893-47ef-b261-84007c381639",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "308ccc8a-452a-4ed7-94e5-5ca17a1667fe",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "2b75bfd8-392e-49d7-866f-1353a5e765c3",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "77902677-0143-437c-ae9b-b3e54642a080",
                  value: null,
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
            {
              key: "b437d8f1-e201-4fc0-a304-e5f78ca1a063",
              value: [
                {
                  key: "d3fd512b-65e8-4550-90d9-018a5ef91c0d",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "67d71fd5-100a-42dd-a6a8-44d40ce2ba50",
                  value: null,
                  isVisible: true,
                },
                {
                  key: "8b022aca-fdf8-4391-8d49-73abc4e63128",
                  value: null,
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
          ],
          isVisible: true,
        },
        {
          key: "ecc58cb2-0808-4d2f-bb2b-64b87617a4ac",
          value: [
            {
              key: "45b8a3d2-374f-4531-b167-ac62b950ad6b",
              value: [
                {
                  key: "da9c415a-e950-4ce9-ab58-5ce1c1b5af53",
                  value: [],
                  isVisible: true,
                },
                {
                  key: "e516635f-aeb6-4e1f-aa9c-d77bf888ec36",
                  value: [
                    {
                      key: "929796f8-a63c-49b4-b2de-56191668dfa0",
                      value: null,
                      isVisible: true,
                    },
                    {
                      key: "ff035b46-8c60-4ffb-9307-9b348210c7dd",
                      value: null,
                      isVisible: true,
                    },
                    {
                      key: "4863ec0b-2061-4bed-a88f-6803547d839b",
                      value: null,
                      isVisible: true,
                    },
                    {
                      key: "a4f13529-fc7b-4a8d-a57d-4c6a04c5de32",
                      value: null,
                      isVisible: true,
                    },
                    {
                      key: "1d9eb246-99b3-41c1-9ef4-473925b77296",
                      value: null,
                      isVisible: true,
                    },
                    {
                      key: "90848b4c-9530-44f3-82c3-5cabd86f936a",
                      value: null,
                      isVisible: true,
                    },
                    {
                      key: "fb030f7e-bb87-49b6-8d67-65dfa01699c0",
                      value: null,
                      isVisible: true,
                    },
                    {
                      key: "548797e6-8cbd-4e2e-8aff-b6bed1c7ed6e",
                      value: null,
                      isVisible: true,
                    },
                  ],
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
            {
              key: "1a24058c-9ebe-4c14-9876-e5181310fb19",
              value: [
                {
                  key: "888d8680-77d1-40ef-8fac-d69586bf37ba",
                  value: [
                    {
                      key: "112c6561-4a51-4f79-b637-bbd8d2c0c74c",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "70fdd906-58d5-4682-967d-90ae90805436",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "53168cfb-433e-41e6-9b23-33c1eac7fa6f",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "05d711d3-08cd-4944-8be9-dc5f40a201fc",
                      value: [
                        {
                          key: "1a5d2580-aaf3-4874-9e20-68ce10671263",
                          value: [],
                          isVisible: true,
                        },
                        {
                          key: "e498015f-062e-4fcd-93a8-c5f1393f568b",
                          value: [],
                          isVisible: true,
                        },
                        {
                          key: "7bf6ccdd-f797-4339-8c76-18a248882016",
                          value: [],
                          isVisible: true,
                        },
                      ],
                      isVisible: true,
                    },
                    {
                      key: "879401c9-2273-465a-91c7-7d8f080da762",
                      value: [
                        {
                          key: "873b46af-f3a5-4329-9aa0-a04fd1114467",
                          value: [],
                          isVisible: true,
                        },
                        {
                          key: "58adafd6-226e-4978-a48c-66f53affd3ac",
                          value: [],
                          isVisible: true,
                        },
                      ],
                      isVisible: true,
                    },
                    {
                      key: "8a7cfdae-1d47-41f9-a366-5ba020c1b90f",
                      value: [],
                      isVisible: true,
                    },
                  ],
                  isVisible: true,
                },
                {
                  key: "90df628b-64cd-46db-bd1e-427897977b63",
                  value: [
                    {
                      key: "252ba9e4-bbba-4660-be9e-470b263c4832",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "cdeffda1-efd5-44c7-b256-1822dd5bafe0",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "05d989a9-7824-459d-aeb3-cc13f1f70c00",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "79a46f97-b0e6-4ea7-a85a-e6430c625257",
                      value: [
                        {
                          key: "4a8f53d5-34c2-4bda-b3ea-ec2b157efe67",
                          value: [],
                          isVisible: true,
                        },
                        {
                          key: "9d7b601c-dc39-4460-a3ab-3bc1178cbae7",
                          value: [],
                          isVisible: true,
                        },
                        {
                          key: "5eea74fb-2cdc-4897-8f04-ce31341f7296",
                          value: [],
                          isVisible: true,
                        },
                      ],
                      isVisible: true,
                    },
                    {
                      key: "35d426ec-2cac-440b-9482-9681c2eae8d4",
                      value: [
                        {
                          key: "151ea0ca-b4a5-4764-a461-67d0c300c915",
                          value: [],
                          isVisible: true,
                        },
                        {
                          key: "f4838f1a-b32e-43bd-9f3e-6de9ff72526d",
                          value: [],
                          isVisible: true,
                        },
                      ],
                      isVisible: true,
                    },
                    {
                      key: "ea846529-712c-4b47-953f-5b39015c4f17",
                      value: [],
                      isVisible: true,
                    },
                  ],
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
            {
              key: "ba17cce1-b542-42a8-a22a-d6a541428858",
              value: [
                {
                  key: "bf6dcb0a-19de-4c77-8ff6-76e57f93ceef",
                  value: [
                    {
                      key: "27bb57dd-0fd8-4595-aaca-53bf1634ca87",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "ffd1a4f7-c19e-4e75-b722-11c6e3fae511",
                      value: [],
                      isVisible: true,
                    },
                  ],
                  isVisible: true,
                },
                {
                  key: "142f6497-9e25-44e9-a79a-f19acade29b9",
                  value: [
                    {
                      key: "50117239-0a0b-427d-9c23-f33c766b2b3a",
                      value: [],
                      isVisible: true,
                    },
                    {
                      key: "3737df7d-c0e0-4a24-8bce-346a479971f5",
                      value: [],
                      isVisible: true,
                    },
                  ],
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
            {
              key: "2a52ac1f-dac3-4d82-83cb-18d2a4b98be2",
              value: [
                {
                  key: "60c13abc-b075-475c-86c5-fa69a7cae259",
                  value: [],
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
            {
              key: "97941562-f066-4520-bf07-fc3330832c68",
              value: [
                {
                  key: "16a24d4d-5846-46f3-89b6-2427ca329dd4",
                  value: [
                    {
                      key: "51240498-fcab-4342-8522-2bf14c84ed39",
                      value: null,
                      isVisible: true,
                    },
                  ],
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
          ],
          isVisible: true,
        },
        {
          key: "1483a8d1-adca-4f39-8038-98cc6f728fcb",
          value: [
            {
              key: "88ff2a6e-3702-4687-9f05-2738f70ccbd0",
              value: [
                {
                  key: "2c6d9562-dea5-488b-9afe-42519c636daf",
                  value: [],
                  isVisible: true,
                },
                {
                  key: "cd0e811a-d9bb-49ee-b0f0-1dae0680eb22",
                  value: [],
                  isVisible: true,
                },
                {
                  key: "62fb0107-a68d-4ba2-822b-bc8adc54369b",
                  value: [],
                  isVisible: true,
                },
                {
                  key: "b1f8c095-fe0c-4bb3-b493-902dc0c31232",
                  value: [],
                  isVisible: true,
                },
                {
                  key: "9b58c917-b14c-4eed-9b3c-40c3d99d45da",
                  value: [],
                  isVisible: true,
                },
                {
                  key: "fd85f91f-2803-41bc-b315-ede3a4a66d4e",
                  value: [],
                  isVisible: true,
                },
              ],
              isVisible: true,
            },
          ],
          isVisible: true,
        },
        {
          key: "a8dbe47f-c740-4edb-8605-451ed9fe6c87",
          value: this.version(),
          isVisible: true,
        },
      ],
      isVisible: true,
    };
  },

  newStoreDeclaration(){
    return {
      json:this.newDeclaracao(),
      estado: null,
      natureza: null,
      cargo: null,
      orgao: null,
      titular: null,
      RelatedDeclarationId: null,
      EntityName: null,
      BoardName: null,
      RoleName: null,
      HolderName: null,
      NIF: null,
    }
  },
  
  getVersion(declaracao){
    if (declaracao == null) {
      return 1
    }
    let version = this.getFieldByKey(declaracao,"a8dbe47f-c740-4edb-8605-451ed9fe6c87")
  
    if (version == null) {
      return 1
    }
    return version.value
  },
  updateVersion(declaracao){
    // if (
    //   !this.setFieldValueByKey(declaracao.value, "a8dbe47f-c740-4edb-8605-451ed9fe6c87", this.version())
    // ) {
    //   declaracao.value.push({
    //     key: "a8dbe47f-c740-4edb-8605-451ed9fe6c87",
    //     value: this.version(),
    //     isVisible: true,
    //   })


    // }
    const objeto = {
      key: "a8dbe47f-c740-4edb-8605-451ed9fe6c87",
      value: this.version(),
      isVisible: true,

    }

    const objetoExistente = declaracao.Data.value.find((item) => item.key === objeto.key);
    if (!objetoExistente) {
      declaracao.Data.value.push(objeto);
    }
  },

  setFieldValueByKey(declaracao, key, newVal) {
    var founded = null;
    declaracao.forEach((element) => {
      if (element.key == key) {
        element.value = newVal;
        founded = element;
      }

      if (founded == null) {
        if (element.value != null && Array.isArray(element.value)) {
          founded = this.setFieldValueByKey(element.value, key, newVal);
        }
      }
    });
    return founded;
  },

  updateItemValue(items, key, value) {
    if (!Array.isArray(items)) return;

    let founded = false;
    items.forEach((item) => {
      if (item.key == key) {
        console.log("item",item);
        
        item.value = value;
      }

      if (!founded) {
        if (item.value != null && Array.isArray(item.value)) {
          this.updateItemValue(item.value, key, value);
        }
      }
    });
  },

  getFieldByKey(declaracao, key) {
    if (declaracao == null)
      return {
        isVisible: false,
        value: null,
      };

    var founded = null;
    let d = Array.isArray(declaracao) ? declaracao : declaracao.value;

    d.forEach((element) => {
      if (element.key == key) {
        founded = element;
      }

      if (founded == null) {
        if (element.value != null && Array.isArray(element.value)) {
          founded = this.getFieldByKey(element.value, key);
        }
      }
    });
    return founded;
  },

  validadeItems(obj) {
    if (isProxy(obj)) obj = toRaw(obj);

    if (!obj) return obj;

    if (isProxy(obj)) obj = toRaw(obj);

    const lang = resources.selectLang("pt");

    if (!Array.isArray(obj)) {
      if (!obj.isVisible) {
        obj.value = lang.decrarationRestrictReasonEnum[obj.reason];
      }
    } else {
      obj.value.forEach((item) => {
        obj.value = this.validadeItems(item);
      });
    }

    return obj;
  },

  getAllGUIDToValidate() {
    return [
      "d34934dc-dc8f-4515-abc3-eb2a16d58ed1", //titular
      "c83b94a8-5d7a-4061-a001-0c3522d2e533", //entidade
      "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //cargo/funcão
      "418cdc5e-7f77-4922-b0b7-3ab6b2f84c6f", //data inicio funções
      "d3fd512b-65e8-4550-90d9-018a5ef91c0d", //email
      "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b", //nome completo
      "54854c64-5845-40bb-a536-8c03da55420a", //sexo
      "97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e", //nic
      "a5bf96eb-8af0-4c05-bb76-a15f57f8490d", //naturalidade
      "d155af2b-21e9-475f-be81-8e42bc5a5641", //nif
      "25fa81a1-70d2-4fca-8292-4717ba2e8cdb", //data nascimento
      "ed9a8413-b9f0-4d32-89bd-03593c826211", //estado civil
      "37b963d3-b73a-4da7-9a78-cb417f92214a", //morada
      "38cffadd-0893-47ef-b261-84007c381639", //codigo postal
      "308ccc8a-452a-4ed7-94e5-5ca17a1667fe", //localidade
      "77902677-0143-437c-ae9b-b3e54642a080", //freguesia
      "2b75bfd8-392e-49d7-866f-1353a5e765c3", //concelho
      "8b022aca-fdf8-4391-8d49-73abc4e63128", //telemovel
    ];
  },

  getAllGuidTablesToClear() {
    return [
    "60c13abc-b075-475c-86c5-fa69a7cae259",
    "da9c415a-e950-4ce9-ab58-5ce1c1b5af53",
    "1a5d2580-aaf3-4874-9e20-68ce10671263",
    "4a8f53d5-34c2-4bda-b3ea-ec2b157efe67",
    "e498015f-062e-4fcd-93a8-c5f1393f568b",
    "9d7b601c-dc39-4460-a3ab-3bc1178cbae7",
    "7bf6ccdd-f797-4339-8c76-18a248882016",
    "5eea74fb-2cdc-4897-8f04-ce31341f7296",
    "873b46af-f3a5-4329-9aa0-a04fd1114467",
    "151ea0ca-b4a5-4764-a461-67d0c300c915",
    "58adafd6-226e-4978-a48c-66f53affd3ac",
    "f4838f1a-b32e-43bd-9f3e-6de9ff72526d",
    "53168cfb-433e-41e6-9b23-33c1eac7fa6f",
    "05d989a9-7824-459d-aeb3-cc13f1f70c00",
    "8a7cfdae-1d47-41f9-a366-5ba020c1b90f",
    "ea846529-712c-4b47-953f-5b39015c4f17",
    "112c6561-4a51-4f79-b637-bbd8d2c0c74c",
    "252ba9e4-bbba-4660-be9e-470b263c4832",
    "70fdd906-58d5-4682-967d-90ae90805436",
    "cdeffda1-efd5-44c7-b256-1822dd5bafe0",
    "ffd1a4f7-c19e-4e75-b722-11c6e3fae511",
    "3737df7d-c0e0-4a24-8bce-346a479971f5",
    "27bb57dd-0fd8-4595-aaca-53bf1634ca87",
    "50117239-0a0b-427d-9c23-f33c766b2b3a",
    "62fb0107-a68d-4ba2-822b-bc8adc54369b",
    "2c6d9562-dea5-488b-9afe-42519c636daf",
    "cd0e811a-d9bb-49ee-b0f0-1dae0680eb22",
    "fd85f91f-2803-41bc-b315-ede3a4a66d4e",
    "b1f8c095-fe0c-4bb3-b493-902dc0c31232",
    "9b58c917-b14c-4eed-9b3c-40c3d99d45da",
    ]
  },
  getAllGuidToClear() {
    return [
      "d3fd512b-65e8-4550-90d9-018a5ef91c0d", //email
      "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b", //nome completo
      "54854c64-5845-40bb-a536-8c03da55420a", //sexo
      "97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e", //nic
      "a5bf96eb-8af0-4c05-bb76-a15f57f8490d", //naturalidade
      "d155af2b-21e9-475f-be81-8e42bc5a5641", //nif
      "25fa81a1-70d2-4fca-8292-4717ba2e8cdb", //data nascimento
      //"ed9a8413-b9f0-4d32-89bd-03593c826211", //estado civil
      "37b963d3-b73a-4da7-9a78-cb417f92214a", //morada
      "38cffadd-0893-47ef-b261-84007c381639", //codigo postal
      "308ccc8a-452a-4ed7-94e5-5ca17a1667fe", //localidade
      "77902677-0143-437c-ae9b-b3e54642a080", //freguesia
      "2b75bfd8-392e-49d7-866f-1353a5e765c3", //concelho
      "8b022aca-fdf8-4391-8d49-73abc4e63128", //telemovel
    ];
  },

  getTab1Guids() {
    return [
      "c83b94a8-5d7a-4061-a001-0c3522d2e533", //Entidade
      "21cdd43f-aebd-43c1-8b47-0eafedb644c8", //Orgão
      //"22cdd43f-aebd-43c1-8b47-0eafedb644c8", //Outro Orgão
      "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //Cargo
      //"6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", //Outro Cargo
      "d34934dc-dc8f-4515-abc3-eb2a16d58ed1", //Titular
      //"d34934dc-dc8f-4515-abc3-eb2a16d58ed1", //Outro Titular
      "418cdc5e-7f77-4922-b0b7-3ab6b2f84c6f", //Data de início de funções/recondução/ reeleição
      //"5783eba6-4662-4e01-a1ab-14dbe7bb693c", //Data de cessação de funções
      "b8de313d-cc0d-445d-b211-368794d7a2df", //Exercício de funções em regime de exclusividade
    ];
  },

  getTab2Guids() {
    return [
      "d3fd512b-65e8-4550-90d9-018a5ef91c0d", //email
      "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b", //nome completo
      "54854c64-5845-40bb-a536-8c03da55420a", //sexo
      "97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e", //nic
      "a5bf96eb-8af0-4c05-bb76-a15f57f8490d", //naturalidade
      "d155af2b-21e9-475f-be81-8e42bc5a5641", //nif
      "25fa81a1-70d2-4fca-8292-4717ba2e8cdb", //data nascimento
      "ed9a8413-b9f0-4d32-89bd-03593c826211", //estado civil
      "37b963d3-b73a-4da7-9a78-cb417f92214a", //morada
      "38cffadd-0893-47ef-b261-84007c381639", //codigo postal
      "308ccc8a-452a-4ed7-94e5-5ca17a1667fe", //localidade
      "77902677-0143-437c-ae9b-b3e54642a080", //freguesia
      "2b75bfd8-392e-49d7-866f-1353a5e765c3", //concelho
      //"8b022aca-fdf8-4391-8d49-73abc4e63128", //telemovel
      //"ed9a8413-b9f0-4d32-89bd-03593c826212", //Nome do cônjuge
      //"ed9a8413-b9f0-4d32-89bd-03593c826213", //Regime de Bens
    ];
  },

  isValidTab1Declaration(declaration) {
    let GUIDToValidate = this.getTab1Guids();
    let result = true;

    GUIDToValidate.forEach((guid) => {
      let field = this.getFieldByKey(declaration, guid);

      if (field == null) {
        result = false;
      }

      if (utils.isNullOrEmpty(field.value)) {
        result = false;
      }
    });

    let entidade = this.getFieldByKey(
      declaration,
      "c83b94a8-5d7a-4061-a001-0c3522d2e533"
    );
    let outraEntidade = this.getFieldByKey(
      declaration,
      "c83b94a9-5d7a-4061-a001-0c3522d2e533"
    );

    let orgao = this.getFieldByKey(
      declaration,
      "21cdd43f-aebd-43c1-8b47-0eafedb644c8"
    );
    let outroOrgao = this.getFieldByKey(
      declaration,
      "22cdd43f-aebd-43c1-8b47-0eafedb644c8"
    );

    let cargo = this.getFieldByKey(
      declaration,
      "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67"
    );
    let outroCargo = this.getFieldByKey(
      declaration,
      "6a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67"
    );

    let titular = this.getFieldByKey(
      declaration,
      "d34934dc-dc8f-4515-abc3-eb2a16d58ed1"
    );
    let outroTitular = this.getFieldByKey(
      declaration,
      "d34723dc-dc8f-4515-abc3-eb2a16d58ed1"
    );
    
    let natureza = this.getFieldByKey(
      declaration,
      "f649defc-e001-4866-9c2b-01ed9fa80eef"
    );

    let dataCessacaoFuncoes = this.getFieldByKey(
      declaration,
      "5783eba6-4662-4e01-a1ab-14dbe7bb693c"
    );

    if (entidade != null) {
      if (
        entidade.value == -1 &&
        (outraEntidade == null ||
          outraEntidade.value == null ||
          outraEntidade.value.trim() == "")
      ) {
        return false;
      }
    }

    if (orgao != null) {
      if (
        orgao.value == -1 &&
        (outroOrgao == null ||
          outroOrgao.value == null ||
          outroOrgao.value.trim() == "")
      ) {
        return false;
      }
    }

    if (cargo != null) {
      if (
        cargo.value == -1 &&
        (outroCargo == null ||
          outroCargo.value == null ||
          outroCargo.value.trim() == "")
      ) {
        return false;
      }
    }

    if (titular != null) {
      if (
        titular.value == -1 &&
        (outroTitular == null ||
          outroTitular.value == null ||
          outroTitular.value.trim() == "")
      ) {
        return false;
      }
    }

    if(natureza != null){
      if(natureza.value == "Cessação" || natureza.value ==  "Final"){
        if(!dataCessacaoFuncoes.value){
          return false;
        }
      }
    }

    return result;
  },

  isValidTab2Declaration(declaration) {
    let GUIDToValidate = this.getTab2Guids();
    let result = true;

    GUIDToValidate.forEach((guid) => {
      let field = this.getFieldByKey(declaration, guid);
      if (field == null) {
        result = false;
      }

      if (utils.isNullOrEmpty(field.value)) {
        result = false;
      }
    });

    let estadoCivil = this.getFieldByKey(
      declaration,
      "ed9a8413-b9f0-4d32-89bd-03593c826211"
    );

    let nomeConjugue = this.getFieldByKey(
      declaration,
      "ed9a8413-b9f0-4d32-89bd-03593c826213"
    );

    let regimeBens = this.getFieldByKey(
      declaration,
      "ed9a8413-b9f0-4d32-89bd-03593c826212"
    );

    //Nome do cônjuge
    if ((estadoCivil.value == 1 || estadoCivil.value == 4 || estadoCivil.value == 5) && nomeConjugue !== null) {
      if (utils.isNullOrEmpty(nomeConjugue.value)) {
        result = false;
      }
    }

    //Regime de Bens
    if (estadoCivil.value == 1 && regimeBens !== null) {
      if (regimeBens.value == null || regimeBens.value < 0) {
        result = false;
      }
    }

    return result;
  },

  isValidTab4Declaration() {
    let result = true;
    //card C01025P002S00-1440
    //é para deixar de validar

    // const exercicioFuncao = this.getFieldByKey(
    //   declaration,
    //   "b8de313d-cc0d-445d-b211-368794d7a2df"
    // );
    // if (exercicioFuncao.value > 0) {
    //   const atividades = this.getFieldByKey(
    //     declaration,
    //     "2c6d9562-dea5-488b-9afe-42519c636daf"
    //   );
    //   if (!atividades.value.length) {
    //     result = false;
    //   }
    // }

    return result;
  },

  validateDeclaration(declaration) {
    let result = true;

    if (!this.validadeOtherHolder(declaration)) result = false;

    if (!this.isValidTab1Declaration(declaration)) result = false;
    if (!this.isValidTab2Declaration(declaration)) result = false;
    if (!this.isValidTab4Declaration(declaration)) result = false;

    return result;
  },

  validadeOtherHolder(declaration){
    let titular = this.getFieldByKey(
      declaration,
      "d34934dc-dc8f-4515-abc3-eb2a16d58ed1"
    ).value;
    let outroTitular = this.getFieldByKey(
      declaration,
      "d34723dc-dc8f-4515-abc3-eb2a16d58ed1"
    ).value;
    let NomeCompleto = this.getFieldByKey(
      declaration,
      "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b"
    ).value;

    let holderName = store.state.declaracao.HolderName

    if (titular == -1) {
      if (utils.normalizeString(outroTitular) != utils.normalizeString(NomeCompleto)) {
       return false
      }
    } else if (!utils.isNullOrEmpty(holderName)) {
      if (utils.normalizeString(holderName) != utils.normalizeString(NomeCompleto)) {
        return false
       }
    }

    return true
  },

  updateStore() {},

  storeToModel() {
    let model = {
      Nature: store.state.declaracao.natureza,
      HolderId: store.state.declaracao.titular,
      Data: store.state.declaracao.json,
      RoleId: store.state.declaracao.cargo,
      EntityId: store.state.declaracao.entidade,
      BoardId: store.state.declaracao.orgao,
      RelatedDeclarationId: store.state.declaracao.RelatedDeclarationId,
      DraftId: store.state.declaracao.draftId,
      Id: store.state.declaracao.draftId,
      EntityName: store.state.declaracao.EntityName,
      BoardName: store.state.declaracao.BoardName,
      RoleName: store.state.declaracao.RoleName,
      HolderName: store.state.declaracao.HolderName,
      attachments: store.state.declaracao.attachments,
      NIF: store.state.declaracao.NIF,
    };

    return model;
  },

  responseToStore(response, id) {
    store.state.declaracao.startDate = response.data.submitedDate;
    store.state.declaracao.json = response.data.data;
    store.state.declaracao.estado = response.data.stateType;
    store.state.declaracao.natureza = response.data.natureType;
    store.state.declaracao.cargo = response.data.roleId;
    store.state.declaracao.orgao = response.data.boardId;
    store.state.declaracao.titular = response.data.holderId;
    store.state.declaracao.entidade = response.data.entityId;
    store.state.declaracao.RelatedDeclarationId = response.data.relatedDeclarationId;
    store.state.declaracao.draftId = id;
    store.state.declaracao.EntityName = response.data.entity;
    store.state.declaracao.BoardName = response.data.board;
    store.state.declaracao.RoleName = response.data.role;
    store.state.declaracao.HolderName = response.data.holder;
    store.state.declaracao.attachments = response.data.attachments;
    store.state.declaracao.alreadyPublished = response.data.alreadyPublished;

    // Atribui os Ids corretamente para o caso da declaração ser retificada.
    var json = store.state.declaracao.json.value;
    this.setFieldValueByKey(json, "c83b94a8-5d7a-4061-a001-0c3522d2e533", response.data.entityId);
    this.setFieldValueByKey(json, "21cdd43f-aebd-43c1-8b47-0eafedb644c8", response.data.boardId);
    this.setFieldValueByKey(json, "5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67", response.data.roleId);
    this.setFieldValueByKey(json, "d34934dc-dc8f-4515-abc3-eb2a16d58ed1", response.data.holderId);

    if (response.data.nif == null) {
      store.state.declaracao.NIF = "123456789";
    } else {
      store.state.declaracao.NIF = response.data.nif;
    }
  },

  canEditDeclaration(){
    var auth = authentication.get();
    var isHolder = auth != null && !auth.expired && this.checkProfiles(1)

    var checkState = store.state.declaracao.estado == 1 || (store.state.declaracao.estado == 4 && !store.state.declaracao.alreadyPublished )
    
    var result = isHolder && checkState
    
    return result;
  },
  checkProfiles(profileId){
    const auth = authentication.get();
  
      for (var i = 0; i < auth.profiles.length; i++) {
        const profile = auth.profiles[0];
        if (profile == profileId) return true;
      }
  
      return false;
  },

  NewTableItem(guid, id,colNumber, isEmpty = false){
    let value = []
    for (let col = 1; col <= colNumber; col++) {
      value.push({
        key: `${guid}-col${col}`,
        value: null,
        isVisible: true,
      })
    }
    return {
      key: `${guid}_${id}`,
      value: value,
      isVisible: true,
      isEmpty: isEmpty,
    };
  },
  isNothingDeclare(table = []){
    console.log("table",table);
    
    //verifica as tabelas da declaração se estão vazias para mostara a msg "nada a declarar"
    return table.length == 0 || (table[0].isEmpty && !this.checkIfAnyHAveReason(table[0].value) && this.checkIfAllrowsAreNull(table[0].value))
  },
  checkIfAnyHAveReason(rows){
    //verifica se alguma das colunas de uma linha tem razao para estar bloqueada
    let result = false
    rows.forEach(row => {
      if (row.reason != null) {
        result = true
      }
    });
    return result
  },
  checkIfAllrowsAreNull(rows){
    //verifica se alguma das colunas tem valor ou n¬ao
    let result = true
    rows.forEach(row => {
      if (row.value != null) {
        result = false
      }
    });
    return result
  },
  nothingToDeclareEmptyRow(declaration, guid,colNumber){
    //quando um user entrega uma declaração é preciso percorrer as tabelas que estão fazias e adicionar uma linha fantasma
    if (this.declarationTableIsEmpty(declaration,guid)) {
      let value = this.getFieldByKey(
        declaration,
        guid
      );
      if (value != null && value.value != null) {
        
        value.value[0] = this.NewTableItem(
          guid,
          0,
          colNumber,
          true
          );
        }
    }

  },
  declarationTableIsEmpty(declaration,key){
    //verifica se a tabela por guid esta vazia
    let value = this.getFieldByKey(
      declaration,
      key
    );

    let result = null;

    if (Array.isArray(value.value)) {
      result = !value.value.length;
    } else {
      result = !value.value;
    }

    if (result) this.hasInformation = true;

    return result;
  },
  clearTablesNothingToDeclareEmptyRow(){
    //limpa todas as tabelas que tenham a linha fantasma
    const guidTablesToClear = this.getAllGuidTablesToClear();
    guidTablesToClear.forEach((guid) => {

      this.resetIsDeletedByKey(store.state.declaracao.json.value,guid)

      var table = this.getFieldByKey(
        store.state.declaracao.json.value,
        guid
      );
      
      if (this.isNothingDeclare(table.value)) {
        this.setFieldValueByKey(
          store.state.declaracao.json.value,
          guid,
          []
        );
      }
    });
  },
  tableSizeByGuid(guid){
    let tables = {
      "da9c415a-e950-4ce9-ab58-5ce1c1b5af53":7,
      "60c13abc-b075-475c-86c5-fa69a7cae259":4,
      "1a5d2580-aaf3-4874-9e20-68ce10671263":10,
      "4a8f53d5-34c2-4bda-b3ea-ec2b157efe67":10,
      "e498015f-062e-4fcd-93a8-c5f1393f568b":11,
      "9d7b601c-dc39-4460-a3ab-3bc1178cbae7":11,
      "7bf6ccdd-f797-4339-8c76-18a248882016":9,
      "5eea74fb-2cdc-4897-8f04-ce31341f7296":9,
      "873b46af-f3a5-4329-9aa0-a04fd1114467":10,
      "151ea0ca-b4a5-4764-a461-67d0c300c915":10,
      "58adafd6-226e-4978-a48c-66f53affd3ac":10,
      "f4838f1a-b32e-43bd-9f3e-6de9ff72526d":10,
      "53168cfb-433e-41e6-9b23-33c1eac7fa6f":28,
      "05d989a9-7824-459d-aeb3-cc13f1f70c00":27,
      "8a7cfdae-1d47-41f9-a366-5ba020c1b90f":14,
      "ea846529-712c-4b47-953f-5b39015c4f17":14,
      "112c6561-4a51-4f79-b637-bbd8d2c0c74c":16,
      "252ba9e4-bbba-4660-be9e-470b263c4832":12,
      "70fdd906-58d5-4682-967d-90ae90805436":17,
      "cdeffda1-efd5-44c7-b256-1822dd5bafe0":10,
      "ffd1a4f7-c19e-4e75-b722-11c6e3fae511":4,
      "3737df7d-c0e0-4a24-8bce-346a479971f5":4,
      "27bb57dd-0fd8-4595-aaca-53bf1634ca87":7,
      "50117239-0a0b-427d-9c23-f33c766b2b3a":7,
      "62fb0107-a68d-4ba2-822b-bc8adc54369b":9,
      "2c6d9562-dea5-488b-9afe-42519c636daf":9,
      "cd0e811a-d9bb-49ee-b0f0-1dae0680eb22":8,
      "fd85f91f-2803-41bc-b315-ede3a4a66d4e":5,
      "b1f8c095-fe0c-4bb3-b493-902dc0c31232":7,
      "9b58c917-b14c-4eed-9b3c-40c3d99d45da":8,
    }
    return tables[guid]
  },
  intToArray(num) {
    return Array.from({ length: num }, (_, index) => index);
  },
  clearCols(item,cols = []){
    cols.forEach(col => {
      item.value[col].value = null
    });
  },
  itemClearCols(item,cols = 0, saveCols = []){
    let colsToClear = this.intToArray(cols)
    
    saveCols.forEach(col => {
      const index = colsToClear.indexOf(col);
      colsToClear.splice(index, 1);
    });

    colsToClear.forEach(col => {
      item.value[col].value = null
    });
  },

  
  resetIsDeletedByKey(declaracao, key) {
    var founded = null;
    declaracao.forEach((element) => {
      if (element.key == key) {
        element.isDeleted = false;
        founded = element;
      }

      if (founded == null) {
        if (element.value != null && Array.isArray(element.value)) {
          founded = this.resetIsDeletedByKey(element.value, key);
        }
      }
    });
    return founded;
  },
};
