import service from "./service-base";

export default {
    portfolioTitleSpeciesType: () => {
        return service.get(`/parameter/group/portfolioTitleSpeciesType`);
    },
    registrationInterestOtherSituationType: () => {
        return service.get(`/parameter/group/registrationInterestOtherSituation`);
    }
};
