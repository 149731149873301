<template>
  <CustomCard>
    <div class="p-5">
      <div class="row mb-1">
        <div class="col-12 ta-last-left">
          <span class="title">{{ selectedLang.personalData }}</span>
          <button
            type="button"
            @click="cancel"
            class="btn btnS btnSecundary f-r"
          >
            {{ selectedLang.cancel }}
          </button>
          <button
            type="button"
            @click="submit"
            class="btn btnS btnPrimary f-r mr-1"
          >
            {{ selectedLang.save }}
          </button>
        </div>
        <div class="col-12">
          <hr class="rounded" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8 mb-4">
          <CustomInput
            type="Text"
            :label="selectedLang.completeName"
            v-model="user.name"
            :required="true"
            :showErrorMsg="!formNameIsValid && validateForm"
            :placeholder="selectedLang.requiredField"
            :disabled="isDisabledName"
            :maxlength="150"
          />
        </div>
        <div class="col-12 col-lg-4 mb-4">
          <CustomInput
            type="RadioBtn"
            :label="selectedLang.sexTxt"
            v-model="user.sexo"
            :placeholder="null"
            :disabled="loading"
            :required="true"
            :options="genderOptions"
            :searchable="false"
            :maxval="null"
            :maxDate="null"
            :loading="false"
            :errorMsg="null"
            :canDeselect="false"
            :canClear="false"
            :parseIntProp="false"
            groupName="gender"
            :showErrorMsg="validateForm && !formSexoIsValid"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <CustomInput
            type="Number"
            :label="selectedLang.NIC"
            v-model="user.nic"
            :required="true"
            :showErrorMsg="validateForm && !formNicIsValid"
            :placeholder="selectedLang.add"
            :disabled="isDisabledNic"
            :maxlength="8"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <CustomInput
            type="Number"
            :label="selectedLang.NIF"
            v-model="user.nif"
            :required="true"
            :showErrorMsg="validateForm && !formNifIsValid"
            :placeholder="selectedLang.add"
            :disabled="isDisabledNif"
            :maxlength="9"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <CustomInput
            type="Text"
            :label="selectedLang.naturalness"
            v-model="user.naturalidade"
            :required="true"
            :showErrorMsg="validateForm && !formNaturalidadeIsValid"
            :placeholder="selectedLang.add"
            :disabled="loading"
            :maxlength="40"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <CustomInput
            type="DatePicker"
            :label="selectedLang.birthdate"
            v-model="user.dataNascimento"
            :disabled="isDisabledDataNascimento"
            :required="true"
            :showErrorMsg="validateForm && !formDataNascimentoIsValid"
          />
        </div>
        <!-- <div class="col-12 col-md-6 col-lg-4 mb-4 d-none">
          <label class="required form-label">{{
            selectedLang.maritalStatus
          }}</label>
          <VueMultiselect
            :class="{ invalid: !formEstadoCivilIsValid && validateForm }"
            v-model="user.estadoCivil"
            :options="estadoCivilOptions"
            :searchable="true"
            :placeholder="selectedLang.add"
            :noResultsText="selectedLang.noResultsText"
          />
          <InputInvalidMsg
            :show="!formEstadoCivilIsValid && validateForm"
            :msg="selectedLang.requiredField"
          />
        </div> -->
      </div>
      <div class="row mb-1 mt-2">
        <div class="col-12 ta-last-left">
          <span class="title">{{ selectedLang.household }}</span>
        </div>
        <div class="col-12">
          <hr class="rounded" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8 mb-4">
          <CustomInput
            type="Text"
            :label="selectedLang.household"
            v-model="user.morada"
            :required="true"
            :showErrorMsg="validateForm && !formMoradaIsValid"
            :placeholder="selectedLang.add"
            :disabled="loading"
            :maxlength="95"
          />
        </div>
        <div class="col-12 col-lg-4 mb-4">
          <CustomInput
            :showErrorMsg="!formCodigoPostalIsValid && validateForm"
            type="Zip"
            :label="selectedLang.postalCode"
            v-model="user.codigoPostal"
            :required="true"
            :disabled="loading"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <CustomInput
            type="Text"
            :label="selectedLang.location"
            v-model="user.localidade"
            :required="true"
            :showErrorMsg="!formLocalidadeIsValid && validateForm"
            :placeholder="selectedLang.add"
            :disabled="loading"
            :maxlength="40"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <CustomInput
            type="Text"
            :label="selectedLang.parish"
            v-model="user.freguesia"
            :required="true"
            :showErrorMsg="!formFreguesiaIsValid && validateForm"
            :placeholder="selectedLang.add"
            :disabled="loading"
            :maxlength="60"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <CustomInput
            type="Text"
            :label="selectedLang.county"
            v-model="user.concelho"
            :required="true"
            :showErrorMsg="!formConcelhoIsValid && validateForm"
            :placeholder="selectedLang.add"
            :disabled="loading"
            :maxlength="80"
          />
        </div>
      </div>
      <div class="row mb-1 mt-2">
        <div class="col-12 ta-last-left">
          <span class="title">{{ selectedLang.contacts }}</span>
        </div>
        <div class="col-12">
          <hr class="rounded" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <CustomInput
            type="PhoneNumber"
            :label="selectedLang.mobilePhone"
            v-model="user.telemovel"
            :required="false"
            :placeholder="selectedLang.add"
            :disabled="loading"
            :maxlength="20"
          />
        </div>
        <div class="col-12 col-md-6 mb-4">
          <CustomInput
            v-model="user.email"
            type="Text"
            :label="selectedLang.email"
            :disabled="loading"
            :maxlength="100"
            :required="true"
            :showErrorMsg="validateForm && !formEmailIsValid"
            :errorMsg="emailErrorMsg"
          />
        </div>
      </div>
      <div class="row mb-1 mt-2">
        <div class="col-12 ta-last-left">
          <span class="title">{{ selectedLang.password }}</span>
        </div>
        <div class="col-12">
          <hr class="rounded" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <label class="required form-label">{{ selectedLang.password }}</label>
          <div class="input-group">
            <input
              maxlength="20"
              :class="{ invalid: !formPasswordIsValid && validateForm }"
              v-if="showPassword"
              type="text"
              class="form-control br-0"
              :placeholder="selectedLang.add"
              v-model="user.password"
              :disabled="loading"
            />
            <input
              maxlength="20"
              :class="{ invalid: !formPasswordIsValid && validateForm }"
              v-else
              type="password"
              class="form-control br-0"
              :placeholder="selectedLang.add"
              v-model="user.password"
              :disabled="loading"
            />
            <div
              class="input-group-append"
              :class="{ invalid: !formPasswordIsValid && validateForm }"
            >
              <span
                class="input-group-text bl-0"
                @click="showPassword = !showPassword"
              >
                <template v-if="showPassword">
                  <svg
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4C13.76 4 16 6.24 16 9C16 9.65 15.87 10.26 15.64 10.83L18.56 13.75C20.07 12.49 21.26 10.86 21.99 9C20.26 4.61 15.99 1.5 10.99 1.5C9.59 1.5 8.25 1.75 7.01 2.2L9.17 4.36C9.74 4.13 10.35 4 11 4ZM1 1.27L3.28 3.55L3.74 4.01C2.08 5.3 0.78 7.02 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.8 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.78 8 9C8 10.66 9.34 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C8.24 14 6 11.76 6 9C6 8.21 6.2 7.47 6.53 6.8V6.8ZM10.84 6.02L13.99 9.17L14.01 9.01C14.01 7.35 12.67 6.01 11.01 6.01L10.84 6.02Z"
                      fill="#C2C9D1"
                    />
                    <path
                      d="M11 4C13.76 4 16 6.24 16 9C16 9.65 15.87 10.26 15.64 10.83L18.56 13.75C20.07 12.49 21.26 10.86 21.99 9C20.26 4.61 15.99 1.5 10.99 1.5C9.59 1.5 8.25 1.75 7.01 2.2L9.17 4.36C9.74 4.13 10.35 4 11 4ZM1 1.27L3.28 3.55L3.74 4.01C2.08 5.3 0.78 7.02 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.8 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.78 8 9C8 10.66 9.34 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C8.24 14 6 11.76 6 9C6 8.21 6.2 7.47 6.53 6.8V6.8ZM10.84 6.02L13.99 9.17L14.01 9.01C14.01 7.35 12.67 6.01 11.01 6.01L10.84 6.02Z"
                      fill="black"
                      fill-opacity="0.25"
                    />
                  </svg>
                </template>
                <template v-else>
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                      fill="#C2C9D1"
                    />
                    <path
                      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                      fill="black"
                      fill-opacity="0.25"
                    />
                  </svg>
                </template>
              </span>
            </div>
          </div>

          <InputInvalidMsg
            :show="!formPasswordIsValid && validateForm"
            :msg="selectedLang.invalidPassword"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <label class="required form-label"
            >{{ selectedLang.confirmPassword }}
            <span class="icon-information">
              <tooltip :text="selectedLang.passwordRequirements" size="medium">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                    fill="black"
                    fill-opacity="0.25"
                  />
                </svg>
              </tooltip>
            </span>
          </label>
          <div class="input-group">
            <input
              :class="{
                invalid: !formConfirmarPasswordIsValid && validateForm,
              }"
              maxlength="20"
              v-if="showConfirmarPassword"
              type="text"
              class="form-control br-0"
              :placeholder="selectedLang.add"
              v-model="user.confirmarPassword"
              :disabled="loading"
            />
            <input
              :class="{
                invalid: !formConfirmarPasswordIsValid && validateForm,
              }"
              v-else
              maxlength="20"
              type="password"
              class="form-control br-0"
              :placeholder="selectedLang.add"
              v-model="user.confirmarPassword"
              :disabled="loading"
            />
            <div
              class="input-group-append"
              :class="{
                invalid: !formConfirmarPasswordIsValid && validateForm,
              }"
            >
              <span
                class="input-group-text bl-0"
                @click="showConfirmarPassword = !showConfirmarPassword"
              >
                <template v-if="showConfirmarPassword">
                  <svg
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4C13.76 4 16 6.24 16 9C16 9.65 15.87 10.26 15.64 10.83L18.56 13.75C20.07 12.49 21.26 10.86 21.99 9C20.26 4.61 15.99 1.5 10.99 1.5C9.59 1.5 8.25 1.75 7.01 2.2L9.17 4.36C9.74 4.13 10.35 4 11 4ZM1 1.27L3.28 3.55L3.74 4.01C2.08 5.3 0.78 7.02 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.8 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.78 8 9C8 10.66 9.34 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C8.24 14 6 11.76 6 9C6 8.21 6.2 7.47 6.53 6.8V6.8ZM10.84 6.02L13.99 9.17L14.01 9.01C14.01 7.35 12.67 6.01 11.01 6.01L10.84 6.02Z"
                      fill="#C2C9D1"
                    />
                    <path
                      d="M11 4C13.76 4 16 6.24 16 9C16 9.65 15.87 10.26 15.64 10.83L18.56 13.75C20.07 12.49 21.26 10.86 21.99 9C20.26 4.61 15.99 1.5 10.99 1.5C9.59 1.5 8.25 1.75 7.01 2.2L9.17 4.36C9.74 4.13 10.35 4 11 4ZM1 1.27L3.28 3.55L3.74 4.01C2.08 5.3 0.78 7.02 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.8 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.78 8 9C8 10.66 9.34 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C8.24 14 6 11.76 6 9C6 8.21 6.2 7.47 6.53 6.8V6.8ZM10.84 6.02L13.99 9.17L14.01 9.01C14.01 7.35 12.67 6.01 11.01 6.01L10.84 6.02Z"
                      fill="black"
                      fill-opacity="0.25"
                    />
                  </svg>
                </template>
                <template v-else>
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                      fill="#C2C9D1"
                    />
                    <path
                      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                      fill="black"
                      fill-opacity="0.25"
                    />
                  </svg>
                </template>
              </span>
            </div>
          </div>

          <InputInvalidMsg
            :show="!formConfirmarPasswordIsValid && validateForm"
            :msg="selectedLang.diferentPasswords"
          />
        </div>
      </div>
    </div>
  </CustomCard>
</template>

<script>
import tooltip from "@/components/tooltip.vue";
import authentication from "@/helpers/authentication";
import parameter from "@/helpers/parameter";
import utils from "@/helpers/utils";
//import security from "@/helpers/security";
import userService from "@/services/user.service";
import accountService from "@/services/account.service";

export default {
  name: "PerfilPage",
  components: {
    tooltip,
  },
  data() {
    return {
      loading: false,
      validateForm: false,
      options: {
        naturalidadeOptions: [],
        estadoCivilOptions: [],
        entityOptions: [
          {
            value: 1,
            label: "Entidade1",
          },
        ],
      },
      user: {
        name: null,
        sexo: null,
        nic: null,
        nif: null,
        naturalidade: null,
        estadoCivil: null,
        dataNascimento: null,
        morada: null,
        codigoPostal: null,
        localidade: null,
        freguesia: null,
        concelho: null,
        telemovel: null,
        telefone: null,
        email: null,
        password: null,
        confirmarPassword: null,
      },
      showPassword: false,
      showConfirmarPassword: false,
      disabledNaturalidade: true,

      isDisabledName: true,
      isDisabledNic: true,
      isDisabledNif: true,
      isDisabledDataNascimento: true,
    };
  },
  props: {},
  methods: {
    submit() {
      this.validateForm = true;

      if (this.formIsValid()) {
        this.loading = true;

        const user = {
          name: this.user.name,
          email: this.user.email,
          //password: security.encryptMessage(this.user.password)
          password: this.user.password,
          language: "PT",
          personalDataRequest: {
            gender: this.user.sexo,
            nic: this.user.nic,
            nif: this.user.nif,
            nationality: this.user.naturalidade,
            dateBirth: this.user.dataNascimento,
            address: this.user.morada,
            zipCode: this.user.codigoPostal,
            locale: this.user.localidade,
            parish: this.user.freguesia,
            county: this.user.concelho,
            phone: this.user.telemovel,
            // maritalStatusId: this.user.estadoCivil,
          },
        };

        userService
          .createFull(user)
          .then((response) => {
            if (response.code == 0) {
              accountService
                .loginHolder(user.email, user.password)
                .then((response) => {
                  utils.validateResponse(
                    response,
                    this.selectedLang.perfilCreated,
                    () => {
                      var auth = authentication.create(response);
                      authentication.set(auth);
                      this.$router.push({ name: "ConsultarDeclaracoes" });
                    }
                  );
                })
                .catch((error) => {
                  console.error(error);
                  let errorMsg =
                    error.response?.description ??
                    this.selectedLang.perfilCreatedError;
                  utils.errorToast(errorMsg);
                })
                .finally(() => {
                  this.loading = false;
                });
            } else if (response.code == -43) {
              utils.warningToast(this.selectedLang.userAlreadyExists);
            } else {
              utils.errorToast(this.selectedLang.InternalServerError);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    cancel() {
      this.$router.push({ name: "Login" });
    },
    formIsValid() {
      return (
        this.formNameIsValid &&
        this.formSexoIsValid &&
        this.formNicIsValid &&
        this.formNifIsValid &&
        this.formNaturalidadeIsValid &&
        this.formDataNascimentoIsValid &&
        this.formDataNascimentoIsValid &&
        this.formMoradaIsValid &&
        this.formCodigoPostalIsValid &&
        this.formLocalidadeIsValid &&
        this.formFreguesiaIsValid &&
        this.formConcelhoIsValid &&
        //this.formTelemovelIsValid &&
        this.formEmailIsValid &&
        // this.formEstadoCivilIsValid &&
        this.formPasswordIsValid &&
        this.formConfirmarPasswordIsValid
      );
    },
    checkAOUsrGender(gender) {
      gender = utils.truncate(gender, 1);
      switch (gender) {
        case "M":
        case "m":
          return "M";
        case "F":
        case "f":
          return "F";

        default:
          return "";
      }
    },
  },
  computed: {
    formNameIsValid() {
      return !!this.user.name;
    },
    formSexoIsValid() {
      return this.user.sexo != null;
    },
    formNicIsValid() {
      return !!this.user.nic;
    },
    formNifIsValid() {
      return !!this.user.nif;
    },
    formNaturalidadeIsValid() {
      return !!this.user.naturalidade;
    },
    formDataNascimentoIsValid() {
      return !!this.user.dataNascimento;
    },
    formMoradaIsValid() {
      return !!this.user.morada;
    },
    formCodigoPostalIsValid() {
      return this.user.codigoPostal != null && this.user.codigoPostal != "";
    },
    formLocalidadeIsValid() {
      return !!this.user.localidade;
    },
    formFreguesiaIsValid() {
      return !!this.user.freguesia;
    },
    formConcelhoIsValid() {
      return !!this.user.concelho;
    },
    // formTelemovelIsValid() {
    //   return !!this.user.telemovel;
    // },
    formTelefoneIsValid() {
      return !!this.user.telefone;
    },
    formEmailIsValid() {
      let result = utils.isValidEmail(this.user.email);
      return result;
    },
    emailErrorMsg() {
      return this.formEmailIsValid
        ? this.selectedLang.required
        : this.selectedLang.invalidEmail;
    },
    // formEstadoCivilIsValid() {
    //   return (
    //     this.user.estadoCivil !== null &&
    //     this.user.estadoCivil >= 0 &&
    //     this.user.estadoCivil <= 3
    //   );
    // },
    formPasswordIsValid() {
      return utils.isValidPassword(this.user.password);
    },
    formConfirmarPasswordIsValid() {
      return this.user.confirmarPassword == this.user.password;
    },
    // estadoCivilOptions() {
    //   return [
    //     {
    //       value: 0,
    //       label: this.selectedLang.maritalStatusOptions[0],
    //     },
    //     {
    //       value: 1,
    //       label: this.selectedLang.maritalStatusOptions[1],
    //     },
    //     {
    //       value: 2,
    //       label: this.selectedLang.maritalStatusOptions[2],
    //     },
    //     {
    //       value: 3,
    //       label: this.selectedLang.maritalStatusOptions[3],
    //     },
    //   ];
    // },
    genderOptions() {
      return [
        {
          value: "F",
          label: this.selectedLang.femenine,
        },
        {
          value: "M",
          label: this.selectedLang.masculine,
        },
      ];
    },
  },
  mounted() {
    var key = this.$route.params.key;
    //User do Autenticação GOV
    const aoUsr = parameter.get(key);

    if (aoUsr != null) {
      //isNullOrEmpty
      this.user.name = utils.truncate(aoUsr.name, 150);
      if (utils.isNullOrEmpty(this.user.name)) this.isDisabledName = false;
      this.user.nic = utils.truncate(aoUsr.nic, 8);
      if (utils.isNullOrEmpty(this.user.nic)) this.isDisabledNic = false;
      this.user.nif = utils.truncate(aoUsr.nif, 9);
      if (utils.isNullOrEmpty(this.user.nif)) this.isDisabledNif = false;
      this.user.dataNascimento = utils.truncate(aoUsr.dateBirth, 10);
      if (utils.isNullOrEmpty(this.user.dataNascimento))
        this.isDisabledDataNascimento = false;

      this.user.sexo = this.checkAOUsrGender(aoUsr.gender);
      this.user.naturalidade = utils.truncate(aoUsr.nationality, 40);
      this.user.morada = utils.truncate(aoUsr.address, 95);
      this.user.codigoPostal = utils.truncate(aoUsr.zipCode, 8);
      this.user.localidade = utils.truncate(aoUsr.locale, 40);
      this.user.freguesia = utils.truncate(aoUsr.parish, 60);
      this.user.concelho = utils.truncate(aoUsr.county, 80);
      this.user.telemovel = utils.truncate(aoUsr.phone, 20);
      this.user.email = utils.truncate(aoUsr.email, 40);
    }

    if (!this.user.naturalidade) {
      this.disabledNaturalidade = false;
    }
  },
  watch: {},
};
</script>
<style scoped>
.icon-information {
  position: absolute;
  left: 200px;
  cursor: pointer;
}
</style>
