<template>
  <div>
    <svg
      width="132"
      height="111"
      viewBox="0 0 132 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.6644 64.6588C44.0426 64.6588 33 53.5423 33 39.8294C33 26.1165 44.0426 15 57.6644 15C71.2861 15 82.3287 26.1165 82.3287 39.8294C82.3287 46.6858 79.5681 52.8932 75.1047 57.3865C70.6413 61.8797 64.4753 64.6588 57.6644 64.6588Z"
        fill="#CAEBFF"
      />
      <path
        d="M99 82L75.1047 57.3865M75.1047 57.3865C79.5681 52.8932 82.3287 46.6858 82.3287 39.8294C82.3287 26.1165 71.2861 15 57.6644 15C44.0426 15 33 26.1165 33 39.8294C33 53.5423 44.0426 64.6588 57.6644 64.6588C64.4753 64.6588 70.6413 61.8797 75.1047 57.3865Z"
        stroke="#007AC4"
        stroke-width="6.6875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <h1 class="tal-center">
      {{ selectedLang.hidedHomePageTable }}
    </h1>
  </div>
</template>

<script setup></script>
