<!-- <template>
  <DeclaracaoUnicaV1
    v-if="version == 1"
    :declaracaoUnicaProp="props.declaracaoUnicaProp"
    :declaration="props.declaration"
    :ispublic="props.ispublic"
  />
  <DeclaracaoUnicaV2
    v-if="version == 2"
    :declaracaoUnicaProp="props.declaracaoUnicaProp"
    :declaration="props.declaration"
    :ispublic="props.ispublic"
  />
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import DeclaracaoUnicaV1 from "./v1/DeclaracaoUnica.vue";
import DeclaracaoUnicaV2 from "./v2/DeclaracaoUnica.vue";
import declaracaoUnicaHelper from "@/helpers/declaracaoUnicaHelper";

var props = defineProps({
  declaracaoUnicaProp: {
    type: [Object, Array],
  },
  declaration: {
    type: Object,
  },
  ispublic: {
    type: Boolean,
    default: false,
  },
});

var version = ref(null);

onMounted(() => {
  let declaration = null;
  if (props.declaration.json != null) {
    declaration = props.declaration.json.value;
  } else if (props.declaration.data != null) {
    declaration = props.declaration.data.value;
  }
  version.value = declaracaoUnicaHelper.getVersion(declaration);
});
</script>

<style></style> -->
<template>
  <div>
    <DeclaracaoUnicaTabHeader
      :selectedTab="selectedTab"
      :ispublic="ispublic"
      :declaration="declaration"
      :showExport="showExport"
    />
    <AnexosAccordion
      v-if="!ispublic"
      class="mt-1"
      :declaracaoUnicaProp="declaracaoUnicaProp"
    />
    <div class="p-5">
      <DeclaracaoUnicaTab1
        v-if="showTab1"
        :declaracaoUnicaProp="declaracaoUnicaProp.value"
      />
      <DeclaracaoUnicaTab2
        v-if="showTab2"
        :declaracaoUnicaProp="declaracaoUnicaProp.value"
      />
      <DeclaracaoUnicaTab3
        v-if="showTab3"
        :declaracaoUnicaProp="declaracaoUnicaProp.value"
      />
      <DeclaracaoUnicaTab4
        v-if="showTab4"
        :declaracaoUnicaProp="declaracaoUnicaProp.value"
      />
    </div>
  </div>
</template>

<script>
import DeclaracaoUnicaTab1 from "./DeclaracaoUnicaTab1.vue";
import DeclaracaoUnicaTab2 from "./DeclaracaoUnicaTab2.vue";
import DeclaracaoUnicaTab3 from "./DeclaracaoUnicaTab3.vue";
import DeclaracaoUnicaTab4 from "./DeclaracaoUnicaTab4.vue";
import DeclaracaoUnicaTabHeader from "./DeclaracaoUnicaTabHeader.vue";
import AnexosAccordion from "./AnexosAccordion.vue";

export default {
  components: {
    DeclaracaoUnicaTab1,
    DeclaracaoUnicaTab2,
    DeclaracaoUnicaTab3,
    DeclaracaoUnicaTab4,
    DeclaracaoUnicaTabHeader,
    AnexosAccordion,
  },
  data() {
    return {
      selectedTab: 1,
    };
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
    declaration: [Object],
    ispublic: {
      Boolean,
      default: false,
    },
    showExport: {
      Boolean,
      default: false,
    },
  },

  mounted() {
    this.emitter.on("selectTab", (tabId) => {
      this.selectedTab = tabId;
    });
  },
  computed: {
    showTab1() {
      return this.selectedTab == 1;
    },
    showTab2() {
      return this.selectedTab == 2;
    },
    showTab3() {
      return this.selectedTab == 3;
    },
    showTab4() {
      return this.selectedTab == 4;
    },

    declaracaoUnicaAnexosForm: {
      get() {
        return this.getFieldByKey(
          this.declaracaoUnicaProp,
          "358af850-b811-494a-8a38-81eadaf9d695"
        );
      },
      set(newValue) {
        this.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "358af850-b811-494a-8a38-81eadaf9d695",
          newValue
        );
      },
    },
    declaracaoUnicaTab1Form: {
      get() {
        return this.getFieldByKey(
          this.declaracaoUnicaProp,
          "358af850-b811-494a-8a38-81eadaf9d695"
        );
      },
      set(newValue) {
        this.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "358af850-b811-494a-8a38-81eadaf9d695",
          newValue
        );
      },
    },
    declaracaoUnicaTab2Form: {
      get() {
        return this.getFieldByKey(
          this.declaracaoUnicaProp,
          "7df770a3-e06b-4448-b5ac-c1b162c80fcc"
        );
      },
      set(newValue) {
        this.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "7df770a3-e06b-4448-b5ac-c1b162c80fcc",
          newValue
        );
      },
    },
    declaracaoUnicaTab3Form: {
      get() {
        return this.getFieldByKey(
          this.declaracaoUnicaProp,
          "ecc58cb2-0808-4d2f-bb2b-64b87617a4ac"
        );
      },
      set(newValue) {
        this.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "ecc58cb2-0808-4d2f-bb2b-64b87617a4ac",
          newValue
        );
      },
    },
    declaracaoUnicaTab4Form: {
      get() {
        return this.getFieldByKey(
          this.declaracaoUnicaProp,
          "1483a8d1-adca-4f39-8038-98cc6f728fcb"
        );
      },
      set(newValue) {
        this.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "1483a8d1-adca-4f39-8038-98cc6f728fcb",
          newValue
        );
      },
    },
  },
  methods: {},
};
</script>

<style></style>
