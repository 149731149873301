<template>
  <CustomAccordion
    :title="selectedLang.passive"
    :isOpenDefault="items.value != null && items.value.length > 0"
    :tooltipInfo="selectedLang.passiveInfo"
  >
    <div>
      <DeclaracaoUnicaBlockedData
        v-if="!items.isVisible"
        :reason="items.reason"
      />
      <TableSort
        :showEmptyrow="false"
        :totalItems="1"
        v-if="items.isVisible"
        defaultSort=""
        :checkBox="false"
        :loading="loading"
        :tableModel="tableModel"
        :actions="canEditDeclaration"
        :searchFunc="searchFunc"
        :sortFunc="sortFunc"
        :showPagination="false"
        :showTotal="false"
        :sortHeader="false"
        :key="tableRef"
      >
        <tr
          v-if="
            !canEditDeclaration &&
            declaracaoUnicaHelper.isNothingDeclare(items.value)
          "
        >
          <td colspan="11" class="p-3 jc-center tal-center">
            <h1>
              {{ selectedLang.nothingDeclare }}
            </h1>
          </td>
        </tr>
        <DeclarationTableRow
          v-for="(item, index) in items.value"
          :key="index"
          :rowGUID="item.key"
          v-else
        >
          <template v-slot="slotProps">
            <td>
              <declaration-column-table
                :emphasize="slotProps.emphasize"
                :item="item.value[0]"
              />
            </td>
            <td>
              <declaration-column-table
                :emphasize="slotProps.emphasize"
                :item="item.value[1]"
                type="currency"
              />
            </td>
            <td>
              <declaration-column-table
                :emphasize="slotProps.emphasize"
                :item="item.value[3]"
                type="date"
              />
            </td>
            <td class="d-f" v-if="canEditDeclaration">
              <span @click="openDeleteDetail(item)" class="btnAlike">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.8494 9.14001L18.1994 19.21C18.0894 20.78 17.9994 22 15.2094 22H8.78941C5.99941 22 5.90941 20.78 5.79941 19.21L5.14941 9.14001"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.3301 16.5H13.6601"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="ml-1 mr-1">
                <svg
                  width="1"
                  height="40"
                  viewBox="0 0 1 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499998"
                    y2="40"
                    stroke="#D8D8D8"
                  />
                </svg>
              </span>
              <span @click="editItem(item)" class="btnAlike">
                <svg
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21H21.5"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3845 4.82378C16.0155 3.62383 13.9241 3.74942 12.7122 5.10499C12.7122 5.10499 6.68886 11.8419 4.60018 14.1803C2.50875 16.5174 4.04182 19.746 4.04182 19.746C4.04182 19.746 7.494 20.8285 9.55372 18.5242C11.6148 16.2198 17.6685 9.45019 17.6685 9.45019C18.8804 8.09462 18.7522 6.02372 17.3845 4.82378Z"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.8604 7.29236L15.4255 11.2791"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </td>
            <td v-else>
              <span @click="detailItem(item)" class="btnAlike">
                <IconEye />
              </span>
            </td>
          </template>
        </DeclarationTableRow>
        <tr v-if="canEditDeclaration">
          <td colspan="11" class="p-0 pt-1">
            <span @click="newItem" class="d-f jc-center btnAlike">
              <svg
                style="margin: 9px 0px"
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2241 7.57143V6.42857C12.2241 6.27381 12.1658 6.13988 12.0491 6.02678C11.9324 5.91369 11.7942 5.85714 11.6345 5.85714H9.27579V3.57143C9.27579 3.41667 9.21744 3.28274 9.10074 3.16964C8.98403 3.05655 8.84583 3 8.68613 3H7.50679C7.34709 3 7.20889 3.05655 7.09218 3.16964C6.97548 3.28274 6.91713 3.41667 6.91713 3.57143V5.85714H4.55847C4.39877 5.85714 4.26056 5.91369 4.14386 6.02678C4.02715 6.13988 3.9688 6.27381 3.9688 6.42857V7.57143C3.9688 7.72619 4.02715 7.86012 4.14386 7.97322C4.26056 8.08631 4.39877 8.14286 4.55847 8.14286H6.91713V10.4286C6.91713 10.5833 6.97548 10.7173 7.09218 10.8304C7.20889 10.9435 7.34709 11 7.50679 11H8.68613C8.84583 11 8.98403 10.9435 9.10074 10.8304C9.21744 10.7173 9.27579 10.5833 9.27579 10.4286V8.14286H11.6345C11.7942 8.14286 11.9324 8.08631 12.0491 7.97322C12.1658 7.86012 12.2241 7.72619 12.2241 7.57143ZM14.3511 3.49089C14.9969 4.56033 15.3199 5.73004 15.3199 7C15.3199 8.26996 14.9969 9.44119 14.3511 10.5137C13.7052 11.5862 12.829 12.4353 11.7223 13.0612C10.6156 13.6871 9.40695 14 8.09645 14C6.78596 14 5.57735 13.6871 4.47064 13.0612C3.36393 12.4353 2.48765 11.5862 1.84181 10.5137C1.19597 9.44119 0.873047 8.26996 0.873047 7C0.873047 5.73004 1.19597 4.55881 1.84181 3.48633C2.48765 2.41385 3.36393 1.56467 4.47064 0.938802C5.57735 0.312934 6.78596 0 8.09645 0C9.40695 0 10.6156 0.312934 11.7223 0.938802C12.829 1.56467 13.7052 2.41536 14.3511 3.49089Z"
                  fill="#007AC4"
                />
              </svg>
              <p class="declarationRow">
                {{ selectedLang.adding }}
              </p>
            </span>
          </td>
        </tr>
      </TableSort>

      <CustomModal modalId="PassivoPassivoEdit" size="xl" :title="modalTitle">
        <div class="row mt-5">
          <div class="col-12 col-md-6 col-lg-4 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="guid + '-col1'"
              type="Text"
              :label="selectedLang.creditorDesignation"
              :required="true"
              :maxlength="60"
              :showErrorMsg="identificacaoCredorShowError"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="guid + '-col6'"
              type="Text"
              :label="selectedLang.taxIdentification"
              :required="true"
              :maxlength="40"
              :showErrorMsg="NIFNIPCShowError"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="guid + '-col2'"
              type="Monetery"
              :label="
                guid == '50117239-0a0b-427d-9c23-f33c766b2b3a'
                  ? selectedLang.debtAmountEuro
                  : selectedLang.debtAmount
              "
              :required="true"
              :maxlength="20"
              :showErrorMsg="montanteDebitoShowError"
              tooltipInfoSize="large"
              :tooltipInfo="
                guid == '50117239-0a0b-427d-9c23-f33c766b2b3a'
                  ? selectedLang.euroInformation
                  : null
              "
            />
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="guid + '-col3'"
              type="Monetery"
              :label="selectedLang.share"
              :required="true"
              :maxlength="20"
              :showErrorMsg="quotaParteShowError"
              :errorMsg="shareErrorMsg"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="guid + '-col4'"
              type="DatePicker"
              :label="selectedLang.dueDate"
              :required="false"
            />
          </div>
          <div class="col-12 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="guid + '-col5'"
              type="Text"
              :label="selectedLang.indicationTxt"
              :required="false"
              :maxlength="255"
            />
          </div>
          <!-- <div class="col-12 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="`${guid}-col7`"
              type="Text"
              :label="selectedLang.indicationOfTheFact"
              :required="false"
              :maxlength="255"
            />
          </div> -->
        </div>
        <div class="row">
          <div class="col-12 d-f jc-right" v-if="canEditDeclaration">
            <button
              v-if="!newItemFlag"
              type="button"
              @click="openDeleteDetail(itemToEdit)"
              class="btn btnIcon btnSecundary mr-1"
            >
              <svg
                width="30"
                height="28"
                viewBox="0 0 30 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.25 6.97664C22.0875 6.59164 17.9 6.39331 13.725 6.39331C11.25 6.39331 8.775 6.50998 6.3 6.74331L3.75 6.97664"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.625 5.79834L10.9 4.27C11.1 3.16167 11.25 2.33334 13.3625 2.33334H16.6375C18.75 2.33334 18.9125 3.20834 19.1 4.28167L19.375 5.79834"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.5625 10.6633L22.75 22.4117C22.6125 24.2433 22.5 25.6667 19.0125 25.6667H10.9875C7.5 25.6667 7.3875 24.2433 7.25 22.4117L6.4375 10.6633"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.9121 19.25H17.0746"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.875 14.5833H18.125"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              type="button"
              @click="saveItem"
              class="btn btnS btnPrimary mr-1"
            >
              {{ selectedLang.save }}
            </button>
            <button type="button" @click="cancel" class="btn btnS btnSecundary">
              {{ selectedLang.cancel }}
            </button>
          </div>
          <div v-else class="col-12 d-f jc-right">
            <button type="button" @click="cancel" class="btn btnS btnSecundary">
              {{ selectedLang.close }}
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        modalId="PassivoPassivoDelete"
        size="md"
        :isCenter="true"
        :title="selectedLang.passiveModalDelete"
      >
        <p class="p-3">{{ selectedLang.deleteQuestion }}</p>
        <div class="d-f pc-center">
          <button
            type="button"
            @click="deleteItem"
            class="btn btnS btnSecundary mr-1"
          >
            {{ selectedLang.eliminate }}
          </button>
          <button type="button" @click="cancel" class="btn btnS btnPrimary">
            {{ selectedLang.cancel }}
          </button>
        </div>
      </CustomModal>
    </div>
  </CustomAccordion>
</template>

<script>
import { utils } from "@/helpers";
export default {
  components: {},
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {
      validateForm: false,
      loading: false,
      tableModelAux: {
        total: 200,
        pages: 7,
      },
      modalDetailAux: {
        visible: false,
        size: "xl",
      },
      modalDeleteAux: {
        visible: false,
        size: "lg",
      },
      idToDelete: null,
      itemToEdit: {
        id: null,
        identificacaoCredor: null,
        montanteDebito: null,
        dataVencimento: null,
        quotaParte: null,
      },
      newItemFlag: false,
      tableRef: 0,
      guid: "50117239-0a0b-427d-9c23-f33c766b2b3a",
    };
  },
  methods: {
    searchFunc() {},
    sortFunc() {},
    openModal(modalId) {
      this.validateForm = false;
      this.emitter.emit("openCustomModal", modalId);
    },
    closeModal(modalId) {
      this.emitter.emit("closeCustomModal", modalId);
    },
    newItemAux(id) {
      return this.declaracaoUnicaHelper.NewTableItem(
        this.guid,
        id,
        this.declaracaoUnicaHelper.tableSizeByGuid(this.guid)
      );
    },
    newItem() {
      this.newItemFlag = true;
      var id = this.items.value == null ? 0 : this.items.value.length;

      let item = this.newItemAux(id);

      this.itemToEdit = item;
      this.openModal("PassivoPassivoEdit");
    },
    editItem(item) {
      let valueToEdit = JSON.stringify(item);
      this.itemToEdit = JSON.parse(valueToEdit);
      utils.checkAllIfGUIDExistsInArray(
        this.itemToEdit.value,
        this.guid,
        this.declaracaoUnicaHelper.intToArray(
          this.declaracaoUnicaHelper.tableSizeByGuid(this.guid)
        )
      );
      this.openModal("PassivoPassivoEdit");
    },
    detailItem(item) {
      let valueToEdit = JSON.stringify(item);
      this.itemToEdit = JSON.parse(valueToEdit);
      this.openModal("PassivoPassivoEdit");
    },
    saveItem() {
      this.validateForm = true;
      if (this.formIsValid) {
        if (this.items.value == null) {
          this.items.value = [];
        }
        var id = parseInt(this.itemToEdit.key.split("_")[1]);

        this.items.value[id] = this.itemToEdit;

        this.cancel();
      }
    },
    openDeleteDetail(item) {
      this.idToDelete = parseInt(item.key.split("_")[1]);

      this.openModal("PassivoPassivoDelete");
    },
    deleteItem() {
      this.items.value.splice(this.idToDelete, 1);

      var idReset = 0;
      this.items.value.forEach((element) => {
        (element.key = `${this.guid}_${idReset}`), idReset++;
      });

      this.items.isDeleted = true;
      this.cancel();
    },
    cancel() {
      this.closeModal("PassivoPassivoEdit");
      this.closeModal("PassivoPassivoDelete");
      this.idToDelete = null;
      this.itemToEdit = this.newItemAux(0);
      this.newItemFlag = false;
      this.validateForm = false;
      this.tableRef++;
    },
  },
  computed: {
    isPortugal() {
      return (
        this.declaracaoUnicaProp.key == "27bb57dd-0fd8-4595-aaca-53bf1634ca87"
      );
    },
    items: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracao.json,
          this.guid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracao.json,
          this.guid,
          newValue
        );
      },
    },
    modalTitle() {
      if (this.declaracao.estado != 1) {
        return this.selectedLang.passiveModalDetail;
      } else if (this.newItemFlag) {
        return this.selectedLang.passiveModalAdd;
      }
      return this.selectedLang.passiveModalEdit;
    },
    tableModel() {
      return {
        headers: [
          {
            title: this.selectedLang.creditorDesignation,
            sort: "creditorIdentification",
          },
          {
            title: this.selectedLang.debtAmount,
            sort: "debtAmount",
          },
          {
            title: this.selectedLang.dueDate,
            sort: "dueDate",
          },
        ],
        total: this.tableModelAux.total,
        pages: this.tableModelAux.pages,
      };
    },

    goodTypeOptions() {
      return [
        {
          value: 0,
          label: this.selectedLang.goodTypeEnum[0],
        },
        {
          value: 1,
          label: this.selectedLang.goodTypeEnum[1],
        },
        {
          value: 2,
          label: this.selectedLang.goodTypeEnum[2],
        },
      ];
    },

    identificacaoCredorIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 0);
      let value = this.itemToEdit.value[0].value;
      return !!value;
    },
    identificacaoCredorShowError() {
      return this.validateForm && !this.identificacaoCredorIsValid;
    },

    montanteDebitoIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 1);
      let value = this.itemToEdit.value[1].value;
      return !!value;
    },
    montanteDebitoShowError() {
      return this.validateForm && !this.montanteDebitoIsValid;
    },

    quotaParteIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 2);
      let value = this.itemToEdit.value[2].value;
      return !!value && this.shareIslessThanDebtAmount;
    },
    quotaParteShowError() {
      return this.validateForm && !this.quotaParteIsValid;
    },
    shareErrorMsg() {
      if (this.shareIslessThanDebtAmount) {
        return this.selectedLang.required;
      } else {
        return this.selectedLang.shareIsMoreThanDebtAmount;
      }
    },
    shareIslessThanDebtAmount() {
      return this.itemToEdit.value[2].value <= this.itemToEdit.value[1].value;
    },

    NIFNIPCIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 5);
      return this.itemToEdit.value[5].value != null && !this.isPortugal;
    },
    NIFNIPCShowError() {
      return this.validateForm && !this.NIFNIPCIsValid;
    },
    NIFNIPCErrorMsg() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 5);
      return this.itemToEdit.value[5].value != null
        ? this.selectedLang.invalidNIF
        : null;
    },

    formIsValid() {
      return (
        this.NIFNIPCIsValid &&
        this.identificacaoCredorIsValid &&
        this.montanteDebitoIsValid &&
        this.quotaParteIsValid
      );
    },
  },
};
</script>
