<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66669 8.33336L8.95885 2.49962C9.56752 2.01266 10.4325 2.01266 11.0412 2.49962L18.3334 8.33336"
      :stroke="props.color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.898 3.37454L4.56467 6.08287C3.95946 6.5746 3.65685 6.82047 3.49508 7.16034C3.33331 7.50022 3.33331 7.89012 3.33331 8.66993V11.6667C3.33331 14.8093 3.33331 16.3808 4.30962 17.357C5.28594 18.3333 6.85728 18.3333 9.99998 18.3333C13.1426 18.3333 14.7141 18.3333 15.6903 17.357C16.6666 16.3808 16.6666 14.8093 16.6666 11.6667V8.66993C16.6666 7.89012 16.6666 7.50022 16.5049 7.16034C16.3431 6.82047 16.0405 6.5746 15.4353 6.08287L12.102 3.37454C11.0951 2.55649 10.5917 2.14746 9.99998 2.14746C9.40823 2.14746 8.90481 2.55649 7.898 3.37454Z"
      :stroke="props.color"
      stroke-width="1.5"
    />
    <path
      d="M8.125 13.5416H11.875"
      :stroke="props.color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#102E40",
  },
});
</script>
