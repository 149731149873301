<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6.75C12 6.33579 12.3358 6 12.75 6H21.75C22.1642 6 22.5 6.33579 22.5 6.75V6.75C22.5 7.16421 22.1642 7.5 21.75 7.5H12.75C12.3358 7.5 12 7.16421 12 6.75V6.75ZM12 17.25C12 16.8358 12.3358 16.5 12.75 16.5H21.75C22.1642 16.5 22.5 16.8358 22.5 17.25V17.25C22.5 17.6642 22.1642 18 21.75 18H12.75C12.3358 18 12 17.6642 12 17.25V17.25ZM7.5 10.5H3C2.6023 10.4996 2.221 10.3414 1.93978 10.0602C1.65856 9.779 1.5004 9.3977 1.5 9V4.5C1.5004 4.1023 1.65856 3.721 1.93978 3.43978C2.221 3.15856 2.6023 3.0004 3 3H7.5C7.8977 3.0004 8.279 3.15856 8.56022 3.43978C8.84144 3.721 8.9996 4.1023 9 4.5V9C8.9996 9.3977 8.84144 9.779 8.56022 10.0602C8.279 10.3414 7.8977 10.4996 7.5 10.5ZM7.5 21H3C2.6023 20.9996 2.221 20.8414 1.93978 20.5602C1.65856 20.279 1.5004 19.8977 1.5 19.5V15C1.5004 14.6023 1.65856 14.221 1.93978 13.9398C2.221 13.6586 2.6023 13.5004 3 13.5H7.5C7.8977 13.5004 8.279 13.6586 8.56022 13.9398C8.84144 14.221 8.9996 14.6023 9 15V19.5C8.9996 19.8977 8.84144 20.279 8.56022 20.5602C8.279 20.8414 7.8977 20.9996 7.5 21ZM3 15V19.5H7.50075L7.5 15H3Z"
      fill="#102E40"
    />
  </svg>
</template>
