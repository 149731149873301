<template>
    <svg
              width="25"
              height="26"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21H21.5"
                stroke="#102E40"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.3845 4.82378C16.0155 3.62383 13.9241 3.74942 12.7122 5.10499C12.7122 5.10499 6.68886 11.8419 4.60018 14.1803C2.50875 16.5174 4.04182 19.746 4.04182 19.746C4.04182 19.746 7.494 20.8285 9.55372 18.5242C11.6148 16.2198 17.6685 9.45019 17.6685 9.45019C18.8804 8.09462 18.7522 6.02372 17.3845 4.82378Z"
                stroke="#102E40"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.8604 7.29236L15.4255 11.2791"
                stroke="#102E40"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
</template>