import axios from "axios";
import { authentication, utils } from "@/helpers";
import moment from "moment";

export default function setup(emitter) {
  //const whiteUrls = ["/account", "/publicquery", "/declaration/search", "/user/createfull"];

  var refreshing = false;
  const _emitter = emitter

  function redirectToLogin() {
    if (
      window.location.pathname &&
      window.location.pathname.indexOf("login") == -1
    ) {
      utils.setUrlRedirect(window.location.pathname);
      window.location.href = "/login?expired=true";
    }
  }

  function refreshToken() {
    return axios.get(`${process.env.VUE_APP_API_URL}/account/refreshtoken`);
  }

  function canRefreshToken(auth) {
    if (!auth) return false;
    
    var actualDate = moment();
    var endExpirationDate = moment(auth.token.expiration);
    var startExpiration = moment(auth.token.expiration).subtract(5, "minutes");

    if (actualDate >= startExpiration && actualDate <= endExpirationDate)
      return true;

    return false;
  }

  axios.interceptors.request.use(
    async (config) => {
      if (config.data != null) {
        
        for (const prop in config.data) {
          if (Object.prototype.hasOwnProperty.call(config.data, prop)) {
            if (config.data[prop] === "") {
              config.data[prop] = null;
            }
          }
        }
      }
      var auth = authentication.get();
      var url = config.url.toLowerCase();

      //Todos os endPoints têm que ser minúsculas
      if (
        auth != null &&
        url.indexOf("/account/login") == -1 &&
        url.indexOf("/account/authenticate") == -1 &&
        url.indexOf("/publicquery") == -1 &&
        url.indexOf("/user/createfull") == -1 &&
        url.indexOf("/holder") == -1 &&
        url.indexOf("/requirement/getbycode") == -1 &&
        url.indexOf("/requirement/gettypes") == -1 &&
        url.indexOf("/requirement/create") == -1 &&
        url.indexOf("/requirement/resendemail") == -1 &&
        url.indexOf("/requirement/validatecode") == -1 &&
        url.indexOf("/requirement/declaration/getanonymous") == -1 &&
        url.indexOf("/requirement/getattachment") == -1 &&
        url.indexOf("/declaration/getsectiontypes") == -1 &&
        url.indexOf("/attachment/addanonymous") == -1 &&
        url.indexOf("/entitycollaboration/") == -1 &&
        url.indexOf("/requirement/getprojectattachment") == -1 
      ) {
        config.headers["X-Language"] = auth.language;

        if (!auth.expired) {
          let bearer = auth.token.hash ?? auth.token;
          config.headers.Authorization = `Bearer ${bearer}`;
        } else {
                    
          redirectToLogin();
        }
      } else {
        config.headers["X-Language"] = "PT";
      }

      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    async (response) => {
      var url = response.config.url;
      if (url.indexOf("/account/login") > -1 && response.data.code != -30 && response.data.code != -31 && response.data.code != -32 && response.data.code != -33 && response.data.code != -41) {
        let auth = authentication.create(response.data);
        authentication.set(auth);
        // const refreshResponse = await refreshToken();
        // if(refreshResponse.code == 0){
        //   authentication.set(refreshResponse.data);
        // }
      } else {
        let auth = authentication.get();
        if (canRefreshToken(auth) && !refreshing) {
          refreshing = true;
          const refreshResponse = await refreshToken();
          if (refreshResponse.code == 0) {
            var auth1 = authentication.create(refreshResponse);
            authentication.set(auth1);
            refreshing = false;
          }
        } else{
          if (_emitter != null) {
            //_emitter.emit("AutoSave");
          }
        }
          
      }
      return response.data;
    },
    async function (error) {
      
      var auth = authentication.get();
      if (auth != null) {
        if (!auth.expired) {
          if (error.response.status === 403) {
            window.location.href = "/permissaonegada";
          } else if (error.response.status === 401) {
            //redirectToLogin();
          }
        } 
      }

      let description = "InternalServerError";

      if (error.code === "ERR_NETWORK") {
        description = error.code;
      }

      return { code: -1, description: description };
    }
  );
}
