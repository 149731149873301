import service from "./service-base";

export default {
    search: (declarationId) => {
        return service.post(`/declaration-clarification-request/search`, {declarationId: declarationId});
    },
    get: (model) => {
        return service.post(`/declaration-clarification-request/get`, model);
    },
    addcomment: (model) => {
        return service.post(`/declaration-clarification-request/addcomment`, model);
    },
    getAttachment:(clarificationRequestId, attachmentId)=>{
        return service.post(`/declaration-clarification-request/attachment`, {
            clarificationRequestId,
            attachmentId
        });
    }
}; 