import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/home/HomePage.vue";
import Login from "@/views/login/Login.vue";
import PerfilPage from "@/views/perfil/PerfilPage.vue";
import ConsultarDeclaracoes from "@/views/consultarDeclaracoes/ConsultarDeclaracoes.vue";
import DeclaracaoUnicaPage from "@/views/declaracaoUnica/DeclaracaoUnicaPage.vue";
import { authentication, utils,enums } from "@/helpers";

async function authGuard(to, from, next) {
  const auth = authentication.get();

  if (auth && !auth.expired) {
    if (checkProfiles(2))
      //Comissão Parlamentar
      next("/comissaoparlamentar/titulares");
    else next("/declaracoes");
  } else {
    next('/login');
  }
}

function checkProfiles(profileId){
  const auth = authentication.get();

    for (var i = 0; i < auth.profiles.length; i++) {
      const profile = auth.profiles[0];
      if (profile == profileId) return true;
    }

    return false;
}

// function authGuardWithPermission(to, from, next) {
//   var auth = authentication.get();
  
//   if (auth != null) {
//     if (auth.expired) {
//       utils.setUrlRedirect(from.fullPath);
//       next("/login");
//     }
//     else if (auth.firstTime && to.path != "/login/primeiroacesso") {
//       if(checkProfiles(2))
//         next("/login/primeiroacesso");
//       else
//         next();
//     } else {
//       next();
//     }
//   } else {
//     next("/login");
//   }
// }

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      breadCrumb: [],
    },
  },
  {
    path: "/areaprivada",
    name: "AreaPrivada",
    component: HomePage,
    meta: {
      breadCrumb: [],
    },
    beforeEnter: authGuard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "loginPage",
        },
      ],
    },
  },
  {
    path: "/login/reporpalavrapasse/:profileId",
    name: "RecoveryPassword",
    component: () => import("@/views/login/RecoveryPassword.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "recoverPassword",
        },
      ],
    }
  },
  {
    path: "/login/alterarpalavrapasse/:accessCode",
    name: "ChangePassword",
    component: () => import("@/views/login/ResetPassword.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "changePassword",
        },
      ],
    },
  },
  {
    path: "/login/primeiroacesso",
    name: "ComissaoParlamentarPrimeiroAcesso",
    component: () => import("@/views/login/first-time.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "defineNewPassword",
        },
      ],
    },
  },
  {
    path: "/perfilgov/:key",
    name: "PerfilPageGov",
    component: PerfilPage,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/areaprivada",
          text: "areaprivada",
        },
        {
          to: "",
          text: "personalData",
        },
      ],
    },
  },
  {
    path: "/declaracoes",
    name: "ConsultarDeclaracoes",
    component: ConsultarDeclaracoes,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "homePageCard2Title",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.holder,
    },
  },
  {
    path: "/declaracaounica",
    name: "DeclaracaoUnicaPage",
    component: DeclaracaoUnicaPage,
    props: true,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/declaracoes",
          text: "homePageCard2Title",
        },
        {
          to: "",
          text: "singleDeclaration",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.holder,
    },
  },
  {
    path: "/titularidades/:ids/declaracoes",
    name: "Declaracoes",
    component: () => import("@/views/titulares/declaracoes/Index.vue"),
    props: true,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "consultDeclarations",
        },
      ],
    },
  },
  {
    path: "/titularidades/:ids/declaracoes/:declaracaoId",
    name: "Declaracao",
    component: () => import("@/views/consultarDeclaracaoPublica/Index.vue"),
    props: true,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/titularidades/:ids/declaracoes",
          text: "consultDeclarations",
          params: ["ids"],
        },
        {
          to: "",
          text: "declarationDetail",
        },
      ],
    },
  },
  {
    path: "/govauthredirect",
    name: "GovAuthRedirect",
    component: () => import("@/views/login/GovAuthRedirect.vue"),
    props: true,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
      ],
    },
  },
  {
    path: "/consultardeclaracaopublica",
    name: "ConsultarDeclaracaoPublica",
    component: () => import("@/views/consultarDeclaracaoPublica/Index.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
      ],
    },
  },
  {
    path: "/declaracao/pedidooposicao",
    name: "PedidoOposicao",
    component: () => import("@/views/pedidoOposicao/Index.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.holder,
    },
  },
  {
    path: "/consultardeclaracaoprivada/:declarationId",
    name: "ConsultaDeclaracaoPrivada",
    component: () => import("@/views/consultaDeclaracaoPrivada/Index.vue"),
    props: true,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/declaracoes",
          text: "privateAreaCrumb",
        },
        {
          to: "",
          text: "declarationDetail",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.holder,
    },
  },
  {
    path: "/requerimentos",
    name: "Requerimento",
    component: () =>
      import("@/views/comissaoParlamentar/requerimento/Index.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "consultDeclarations",
        },
      ],
    },
  },
  {
    path: "/comissaoparlamentar/titular/:holderId",
    name: "ComissaoParlamentarTitularDeclaracoes",
    props: true,
    component: () =>
      import("@/views/comissaoParlamentar/titulares/Declarations.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/comissaoparlamentar/titulares",
          text: "parliamentaryCommittee",
        },
        {
          to: "",
          text: "declarationsTxt",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.comission,
    },
  },
  {
    path: "/titulares/validarcodigo",
    name: "ValidacaoCodigo",
    component: () => import("@/views/titulares/validate-code.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "validationCode",
        },
      ],
    },
  },
  {
    path: "/titulares/comunicacao/ativar/:entityCollaborationId/:code",
    name: "TitularesAtivarComunicao",
    component: () => import("@/views/titulares/activate-communication-frm.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "holdersCommunication",
        },
      ],
    },
  },
  {
    path: "/comissaoparlamentar/titulares",
    name: "ComissaoParlamentarTitulares",
    component: () => import("@/views/comissaoParlamentar/titulares/Index.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "parliamentaryCommittee",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.comission,
    },
  },
  {
    path: "/comissaoparlamentar/titular/:holderId/:entityId/:roleId/declaracoes",
    name: "ComissaoParlamentarTitularDeclaracoes",
    props: true,
    component: () =>
      import("@/views/comissaoParlamentar/titulares/Declarations.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "holderDetail",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.comission,
    },
  },
  {
    path: "/comissaoparlamentar/requerimentos",
    name: "ComissaoParlamentarRequerimentos",
    component: () =>
      import("@/views/comissaoParlamentar/requerimento/ConsultRequirement.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/comissaoparlamentar/titulares",
          text: "parliamentaryCommittee",
        },
        {
          to: "",
          text: "consultRequirement",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.comission,
    },
  },
  {
    path: "/comissaoparlamentar/requerimento/:requirementId",
    name: "ComissaoParlamentarRequerimento",
    component: () =>
      import("@/views/comissaoParlamentar/requerimento/requirement.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/comissaoparlamentar/requerimentos",
          text: "consultRequirement",
        },
        {
          to: "",
          text: "requirementRequest",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.comission,
    },
  },
  {
    path: "/comissaoparlamentar/requerimento/consultar",
    name: "ConsultarRequerimento",
    component: () =>
      import("@/views/comissaoParlamentar/requerimento/ConsultRequirement.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "",
          text: "consultRequirement",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.comission,
    },
  },
  {
    path: "/requerimento/:requirementId/declaracao/:declarationId",
    name: "ConsultarDeclaracaoRequerimento",
    component: () =>
      import("@/views/consultarDeclaracaoRequerimento/Index.vue"),
    props: true,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/comissaoparlamentar/requerimentos",
          text: "consultRequirement",
        },
        {
          to: "",
          text: "declarationDetail",
        },
      ],
    },
  },
  {
    path: "/comissaoparlamentar/titular/:holderId/:entityId/:roleId/declaracao/:declaracaoId",
    name: "ConsultaDeclaracaoComissaoParlamentar",
    component: () =>
      import("@/views/consultaDeclaracaoComissaoParlamentar/Index.vue"),
    props: true,
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/comissaoparlamentar/titular/:holderId/:entityId/:roleId/declaracoes",
          text: "consultDeclarations",
          params: ["holderId","entityId","roleId"],
        },
        {
          to: "",
          text: "declarationDetail",
        },
      ],
      requiresAuth: true,
      profile: enums.userProfileEnum.comission,
    },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: () =>
      import("@/views/perfil/Perfil.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/areaprivada",
          text: "areaprivada",
        },
        {
          to: "",
          text: "personalData",
        },
      ],
      requiresAuth: true
    },
  },
  {
    path: "/perfilComissao",
    name: "PerfilComissao",
    component: () =>
      import("@/views/perfil/PerfilComission.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/areaprivada",
          text: "parliamentaryCommittee",
        },
        {
          to: "",
          text: "personalData",
        },
      ],
      requiresAuth: true
    },
  },
  {
    path: "/test",
    name: "Test",
    component: () =>
      import("@/views/test.vue"),
    meta: {
      breadCrumb: [{
        to: "/",
        text: "homePage",
      },],
      requiresAuth: false
    },
  },
  {
    path: "/perfil/primeirologin",
    name: "PerfilPage",
    component: () =>
    import("@/views/perfil/holder-perfil-page-first-time.vue"),
    meta: {
      breadCrumb: [
        {
          to: "/",
          text: "homePage",
        },
        {
          to: "/areaprivada",
          text: "areaprivada",
        },
        {
          to: "",
          text: "personalData",
        },
      ],
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  document.title = "Entidade para a Transparência"
  if (to.meta.requiresAuth){
    var auth = authentication.get()
    
    if (auth == null || auth.expired) {
    return {
      path: '/login',
    } 
  } else if (auth.firstTime && to.path != "/login/primeiroacesso") {
    if(checkProfiles(enums.userProfileEnum.comission)){
      return {
        path: '/login/primeiroacesso',
      } 
    }
    if(checkProfiles(enums.userProfileEnum.holder)){
      if (to.fullPath != '/perfil/primeirologin') {
        
        return {
          path: '/perfil/primeirologin',
        }
      } 
    }
  }

  if (!utils.isNullOrEmpty(to.meta.profile)) {
    if ( !checkProfiles(to.meta.profile)) {
      return {
        path: '/',
      }
    }
  }
}
})

export default router;
