<template>
  <div class="m-2">
    <span
      v-if="showNumber"
      @click="tabClickHandler"
      class="circle-indicator btnAlike"
      :class="{ active: isSelected, inactive: !isSelected }"
    >
      {{ tabId }}
    </span>
    <span
      @click="tabClickHandler"
      class="circle-indicator btnAlike"
      :class="{ active: isSelected, inactive: !isSelected }"
      v-else
    >
      <svg
        width="21"
        height="17"
        viewBox="0 0 21 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.1167 0.878097C18.6833 0.431097 18.0339 0.380442 17.5516 0.726131H17.4445L7.61535 11.2429L3.70354 6.91453L3.62437 6.83287C3.13563 6.32874 2.37207 6.32874 1.88332 6.83287L0.860243 7.88815C0.616274 8.13979 0.5 8.49414 0.5 8.76382C0.5 9.0335 0.616275 9.38784 0.860243 9.63949L0.99997 9.78362L6.65862 16.0693C6.77065 16.2455 6.9369 16.3484 7.07565 16.4057C7.23836 16.4728 7.41478 16.5 7.57692 16.5C7.74691 16.5 7.91113 16.4701 8.06846 16.398C8.22315 16.3271 8.3456 16.2271 8.44751 16.122L20.1433 3.60564C20.5806 3.15077 20.6647 2.36529 20.1184 1.91132L19.1167 0.878097Z"
          fill="white"
        />
      </svg>
    </span>
    <!-- <span v-if="declaracao.estado == 1">{{ progress }}%</span> -->
  </div>
</template>

<script>
export default {
  props: {
    done: {
      Boolean,
      default: false,
    },
    active: {
      Boolean,
      default: false,
    },
    tabId: Number,
    progress: Number,
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {
      isSelected: this.active,
      showNumber: true,
    };
  },
  methods: {
    selectTab(tabId) {
      this.isSelected = false;
      if (this.tabId == tabId) {
        this.isSelected = true;
      }
      if (tabId > this.tabId) {
        this.showNumber = this.declaracao.estado != 1;
        this.isSelected = true;
      } else {
        this.showNumber = true;
      }
    },
    tabClickHandler() {
      this.emitter.emit("selectTab", this.tabId);
    },
  },
  mounted() {
    this.emitter.on("selectTab", (tabId) => {
      this.selectTab(tabId);
    });
  },
};
</script>
