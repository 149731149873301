<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.79164 9.99999C7.3225 8.99608 8.22427 8.16547 9.36106 7.6333C10.4979 7.10113 11.8081 6.89623 13.0944 7.04948C14.3806 7.20273 15.5732 7.70582 16.4925 8.48296L18.25 9.8186M18.25 6.99999L18.25 9.99999L15.125 9.99999M17.2083 14C16.6775 15.0039 15.7757 15.8345 14.6389 16.3667C13.5021 16.8989 12.1919 17.1038 10.9056 16.9505C9.61935 16.7973 8.42674 16.2942 7.50748 15.517L5.75 14.1814M5.75 17L5.75 14L8.875 14M23.25 12C23.25 5.78679 18.2132 0.749998 12 0.749999C5.78679 0.75 0.749998 5.7868 0.749999 12C0.75 18.2132 5.7868 23.25 12 23.25C18.2132 23.25 23.25 18.2132 23.25 12Z"
      :stroke="props.color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#102E40",
  },
});
</script>
