<template>
  <CustomAccordion
    :title="selectedLang.equivalentFinancialInvestments"
    :isOpenDefault="isOpenDefault"
  >
    <DeclaracaoUnicaBlockedData
      v-if="!declaracaoUnicaProp?.isVisible"
      :reason="declaracaoUnicaProp.reason"
    />
    <div v-else>
      <CarteirasTitulos
        :guid="CarteirasTitulosGuid"
        :declaracaoUnicaProp="CarteirasTitulos"
      />
      <ContasBancariasPrazoPortugal
        v-if="isPortugal"
        :guid="ContasBancariasPrazoGuid"
        :declaracaoUnicaProp="ContasBancariasPrazo"
      />
      <ContasBancariasPrazoEstrangeiro
        v-else
        :guid="ContasBancariasPrazo"
        :declaracaoUnicaProp="ContasBancariasPrazo"
      />
      <AplicacoesFinanceiras
        :guid="AplicacoesFinanceirasGuid"
        :declaracaoUnicaProp="AplicacoesFinanceiras"
      />
    </div>
  </CustomAccordion>
</template>

<script>
import CarteirasTitulos from "./AFE/CarteirasTitulos.vue";
import ContasBancariasPrazoPortugal from "./AFE/ContasBancariasPrazo-Portugal.vue";
import ContasBancariasPrazoEstrangeiro from "./AFE/ContasBancariasPrazo-Estrangeiro.vue";
import AplicacoesFinanceiras from "./AFE/AplicacoesFinanceiras.vue";

export default {
  components: {
    CarteirasTitulos,
    ContasBancariasPrazoPortugal,
    ContasBancariasPrazoEstrangeiro,
    AplicacoesFinanceiras,
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {};
  },
  computed: {
    isPortugal() {
      return (
        this.declaracaoUnicaProp.key == "05d711d3-08cd-4944-8be9-dc5f40a201fc"
      );
    },
    CarteirasTitulosGuid() {
      return this.declaracaoUnicaProp.key ==
        "05d711d3-08cd-4944-8be9-dc5f40a201fc"
        ? "1a5d2580-aaf3-4874-9e20-68ce10671263"
        : "4a8f53d5-34c2-4bda-b3ea-ec2b157efe67";
    },
    ContasBancariasPrazoGuid() {
      return this.declaracaoUnicaProp.key ==
        "05d711d3-08cd-4944-8be9-dc5f40a201fc"
        ? "e498015f-062e-4fcd-93a8-c5f1393f568b"
        : "9d7b601c-dc39-4460-a3ab-3bc1178cbae7";
    },
    AplicacoesFinanceirasGuid() {
      return this.declaracaoUnicaProp.key ==
        "05d711d3-08cd-4944-8be9-dc5f40a201fc"
        ? "7bf6ccdd-f797-4339-8c76-18a248882016"
        : "5eea74fb-2cdc-4897-8f04-ce31341f7296";
    },
    CarteirasTitulos: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.CarteirasTitulosGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.CarteirasTitulosGuid,
          newValue
        );
      },
    },
    ContasBancariasPrazo: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasPrazo
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasPrazo,
          newValue
        );
      },
    },
    AplicacoesFinanceiras: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.AplicacoesFinanceirasGuid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.AplicacoesFinanceirasGuid,
          newValue
        );
      },
    },

    isOpenDefault() {
      return (
        (this.CarteirasTitulos?.value != null &&
          this.CarteirasTitulos?.value.length > 0) ||
        (this.ContasBancariasPrazo?.value != null &&
          this.ContasBancariasPrazo?.value.length > 0) ||
        (this.AplicacoesFinanceiras?.value != null &&
          this.AplicacoesFinanceiras?.value.length > 0)
      );
    },
  },
  mounted() {},
};
</script>
