<template>
<svg class="mr-1" width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2085 4.5C15.4846 4.5 15.7085 4.27614 15.7085 4C15.7085 3.72386 15.4846 3.5 15.2085 3.5L15.2085 4.5ZM0.754222 3.64645C0.55896 3.84171 0.55896 4.15829 0.754222 4.35355L3.9362 7.53553C4.13146 7.7308 4.44805 7.7308 4.64331 7.53553C4.83857 7.34027 4.83857 7.02369 4.64331 6.82843L1.81488 4L4.64331 1.17157C4.83857 0.97631 4.83857 0.659727 4.64331 0.464465C4.44805 0.269203 4.13147 0.269203 3.9362 0.464465L0.754222 3.64645ZM15.2085 3.5L1.10778 3.5L1.10778 4.5L15.2085 4.5L15.2085 3.5Z" :fill="props.color" :stroke="props.color"></path></svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#007ac4",
  },
});
</script>
