<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_930_18648)">
      <rect
        x="1"
        y="1"
        width="48"
        height="48"
        rx="9"
        fill="white"
        stroke="#007AC4"
        stroke-width="2"
      />
    </g>
    <mask
      id="mask0_930_18648"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="16"
      y="15"
      width="18"
      height="21"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1716 16.1716C16 17.3432 16 19.2288 16 23V33.601C16 35.1259 17.6382 36.0899 18.9713 35.3493L24.0287 32.5396C24.6328 32.204 25.3672 32.204 25.9713 32.5396L31.0287 35.3493C32.3618 36.0899 34 35.1259 34 33.601V23C34 19.2288 34 17.3432 32.8284 16.1716C31.6569 15 29.7712 15 26 15H24C20.2288 15 18.3431 15 17.1716 16.1716Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_930_18648)">
      <path
        d="M32.8284 16.1716L33.8891 15.1109L32.8284 16.1716ZM24.0287 32.5396L24.7572 33.8508L24.0287 32.5396ZM25.9713 32.5396L25.2428 33.8508L25.9713 32.5396ZM18.9713 35.3493L19.6998 36.6605L18.9713 35.3493ZM17.5 23C17.5 21.072 17.5032 19.7769 17.6331 18.8108C17.7572 17.8879 17.9716 17.4929 18.2322 17.2322L16.1109 15.1109C15.2 16.0219 14.8286 17.1555 14.6598 18.411C14.4968 19.6235 14.5 21.1568 14.5 23H17.5ZM17.5 33.601V23H14.5V33.601H17.5ZM19.6998 36.6605L24.7572 33.8508L23.3003 31.2284L18.2428 34.038L19.6998 36.6605ZM25.2428 33.8508L30.3003 36.6605L31.7572 34.038L26.6998 31.2284L25.2428 33.8508ZM32.5 23V33.601H35.5V23H32.5ZM31.7678 17.2322C32.0284 17.4929 32.2428 17.8879 32.3669 18.8108C32.4968 19.7769 32.5 21.072 32.5 23H35.5C35.5 21.1568 35.5032 19.6235 35.3402 18.411C35.1714 17.1555 34.8 16.0219 33.8891 15.1109L31.7678 17.2322ZM26 16.5C27.928 16.5 29.2231 16.5032 30.1892 16.6331C31.1121 16.7572 31.5071 16.9716 31.7678 17.2322L33.8891 15.1109C32.9781 14.2 31.8445 13.8286 30.589 13.6598C29.3765 13.4968 27.8432 13.5 26 13.5V16.5ZM24 16.5H26V13.5H24V16.5ZM18.2322 17.2322C18.4929 16.9716 18.8879 16.7572 19.8108 16.6331C20.7769 16.5032 22.072 16.5 24 16.5V13.5C22.1568 13.5 20.6235 13.4968 19.411 13.6598C18.1555 13.8286 17.0219 14.2 16.1109 15.1109L18.2322 17.2322ZM30.3003 36.6605C32.6331 37.9566 35.5 36.2697 35.5 33.601H32.5C32.5 33.9822 32.0904 34.2232 31.7572 34.038L30.3003 36.6605ZM24.7572 33.8508C24.9082 33.7669 25.0918 33.7669 25.2428 33.8508L26.6998 31.2284C25.6427 30.6411 24.3573 30.6411 23.3003 31.2284L24.7572 33.8508ZM14.5 33.601C14.5 36.2697 17.3669 37.9566 19.6998 36.6605L18.2428 34.038C17.9096 34.2232 17.5 33.9822 17.5 33.601H14.5Z"
        fill="#007AC4"
      />
      <path
        d="M22.75 23.25H27.25"
        stroke="#007AC4"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_930_18648">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
