<template>
  <div class="declaracaoUnicaBlockedData mb-5">
    <div class="d-f">
      <span class="">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.125 3C10.8385 3 9.72526 3.47005 8.78516 4.41016C7.84505 5.35026 7.375 6.46354 7.375 7.75V10.125C6.70703 10.125 6.14421 10.3538 5.68652 10.8115C5.22884 11.2692 5 11.832 5 12.5V19.625C5 20.293 5.22884 20.8558 5.68652 21.3135C6.14421 21.7712 6.70703 22 7.375 22H16.875C17.543 22 18.1058 21.7712 18.5635 21.3135C19.0212 20.8558 19.25 20.293 19.25 19.625V12.5C19.25 11.832 19.0212 11.2692 18.5635 10.8115C18.1058 10.3538 17.543 10.125 16.875 10.125V7.75C16.875 6.46354 16.4049 5.35026 15.4648 4.41016C14.5247 3.47005 13.4115 3 12.125 3ZM17.6543 12.5V19.625C17.6543 20.1445 17.3945 20.4043 16.875 20.4043H7.375C6.85547 20.4043 6.5957 20.1445 6.5957 19.625V12.5C6.5957 11.9805 6.85547 11.7207 7.375 11.7207H16.875C17.3945 11.7207 17.6543 11.9805 17.6543 12.5ZM8.9707 10.125V7.75C8.9707 6.88411 9.27995 6.14193 9.89844 5.52344C10.5169 4.90495 11.2591 4.5957 12.125 4.5957C12.9909 4.5957 13.7331 4.90495 14.3516 5.52344C14.9701 6.14193 15.2793 6.88411 15.2793 7.75V10.125H8.9707ZM12.125 12.5C11.457 12.5 10.8942 12.7288 10.4365 13.1865C9.97884 13.6442 9.75 14.207 9.75 14.875C9.75 15.3698 9.89844 15.8213 10.1953 16.2295C10.4922 16.6377 10.8757 16.9284 11.3457 17.1016V18.0293C11.3457 18.5736 11.6055 18.8457 12.125 18.8457C12.6445 18.8457 12.9043 18.5736 12.9043 18.0293V17.1016C13.9681 16.7305 14.5 15.9883 14.5 14.875C14.5 14.207 14.2712 13.6442 13.8135 13.1865C13.3558 12.7288 12.793 12.5 12.125 12.5ZM12.125 15.6543C11.6055 15.6543 11.3457 15.3945 11.3457 14.875C11.3457 14.3555 11.6055 14.0957 12.125 14.0957C12.6445 14.0957 12.9043 14.3555 12.9043 14.875C12.9043 15.3945 12.6445 15.6543 12.125 15.6543Z"
            fill="#1B1B1B"
          />
        </svg>
      </span>
      <div class="text">
        {{ selectedLang.decrarationRestrictReasonEnum[props.reason] }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  reason: { Number, default: -1 },
});
</script>
