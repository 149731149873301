import service from "./service-base";

export default {
  addimage: (imageBase64, fileGuid) => {
    return service.put(`/document/addimage`, {
      ImageBase64:imageBase64,
      FileGuid:fileGuid
    });
  },
  download: (fileGuid,filename) => {
    return service.put(`/document/download`, {
      Filename:filename,
      FileGuid:fileGuid
    });
  },
  create: () => {
    return service.get(`/document/create`);
  },
  adddata: (fileGuid, data) => {
    if (Object.keys(data).length > 0) {
      return service.put(`/document/adddata`,{
        fileGuid:fileGuid,
        data:data,
      });
    }
  },
  adddatatotable: (fileGuid, data) => {
      return service.put(`/document/adddatatotable`,{
        fileGuid:fileGuid,
        tables:data,
      });
  },
};