import service from "./service-base";

export default {
    search: (pageNumber, sortBy, model) => {
        model.pageNumber = pageNumber;
        model.sortBy = sortBy; 
        return service.post(`/declaration/search`, model);
        
    },

    getdeclarationfilters: (model) => {
        return service.post("/declaration/getdeclarationfilters", model);
    },

    create: (model) => {
        return service.post("/declaration/create", model);
    },

    get: (model) => {
        //model.isDraft = true
        return service.post("/declaration", model);
    },

    createdraft: (model) => {
        return service.post("/declaration/createdraft", model);
    },
    
    updatedraft: (model) => {
        return service.put("/declaration/updatedraft", model);
    },
    
    deletedraft: (id) => {
        return service.delete(`/declaration/deletedraft/${id}`);
    },
    
    submit: (model) => {
        return service.put(`/declaration/submit`,model);
    },

    getLastSubmited: () => {
        return service.post(`/declaration/getlastsubmited`,{});
    },

    getAllDraftAttachments:(id) => {
        return service.get(`/declaration/draft/${id}/attachments`);
    },

    addDraftAttachment: (attachment) => {
        return service.post("/declaration/draft/attachment", attachment);
    },

    createDraftAttachment: (attachment) => {
        return service.put("/declaration/draft/attachment", attachment);
    },

    deleteDraftAttachment: (id) => {
        return service.delete(`/declaration/draft/attachment/${id}`, );
    },

    getAllAttachments:(declarationId) => {
        return service.get(`/declaration/${declarationId}/attachments`);
    },

    getAttachmentByDeclaration:(declarationId, attachmentId) => {
        return service.post('/declaration/getattachment', {
            declarationId,
            attachmentId
        });
    },
    getAttachmentByDraft:(declarationDraftId, attachmentId) => {
        return service.post('/declaration/draft/getattachment',{
            declarationDraftId,
            attachmentId 
        });
    },
    getSectionsType:() => {
        return service.get('/declaration/getsectiontypes');
    },    
    update:(model) => {
        return service.put('/declaration/update',model);
    },    
    cancel:(model) => {
        return service.put('/declaration/cancel',model);
    },    
};