<template>
  <span v-if="props.statusId == 1" class="badge badge-warning">{{
    selectedLang.requirementStatusTypeEnum[props.statusId]
  }}</span>
  <span v-if="props.statusId == 2" class="badge badge-warning">{{
    selectedLang.requirementStatusTypeEnum[props.statusId]
  }}</span>
  <!-- <span v-if="props.statusId == 3" class="badge badge-success">{{
    selectedLang.requirementStatusTypeEnum[props.statusId]
  }}</span> -->
  <template v-if="props.statusId == 3">
    <span class="badge badge-success" v-if="props.state == stateEnum[0]">{{
      state
    }}</span>
    <span class="badge badge-danger" v-if="props.state == stateEnum[1]">{{
      state
    }}</span>
    <span class="badge badge-warning" v-if="props.state == stateEnum[2]">{{
      state
    }}</span>
  </template>
  <span v-if="props.statusId == 4" class="badge badge-warning">{{
    selectedLang.requirementStatusTypeEnum[props.statusId]
  }}</span>
</template>
<script setup>
import { defineProps } from "vue";

const props = defineProps(["statusId", "state"]);

const stateEnum = {
  0: "Deferido",
  1: "Indeferido",
  2: "Deferido Parcialmente",
};
</script>
