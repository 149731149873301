<template>
  <CustomAccordion
    :title="selectedLang.vehicleRights"
    :isOpenDefault="items.value != null && items.value.length > 0"
  >
    <div>
      <DeclaracaoUnicaBlockedData
        v-if="!items.isVisible"
        :reason="items.reason"
      />
      <TableSort
        :showEmptyrow="false"
        :totalItems="1"
        v-if="items.isVisible"
        defaultSort=""
        :checkBox="false"
        :loading="loading"
        :tableModel="tableModel"
        :actions="canEditDeclaration"
        :searchFunc="searchFunc"
        :sortFunc="sortFunc"
        :showPagination="false"
        :showTotal="false"
        :sortHeader="false"
        :key="tableRef"
      >
        <tr
          v-if="
            !canEditDeclaration &&
            declaracaoUnicaHelper.isNothingDeclare(items.value)
          "
        >
          <td colspan="11" class="p-3 jc-center tal-center">
            <h1>
              {{ selectedLang.nothingDeclare }}
            </h1>
          </td>
        </tr>
        <DeclarationTableRow
          v-for="(item, index) in items.value"
          :key="index"
          :rowGUID="item.key"
          v-else
        >
          <template v-slot="slotProps">
            <td>
              <declaration-column-table
                :emphasize="slotProps.emphasize"
                :item="item.value[0]"
                type="enum"
                :enum="selectedLang.goodTypeEnum"
              />
            </td>
            <td>
              <declaration-column-table
                :emphasize="slotProps.emphasize"
                :item="item.value[23]"
                type="enum"
                :enum="selectedLang.natureApplicableGoodOptions"
              />
            </td>
            <td>
              <declaration-column-table
                :emphasize="slotProps.emphasize"
                :item="item.value[24]"
                type="enum"
                :enum="selectedLang.goodOwnershipRightOptions"
              />
            </td>
            <td class="d-f" v-if="canEditDeclaration">
              <span @click="openDeleteDetail(item)" class="btnAlike">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.8494 9.14001L18.1994 19.21C18.0894 20.78 17.9994 22 15.2094 22H8.78941C5.99941 22 5.90941 20.78 5.79941 19.21L5.14941 9.14001"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.3301 16.5H13.6601"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="ml-1 mr-1">
                <svg
                  width="1"
                  height="40"
                  viewBox="0 0 1 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.5"
                    y1="2.18557e-08"
                    x2="0.499998"
                    y2="40"
                    stroke="#D8D8D8"
                  />
                </svg>
              </span>
              <span @click="editItem(item)" class="btnAlike">
                <svg
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21H21.5"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3845 4.82378C16.0155 3.62383 13.9241 3.74942 12.7122 5.10499C12.7122 5.10499 6.68886 11.8419 4.60018 14.1803C2.50875 16.5174 4.04182 19.746 4.04182 19.746C4.04182 19.746 7.494 20.8285 9.55372 18.5242C11.6148 16.2198 17.6685 9.45019 17.6685 9.45019C18.8804 8.09462 18.7522 6.02372 17.3845 4.82378Z"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.8604 7.29236L15.4255 11.2791"
                    stroke="#102E40"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </td>
            <td v-else>
              <span @click="detailItem(item)" class="btnAlike">
                <IconEye />
              </span>
            </td>
          </template>
        </DeclarationTableRow>
        <tr v-if="canEditDeclaration">
          <td colspan="11" class="p-0 pt-1">
            <span @click="newItem" class="d-f jc-center btnAlike">
              <svg
                style="margin: 9px 0px"
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2241 7.57143V6.42857C12.2241 6.27381 12.1658 6.13988 12.0491 6.02678C11.9324 5.91369 11.7942 5.85714 11.6345 5.85714H9.27579V3.57143C9.27579 3.41667 9.21744 3.28274 9.10074 3.16964C8.98403 3.05655 8.84583 3 8.68613 3H7.50679C7.34709 3 7.20889 3.05655 7.09218 3.16964C6.97548 3.28274 6.91713 3.41667 6.91713 3.57143V5.85714H4.55847C4.39877 5.85714 4.26056 5.91369 4.14386 6.02678C4.02715 6.13988 3.9688 6.27381 3.9688 6.42857V7.57143C3.9688 7.72619 4.02715 7.86012 4.14386 7.97322C4.26056 8.08631 4.39877 8.14286 4.55847 8.14286H6.91713V10.4286C6.91713 10.5833 6.97548 10.7173 7.09218 10.8304C7.20889 10.9435 7.34709 11 7.50679 11H8.68613C8.84583 11 8.98403 10.9435 9.10074 10.8304C9.21744 10.7173 9.27579 10.5833 9.27579 10.4286V8.14286H11.6345C11.7942 8.14286 11.9324 8.08631 12.0491 7.97322C12.1658 7.86012 12.2241 7.72619 12.2241 7.57143ZM14.3511 3.49089C14.9969 4.56033 15.3199 5.73004 15.3199 7C15.3199 8.26996 14.9969 9.44119 14.3511 10.5137C13.7052 11.5862 12.829 12.4353 11.7223 13.0612C10.6156 13.6871 9.40695 14 8.09645 14C6.78596 14 5.57735 13.6871 4.47064 13.0612C3.36393 12.4353 2.48765 11.5862 1.84181 10.5137C1.19597 9.44119 0.873047 8.26996 0.873047 7C0.873047 5.73004 1.19597 4.55881 1.84181 3.48633C2.48765 2.41385 3.36393 1.56467 4.47064 0.938802C5.57735 0.312934 6.78596 0 8.09645 0C9.40695 0 10.6156 0.312934 11.7223 0.938802C12.829 1.56467 13.7052 2.41536 14.3511 3.49089Z"
                  fill="#007AC4"
                />
              </svg>
              <p class="declarationRow">
                {{ selectedLang.adding }}
              </p>
            </span>
          </td>
        </tr>
      </TableSort>

      <CustomModal
        modalId="DireitosSobreVeiculosEdit"
        size="xl"
        :title="modalTitle"
      >
        <div class="row mt-5">
          <div class="col-12 col-md-6 col-lg-4 mb-4">
            <DeclaracaoUnicaInput
              :declaracaoUnicaProp="itemToEdit.value"
              :inputKey="guid + '-col1'"
              type="Multiselect"
              :label="selectedLang.goodType"
              :required="true"
              :options="goodTypeOptions"
              :showErrorMsg="tipoBemShowError"
              @select="goodTypeSelect"
            />
          </div>
          <template v-if="isBarco">
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col2'"
                type="Text"
                :label="selectedLang.registerNumber"
                :required="true"
                :maxlength="40"
                :showErrorMsg="registerNumberShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col3'"
                type="Text"
                :label="selectedLang.shipName"
                :required="true"
                :maxlength="40"
                :showErrorMsg="shipNameShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col4'"
                type="Text"
                :label="selectedLang.manufacturerBrand"
                :required="true"
                :maxlength="40"
                :showErrorMsg="manufacturerBrandShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col5'"
                type="Text"
                :label="selectedLang.intendedPurpose"
                :required="true"
                :maxlength="40"
                :showErrorMsg="intendedPurposeShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col6'"
                type="Text"
                :label="selectedLang.constructionYear"
                :required="true"
                :maxlength="40"
                :showErrorMsg="constructionYearShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col7'"
                type="Text"
                :label="selectedLang.mainDimensions"
                :required="true"
                :maxlength="40"
                :showErrorMsg="mainDimensionsShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col8'"
                type="Text"
                :label="selectedLang.engineCharacteristics"
                :required="true"
                :maxlength="40"
                :showErrorMsg="engineCharacteristicsShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col9'"
                type="Text"
                :label="selectedLang.grossTonnage"
                :required="true"
                :maxlength="40"
                :showErrorMsg="grossTonnageShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col10'"
                type="Text"
                :label="selectedLang.netTonnage"
                :required="true"
                :maxlength="40"
                :showErrorMsg="netTonnageShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col11'"
                type="Text"
                :label="selectedLang.motivePower"
                :required="true"
                :maxlength="40"
                :showErrorMsg="motivePowerShowError"
              />
            </div>
          </template>
          <template v-if="isAeronave">
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col12'"
                type="Text"
                :label="selectedLang.aircraftType"
                :required="true"
                :maxlength="40"
                :showErrorMsg="aircraftTypeShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col13'"
                type="Text"
                :label="selectedLang.carRegistrationNumber"
                :required="true"
                :maxlength="40"
                :showErrorMsg="carRegistrationNumberShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col4'"
                type="Text"
                :label="selectedLang.manufacturerBrand"
                :required="true"
                :maxlength="40"
                :showErrorMsg="manufacturerBrandShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col14'"
                type="Text"
                :label="selectedLang.model"
                :required="true"
                :maxlength="40"
                :showErrorMsg="modelShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col15'"
                type="Text"
                :label="selectedLang.manufactureYear"
                :required="true"
                :maxlength="40"
                :showErrorMsg="manufactureYearShowError"
              />
            </div>
            <div class="col-12 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col16'"
                type="Text"
                :label="selectedLang.maximumWeight"
                :required="true"
                :maxlength="40"
                :showErrorMsg="maximumWeightShowError"
              />
            </div>
          </template>
          <template v-if="isAutomovel">
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col17'"
                type="Text"
                :label="selectedLang.registration"
                :required="true"
                :maxlength="40"
                :showErrorMsg="registrationShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col18'"
                type="Number"
                :label="selectedLang.enrollmentYear"
                :required="true"
                :maxlength="4"
                :showErrorMsg="enrollmentYearShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col19'"
                type="Text"
                :label="selectedLang.brand"
                :required="true"
                :maxlength="40"
                :showErrorMsg="brandShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col14'"
                type="Text"
                :label="selectedLang.model"
                :required="true"
                :maxlength="40"
                :showErrorMsg="modelShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col20'"
                type="Text"
                :label="selectedLang.cisplacement"
                :required="true"
                :maxlength="40"
                :showErrorMsg="cisplacementShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col21'"
                type="Text"
                :label="selectedLang.maximumUsefulPower"
                :required="true"
                :maxlength="40"
                :showErrorMsg="maximumUsefulPowerShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col22'"
                type="Text"
                :label="selectedLang.nationalCategory"
                :required="true"
                :maxlength="40"
                :showErrorMsg="nationalCategoryShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col23'"
                type="Text"
                :label="selectedLang.vehicleType"
                :required="true"
                :maxlength="40"
                :showErrorMsg="vehicleTypeShowError"
              />
            </div>
          </template>
          <template v-if="tipoBemIsValid">
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col24'"
                type="Multiselect"
                :label="selectedLang.natureApplicableGood"
                :required="true"
                :options="natureApplicableGoodOptions"
                :showErrorMsg="natureApplicableGoodShowError"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col25'"
                type="Multiselect"
                :label="selectedLang.goodOwnershipRight"
                :required="true"
                :options="goodOwnershipRightOptions"
                :showErrorMsg="goodOwnershipRightShowError"
              />
            </div>
            <div class="col-12 col-md-12 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="`${guid}-col26`"
                type="RadioBtn"
                :label="selectedLang.declarantIsTheHolder"
                :required="true"
                :options="declarantIsTheHolderOptions"
                groupName="declarantIsTheHolder"
                :showErrorMsg="declarantIsTheHolderShowError"
              />
            </div>
            <div
              class="col-12 col-md-12 mb-4"
              v-if="isDeclarantIsTheHolderOption2"
            >
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="`${guid}-col27`"
                type="Textarea"
                :label="selectedLang.legaOrNaturalPerson"
                :required="isDeclarantIsTheHolderOption2"
                :showErrorMsg="legaOrNaturalPersonShowError"
                :maxlength="50"
              />
            </div>
            <div class="col-12 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="itemToEdit.value"
                :inputKey="guid + '-col28'"
                type="Text"
                :label="selectedLang.indicationOfTheFact"
                :required="false"
                :maxlength="255"
              />
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-12 d-f jc-right" v-if="canEditDeclaration">
            <button
              v-if="!newItemFlag"
              type="button"
              @click="openDeleteDetail(itemToEdit)"
              class="btn btnIcon btnSecundary mr-1"
            >
              <svg
                width="30"
                height="28"
                viewBox="0 0 30 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.25 6.97664C22.0875 6.59164 17.9 6.39331 13.725 6.39331C11.25 6.39331 8.775 6.50998 6.3 6.74331L3.75 6.97664"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.625 5.79834L10.9 4.27C11.1 3.16167 11.25 2.33334 13.3625 2.33334H16.6375C18.75 2.33334 18.9125 3.20834 19.1 4.28167L19.375 5.79834"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.5625 10.6633L22.75 22.4117C22.6125 24.2433 22.5 25.6667 19.0125 25.6667H10.9875C7.5 25.6667 7.3875 24.2433 7.25 22.4117L6.4375 10.6633"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.9121 19.25H17.0746"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.875 14.5833H18.125"
                  stroke="#007AC4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              type="button"
              @click="saveItem"
              class="btn btnS btnPrimary mr-1"
            >
              {{ selectedLang.save }}
            </button>
            <button type="button" @click="cancel" class="btn btnS btnSecundary">
              {{ selectedLang.cancel }}
            </button>
          </div>
          <div v-else class="col-12 d-f jc-right">
            <button type="button" @click="cancel" class="btn btnS btnSecundary">
              {{ selectedLang.close }}
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        modalId="DireitosSobreVeiculosDelete"
        size="md"
        :isCenter="true"
        :title="selectedLang.vehicleRightsModalDelete"
      >
        <p class="p-3">{{ selectedLang.deleteQuestion }}</p>
        <div class="d-f pc-center">
          <button
            type="button"
            @click="deleteItem"
            class="btn btnS btnPrimary mr-1"
          >
            {{ selectedLang.eliminate }}
          </button>
          <button type="button" @click="cancel" class="btn btnS btnSecundary">
            {{ selectedLang.cancel }}
          </button>
        </div>
      </CustomModal>
    </div>
  </CustomAccordion>
</template>

<script>
import { utils } from "@/helpers";
export default {
  components: {},
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {
      validateForm: false,
      loading: false,
      tableModelAux: {
        total: 200,
        pages: 7,
      },
      modalDetailAux: {
        visible: false,
        size: "xl",
      },
      modalDeleteAux: {
        visible: false,
        size: "lg",
      },
      idToDelete: null,
      itemToEdit: {},
      newItemFlag: false,
      tableRef: 0,
      guid: "53168cfb-433e-41e6-9b23-33c1eac7fa6f",
    };
  },
  methods: {
    goodTypeSelect() {
      this.declaracaoUnicaHelper.itemClearCols(this.itemToEdit, 27, [0]);
    },
    searchFunc() {},
    sortFunc() {},
    openModal(modalId) {
      this.validateForm = false;
      this.emitter.emit("openCustomModal", modalId);
    },
    closeModal(modalId) {
      this.emitter.emit("closeCustomModal", modalId);
    },
    newItemAux(id) {
      return this.declaracaoUnicaHelper.NewTableItem(
        this.guid,
        id,
        this.declaracaoUnicaHelper.tableSizeByGuid(this.guid)
      );
    },
    newItem() {
      this.newItemFlag = true;
      var id = this.items.value == null ? 0 : this.items.value.length;

      let item = this.newItemAux(id);

      this.itemToEdit = item;
      this.openModal("DireitosSobreVeiculosEdit");
    },
    editItem(item) {
      let valueToEdit = JSON.stringify(item);
      this.itemToEdit = JSON.parse(valueToEdit);
      utils.checkAllIfGUIDExistsInArray(
        this.itemToEdit.value,
        this.guid,
        this.declaracaoUnicaHelper.intToArray(
          this.declaracaoUnicaHelper.tableSizeByGuid(this.guid)
        )
      );
      this.openModal("DireitosSobreVeiculosEdit");
    },
    detailItem(item) {
      let valueToEdit = JSON.stringify(item);
      this.itemToEdit = JSON.parse(valueToEdit);
      this.openModal("DireitosSobreVeiculosEdit");
    },
    saveItem() {
      this.validateForm = true;
      if (this.formIsValid) {
        if (this.items.value == null) {
          this.items.value = [];
        }
        var id = parseInt(this.itemToEdit.key.split("_")[1]);

        this.items.value[id] = this.itemToEdit;

        this.cancel();
      }
    },
    openDeleteDetail(item) {
      this.idToDelete = parseInt(item.key.split("_")[1]);

      this.openModal("DireitosSobreVeiculosDelete");
    },
    deleteItem() {
      this.items.value.splice(this.idToDelete, 1);

      var idReset = 0;
      this.items.value.forEach((element) => {
        (element.key = `${this.guid}_${idReset}`), idReset++;
      });

      this.items.isDeleted = true;
      this.cancel();
    },
    cancel() {
      this.closeModal("DireitosSobreVeiculosEdit");
      this.closeModal("DireitosSobreVeiculosDelete");
      this.idToDelete = null;
      this.itemToEdit = this.newItemAux(0);
      this.newItemFlag = false;
      this.validateForm = false;
      this.tableRef++;
    },
  },
  computed: {
    items: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracao.json,
          this.guid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracao.json,
          this.guid,
          newValue
        );
      },
    },
    modalTitle() {
      if (this.declaracao.estado != 1) {
        return this.selectedLang.vehicleRightsModalDetail;
      } else if (this.newItemFlag) {
        return this.selectedLang.vehicleRightsModalAdd;
      }
      return this.selectedLang.vehicleRightsModalEdit;
    },
    tableModel() {
      return {
        headers: [
          {
            title: this.selectedLang.goodType,
            sort: "goodType",
          },
          {
            title: this.selectedLang.natureApplicableGood,
            sort: "natureApplicableGood",
          },
          {
            title: this.selectedLang.goodOwnershipRight,
            sort: "goodOwnershipRight",
          },
        ],
        total: this.tableModelAux.total,
        pages: this.tableModelAux.pages,
      };
    },
    goodTypeOptions() {
      return [
        {
          value: 0,
          label: this.selectedLang.goodTypeEnum[0],
        },
        {
          value: 1,
          label: this.selectedLang.goodTypeEnum[1],
        },
        {
          value: 2,
          label: this.selectedLang.goodTypeEnum[2],
        },
      ];
    },
    natureApplicableGoodOptions() {
      return [
        {
          value: 0,
          label: this.selectedLang.natureApplicableGoodOptions[0],
        },
        {
          value: 1,
          label: this.selectedLang.natureApplicableGoodOptions[1],
        },
        {
          value: 2,
          label: this.selectedLang.natureApplicableGoodOptions[2],
        },
        {
          value: 3,
          label: this.selectedLang.natureApplicableGoodOptions[3],
        },
        {
          value: 4,
          label: this.selectedLang.natureApplicableGoodOptions[4],
        },
        {
          value: 5,
          label: this.selectedLang.natureApplicableGoodOptions[5],
        },
      ];
    },
    goodOwnershipRightOptions() {
      return [
        {
          value: 0,
          label: this.selectedLang.goodOwnershipRightOptions[0],
        },
        {
          value: 1,
          label: this.selectedLang.goodOwnershipRightOptions[1],
        },
      ];
    },
    declarantIsTheHolderOptions() {
      return [
        {
          value: 1,
          label: this.selectedLang.declarantIsTheHolderOption1,
        },
        {
          value: 2,
          label: this.selectedLang.declarantIsTheHolderOption2,
        },
      ];
    },
    isBarco() {
      return (
        this.itemToEdit?.value != null && this.itemToEdit?.value[0]?.value == 0
      );
    },
    isAeronave() {
      return (
        this.itemToEdit?.value != null && this.itemToEdit?.value[0]?.value == 1
      );
    },
    isAutomovel() {
      return (
        this.itemToEdit?.value != null && this.itemToEdit?.value[0]?.value == 2
      );
    },

    tipoBemIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 0);
      let value = this.itemToEdit.value[0].value;
      return value != null && (value != "" || value == 0);
    },
    tipoBemShowError() {
      return this.validateForm && !this.tipoBemIsValid;
    },
    registerNumberIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 1);
      let value = this.itemToEdit.value[1].value;
      return !this.isBarco || (value != null && value != "");
    },
    registerNumberShowError() {
      return this.validateForm && !this.registerNumberIsValid;
    },
    shipNameIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 2);
      let value = this.itemToEdit.value[2].value;
      return !this.isBarco || (value != null && value != "");
    },
    shipNameShowError() {
      return this.validateForm && !this.shipNameIsValid;
    },
    manufacturerBrandIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 3);
      let value = this.itemToEdit.value[3].value;
      return (
        (!this.isBarco && !this.isAeronave) || (value != null && value != "")
      );
    },
    manufacturerBrandShowError() {
      return this.validateForm && !this.manufacturerBrandIsValid;
    },
    intendedPurposeIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 4);
      let value = this.itemToEdit.value[4].value;
      return !this.isBarco || (value != null && value != "");
    },
    intendedPurposeShowError() {
      return this.validateForm && !this.intendedPurposeIsValid;
    },
    constructionYearIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 5);
      let value = this.itemToEdit.value[5].value;
      return !this.isBarco || (value != null && value != "");
    },
    constructionYearShowError() {
      return this.validateForm && !this.constructionYearIsValid;
    },
    mainDimensionsIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 6);
      let value = this.itemToEdit.value[6].value;
      return !this.isBarco || (value != null && value != "");
    },
    mainDimensionsShowError() {
      return this.validateForm && !this.mainDimensionsIsValid;
    },
    engineCharacteristicsIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 7);
      let value = this.itemToEdit.value[7].value;
      return !this.isBarco || (value != null && value != "");
    },
    engineCharacteristicsShowError() {
      return this.validateForm && !this.engineCharacteristicsIsValid;
    },
    grossTonnageIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 8);
      let value = this.itemToEdit.value[8].value;
      return !this.isBarco || (value != null && value != "");
    },
    grossTonnageShowError() {
      return this.validateForm && !this.grossTonnageIsValid;
    },
    netTonnageIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 9);
      let value = this.itemToEdit.value[9].value;
      return !this.isBarco || (value != null && value != "");
    },
    netTonnageShowError() {
      return this.validateForm && !this.netTonnageIsValid;
    },
    motivePowerIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 10);
      let value = this.itemToEdit.value[10].value;
      return !this.isBarco || (value != null && value != "");
    },
    motivePowerShowError() {
      return this.validateForm && !this.motivePowerIsValid;
    },

    aircraftTypeIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 11);
      let value = this.itemToEdit.value[11].value;
      return !this.isAeronave || (value != null && value != "");
    },
    aircraftTypeShowError() {
      return this.validateForm && !this.aircraftTypeIsValid;
    },
    carRegistrationNumberIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 12);
      let value = this.itemToEdit.value[12].value;
      return !this.isAeronave || (value != null && value != "");
    },
    carRegistrationNumberShowError() {
      return this.validateForm && !this.carRegistrationNumberIsValid;
    },
    modelIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 13);
      let value = this.itemToEdit.value[13].value;
      return (
        (!this.isAeronave && !this.isAutomovel) ||
        (value != null && value != "")
      );
    },
    modelShowError() {
      return this.validateForm && !this.modelIsValid;
    },
    manufactureYearIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 14);
      let value = this.itemToEdit.value[14].value;
      return !this.isAeronave || (value != null && value != "");
    },
    manufactureYearShowError() {
      return this.validateForm && !this.manufactureYearIsValid;
    },
    maximumWeightIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 15);
      let value = this.itemToEdit.value[15].value;
      return !this.isAeronave || (value != null && value != "");
    },
    maximumWeightShowError() {
      return this.validateForm && !this.maximumWeightIsValid;
    },

    registrationIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 16);
      let value = this.itemToEdit.value[16].value;
      return !this.isAutomovel || (value != null && value != "");
    },
    registrationShowError() {
      return this.validateForm && !this.registrationIsValid;
    },
    enrollmentYearIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 17);
      let value = this.itemToEdit.value[17].value;
      return !this.isAutomovel || (value != null && value != "");
    },
    enrollmentYearShowError() {
      return this.validateForm && !this.enrollmentYearIsValid;
    },
    brandIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 18);
      let value = this.itemToEdit.value[18].value;
      return !this.isAutomovel || (value != null && value != "");
    },
    brandShowError() {
      return this.validateForm && !this.brandIsValid;
    },
    cisplacementIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 19);
      let value = this.itemToEdit.value[19].value;
      return !this.isAutomovel || (value != null && value != "");
    },
    cisplacementShowError() {
      return this.validateForm && !this.cisplacementIsValid;
    },
    maximumUsefulPowerIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 20);
      let value = this.itemToEdit.value[20].value;
      return !this.isAutomovel || (value != null && value != "");
    },
    maximumUsefulPowerShowError() {
      return this.validateForm && !this.maximumUsefulPowerIsValid;
    },
    nationalCategoryIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 21);
      let value = this.itemToEdit.value[21].value;
      return !this.isAutomovel || (value != null && value != "");
    },
    nationalCategoryShowError() {
      return this.validateForm && !this.nationalCategoryIsValid;
    },
    vehicleTypeIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 22);
      let value = this.itemToEdit.value[22].value;
      return !this.isAutomovel || (value != null && value != "");
    },
    vehicleTypeShowError() {
      return this.validateForm && !this.vehicleTypeIsValid;
    },

    natureApplicableGoodIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 23);
      let value = this.itemToEdit.value[23].value;
      return value != null && (value != "" || value == 0);
    },
    natureApplicableGoodShowError() {
      return this.validateForm && !this.natureApplicableGoodIsValid;
    },
    goodOwnershipRightIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 24);
      let value = this.itemToEdit.value[24].value;
      return value != null && (value != "" || value == 0);
    },
    goodOwnershipRightShowError() {
      return this.validateForm && !this.goodOwnershipRightIsValid;
    },
    declarantIsTheHolderIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 25);
      let value = this.itemToEdit.value[25].value;
      return !!value;
    },
    declarantIsTheHolderShowError() {
      return this.validateForm && !this.declarantIsTheHolderIsValid;
    },
    legaOrNaturalPersonIsValid() {
      utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 26);
      let value = this.itemToEdit.value[26].value;
      return (
        !this.isDeclarantIsTheHolderOption2 || (value != null && value != "")
      );
    },
    legaOrNaturalPersonShowError() {
      return this.validateForm && !this.legaOrNaturalPersonIsValid;
    },

    formIsValid() {
      return (
        this.tipoBemIsValid &&
        this.registerNumberIsValid &&
        this.manufacturerBrandIsValid &&
        this.intendedPurposeIsValid &&
        this.constructionYearIsValid &&
        this.mainDimensionsIsValid &&
        this.engineCharacteristicsIsValid &&
        this.grossTonnageIsValid &&
        this.netTonnageIsValid &&
        this.motivePowerIsValid &&
        this.aircraftTypeIsValid &&
        this.carRegistrationNumberIsValid &&
        this.modelIsValid &&
        this.manufactureYearIsValid &&
        this.maximumWeightIsValid &&
        this.registrationIsValid &&
        this.brandIsValid &&
        this.cisplacementIsValid &&
        this.maximumUsefulPowerIsValid &&
        this.nationalCategoryIsValid &&
        this.vehicleTypeIsValid &&
        this.natureApplicableGoodIsValid &&
        this.goodOwnershipRightIsValid &&
        this.declarantIsTheHolderIsValid &&
        this.shipNameIsValid
      );
    },
    isDeclarantIsTheHolderOption2() {
      return this.itemToEdit.value[25]?.value == 2;
    },
  },
};
</script>

<style></style>
