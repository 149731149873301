import { createStore } from "vuex";

export default createStore({
  state: {
    lang: 0,
    count: 0,
    teste: "LOGIN",

    declaracao: {
      json: null,
      estado: null,
      natureza: null,
      cargo: null,
      orgao: null,
      titular: null,
      RelatedDeclarationId: null,
      EntityName: null,
      BoardName: null,
      RoleName: null,
      HolderName: null,
      NIF: null,
    },
    declaracaoSub: {
      json: null,
      estado: null,
      natureza: null,
      cargo: null,
      orgao: null,
      titular: null,
      RelatedDeclarationId: null,
      EntityName: null,
      BoardName: null,
      RoleName: null,
      HolderName: null,
      NIF: null,
      draftId: null,
    },

    navigation: {
      email: null,
      acessCode: null,
      backAction: 0,
      roleId: null,
      entityId: null,
      holderId: null
    },

    declarationInputErrors: [],

    user: null,
    draftId: null,
  },
  mutations: {},
  actions: {},
  modules: {},
});
