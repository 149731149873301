<template>
  <span v-if="show" class="f-l inputInvalidMsg">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.40455 10.9979C1.63262 12.3313 2.59473 14 4.1354 14H11.8643C13.4049 14 14.3671 12.3313 13.5951 10.9979L9.73069 4.323C8.96036 2.99243 7.03931 2.99243 6.26898 4.323L2.40455 10.9979ZM8.6665 11.3333C8.6665 11.7015 8.36803 12 7.99984 12C7.63165 12 7.33317 11.7015 7.33317 11.3333C7.33317 10.9652 7.63165 10.6667 7.99984 10.6667C8.36803 10.6667 8.6665 10.9652 8.6665 11.3333ZM8.6665 8.66668C8.6665 9.03487 8.36803 9.33334 7.99984 9.33334C7.63165 9.33334 7.33317 9.03487 7.33317 8.66668V7.33334C7.33317 6.96515 7.63165 6.66668 7.99984 6.66668C8.36803 6.66668 8.6665 6.96515 8.6665 7.33334V8.66668Z"
        fill="#F03738"
      />
    </svg>
    {{ msg }}
  </span>
</template>

<script>
export default {
  props: {
    show: {
      Boolean,
      default: false,
    },
    msg: String,
  },
  mounted() {
  },
};
</script>

<style></style>
