<template>
  <div class="TabMenu">
    <div class="d-f">
      <slot></slot>
    </div>
    <hr class="rounded" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
