import pt from "@/resources/pt"

export default {
    selectLang(lang){
        switch (lang) {
            case 0:
            default:
                return pt;
        }
    }
}