import axios from 'axios';

export default {
    get: (resource, config) => {
        var url = `${process.env.VUE_APP_API_URL}${resource}`;
        return axios.get(url, config);
    },
    post: (resource, model) => {
        var url = `${process.env.VUE_APP_API_URL}${resource}`;
        return axios.post(url, model);
    },
    put: (resource, model) => {
        var url = `${process.env.VUE_APP_API_URL}${resource}`;
        return axios.put(url, model);
    },
    delete: (resource) => {
        var url = `${process.env.VUE_APP_API_URL}${resource}`;
        return axios.delete(url);
    },
    // delete: (resource, model) => {
    //     var url = `${process.env.VUE_APP_API_URL}${resource}`;
    //     return axios.delete(url, { data: model });
    // },
    postFile: (resource, model) => {
        var url = `${process.env.VUE_APP_API_URL}${resource}`;
        return axios.post(url, model, { responseType: 'blob' });
    },
    getFile: (resource) => {
        var url = `${process.env.VUE_APP_API_URL}${resource}`;
        return axios.get(url, { responseType: 'blob' });
    },
};