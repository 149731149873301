import service from "./service-base";

export default {
    search: (pageNumber, sortBy, searchModel) => {
        searchModel.pageNumber = pageNumber
        searchModel.sortBy = sortBy
        return service.post(`/publicquery`, searchModel);
    },
    getAllEntities: (searchModel) => {
        return service.post(`/publicquery/getallentities`, searchModel);
    },
    getAllRoles: (searchModel) => {
        return service.post(`/publicquery/getallroles`, searchModel);
    },
    getAllHolders: (searchModel) => {
        return service.post(`/publicquery/getallholders`, searchModel);
    },
    searchDeclaration: (pageNumber, sortBy, searchModel) => {
        searchModel.pageNumber = pageNumber
        searchModel.sortBy = sortBy
        return service.post(`/publicquery/search`, searchModel);
    },
    getdeclaration: (searchModel) => {
        return service.post(`/publicquery/getdeclaration`, searchModel);
    },
    getallboards: (searchModel) => {
        return service.post(`/publicquery/getallboards`, searchModel);
    },
    getloginenabled: () => {
        return service.get(`/publicquery/getloginenabled`);
    },
};
