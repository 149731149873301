<template>
  <div>
    <template v-if="showTable">
      <TableSort
        v-if="itemsLength > 0"
        :showEmptyrow="true"
        :totalItems="itemsLength"
        :checkBox="false"
        :loading="false"
        :tableModel="tableModel"
        :actions="true"
        :searchFunc="searchFunc"
        :sortFunc="sortFunc"
        defaultSort="Entity"
      >
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.entity }}</td>
          <td>{{ item.role }}</td>
          <td>{{ item.holder }}</td>
          <td>{{ $filters.formatDate(item.beginDate) }}</td>
          <td>{{ $filters.formatDate(item.endDate) }}</td>
          <td>
            <a class="btnAlike" @click="detail(item)">
              <svg
                class="btnAlike"
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 4.66699L17 12.4795L9.5 20.292"
                  stroke="#102E40"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </td>
        </tr>
        <!-- <tr v-for="(item, index) in items" :key="index" class="testRow">
        <td>{{ item.entidade }}</td>
        <td>{{ item.cargo }}</td>
      </tr> -->
      </TableSort>
      <EmptyTable
        customMessage="Não encontrámos resultados para a sua pesquisa. Experimente utilizar outra combinação de filtros"
        v-else
      />
    </template>
    <template v-else>
      <HidedHomePageTable />
    </template>
  </div>
</template>

<script>
import publicQueryService from "@/services/publicQuery.service";
import HidedHomePageTable from "./HidedHomePageTable.vue";
import { utils } from "@/helpers";

export default {
  name: "TestePage",
  components: { HidedHomePageTable },
  data() {
    return {
      items: [],
      testData: [],
      tableModelAux: {
        total: 0,
        pages: 0,
        sortBy: "Entity asc",
      },
      emitterItem: {},
      showTable: false,
    };
  },
  mounted() {
    this.emitter.on("HomeSearch", (emitterItem) => {
      this.showTable = true;
      this.emitterItem = emitterItem;
      this.search(1, this.tableModelAux.sortBy, emitterItem);
    });

    this.emitter.on("HomeHideTable", () => {
      this.showTable = false;
    });
  },
  methods: {
    detail(item) {
      this.$router.push({
        name: "Declaracoes",
        params: {
          ids: utils.encryptData(
            `${item.holderId}-${item.roleId}-${item.entityId}`
          ),
        },
      });
    },
    searchFunc(page) {
      this.search(page, this.tableModelAux.sortBy, this.emitterItem);
    },
    sortFunc(sort) {
      this.tableModelAux.sortBy = sort;
      this.search(1, sort, this.emitterItem);
    },
    search(pageNumber, sortBy, filter) {
      publicQueryService
        .search(pageNumber, sortBy, filter)
        .then((response) => {
          if (response.code == 0) {
            this.tableModelAux.total = response.data.total;
            this.tableModelAux.pages = response.data.pageCount;
            this.items = response.data.items;
          }
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.InternalServerError);
        })
        .finally(() => {});
    },
  },
  computed: {
    tableModel() {
      return {
        headers: [
          {
            title: this.selectedLang.entityTxt,
            sort: "Entity",
          },
          {
            title: this.selectedLang.officeTxt,
            sort: "Role",
          },
          {
            title: this.selectedLang.titleName,
            sort: "Holder",
          },
          {
            title: this.selectedLang.officeStart,
            sort: "BeginDate",
          },
          {
            title: this.selectedLang.officeEnd,
            sort: "EndDate",
          },
        ],
        total: this.tableModelAux.total,
        pages: this.tableModelAux.pages,
      };
    },
    itemsLength() {
      return this.items == null ? 0 : this.items.length;
    },
  },
  beforeUnmount() {
    this.emitter.off("HomeSearch");
    this.emitter.off("HomeHideTable");
  },
};
</script>

<style></style>
