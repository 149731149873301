<template>
  <div
    @click="selectNovaDeclaracaoBtnHandler"
    class="novaDeclaracaoBtn btnAlike"
    :class="{ isSelected: isSelected }"
  >
    <div class="novaDeclaracaoBtn-icon" v-if="showIcon">
      <slot name="icon"></slot>
    </div>
    <div class="novaDeclaracaoBtn-body">
      <slot name="body"></slot>
      <p>{{ txt }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSelected: false,
    };
  },
  props: {
    txt: String,
    showIcon: {
      Boolean,
      default: true,
    },
    group: String,
    btnId: String,
    value: [String, Boolean],
  },
  methods: {
    selectNovaDeclaracaoBtnHandler() {
      const emitter = {
        btnId: this.btnId,
        group: this.group,
        value: this.value,
      };
      this.emitter.emit("selectNovaDeclaracaoBtn", emitter);
    },
    selectNovaDeclaracaoBtn(emitterItem) {
      if (emitterItem.group == this.group) {
        if (this.btnId == emitterItem.btnId) {
          this.isSelected = true;
        } else {
          this.isSelected = false;
        }
      } else {
        this.isSelected = false;
      }
    },
  },
  mounted() {
    this.emitter.on("selectNovaDeclaracaoBtn", (emitterItem) => {
      this.selectNovaDeclaracaoBtn(emitterItem);
    });
  },
  // beforeUnmount() {
  //   this.emitter.off("selectNovaDeclaracaoBtn");
  // },
};
</script>
