<template>
  <div>
    <div class="row mb-2">
      <div class="col-12 ta-last-left">
        <span class="title">{{ selectedLang.otherStatements }}</span>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <CustomAccordion
          :title="selectedLang.otherStatements"
          :isOpenDefault="isOpenDefault"
        >
          <div class="row mt-1">
            <div class="col-12 mb-4">
              <DeclaracaoUnicaInput
                :declaracaoUnicaProp="declaracaoUnicaProp"
                inputKey="51240498-fcab-4342-8522-2bf14c84ed39"
                type="Text"
                :label="selectedLang.otherStatementsFormField"
                v-model="formAreaDisponivel"
                :required="false"
                :maxlength="150"
              />
            </div>
          </div>
        </CustomAccordion>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      formAreaDisponivel: null,
    };
  },
  methods: {},
  computed: {
    outrasDeclaracoes: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracao.json,
          "51240498-fcab-4342-8522-2bf14c84ed39"
        );
      },
    },
    isOpenDefault() {
      return this.outrasDeclaracoes?.value != null;
    },
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
};
</script>
