<template>
  <svg
    width="62"
    height="63"
    viewBox="0 0 62 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.3132 45.1734C12.9004 45.1734 3.64844 35.8596 3.64844 24.3704C3.64844 12.8812 12.9004 3.56738 24.3132 3.56738C35.726 3.56738 44.9779 12.8812 44.9779 24.3704C44.9779 30.115 42.6649 35.3158 38.9253 39.0804C35.1858 42.845 30.0196 45.1734 24.3132 45.1734Z"
      fill="#CAEBFF"
    />
    <path
      d="M58.9457 59.7025L38.9253 39.0804M38.9253 39.0804C42.6649 35.3158 44.9779 30.115 44.9779 24.3704C44.9779 12.8812 35.726 3.56738 24.3132 3.56738C12.9004 3.56738 3.64844 12.8812 3.64844 24.3704C3.64844 35.8596 12.9004 45.1734 24.3132 45.1734C30.0196 45.1734 35.1858 42.845 38.9253 39.0804Z"
      stroke="#007AC4"
      stroke-width="5.60304"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
