<template>
  <button @click="recaptcha">Execute recaptcha</button>
</template>

<script setup>
import { useReCaptcha } from "vue-recaptcha-v3";

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

const recaptcha = async () => {
  await recaptchaLoaded();

  const token = await executeRecaptcha("submit");
  console.log(token);

  // Do stuff with the received token.
};
</script>

<style scoped></style>
