export default {
  declarationStateTypeEnum: {
      draft:1,
      pendingVerification:2,
      notPublished:4,
      published:8,
      canceled:16,
      modified:32,
      replaced:64,
  },
  userProfileEnum:{
    holder:1,
    comission:2
  },
  mimes:{
      pdf: "application/pdf",
      xlsx: "application/xlsx",
      doc: "application/doc",
      docx: "application/docx",
      png: "image/png",
      jpeg: "image/jpeg",
      jpg: "image/jpg",
  },
  declarationNatureTypeEnum:{
      initial:1,
      modification:2,
      cessation:4,
      final:8,
      replace:16,
  },
}