<template>
  <form>
    <div class="row mb-1">
      <div class="col-12">
        <h1 class="">
          {{ selectedLang.otherIncome }}
        </h1>
      </div>
    </div>
    <DeclaracaoUnicaBlockedData
      v-if="!showOutrosRedimentosField"
      :reason="reason"
    />

    <div class="row" v-if="showOutrosRedimentosField">
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="929796f8-a63c-49b4-b2de-56191668dfa0"
          type="Monetery"
          :label="selectedLang.independentWork"
          v-model="form.independentWork"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="ff035b46-8c60-4ffb-9307-9b348210c7dd"
          type="Monetery"
          :label="selectedLang.commercialIndustrial"
          v-model="form.commercialIndustrial"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="4863ec0b-2061-4bed-a88f-6803547d839b"
          type="Monetery"
          :label="selectedLang.agricultural"
          v-model="form.agricultural"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="a4f13529-fc7b-4a8d-a57d-4c6a04c5de32"
          type="Monetery"
          :label="selectedLang.capitals"
          v-model="form.capitals"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="1d9eb246-99b3-41c1-9ef4-473925b77296"
          type="Monetery"
          :label="selectedLang.building"
          v-model="form.building"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="90848b4c-9530-44f3-82c3-5cabd86f936a"
          type="Monetery"
          :label="selectedLang.gains"
          v-model="form.gains"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="fb030f7e-bb87-49b6-8d67-65dfa01699c0"
          type="Monetery"
          :label="selectedLang.pensions"
          v-model="form.pensions"
        />
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 col-12 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="548797e6-8cbd-4e2e-8aff-b6bed1c7ed6e"
          type="Monetery"
          :label="selectedLang.otherIncome"
          v-model="form.otherIncome"
        />
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        independentWork: null,
        commercialIndustrial: null,
        agricultural: null,
        capitals: null,
        building: null,
        gains: null,
        pensions: null,
        otherIncome: null,
      },
    };
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  computed: {
    outrosRedimentosField() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        "e516635f-aeb6-4e1f-aa9c-d77bf888ec36"
      );
    },

    showOutrosRedimentosField() {
      return this.outrosRedimentosField.isVisible;
    },
    reason() {
      return this.outrosRedimentosField.reason;
    },
  },
};
</script>

<style></style>
