<template>
  <CustomCard :size="'s'">
    <template v-if="loading">
      <LoadingSpinner />
    </template>
    <template v-else>
      <template v-if="!isLoginEnabled">
        <div class="p-5">
          <div class="row">
            <div class="col-12">
              <p class="home-card-h">{{ selectedLang.loginNotEnabledTitle }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="home-card-p" style="white-space: pre-line">
                {{ selectedLang.loginNotEnabledTxt }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <TabSelector>
          <Tab :tabName="selectedLang.holders" :selected="true">
            <TitularesLoginForm class="p-5" :profile-id="1" />
          </Tab>
          <Tab :tabName="selectedLang.commission">
            <ComissaoLoginForm class="p-5" :profile-id="2" />
          </Tab>
        </TabSelector>
      </template>
    </template>
  </CustomCard>
</template>

<script setup>
import TitularesLoginForm from "./TitularesLoginForm.vue";
import ComissaoLoginForm from "./ComissaoLoginForm.vue";
import { publicQueryService } from "@/services";
import { onMounted, ref } from "vue";
import { authentication } from "@/helpers";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(() => {
  let auth = authentication.get();

  if (auth && !auth.expired) {
    router.push({
      name: "AreaPrivada",
    });
  }
  getloginenabled();
});

var isLoginEnabled = ref(false);
var loading = ref(true);

async function getloginenabled() {
  loading.value = true;

  var response = await publicQueryService.getloginenabled();

  isLoginEnabled.value = response.data;

  // utils.validateResponse(response, null, () => {
  //   isLoginEnabled.value = response.data;
  // });

  loading.value = false;
}
</script>
