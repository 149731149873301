import { documentService } from "@/services";
import { utils,declaracaoUnicaHelper } from "@/helpers";
import moment from "moment";

export default {
    async getFileGuid(){
        let guid = null
        let response = await documentService.create()

        if (response.code == 0) {
            guid = response.data
        }

        return guid
    },

    async addGuidsCurrency(declaration,guid){
        let formatPt =  new Intl.NumberFormat('pt-Pt', { style: 'currency', currency: 'EUR' });
        let guidLists = [
            "929796f8-a63c-49b4-b2de-56191668dfa0",
            "ff035b46-8c60-4ffb-9307-9b348210c7dd",
            "4863ec0b-2061-4bed-a88f-6803547d839b",
            "a4f13529-fc7b-4a8d-a57d-4c6a04c5de32",
            "1d9eb246-99b3-41c1-9ef4-473925b77296",
            "90848b4c-9530-44f3-82c3-5cabd86f936a",
            "fb030f7e-bb87-49b6-8d67-65dfa01699c0",
            "548797e6-8cbd-4e2e-8aff-b6bed1c7ed6e",
        ]

        let data = {};
        guidLists.forEach(async g => {
            let field = declaracaoUnicaHelper.getFieldByKey(declaration,g)
            if (field?.value != null) {
                let value = formatPt.format(field?.value / 100).replace(/\s/g, "");
                data[g] = value;
            } else {
                data[g] = "";
            }
        });
        await  documentService.adddata(guid, data);
        
    },
    async addData(declaration,guid,selectedLang){

        let de = "";
        switch (declaration.natureType) {
            case 1:
            case 8:
            default:
                break;
            case 2:
            case 4:
            case 16:
                de= " de"
                break;
        }
        let data = {
            "%SUBMITION_DATE%":utils.formatDate(declaration.submitedDate,"DD-MM-YYYY HH:mm"),
            "[natureza da declaração #]":`Declaração${de} ${selectedLang.declarationNatureTypeEnum[declaration.natureType]} - n.º ${declaration.id}`,
            "[dd-mm-aaaa hh:mm]":utils.formatDate(new Date(), "DD-MM-YYYY HH:mm"),
            "%declaracaoRelacionada%":declaration.relatedDeclarationId != null ? declaration.relatedDeclarationId.toString() : ""
        }
        

        await documentService.adddata(guid, data);
    },
    async addGuids(declaration,guid){
        let guidLists = [
            "f649defc-e001-4866-9c2b-01ed9fa80eef",
            "418cdc5e-7f77-4922-b0b7-3ab6b2f84c6f",
            "5783eba6-4662-4e01-a1ab-14dbe7bb693c",

            "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b",
            "97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e",
            "d155af2b-21e9-475f-be81-8e42bc5a5641",
            "a5bf96eb-8af0-4c05-bb76-a15f57f8490d",
            "25fa81a1-70d2-4fca-8292-4717ba2e8cdb",
            "ed9a8413-b9f0-4d32-89bd-03593c826213",

            "37b963d3-b73a-4da7-9a78-cb417f92214a",
            "38cffadd-0893-47ef-b261-84007c381639",
            "308ccc8a-452a-4ed7-94e5-5ca17a1667fe",
            "77902677-0143-437c-ae9b-b3e54642a080",
            "2b75bfd8-392e-49d7-866f-1353a5e765c3",
            
            "d3fd512b-65e8-4550-90d9-018a5ef91c0d",
            "8b022aca-fdf8-4391-8d49-73abc4e63128",
            "67d71fd5-100a-42dd-a6a8-44d40ce2ba50",

            "51240498-fcab-4342-8522-2bf14c84ed39",
        ]
        let data = {};

        guidLists.forEach(async g => {
            let field = declaracaoUnicaHelper.getFieldByKey(declaration.value,g)
            if (field?.value != null) {
                let value = "";
                // Convertendo para string se for um número
                if (typeof field.value === 'boolean') {
                    value = field.value ? "Sim" : "Não";
                    
                } else if (typeof field.value === 'number') {
                    value = field.value.toString();
                    
                } else if ( g== "38cffadd-0893-47ef-b261-84007c381639") {
                    //codigo postal
                    value = field.value;
                } else if (this.checkIfIsDate(field.value)) {
                    // Formatando a data para dd/mm/aaaa
                    value = utils.formatDate(field.value, "DD/MM/YYYY");
                } else {
                    value = field.value;
                }

                data[g] = value;

            } else {
                data[g] = "";
            }
        });
        
        await  documentService.adddata(guid, data);
    },
    checkIfIsDate(date){
        let result = moment(date, moment.ISO_8601)
        return  result.isValid()
    },
    
    async addGuidsFields(declaration,guid){
        let data = {}
        data["c83b94a8-5d7a-4061-a001-0c3522d2e533"] = declaration.entity
        data["21cdd43f-aebd-43c1-8b47-0eafedb644c8"] = declaration.board
        data["d34934dc-dc8f-4515-abc3-eb2a16d58ed1"] = declaration.holder
        data["5a4b3e2d-c82c-49f8-a1c0-5ad86ddb0d67"] = declaration.role

        await  documentService.adddata(guid, data);
    },

    async addGuidsFieldsEnums(declaration,guid,selectedLang){
        let guidLists = [
            {"b8de313d-cc0d-445d-b211-368794d7a2df":selectedLang.yesNoEnum},
            {"ed9a8413-b9f0-4d32-89bd-03593c826211":selectedLang.maritalStatusOptions},
            {"ed9a8413-b9f0-4d32-89bd-03593c826212":selectedLang.propertyRegimeEnum},
            {"54854c64-5845-40bb-a536-8c03da55420a":selectedLang.sexEnum},
        ]


        let data = {};
        guidLists.forEach(async g => {
            const guidKey = Object.keys(g)[0];

            let field = declaracaoUnicaHelper.getFieldByKey(declaration.value,guidKey.toString())
            if (field?.value != null) {
                let value = g[guidKey][field?.value]
                data[guidKey.toString()] = value;
            } else {
                data[guidKey.toString()] = "";
            }
        });
        await  documentService.adddata(guid, data);
        
    },

    async downloadFile(guid,selectedLang,declaration){
        let response = await documentService.download(guid, `declaração ${selectedLang.declarationNatureTypeEnum[declaration.natureType]} ${utils.formatDate(new Date(), "DDMMYYYY HHmm")}`)
        
          if (response.code == 0) {
            utils.downloadFile(response.data);
           
          }
        
    },

    async addTables(guid,declaration,selectedLang){
        let tables = []
        tables.push(this.addTableTrabalhoDependente(declaration.data,selectedLang,6))
        tables.push(this.addTablePatrimonioImobiliarioPortugal(declaration.data,selectedLang,9))
        tables.push(this.addTablePatrimonioImobiliarioEstrangeiro(declaration.data,selectedLang,10))
        tables.push(this.addTableQuotasAcoesPortugal(declaration.data,selectedLang,11))
        tables.push(this.addTableOutrasPartesPortugal(declaration.data,selectedLang,12))
        tables.push(this.addTableQuotasAcoesOutrasPartesEstrangeiro(declaration.data,selectedLang,13))
        tables.push(this.addTableBarcoPortugal(declaration.data,selectedLang,14))
        tables.push(this.addTableBarcoEstrangeiro(declaration.data,selectedLang,15))
        tables.push(this.addTableAeronavesPortugal(declaration.data,selectedLang,16))
        tables.push(this.addTableAeronavesEstrangeiro(declaration.data,selectedLang,17))
        tables.push(this.addTableAutomovelPortugal(declaration.data,selectedLang,18))
        tables.push(this.addTableAutomovelEstrangeiro(declaration.data,selectedLang,19))
        tables.push(this.addTableCarteirasTituloPortugal(declaration.data,selectedLang,20))
        tables.push(this.addTableCarteirasTituloEstrangeiro(declaration.data,selectedLang,21))
        tables.push(this.addTableContasBancariasPrazoPortugal(declaration.data,selectedLang,22))
        tables.push(this.addTableContasBancariasPrazoEstrangeiro(declaration.data,selectedLang,23))
        tables.push(this.addTableAplicacoesFinanceirasPortugal(declaration.data,selectedLang,24))
        tables.push(this.addTableAplicacoesFinanceirasEstrangeiro(declaration.data,selectedLang,25))
        tables.push(this.addTableContasBancariasOrdemPortugal(declaration.data,selectedLang,26))
        tables.push(this.addTableContasBancariasOrdemEstrangeiro(declaration.data,selectedLang,27))
        tables.push(this.addTableDireitosCreditoPortugal(declaration.data,selectedLang,28))
        tables.push(this.addTableDireitosCreditoEstrangeiro(declaration.data,selectedLang,29))
        tables.push(this.addTableOutrosElementosPortugal(declaration.data,selectedLang,30))
        tables.push(this.addTableOutrosElementosEstrangeiro(declaration.data,selectedLang,31))
        tables.push(this.addTablePassivoPortugal(declaration.data,selectedLang,33))
        tables.push(this.addTablePassivoEstrangeiro(declaration.data,selectedLang,34))
        tables.push(this.addTableGarantiasPatrimoniaisPortugal(declaration.data,selectedLang,35))
        tables.push(this.addTableGarantiasPatrimoniaisEstrangeiro(declaration.data,selectedLang,36))
        tables.push(this.addTablePatrimonial(declaration.data,selectedLang,37))
        tables.push(this.addTableAtividadesProfissionais(declaration.data,selectedLang,40))
        tables.push(this.addTableRelativosFiliacao(declaration.data,selectedLang,41))
        tables.push(this.addTableApoiosBeneficios(declaration.data,selectedLang,42))
        tables.push(this.addTableServicoPrestado(declaration.data,selectedLang,43))
        tables.push(this.addTableSociedades(declaration.data,selectedLang,44))
        tables.push(this.addTableOutrasSituacoes(declaration.data,selectedLang,45))
    
        tables = tables.filter(table => table.rows.some(row => row.some(cell => !utils.isNullOrEmpty(cell))));
        await documentService.adddatatotable(guid, tables);
    },

    addTableTrabalhoDependente(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"da9c415a-e950-4ce9-ab58-5ce1c1b5af53")
        
        let fields = [
            {
                key:"da9c415a-e950-4ce9-ab58-5ce1c1b5af53-col4",
                type:"enum",
                enum:"yesNoEnum",
                label:"Rendimento obtido no estrangeiro?"
            },
            {
                key:"da9c415a-e950-4ce9-ab58-5ce1c1b5af53-col2",
                type:"",
                label:"Designação da entidade pagadora",
            },
            {
                key:"da9c415a-e950-4ce9-ab58-5ce1c1b5af53-col3",
                type:"",
                label:"NIF/NIPC",
            },
            {
                key:"da9c415a-e950-4ce9-ab58-5ce1c1b5af53-col1",
                type:"currency",
                label:"Valor monetário",
            },
            {
                key:"da9c415a-e950-4ce9-ab58-5ce1c1b5af53-col7",
                type:"",
            },
            {
                key:"da9c415a-e950-4ce9-ab58-5ce1c1b5af53-col5",
                type:"",
            },
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTablePatrimonioImobiliarioPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"112c6561-4a51-4f79-b637-bbd8d2c0c74c")
        
        let fields = [
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col1",
                type:"enum",
                enum:"realEstateNatureEnum",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col2",
                type:"",
                enum:"realEstateNatureEnum",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col11",
                type:"",
                enum:"realEstateNatureEnum",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col4",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col5",
                type:"currency",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col6",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col9",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col10",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col7",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col12",
                type:"enum",
                enum:"natureApplicableGoodOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col13",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col14",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col15",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"112c6561-4a51-4f79-b637-bbd8d2c0c74c-col16",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTablePatrimonioImobiliarioEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"252ba9e4-bbba-4660-be9e-470b263c4832")
        
        let fields = [
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col1",
                type:"enum",
                enum:"realEstateNatureEnum",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col2",
                type:"",
                enum:"realEstateNatureEnum",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col3",
                type:"currency",
                enum:"realEstateNatureEnum",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col4",
                type:"",
                enum:"realEstateNatureEnum",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col5",
                type:"",
                enum:"realEstateNatureEnum",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col7",
                type:"enum",
                enum:"natureApplicableBuildingOptions",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col12",
                type:"",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col8",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col9",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col10",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"252ba9e4-bbba-4660-be9e-470b263c4832-col6",
                type:"",
                enum:"declarantIsTheHolderOptions",
            },
            
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableQuotasAcoesPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"70fdd906-58d5-4682-967d-90ae90805436")
        
        let fieldsQuotas = [
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col1",
                type:"enum",
                enum:"natureOptionEnum",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col15",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col2",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col3",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col4",
                type:"percent",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col5",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col6",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col7",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col13",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col14",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col16",
                type:"",
                enum:"",
            },
            
        ];
        let fieldsAcoes = [
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col1",
                type:"enum",
                enum:"natureOptionEnum",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col15",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col2",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col3",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col17",
                type:"percent",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col5",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col6",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col7",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col13",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col14",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col16",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"70fdd906-58d5-4682-967d-90ae90805436-col1").value

                
                if (nature == 1) {
                    
                    rows.push(this.addRow(row,selectedLang,fieldsQuotas))
                }
                if (nature == 2) {
                    
                    rows.push(this.addRow(row,selectedLang,fieldsAcoes))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableOutrasPartesPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"70fdd906-58d5-4682-967d-90ae90805436")
        
        let fields = [
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col8",
                type:"enum",
                enum:"societyTypeOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col15",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col9",
                type:"enum",
                enum:"societyTypeTypeOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col4",
                type:"percent",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col2",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col3",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col12",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col5",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col6",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col7",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col13",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col14",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col10",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col11",
                type:"",
                enum:"",
            },
            {
                key:"70fdd906-58d5-4682-967d-90ae90805436-col16",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"70fdd906-58d5-4682-967d-90ae90805436-col1").value

                if (nature == 3) {
                    
                    rows.push(this.addRow(row,selectedLang,fields))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableQuotasAcoesOutrasPartesEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"cdeffda1-efd5-44c7-b256-1822dd5bafe0")
        
        let fields = [
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col1",
                type:"",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col2",
                type:"",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col3",
                type:"percent",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col4",
                type:"",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col5",
                type:"",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col6",
                type:"",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col7",
                type:"",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col8",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col9",
                type:"",
                enum:"",
            },
            {
                key:"cdeffda1-efd5-44c7-b256-1822dd5bafe0-col10",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                    rows.push(this.addRow(row,selectedLang,fields))
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableBarcoPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"53168cfb-433e-41e6-9b23-33c1eac7fa6f")
        
        let fields = [
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col2",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col3",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col4",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col5",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col6",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col7",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col8",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col9",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col10",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col11",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col24",
                type:"enum",
                enum:"natureApplicableGoodOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col25",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col26",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col27",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col28",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col1").value

                if (nature == 0) {
                    
                    rows.push(this.addRow(row,selectedLang,fields))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableBarcoEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"05d989a9-7824-459d-aeb3-cc13f1f70c00")
        
        let fields = [
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col2",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col3",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col4",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col5",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col6",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col7",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col8",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col9",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col10",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col11",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col12",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col24",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col25",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col26",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col27",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col23",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"05d989a9-7824-459d-aeb3-cc13f1f70c00-col1").value

                if (nature == 0) {
                    
                    rows.push(this.addRow(row,selectedLang,fields))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableAeronavesPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"53168cfb-433e-41e6-9b23-33c1eac7fa6f")
        
        let fields = [
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col12",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col13",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col4",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col14",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col15",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col16",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col24",
                type:"enum",
                enum:"natureApplicableGoodOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col25",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col26",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col27",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col28",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col1").value

                if (nature == 1) {
                    
                    rows.push(this.addRow(row,selectedLang,fields))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableAeronavesEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"05d989a9-7824-459d-aeb3-cc13f1f70c00")
        
        let fields = [
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col13",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col14",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col5",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col15",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col16",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col17",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col24",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col25",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col26",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col27",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col23",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"05d989a9-7824-459d-aeb3-cc13f1f70c00-col1").value

                if (nature == 1) {
                    
                    rows.push(this.addRow(row,selectedLang,fields))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableAutomovelPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"53168cfb-433e-41e6-9b23-33c1eac7fa6f")
        
        let fields = [
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col17",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col18",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col19",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col14",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col20",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col21",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col22",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col23",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col24",
                type:"enum",
                enum:"natureApplicableGoodOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col25",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col26",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col27",
                type:"",
                enum:"",
            },
            {
                key:"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col28",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"53168cfb-433e-41e6-9b23-33c1eac7fa6f-col1").value

                if (nature == 2) {
                    
                    rows.push(this.addRow(row,selectedLang,fields))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableAutomovelEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"05d989a9-7824-459d-aeb3-cc13f1f70c00")
        
        let fields = [
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col13",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col18",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col19",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col20",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col15",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col21",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col22",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col24",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col25",
                type:"enum",
                enum:"buildingOwnershipRightOptions",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col26",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col27",
                type:"",
                enum:"",
            },
            {
                key:"05d989a9-7824-459d-aeb3-cc13f1f70c00-col23",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                let nature = declaracaoUnicaHelper.getFieldByKey(row,"05d989a9-7824-459d-aeb3-cc13f1f70c00-col1").value

                if (nature == 2) {
                    
                    rows.push(this.addRow(row,selectedLang,fields))
                }
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableCarteirasTituloPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"1a5d2580-aaf3-4874-9e20-68ce10671263")
        
        let fields = [
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col1",
                type:"",
                enum:"",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col2",
                type:"",
                enum:"",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col3",
                type:"currency",
                enum:"",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col4",
                type:"",
                enum:"",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col5",
                type:"",
                enum:"",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col6",
                type:"",
                enum:"",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col7",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col8",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col9",
                type:"",
                enum:"",
            },
            {
                key:"1a5d2580-aaf3-4874-9e20-68ce10671263-col10",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableCarteirasTituloEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67")
        
        let fields = [
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col1",
                type:"",
                enum:"",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col2",
                type:"",
                enum:"",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col3",
                type:"currency",
                enum:"",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col4",
                type:"",
                enum:"",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col5",
                type:"",
                enum:"",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col6",
                type:"",
                enum:"",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col7",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col8",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col9",
                type:"",
                enum:"",
            },
            {
                key:"4a8f53d5-34c2-4bda-b3ea-ec2b157efe67-col10",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableContasBancariasPrazoPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"e498015f-062e-4fcd-93a8-c5f1393f568b")
        
        let fields = [
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col1",
                type:"currency",
                enum:"",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col2",
                type:"",
                enum:"",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col7",
                type:"",
                enum:"",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col3",
                type:"",
                enum:"",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col4",
                type:"date",
                enum:"",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col5",
                type:"",
                enum:"",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col11",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col6",
                type:"",
                enum:"",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col8",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"e498015f-062e-4fcd-93a8-c5f1393f568b-col9",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableContasBancariasPrazoEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"9d7b601c-dc39-4460-a3ab-3bc1178cbae7")
        
        let fields = [
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col1",
                type:"currency",
                enum:"",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col2",
                type:"",
                enum:"",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col7",
                type:"",
                enum:"",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col3",
                type:"",
                enum:"",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col4",
                type:"date",
                enum:"",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col5",
                type:"",
                enum:"",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col11",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col6",
                type:"",
                enum:"",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col8",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"9d7b601c-dc39-4460-a3ab-3bc1178cbae7-col9",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableAplicacoesFinanceirasPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"7bf6ccdd-f797-4339-8c76-18a248882016")
        
        let fields = [
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col1",
                type:"",
                enum:"",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col2",
                type:"",
                enum:"",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col3",
                type:"",
                enum:"",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col4",
                type:"",
                enum:"",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col5",
                type:"currency",
                enum:"",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col6",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col7",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col8",
                type:"",
                enum:"",
            },
            {
                key:"7bf6ccdd-f797-4339-8c76-18a248882016-col9",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableAplicacoesFinanceirasEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"5eea74fb-2cdc-4897-8f04-ce31341f7296")
        
        let fields = [
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col1",
                type:"",
                enum:"",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col2",
                type:"",
                enum:"",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col3",
                type:"",
                enum:"",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col4",
                type:"",
                enum:"",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col5",
                type:"currency",
                enum:"",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col6",
                type:"enum",
                enum:"ownershipOptions",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col7",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col8",
                type:"",
                enum:"",
            },
            {
                key:"5eea74fb-2cdc-4897-8f04-ce31341f7296-col9",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableContasBancariasOrdemPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"873b46af-f3a5-4329-9aa0-a04fd1114467")
        
        let fields = [
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col1",
                type:"currency",
                enum:"",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col2",
                type:"",
                enum:"",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col6",
                type:"",
                enum:"",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col3",
                type:"",
                enum:"",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col5",
                type:"",
                enum:"",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col4",
                type:"date",
                enum:"",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col7",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col8",
                type:"",
                enum:"",
            },
            {
                key:"873b46af-f3a5-4329-9aa0-a04fd1114467-col10",
                type:"enum",
                enum:"ownershipOptions",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableContasBancariasOrdemEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"151ea0ca-b4a5-4764-a461-67d0c300c915")
        
        let fields = [
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col1",
                type:"currency",
                enum:"",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col2",
                type:"",
                enum:"",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col6",
                type:"",
                enum:"",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col3",
                type:"",
                enum:"",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col5",
                type:"",
                enum:"",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col4",
                type:"date",
                enum:"",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col7",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col8",
                type:"",
                enum:"",
            },
            {
                key:"151ea0ca-b4a5-4764-a461-67d0c300c915-col10",
                type:"enum",
                enum:"ownershipOptions",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableDireitosCreditoPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"58adafd6-226e-4978-a48c-66f53affd3ac")
        
        let fields = [
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col1",
                type:"currency",
                enum:"",
            },
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col2",
                type:"",
                enum:"",
            },
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col5",
                type:"",
                enum:"",
            },
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col3",
                type:"date",
                enum:"",
            },
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col4",
                type:"",
                enum:"",
            },
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col7",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col8",
                type:"",
                enum:"",
            },
            {
                key:"58adafd6-226e-4978-a48c-66f53affd3ac-col10",
                type:"enum",
                enum:"ownershipOptions",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableDireitosCreditoEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"f4838f1a-b32e-43bd-9f3e-6de9ff72526d")
        
        let fields = [
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col1",
                type:"currency",
                enum:"",
            },
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col2",
                type:"",
                enum:"",
            },
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col5",
                type:"",
                enum:"",
            },
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col3",
                type:"date",
                enum:"",
            },
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col4",
                type:"",
                enum:"",
            },
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col7",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col8",
                type:"",
                enum:"",
            },
            {
                key:"f4838f1a-b32e-43bd-9f3e-6de9ff72526d-col10",
                type:"enum",
                enum:"ownershipOptions",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableOutrosElementosPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"8a7cfdae-1d47-41f9-a366-5ba020c1b90f")
        
        let fields = [
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col7",
                type:"",
                enum:"",
            },
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col11",
                type:"enum",
                enum:"ownershipOptions1",
            },
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col9",
                type:"currency",
                enum:"",
            },
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col8",
                type:"",
                enum:"",
            },
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col10",
                type:"",
                enum:"",
            },
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col12",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col13",
                type:"",
                enum:"",
            },
            {
                key:"8a7cfdae-1d47-41f9-a366-5ba020c1b90f-col6",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableOutrosElementosEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"ea846529-712c-4b47-953f-5b39015c4f17")
        
        let fields = [
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col7",
                type:"",
                enum:"",
            },
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col11",
                type:"enum",
                enum:"ownershipOptions1",
            },
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col9",
                type:"currency",
                enum:"",
            },
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col8",
                type:"",
                enum:"",
            },
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col10",
                type:"",
                enum:"",
            },
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col12",
                type:"enum",
                enum:"declarantIsTheHolderOptions",
            },
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col13",
                type:"",
                enum:"",
            },
            {
                key:"ea846529-712c-4b47-953f-5b39015c4f17-col6",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTablePassivoPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"27bb57dd-0fd8-4595-aaca-53bf1634ca87")
        
        let fields = [
            {
                key:"27bb57dd-0fd8-4595-aaca-53bf1634ca87-col1",
                type:"",
                enum:"",
            },
            {
                key:"27bb57dd-0fd8-4595-aaca-53bf1634ca87-col6",
                type:"",
                enum:"",
            },
            {
                key:"27bb57dd-0fd8-4595-aaca-53bf1634ca87-col2",
                type:"currency",
                enum:"",
            },
            {
                key:"27bb57dd-0fd8-4595-aaca-53bf1634ca87-col3",
                type:"currency",
                enum:"",
            },
            {
                key:"27bb57dd-0fd8-4595-aaca-53bf1634ca87-col4",
                type:"date",
                enum:"",
            },
            {
                key:"27bb57dd-0fd8-4595-aaca-53bf1634ca87-col5",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTablePassivoEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"50117239-0a0b-427d-9c23-f33c766b2b3a")
        
        let fields = [
            {
                key:"50117239-0a0b-427d-9c23-f33c766b2b3a-col1",
                type:"",
                enum:"",
            },
            {
                key:"50117239-0a0b-427d-9c23-f33c766b2b3a-col6",
                type:"",
                enum:"",
            },
            {
                key:"50117239-0a0b-427d-9c23-f33c766b2b3a-col2",
                type:"currency",
                enum:"",
            },
            {
                key:"50117239-0a0b-427d-9c23-f33c766b2b3a-col3",
                type:"currency",
                enum:"",
            },
            {
                key:"50117239-0a0b-427d-9c23-f33c766b2b3a-col4",
                type:"date",
                enum:"",
            },
            {
                key:"50117239-0a0b-427d-9c23-f33c766b2b3a-col5",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableGarantiasPatrimoniaisPortugal(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"ffd1a4f7-c19e-4e75-b722-11c6e3fae511")
        
        let fields = [
            {
                key:"ffd1a4f7-c19e-4e75-b722-11c6e3fae511-col1",
                type:"",
                enum:"",
            },
            {
                key:"ffd1a4f7-c19e-4e75-b722-11c6e3fae511-col2",
                type:"",
                enum:"",
            },
            {
                key:"ffd1a4f7-c19e-4e75-b722-11c6e3fae511-col3",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableGarantiasPatrimoniaisEstrangeiro(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"3737df7d-c0e0-4a24-8bce-346a479971f5")
        
        let fields = [
            {
                key:"3737df7d-c0e0-4a24-8bce-346a479971f5-col1",
                type:"",
                enum:"",
            },
            {
                key:"3737df7d-c0e0-4a24-8bce-346a479971f5-col2",
                type:"",
                enum:"",
            },
            {
                key:"3737df7d-c0e0-4a24-8bce-346a479971f5-col3",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTablePatrimonial(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"60c13abc-b075-475c-86c5-fa69a7cae259")
        
        let fields = [
            {
                key:"60c13abc-b075-475c-86c5-fa69a7cae259-col1",
                type:"date",
                enum:"",
            },
            {
                key:"60c13abc-b075-475c-86c5-fa69a7cae259-col2",
                type:"date",
                enum:"",
            },
            {
                key:"60c13abc-b075-475c-86c5-fa69a7cae259-col3",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableAtividadesProfissionais(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"2c6d9562-dea5-488b-9afe-42519c636daf")
        
        let fields = [
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col1",
                type:"",
                enum:"",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col2",
                type:"",
                enum:"",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col9",
                type:"",
                enum:"",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col3",
                type:"",
                enum:"",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col4",
                type:"",
                enum:"",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col6",
                type:"date",
                enum:"",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col7",
                type:"date",
                enum:"",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col5",
                type:"enum",
                enum:"yesNoEnum",
            },
            {
                key:"2c6d9562-dea5-488b-9afe-42519c636daf-col8",
                type:"boolean",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableRelativosFiliacao(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22")
        
        let fields = [
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col1",
                type:"",
                enum:"",
            },
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col2",
                type:"",
                enum:"",
            },
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col8",
                type:"",
                enum:"",
            },
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col3",
                type:"",
                enum:"",
            },
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col4",
                type:"",
                enum:"",
            },
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col5",
                type:"enum",
                enum:"yesNoEnum",
            },
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col6",
                type:"date",
                enum:"",
            },
            {
                key:"cd0e811a-d9bb-49ee-b0f0-1dae0680eb22-col7",
                type:"date",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableApoiosBeneficios(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"62fb0107-a68d-4ba2-822b-bc8adc54369b")
        
        let fields = [
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col1",
                type:"",
                enum:"",
            },
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col2",
                type:"",
                enum:"",
            },
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col6",
                type:"",
                enum:"",
            },
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col3",
                type:"",
                enum:"",
            },
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col4",
                type:"",
                enum:"",
            },
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col5",
                type:"date",
                enum:"",
            },
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col7",
                type:"date",
                enum:"",
            },
            {
                key:"62fb0107-a68d-4ba2-822b-bc8adc54369b-col8",
                type:"enum",
                enum:"recipientSupportBenefitOptions",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableServicoPrestado(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"b1f8c095-fe0c-4bb3-b493-902dc0c31232")
        
        let fields = [
            {
                key:"b1f8c095-fe0c-4bb3-b493-902dc0c31232-col1",
                type:"",
                enum:"",
            },
            {
                key:"b1f8c095-fe0c-4bb3-b493-902dc0c31232-col2",
                type:"",
                enum:"",
            },
            {
                key:"b1f8c095-fe0c-4bb3-b493-902dc0c31232-col6",
                type:"",
                enum:"",
            },
            {
                key:"b1f8c095-fe0c-4bb3-b493-902dc0c31232-col3",
                type:"",
                enum:"",
            },
            {
                key:"b1f8c095-fe0c-4bb3-b493-902dc0c31232-col4",
                type:"",
                enum:"",
            },
            {
                key:"b1f8c095-fe0c-4bb3-b493-902dc0c31232-col5",
                type:"date",
                enum:"",
            },
            {
                key:"b1f8c095-fe0c-4bb3-b493-902dc0c31232-col7",
                type:"date",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableSociedades(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"9b58c917-b14c-4eed-9b3c-40c3d99d45da")
        
        let fields = [
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col1",
                type:"",
                enum:"",
            },
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col7",
                type:"",
                enum:"",
            },
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col6",
                type:"enum",
                enum:"societiesNatureOptions",
            },
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col2",
                type:"",
                enum:"",
            },
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col3",
                type:"",
                enum:"",
            },
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col4",
                type:"currency",
                enum:"",
            },
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col5",
                type:"percent",
                enum:"",
            },
            {
                key:"9b58c917-b14c-4eed-9b3c-40c3d99d45da-col8",
                type:"enum",
                enum:"participationHolderOptions",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },
    addTableOutrasSituacoes(declaration,selectedLang,tableIndex){
        let table = declaracaoUnicaHelper.getFieldByKey(declaration,"fd85f91f-2803-41bc-b315-ede3a4a66d4e")
        
        let fields = [
            {
                key:"fd85f91f-2803-41bc-b315-ede3a4a66d4e-col5",
                type:"",
                enum:"",
            },
            {
                key:"fd85f91f-2803-41bc-b315-ede3a4a66d4e-col2",
                type:"date",
                enum:"",
            },
            {
                key:"fd85f91f-2803-41bc-b315-ede3a4a66d4e-col3",
                type:"currency",
                enum:"",
            },
            {
                key:"fd85f91f-2803-41bc-b315-ede3a4a66d4e-col4",
                type:"",
                enum:"",
            },
            
        ];
        let rows = []

        if (table.value.length > 0) {
            table.value.forEach(row => {
                rows.push(this.addRow(row,selectedLang,fields))
                
            });
            
        }

        let data = {
            index:tableIndex,
            rows:rows,
            columns:null
        }
        return data
        
    },

    async addTableAnexo(guid,declaration,selectedLang,tableIndex){
        let table = declaration.attachments
        
        let rows = []

        if (table.length > 0) {
            table.forEach(row => {
                var newRow = []
                newRow.push(row.name)
                newRow.push(utils.formatBytes(row.size))
                newRow.push(utils.getFormatFileName(row.name))
                newRow.push(selectedLang.attachmentsOptions[row.sectionType])
                newRow.push(row.observations)
                
                rows.push(newRow)
            });
            
            
            let data = {
                index:tableIndex,
                rows:rows,
                columns:null
            }
            await documentService.adddatatotable(guid, [data]);
        }
        
    },
    addRow(row,selectedLang,fields){
        let newRow = [];
        let percent;
        let formatPt =  new Intl.NumberFormat('pt-Pt', { style: 'currency', currency: 'EUR' });

        fields.forEach(f => {
            let value = ""
            let field = declaracaoUnicaHelper.getFieldByKey(row,f.key)

            if (field != null && field.value != null) {
                
            switch (f.type) {
                case "enum":
                    value = selectedLang[f.enum][field.value]
                    break;
                case "currency":
                    value = formatPt.format(field.value / 100).replace(/\s/g, "");
                    break;
                case "percent":
                    percent = field.value * 100;
                    value = !this.isInteger(percent) ? `${percent.toFixed(2)}%` : `${percent.toString()}%`
                    break;
                case "date":
                    value = utils.formatDate(field.value, "DD/MM/YYYY");
                    break;
                case "boolean":
                    value = (/true/).test(field.value) ? selectedLang.yes : selectedLang.no;
                    break;
            
                default:
                    if (typeof field.value === 'number') {
                        value = field.value.toString();
                    }else{
                        value = field?.value
                    }
                    break;
            }
        }

            newRow.push(value);
        });

        return newRow
    },
    isInteger(number) {
        // Verifica se é um número
        if (typeof number !== 'number') {
            return false;
        }

        // Verifica se não tem parte fracionária
        return number % 1 === 0;
    },

    async downloadDeclaration(declaration = null,selectedLang){
        if (declaration != null) {
            
            let guid = await this.getFileGuid()
            
            if (guid != null) {
                try {
                    
                
                await this.addData(declaration,guid,selectedLang);

                await this.addGuids(declaration.data,guid);
                await this.addGuidsFieldsEnums(declaration.data,guid,selectedLang);
                await this.addGuidsCurrency(declaration.data,guid);
                await this.addGuidsFields(declaration,guid);

                await this.addTables(guid,declaration,selectedLang);
                this.addTableAnexo(guid,declaration,selectedLang,46)

                await this.downloadFile(guid,selectedLang,declaration);
            } catch (error) {
                    console.error(error);
                }
            }
        }
    }
}