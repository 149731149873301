<template>
  <CustomAccordion
    :title="selectedLang.currentBankAccounts"
    :isOpenDefault="isOpenDefault"
  >
    <DeclaracaoUnicaBlockedData
      v-if="!declaracaoUnicaProp?.isVisible"
      :reason="declaracaoUnicaProp?.reason"
    />
    <div v-else>
      <ContasBancariasAOrdemPart1Portugal
        v-if="isPortugal"
        :guid="ContasBancariasAOrdemPart1Guid"
        :declaracaoUnicaProp="ContasBancariasAOrdemPart1"
      />
      <ContasBancariasAOrdemPart1Estrangeiro
        v-else
        :guid="ContasBancariasAOrdemPart1Guid"
        :declaracaoUnicaProp="ContasBancariasAOrdemPart1"
      />
      <ContasBancariasAOrdemPart2Portugal
        v-if="isPortugal"
        :guid="ContasBancariasAOrdemPart2Guid"
        :declaracaoUnicaProp="ContasBancariasAOrdemPart2"
      />
      <ContasBancariasAOrdemPart2Estrangeiro
        v-else
        :guid="ContasBancariasAOrdemPart2Guid"
        :declaracaoUnicaProp="ContasBancariasAOrdemPart2"
      />
    </div>
  </CustomAccordion>
</template>

<script>
import ContasBancariasAOrdemPart1Estrangeiro from "./ContasBancariasAOrdemPart1-Estrangeiro.vue";
import ContasBancariasAOrdemPart1Portugal from "./ContasBancariasAOrdemPart1-Portugal.vue";
import ContasBancariasAOrdemPart2Estrangeiro from "./ContasBancariasAOrdemPart2-Estrangeiro.vue";
import ContasBancariasAOrdemPart2Portugal from "./ContasBancariasAOrdemPart2-Portugal.vue";

export default {
  components: {
    ContasBancariasAOrdemPart1Estrangeiro,
    ContasBancariasAOrdemPart1Portugal,
    ContasBancariasAOrdemPart2Estrangeiro,
    ContasBancariasAOrdemPart2Portugal,
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
    guid: [Object, Array, String],
  },
  computed: {
    ContasBancariasAOrdemPart1Guid() {
      return this.declaracaoUnicaProp.key ==
        "879401c9-2273-465a-91c7-7d8f080da762"
        ? "873b46af-f3a5-4329-9aa0-a04fd1114467"
        : "151ea0ca-b4a5-4764-a461-67d0c300c915";
    },
    ContasBancariasAOrdemPart1: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasAOrdemPart1Guid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasAOrdemPart1Guid,
          newValue
        );
      },
    },
    ContasBancariasAOrdemPart2Guid() {
      return this.declaracaoUnicaProp.key ==
        "879401c9-2273-465a-91c7-7d8f080da762"
        ? "58adafd6-226e-4978-a48c-66f53affd3ac"
        : "f4838f1a-b32e-43bd-9f3e-6de9ff72526d";
    },
    isPortugal() {
      return (
        this.declaracaoUnicaProp.key == "879401c9-2273-465a-91c7-7d8f080da762"
      );
    },

    ContasBancariasAOrdemPart2: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasAOrdemPart2Guid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasAOrdemPart2Guid,
          newValue
        );
      },
    },
    ContasBancariasAOrdem: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp.value,
          this.declaracaoUnicaProp.key
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp.value,
          this.ContasBancariasAOrdemGuid,
          newValue
        );
      },
    },

    isOpenDefault() {
      return (
        (this.ContasBancariasAOrdemPart1.value != null &&
          this.ContasBancariasAOrdemPart1.value.length > 0) ||
        (this.ContasBancariasAOrdemPart2.value != null &&
          this.ContasBancariasAOrdemPart2.value.length > 0)
      );
    },
  },
};
</script>
