<template>
  <template v-if="item?.value != null && item?.isVisible">
    <!-- <template v-if="type == 'bool'">
      {{ $filters.formatBoolean(item.value) }}
    </template>
    <template v-else-if="type == 'date'">
      {{ $filters.formatDate(item.value) }}
    </template>
    <template v-else-if="type == 'currency'">
      {{ $filters.formatCurrency(item.value) }}
    </template>
    <template v-else-if="type == 'percent'">
      {{ $filters.formatPercent(item.value) }}
      {{ selectedLang.percent }}
    </template>
    <template v-else-if="type == 'enum'">
      {{ props.enum[item.value] }}
    </template>
    <template v-else>
      {{ item.value }}
    </template> -->
    <p :class="{ emphasizeLocal: emphasizeLocal }">
      {{ value }}
    </p>
  </template>
  <template v-else-if="!item?.isVisible">
    <div class="input-hidden">
      <div class="d-f">
        <span>
          <IconPadlock />
        </span>
        <div class="text" style="top: 2px; position: relative">
          {{ reason }}
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    {{ selectedLang.nothingDeclare }}
  </template>
</template>
<script setup>
import { ref, defineProps, onMounted, getCurrentInstance, computed } from "vue";
import { useStore } from "vuex";
import declaracaoUnicaHelper from "@/helpers/declaracaoUnicaHelper";
import { enums } from "@/helpers";

const { appContext } = getCurrentInstance();
const selectedLang = appContext.config.globalProperties.selectedLang;
const filters = appContext.config.globalProperties.$filters;

const props = defineProps({
  item: Object,
  type: String,
  enum: Object,
  emphasize: {
    Boolean,
    default: false,
  },
});

const item = ref(props.item);
const store = useStore();
var reason = ref(null);

onMounted(() => {
  if (item.value) {
    reason.value =
      selectedLang.decrarationRestrictReasonEnum[item.value.reason];
  }
});

const value = computed({
  get() {
    switch (props.type) {
      case "bool":
        return filters.formatBoolean(props.item.value);
      case "date":
        return filters.formatDate(props.item.value);
      case "currency":
        return filters.formatCurrency(props.item.value);
      case "percent":
        return filters.formatPercent(props.item.value) + selectedLang.percent;
      case "enum":
        return props.enum[item.value.value];

      default:
        return item.value.value;
    }
  },
});
const emphasizeLocal = computed({
  get() {
    return (
      //declaracaoUnicaSubInput.value != null &&
      (store.state.declaracao.natureza ==
        enums.declarationNatureTypeEnum.modification ||
        store.state.declaracao.natureza ==
          enums.declarationNatureTypeEnum.replace) &&
      declaracaoUnicaSubInput.value != item.value.value &&
      props.emphasize
    );
  },
});
const declaracaoUnicaSubInput = computed({
  get() {
    if (store.state.declaracaoSub.json == null) {
      return null;
    }
    let result = declaracaoUnicaHelper.getFieldByKey(
      store.state.declaracaoSub.json.value,
      item.value.key
    );

    return result != null ? result.value : null;
  },
});
</script>
<style lang="scss" scope>
.emphasizeLocal {
  color: #db9102 !important;
}
</style>
