import service from "./service-base";

export default {
    createFull: (user) => {
        return service.post(`/user/createfull`, user);
    },
    updateConfiguration: (emailNotification, systemNotification) => {
        return service.put(`/user/configuration`, {
            emailNotification,
            systemNotification
        });
    },
    getConfiguration: (userId) => {
        return service.get(`/user/configuration/${userId}`);
    },
    get: (userId) => {
        return service.get(`/user/${userId}`);
    },
    getpersonaldata: (userId) => {
        return service.get(`/user/getpersonaldata/${userId}`);
    },
    updatepersonaldata: (model) => {
        return service.put(`/user/updatepersonaldata`,model);
    },
    update: (model) => {
        return service.put(`/user/update`,model);
    },
    getPersonalData:(userId) => {
        return service.get(`/user/getpersonaldata/${userId}`);
    }
};