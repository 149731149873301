<template>
  <div class="progressBar" :class="visibleToggle">
    <div class="d-f">
      <div class="progressBar-text">{{ text }}</div>
      <div>{{ progress }}%</div>
    </div>
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped bg-success progress-bar-animated"
        role="progressbar"
        :style="{ width: progress + '%' }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.emitter.on("DeclaracaoUnicaProgressBar:Toggle", (isVisible) => {
      this.visibleToggle = isVisible ? "" : "d-none";
    });
  },
  props: {
    progress: Number,
    text: String,
    declaracaoUnicaProp: [Object, Array],
 
  },
  data() { 
    return {
      visibleToggle: ""
    }
  },
  methods: {
    
  },
};
</script>
