<template>
  <div class="declaracaoUnicaTabHeader p-3 row">
    <div class="col-lg-12 col-xl-6 d-f">
      <DeclaracaoUnicaTabHeaderSelectTabIcon
        :progress="3"
        :active="true"
        :tabId="1"
      />
      <DeclaracaoUnicaTabHeaderSelectTabBar :tabId="1" :initialProgress="50" />
      <DeclaracaoUnicaTabHeaderSelectTabIcon :progress="45" :tabId="2" />
      <DeclaracaoUnicaTabHeaderSelectTabBar :tabId="2" />
      <DeclaracaoUnicaTabHeaderSelectTabIcon :progress="66" :tabId="3" />
      <DeclaracaoUnicaTabHeaderSelectTabBar :tabId="3" />
      <DeclaracaoUnicaTabHeaderSelectTabIcon :progress="79" :tabId="4" />
      <CustomBadge
        class="declaracaoUnicaHeader-customBadge"
        :type="declaracao.estado"
        :text="selectedLang.declarationStateTypeEnum[declaracao.estado]"
      />
    </div>
    <div class="col-lg-12 col-xl-6 d-f jc-right ai-center">
      <button
        v-if="ispublic"
        type="button"
        class="btn btnS btnSecundary ml-auto mr-1"
        :disabled="
          declaracao.estado != enums.declarationStateTypeEnum.published &&
          declaracao.estado != enums.declarationStateTypeEnum.modified
        "
        @click="requestRequirement"
        v-tooltip="
          declaracao.estado != enums.declarationStateTypeEnum.published &&
          declaracao.estado != enums.declarationStateTypeEnum.modified
            ? selectedLang.tooltipRequirementDisabled
            : null
        "
      >
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.71231 0.400391C5.55449 0.400391 4.55254 0.823437 3.70645 1.66953C2.86035 2.51562 2.4373 3.51758 2.4373 4.67539V6.81289C1.83613 6.81289 1.32959 7.01885 0.917676 7.43076C0.505762 7.84268 0.299805 8.34922 0.299805 8.95039V15.3629C0.299805 15.9641 0.505762 16.4706 0.917676 16.8825C1.32959 17.2944 1.83613 17.5004 2.4373 17.5004H10.9873C11.5885 17.5004 12.095 17.2944 12.5069 16.8825C12.9188 16.4706 13.1248 15.9641 13.1248 15.3629V8.95039C13.1248 8.34922 12.9188 7.84268 12.5069 7.43076C12.095 7.01885 11.5885 6.81289 10.9873 6.81289V4.67539C10.9873 3.51758 10.5643 2.51562 9.71816 1.66953C8.87207 0.823437 7.87012 0.400391 6.71231 0.400391ZM11.6887 8.95039V15.3629C11.6887 15.8305 11.4549 16.0643 10.9873 16.0643H2.4373C1.96973 16.0643 1.73594 15.8305 1.73594 15.3629V8.95039C1.73594 8.48281 1.96973 8.24902 2.4373 8.24902H10.9873C11.4549 8.24902 11.6887 8.48281 11.6887 8.95039ZM3.87344 6.81289V4.67539C3.87344 3.89609 4.15176 3.22813 4.7084 2.67148C5.26504 2.11484 5.93301 1.83652 6.71231 1.83652C7.4916 1.83652 8.15957 2.11484 8.71621 2.67148C9.27285 3.22813 9.55117 3.89609 9.55117 4.67539V6.81289H3.87344ZM6.71231 8.95039C6.11113 8.95039 5.60459 9.15635 5.19268 9.56826C4.78076 9.98018 4.5748 10.4867 4.5748 11.0879C4.5748 11.5332 4.7084 11.9396 4.97559 12.3069C5.24277 12.6743 5.58789 12.9359 6.01094 13.0918V13.9268C6.01094 14.4166 6.24473 14.6615 6.71231 14.6615C7.17988 14.6615 7.41367 14.4166 7.41367 13.9268V13.0918C8.37109 12.7578 8.8498 12.0898 8.8498 11.0879C8.8498 10.4867 8.64385 9.98018 8.23193 9.56826C7.82002 9.15635 7.31348 8.95039 6.71231 8.95039ZM6.71231 11.7893C6.24473 11.7893 6.01094 11.5555 6.01094 11.0879C6.01094 10.6203 6.24473 10.3865 6.71231 10.3865C7.17988 10.3865 7.41367 10.6203 7.41367 11.0879C7.41367 11.5555 7.17988 11.7893 6.71231 11.7893Z"
            fill="#007AC4"
          />
        </svg>
        &nbsp;
        {{ selectedLang.makeRequest }}
      </button>
      <button
        v-if="showExport"
        type="button"
        class="btn btnXS btnSecundary mr-1"
        @click="downloadDeclaration"
        v-tooltip="selectedLang.exportDeclaration"
      >
        <IconDownload color="#007AC4" />
      </button>
      <span
        @click="openDeleteDraftModal"
        class="btnAlike mr-1"
        v-if="canEditDeclaration && declaracao.estado == 1"
      >
        <tooltip
          :text="selectedLang.eliminate"
          size="small"
          :white-border="true"
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_704_13960)">
              <rect
                x="1"
                y="1"
                width="48"
                height="48"
                rx="9"
                fill="white"
                stroke="#007AC4"
                stroke-width="2"
              />
              <path
                d="M36.25 17.9766C32.0875 17.5916 27.9 17.3933 23.725 17.3933C21.25 17.3933 18.775 17.51 16.3 17.7433L13.75 17.9766"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.625 16.7983L20.9 15.27C21.1 14.1617 21.25 13.3333 23.3625 13.3333H26.6375C28.75 13.3333 28.9125 14.2083 29.1 15.2817L29.375 16.7983"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M33.5625 21.6633L32.75 33.4117C32.6125 35.2433 32.5 36.6667 29.0125 36.6667H20.9875C17.5 36.6667 17.3875 35.2433 17.25 33.4117L16.4375 21.6633"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.9121 30.25H27.0746"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.875 25.5833H28.125"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_704_13960">
                <rect width="50" height="50" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </tooltip>
      </span>
      <tooltip size="medium" :text="selectedLang.save" :whiteBorder="true">
        <span
          @click="saveDraft"
          class="btnAlike mr-1"
          v-if="
            canEditDeclaration &&
            declaracao.estado == enums.declarationStateTypeEnum.draft
          "
          :title="selectedLang.saveDraft"
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_930_18648)">
              <rect
                x="1"
                y="1"
                width="48"
                height="48"
                rx="9"
                fill="white"
                stroke="#007AC4"
                stroke-width="2"
              />
            </g>
            <mask
              id="mask0_930_18648"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="16"
              y="15"
              width="18"
              height="21"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.1716 16.1716C16 17.3432 16 19.2288 16 23V33.601C16 35.1259 17.6382 36.0899 18.9713 35.3493L24.0287 32.5396C24.6328 32.204 25.3672 32.204 25.9713 32.5396L31.0287 35.3493C32.3618 36.0899 34 35.1259 34 33.601V23C34 19.2288 34 17.3432 32.8284 16.1716C31.6569 15 29.7712 15 26 15H24C20.2288 15 18.3431 15 17.1716 16.1716Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_930_18648)">
              <path
                d="M32.8284 16.1716L33.8891 15.1109L32.8284 16.1716ZM24.0287 32.5396L24.7572 33.8508L24.0287 32.5396ZM25.9713 32.5396L25.2428 33.8508L25.9713 32.5396ZM18.9713 35.3493L19.6998 36.6605L18.9713 35.3493ZM17.5 23C17.5 21.072 17.5032 19.7769 17.6331 18.8108C17.7572 17.8879 17.9716 17.4929 18.2322 17.2322L16.1109 15.1109C15.2 16.0219 14.8286 17.1555 14.6598 18.411C14.4968 19.6235 14.5 21.1568 14.5 23H17.5ZM17.5 33.601V23H14.5V33.601H17.5ZM19.6998 36.6605L24.7572 33.8508L23.3003 31.2284L18.2428 34.038L19.6998 36.6605ZM25.2428 33.8508L30.3003 36.6605L31.7572 34.038L26.6998 31.2284L25.2428 33.8508ZM32.5 23V33.601H35.5V23H32.5ZM31.7678 17.2322C32.0284 17.4929 32.2428 17.8879 32.3669 18.8108C32.4968 19.7769 32.5 21.072 32.5 23H35.5C35.5 21.1568 35.5032 19.6235 35.3402 18.411C35.1714 17.1555 34.8 16.0219 33.8891 15.1109L31.7678 17.2322ZM26 16.5C27.928 16.5 29.2231 16.5032 30.1892 16.6331C31.1121 16.7572 31.5071 16.9716 31.7678 17.2322L33.8891 15.1109C32.9781 14.2 31.8445 13.8286 30.589 13.6598C29.3765 13.4968 27.8432 13.5 26 13.5V16.5ZM24 16.5H26V13.5H24V16.5ZM18.2322 17.2322C18.4929 16.9716 18.8879 16.7572 19.8108 16.6331C20.7769 16.5032 22.072 16.5 24 16.5V13.5C22.1568 13.5 20.6235 13.4968 19.411 13.6598C18.1555 13.8286 17.0219 14.2 16.1109 15.1109L18.2322 17.2322ZM30.3003 36.6605C32.6331 37.9566 35.5 36.2697 35.5 33.601H32.5C32.5 33.9822 32.0904 34.2232 31.7572 34.038L30.3003 36.6605ZM24.7572 33.8508C24.9082 33.7669 25.0918 33.7669 25.2428 33.8508L26.6998 31.2284C25.6427 30.6411 24.3573 30.6411 23.3003 31.2284L24.7572 33.8508ZM14.5 33.601C14.5 36.2697 17.3669 37.9566 19.6998 36.6605L18.2428 34.038C17.9096 34.2232 17.5 33.9822 17.5 33.601H14.5Z"
                fill="#007AC4"
              />
              <path
                d="M22.75 23.25H27.25"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_930_18648">
                <rect width="50" height="50" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </tooltip>
      <button
        v-if="canEditDeclaration"
        type="button"
        @click="validateDeclaration"
        class="btn btnS btnSecundary mr-1"
      >
        {{ selectedLang.validate }}
      </button>
      <button
        :disabled="!showPriviusBtn"
        type="button"
        @click="previousTab"
        class="btn btnS btnSecundary mr-1"
      >
        <IconArrowLeft />
        {{ selectedLang.previous }}
      </button>
      <button
        :disabled="!showNextBtn"
        type="button"
        @click="nextTab"
        class="btn btnS btnPrimary"
        v-if="showNextBtn || !canEditDeclaration"
      >
        {{ selectedLang.continue }}
        <IconArrowRight />
      </button>
      <button
        type="button"
        @click="SubmitBtnHandler"
        class="btn btnS btnPrimary"
        v-if="
          showSubmitBtn &&
          canEditDeclaration &&
          declaracao.estado == enums.declarationStateTypeEnum.draft
        "
      >
        {{ selectedLang.submit }}
      </button>
      <button
        type="button"
        @click="updateBtnHandler"
        class="btn btnS btnPrimary"
        v-if="
          showSubmitBtn &&
          canEditDeclaration &&
          declaracao.estado == enums.declarationStateTypeEnum.notPublished
        "
      >
        {{ selectedLang.update }}
      </button>
    </div>
    <CustomModal
      modalId="DeclaracaoUnicaNotValid"
      size="md"
      :isCenter="true"
      :title="selectedLang.invalidDeclarationTitle"
    >
      <div class="row">
        <div class="col-12 mt-4 mb-4">
          {{ msgError }}
        </div>
      </div>
    </CustomModal>
    <CustomModal
      modalId="DeleteDraftModal"
      size="md"
      :title="selectedLang.deleteDeclaration"
    >
      <div class="row mb-4">
        <div class="col-12 mt-4 mb-4" style="text-align: left">
          {{ selectedLang.deleteQuestion }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-f jc-right mt-auto">
          <button
            type="button"
            @click="deleteDraft"
            class="btn btnS btnPrimary mr-1"
          >
            {{ selectedLang.eliminate }}
          </button>
          <button
            type="button"
            @click="closeDeleteDraftModal"
            class="btn btnS btnSecundary mr-1"
          >
            {{ selectedLang.cancel }}
          </button>
        </div>
      </div>
    </CustomModal>
    <CustomModal
      modalId="DeclaracaoUnicaUpdateModal"
      size="md"
      :title="selectedLang.updateDeclaration"
    >
      <div class="row mb-4">
        <div class="col-12 mt-4 mb-4" style="text-align: left">
          {{ selectedLang.updateQuestion }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-f jc-right mt-auto">
          <button
            type="button"
            @click="updateDeclaration"
            class="btn btnS btnPrimary mr-1"
          >
            {{ selectedLang.update }}
          </button>
          <button
            type="button"
            @click="closeUpdateDeclarationModal"
            class="btn btnS btnSecundary mr-1"
          >
            {{ selectedLang.cancel }}
          </button>
        </div>
      </div>
    </CustomModal>
    <CustomModal
      modalId="pedidoRequerimentoModal"
      size="xl"
      :title="selectedLang.makeRequest"
    >
      <PedidoRequerimento :declarationList="declarations" :isPublic="false" />
    </CustomModal>
  </div>
  <CustomModal
    modalId="downloadDeclarationModal"
    size="md"
    :isCenter="false"
    :title="selectedLang.exportDeclaration"
  >
    <template v-if="!loadingExport">
      <p class="p-3">
        Tem a certeza que pretende exportar a declaração
        {{ selectedLang.declarationNatureTypeEnum[itemNatureTypeId] }} submetida
        a {{ $filters.formatDate(itemCreationDate) }}?
      </p>
      <div class="d-f pc-center">
        <button
          type="button"
          @click="downloadDeclarationConfirm"
          class="btn btnS btnPrimary mr-1"
        >
          {{ selectedLang.export }}
        </button>
        <button
          type="button"
          @click="downloadDeclarationCancel"
          class="btn btnS btnSecundary"
        >
          {{ selectedLang.cancel }}
        </button>
      </div>
    </template>
    <template v-else>
      <LoadingSpinner />
    </template>
  </CustomModal>
</template>

<script>
import DeclaracaoUnicaTabHeaderSelectTabIcon from "./DeclaracaoUnicaTabHeaderSelectTabIcon.vue";
import DeclaracaoUnicaTabHeaderSelectTabBar from "./DeclaracaoUnicaTabHeaderSelectTabBar.vue";
//import { declarationService } from "@/services";
import { declarationService } from "@/services";
import { utils, declaracaoUnicaHelper, declarationDownload } from "@/helpers";
import tooltip from "@/components/tooltip.vue";
//import html2canvas from "html2canvas";

export default {
  components: {
    DeclaracaoUnicaTabHeaderSelectTabIcon,
    DeclaracaoUnicaTabHeaderSelectTabBar,
    tooltip,
  },
  data() {
    return {
      estado: 0,
      attachments: [],
      declarations: [],
      guid: null,
      loadingExport: false,
      declarationIdToCancel: null,
      declarationIdToDownload: null,
      itemCreationDate: null,
      itemNatureTypeId: null,
      timeoutID: null,
      msgError: null,
      isInative: false,
    };
  },
  props: {
    selectedTab: Number,
    declaration: [Object],
    ispublic: {
      Boolean,
      default: false,
    },
    showExport: {
      Boolean,
      default: false,
    },
  },
  computed: {
    showPriviusBtn() {
      return this.selectedTab > 1;
    },
    showNextBtn() {
      return this.selectedTab < 4;
    },
    showSubmitBtn() {
      return this.selectedTab == 4;
    },
  },
  methods: {
    downloadDeclaration() {
      this.declarationIdToDownload = this.$store.state.declaracao.draftId;
      this.itemCreationDate = this.$store.state.declaracao.startDate;
      this.itemNatureTypeId = this.$store.state.declaracao.natureza;
      this.emitter.emit("openCustomModal", "downloadDeclarationModal");
    },
    downloadDeclarationCancel() {
      this.declarationIdToDownload = null;
      this.itemCreationDate = null;
      this.itemNatureTypeId = null;
      this.emitter.emit("closeCustomModal", "downloadDeclarationModal");
    },
    async downloadDeclarationConfirm() {
      this.loadingExport = true;
      let model = {
        Id: this.declarationIdToDownload,
        isDraft: false,
      };

      declarationService
        .get(model)
        .then(async (response) => {
          if (response.code == 0) {
            await declarationDownload.downloadDeclaration(
              response.data,
              this.selectedLang
            );
          }
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.InternalServerError);
        })
        .finally(() => {
          this.loadingExport = false;
          this.downloadDeclarationCancel();
        });
    },
    //   html2canvas(document.querySelector("#capture")).then((canvas) => {
    //     let img = canvas.toDataURL("image/jpeg").split(";base64,")[1];
    //     //document.body.appendChild(canvas);
    //     documentService
    //       .addimage(img, this.guid)
    //       .then((response) => {
    //         if (response.code == 0) {
    //           this.guid = response.data;
    //         }
    //       })
    //       .catch(() => {
    //         utils.errorToast(this.selectedLang.ERR_NETWORK);
    //       })
    //       .finally(() => {
    //         self.loading = false;
    //       });
    //   });
    // },
    getFormValue(guid) {
      const value = declaracaoUnicaHelper.getFieldByKey(
        this.$store.state.declaracao.json,
        guid
      );
      return value != null ? value.value : null;
    },
    setFormValue(guid, value) {
      return declaracaoUnicaHelper.setFieldValueByKey(
        this.$store.state.declaracao.json.value,
        guid,
        value
      );
    },
    validateEstadoCivil() {
      const estadoCivil = this.getFormValue(
        "ed9a8413-b9f0-4d32-89bd-03593c826211"
      );

      if (
        estadoCivil != null &&
        estadoCivil != 1 &&
        estadoCivil != 4 &&
        estadoCivil != 5
      ) {
        this.setFormValue("ed9a8413-b9f0-4d32-89bd-03593c826212", null);
        this.setFormValue("ed9a8413-b9f0-4d32-89bd-03593c826213", null);
      }
    },
    saveDraft(validateAndSave) {
      if (validateAndSave === undefined || typeof validateAndSave == "object")
        validateAndSave = false;

      const model = declaracaoUnicaHelper.storeToModel();

      this.validateEstadoCivil();

      declarationService
        .updatedraft(model)
        .then((response) => {
          if (response.code == 0) {
            if (validateAndSave)
              utils.infoToast(this.selectedLang.validateAndSaveDraf);
            else utils.infoToast(this.selectedLang.declarationDraftSaved);
          }
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.ERR_NETWORK);
        })
        .finally(() => {
          self.loading = false;
        });
    },
    deleteDraft() {
      declarationService
        .deletedraft(this.declaracao.draftId)
        .catch(() => {
          utils.errorToast(this.selectedLang.ERR_NETWORK);
        })
        .finally(() => {
          this.loading = false;
          this.$router.push({ name: "ConsultarDeclaracoes" });
        });
    },
    openDeleteDraftModal() {
      this.emitter.emit("openCustomModal", "DeleteDraftModal");
    },
    closeDeleteDraftModal() {
      this.emitter.emit("closeCustomModal", "DeleteDraftModal");
    },
    validateDeclaration(showErrorMsg = true) {
      this.validateEstadoCivil();

      let message = "";

      const validadeOtherHolder = declaracaoUnicaHelper.validadeOtherHolder(
        this.$store.state.declaracao.json
      );
      if (!validadeOtherHolder) {
        message = this.selectedLang.invalidOtherHolderName;
      }

      //Verifica se a Tab1 está devidamente preenchida
      const isValidTab1 = declaracaoUnicaHelper.isValidTab1Declaration(
        this.$store.state.declaracao.json
      );

      if (!isValidTab1) {
        message = this.selectedLang.invalidDeclaration;
      }

      //Verifica se a Tab2 está devidamente preenchida
      const isValidTab2 = declaracaoUnicaHelper.isValidTab2Declaration(
        this.$store.state.declaracao.json
      );

      if (isValidTab1 && !isValidTab2) {
        message = this.selectedLang.invalidDeclaration;
      }
      this.msgError = message;

      //Verifica se a Tab4 está devidamente preenchida
      const isValidTab4 = declaracaoUnicaHelper.isValidTab4Declaration(
        this.$store.state.declaracao.json
      );

      if (!isValidTab4) {
        if (message) {
          message += "<br /><br />";
        }
        message += this.selectedLang.invalidActivities;
      }

      if (
        !isValidTab1 ||
        !isValidTab2 ||
        !isValidTab4 ||
        !validadeOtherHolder
      ) {
        if (showErrorMsg) {
          utils.warningToast(message);
        }
        this.emitter.emit("ValidateForm");
      } else {
        this.saveDraft(true);
      }
    },
    previousTab() {
      this.validateEstadoCivil();

      this.emitter.emit("selectTab", this.selectedTab - 1);
    },
    nextTab() {
      if (this.selectedTab < 4) {
        this.validateEstadoCivil();

        this.emitter.emit("selectTab", this.selectedTab + 1);
      }
    },
    requestRequirement() {
      this.declarations = [];
      this.declarations.push(this.declaration);
      this.emitter.emit("openCustomModal", "pedidoRequerimentoModal");
    },
    SubmitBtnHandler() {
      this.validateDeclaration(false);
      let isValid = this.declaracaoUnicaHelper.validateDeclaration(
        this.declaracao.json
      );

      if (isValid) {
        this.emitter.emit("openCustomModal", "DeclaracaoUnicaSubmitModal");
      } else {
        this.emitter.emit("openCustomModal", "DeclaracaoUnicaNotValid");
      }
    },
    updateBtnHandler() {
      let isValid = this.declaracaoUnicaHelper.validateDeclaration(
        this.declaracao.json
      );

      if (isValid) {
        this.emitter.emit("openCustomModal", "DeclaracaoUnicaUpdateModal");
      } else {
        this.emitter.emit("openCustomModal", "DeclaracaoUnicaNotValid");
      }
    },
    closeUpdateDeclarationModal() {
      this.emitter.emit("closeCustomModal", "DeclaracaoUnicaUpdateModal");
    },
    updateDeclaration() {
      const model = declaracaoUnicaHelper.storeToModel();

      declarationService
        .update(model)
        .then((response) => {
          utils.validateResponse(
            response,
            this.selectedLang.savedDdeclaration,
            () => {}
          );
        })
        .catch(() => {
          utils.errorToast(this.selectedLang.ERR_NETWORK);
        })
        .finally(() => {
          self.loading = false;
          this.closeUpdateDeclarationModal();
        });
    },
    // Função para reiniciar o temporizador
    resetTimer() {
      this.isInative = false;
      clearTimeout(this.timeoutID); // Limpa o temporizador existente
      this.timeoutID = setTimeout(() => {
        // Define um novo temporizador para 3 minutos
        // Sua lógica de inatividade aqui...
        console.log("Usuário inativo por 3 minutos.");
        this.saveDraft(false);
        this.isInative = true;
      }, 180000); // 3 minutos em milissegundos
    },
  },
  mounted() {
    // Adiciona um listener para eventos de interação do usuário
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("keypress", this.resetTimer);
    // Inicia o temporizador
    this.resetTimer();

    this.emitter.on("AddAttachments", (files) => {
      this.attachments = files;
    });
    this.emitter.on("AutoSave", () => {
      this.saveDraft(false);
    });

    setTimeout(() => {
      if (this.isInative) {
        this.saveDraft(false);
      }
    }, 900000);
  },
  unmounted() {
    // Remove os listeners quando o componente é desmontado
    window.removeEventListener("mousemove", this.resetTimer);
    window.removeEventListener("keypress", this.resetTimer);
    // Limpa o temporizador quando o componente é desmontado
    clearTimeout(this.timeoutID);
    this.emitter.off("AutoSave");
  },
};
</script>
