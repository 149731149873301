<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 6.75H8C7.30964 6.75 6.75 7.30964 6.75 8V9C6.75 9.69036 7.30964 10.25 8 10.25H9C9.69036 10.25 10.25 9.69036 10.25 9V8C10.25 7.30964 9.69036 6.75 9 6.75Z" stroke="#1B1B1B" stroke-width="1.5"/>
<path d="M16 6.75H15C14.3096 6.75 13.75 7.30964 13.75 8V9C13.75 9.69036 14.3096 10.25 15 10.25H16C16.6904 10.25 17.25 9.69036 17.25 9V8C17.25 7.30964 16.6904 6.75 16 6.75Z" stroke="#1B1B1B" stroke-width="1.5"/>
<path d="M16 13.75H15C14.3096 13.75 13.75 14.3096 13.75 15V16C13.75 16.6904 14.3096 17.25 15 17.25H16C16.6904 17.25 17.25 16.6904 17.25 16V15C17.25 14.3096 16.6904 13.75 16 13.75Z" stroke="#1B1B1B" stroke-width="1.5"/>
<path d="M9 13.75H8C7.30964 13.75 6.75 14.3096 6.75 15V16C6.75 16.6904 7.30964 17.25 8 17.25H9C9.69036 17.25 10.25 16.6904 10.25 16V15C10.25 14.3096 9.69036 13.75 9 13.75Z" stroke="#1B1B1B" stroke-width="1.5"/>
<path d="M3.4645 3.46444C4.7351 2.19385 6.67757 2.02564 10.2484 2.00337C10.6632 2.00079 11 2.33722 11 2.75202C11 3.16573 10.6647 3.50096 10.251 3.50358C8.75903 3.51303 7.62513 3.5471 6.71355 3.66966C5.51831 3.83035 4.93224 4.11802 4.52516 4.5251C4.11808 4.93218 3.83041 5.51825 3.66972 6.71349C3.54716 7.62507 3.51309 8.75897 3.50364 10.251C3.50102 10.6647 3.16579 11 2.75209 11C2.33728 11 2.00085 10.6631 2.00343 10.2483C2.0257 6.67751 2.19391 4.73503 3.4645 3.46444Z" fill="#1B1B1B"/>
<path d="M2.75209 13C2.33728 13 2.00085 13.3368 2.00343 13.7516C2.0257 17.3224 2.19391 19.2649 3.4645 20.5355C4.7351 21.8061 6.67757 21.9743 10.2484 21.9966C10.6632 21.9992 11 21.6627 11 21.2479C11 20.8342 10.6647 20.499 10.251 20.4964C8.75903 20.4869 7.62513 20.4529 6.71355 20.3303C5.51831 20.1696 4.93224 19.8819 4.52516 19.4748C4.11808 19.0678 3.83041 18.4817 3.66972 17.2865C3.54716 16.3749 3.51309 15.241 3.50364 13.749C3.50102 13.3353 3.16579 13 2.75209 13Z" fill="#1B1B1B"/>
<path d="M21.248 13C20.8343 13 20.499 13.3353 20.4964 13.749C20.487 15.241 20.4529 16.3749 20.3304 17.2865C20.1697 18.4817 19.882 19.0678 19.4749 19.4748C19.0678 19.8819 18.4818 20.1696 17.2865 20.3303C16.3749 20.4529 15.241 20.4869 13.749 20.4964C13.3353 20.499 13 20.8342 13 21.2479C13 21.6627 13.3369 21.9992 13.7517 21.9966C17.3225 21.9743 19.265 21.8061 20.5356 20.5355C21.8062 19.2649 21.9744 17.3224 21.9966 13.7516C21.9992 13.3368 21.6628 13 21.248 13Z" fill="#1B1B1B"/>
<path d="M21.248 11C21.6628 11 21.9992 10.6631 21.9966 10.2483C21.9744 6.67751 21.8062 4.73503 20.5356 3.46444C19.265 2.19385 17.3225 2.02564 13.7517 2.00337C13.3369 2.00079 13 2.33722 13 2.75202C13 3.16573 13.3353 3.50096 13.749 3.50358C15.241 3.51303 16.3749 3.5471 17.2865 3.66966C18.4818 3.83035 19.0678 4.11802 19.4749 4.5251C19.882 4.93218 20.1697 5.51825 20.3304 6.71349C20.4529 7.62507 20.487 8.75897 20.4964 10.251C20.499 10.6647 20.8343 11 21.248 11Z" fill="#1B1B1B"/>
</svg>

</template>