<template>
  <template v-if="auth != null">
    <div class="row pl-5 pr-5">
      <div class="col-12 d-f">
        <p class="pageTitle" id="table">
          {{ selectedLang.homePageCard2Title }}
        </p>
        <button
          type="button"
          @click="newDeclaration"
          class="btn btnS btnPrimary f-r ml-auto mr-5 mb-3"
        >
          {{ selectedLang.newDeclarations }}
        </button>
      </div>
    </div>
    <CustomCard class="mb-5">
      <div class="p-5">
        <ConsultarDeclaracoesForm class="mb-5" />
        <div class="row mb-1">
          <div class="col-12 ta-last-left">
            <span class="title">{{ selectedLang.declarations }}</span>
            <hr class="rounded" />
          </div>
        </div>
        <ConsultarDeclaracoesTable />
      </div>
    </CustomCard>
    <CustomModal
      size="lg"
      :title="this.selectedLang.newDeclaration"
      modalId="novaDeclaracaoModal"
    >
      <NovaDeclaracaoModal />
    </CustomModal>
  </template>
</template>

<script>
import ConsultarDeclaracoesForm from "./ConsultarDeclaracoesForm.vue";
import ConsultarDeclaracoesTable from "./ConsultarDeclaracoesTable.vue";
import NovaDeclaracaoModal from "./NovaDeclaracaoModal.vue";
import authentication from "@/helpers/authentication";
//import utils from "@/helpers/utils";

export default {
  components: {
    ConsultarDeclaracoesForm,
    ConsultarDeclaracoesTable,
    NovaDeclaracaoModal,
  },
  data() {
    return {
      auth: null,
    };
  },
  mounted() {
    this.auth = authentication.get();

    if (this.auth == null) {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    newDeclaration() {
      this.emitter.emit("openCustomModal", "novaDeclaracaoModal");
    },
  },
};
</script>
