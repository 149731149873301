import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import resources from "@/resources/index";
import components from'./components/index';
import Datepicker from '@vuepic/vue-datepicker';
import moment from "moment";
import interceptorSetup from "@/helpers/interceptor";
import declaracaoUnicaHelper from "@/helpers/declaracaoUnicaHelper";
import enums from "@/helpers/enums";
import { authentication } from "@/helpers";
//import { ReCaptchaInstance } from 'recaptcha-v3';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import FloatingVue from 'floating-vue'
import icons from "./components/icons/index";

import mitt from 'mitt';
const emitter = mitt();

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@pdur/library/src/assets/fonts/fonts.css';
import '@vueform/multiselect/themes/default.css';
import '@vuepic/vue-datepicker/dist/main.css'
import '@pdur/library/src/assets/css/custom.css';
import '@/assets/css/custom.css';
import "vue-toastification/dist/index.css";
import 'floating-vue/dist/style.css'

import plugin from '@pdur/library';
import Multiselect from '@vueform/multiselect';
import Toast from "vue-toastification";

interceptorSetup(emitter);

const RECAPTCHA_SITE_KEY = '6LdAP3IkAAAAAO7Qz4dU_KOyKx3hB3CfhadfOoOf';

const mixins = {
  computed: {    
    selectedLang() {
      return resources.selectLang(this.$store.state.lang);
    },
    declaracao:{
      get() {
        return this.$store.state.declaracao;
      },
      set(newValue) {
        this.$emit("update:this.$store.state.declaracao", newValue);
      },
    },
    declaracaoValue:{
      get(declaracaoValueKey) {
        return this.declaracaoUnicaHelper.getFieldByKey(this.declaracao, declaracaoValueKey)
      },
      set(declaracaoValueKey, newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(this.declaracao, declaracaoValueKey,newValue)
      },
    },
    declaracaoUnicaHelper(){
      return declaracaoUnicaHelper
    },
    enums(){
      return enums
    },
    canEditDeclaration(){
      return declaracaoUnicaHelper.canEditDeclaration();
    }   
  },
  methods: {
    inputIsNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }, 
    checkProfiles(profileId){
      const auth = authentication.get();
    
        for (var i = 0; i < auth.profiles.length; i++) {
          const profile = auth.profiles[0];
          if (profile == profileId) return true;
        }
    
        return false;
    }
  },
  component:{
  },
  mounted() {
  },
};

const app = createApp(App);

app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: false,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
});

app.use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY, loaderOptions:{autoHideBadge: true} });


Object.keys(components).forEach(name => {
  app.component(name, components[name]);
});
Object.keys(icons).forEach((name) => {
  app.component(name, icons[name]);
});

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.selectedLang = resources.selectLang(store.state.lang);
app.mixin(mixins);

app.component('VueMultiselect', Multiselect);
app.component('DatePicker', Datepicker);

app.config.globalProperties.$filters = {
  formatDate(value){
    if (value) {
      var date = moment(String(value));
      return date.format("DD-MM-YYYY");
    }   
    return "";
  },
  formatDateTime(value){
    if (value) {
      var date = moment(String(value));
      return date.format("DD-MM-YYYY HH:mm");
    }   
    return "";
  },
  formatPercent(value){
    if (value != null) {
      var percent = value * 100;
      return percent.toFixed(2);
    }   
  },
  formatCurrency(value) {
    if (value != null) {
      // Tenta converter o valor para um número
      let numericValue = parseFloat(value);
  
      // Verifica se a conversão foi bem-sucedida
      if (!isNaN(numericValue)) {
        let formatPt = new Intl.NumberFormat("pt-PT", {
          style: "currency",
          currency: "EUR",
        });
  
        // Divide por 100 para obter o valor correto
        var percent = numericValue / 100;
        return formatPt.format(percent);
      } else {
        // Se o valor não puder ser convertido para um número, retorna uma mensagem de erro ou valor padrão
        return value;
      }
    }
  
    // Caso value seja nulo ou indefinido, retorna uma mensagem de erro ou valor padrão
    return "";
  },
  formatBoolean(value){
    if (value == null) return ''
    let result = "Não"
    if (value || value == 1) {
      result = "Sim"
    }   
    if (value == 0) {
      result = "Não"
    }   
    return result
  },
}

app
.use(FloatingVue) 
  .use(plugin)
  .use(router)
  .use(store)
  .use(mixins)
  .mount('#app');
