<template>
  <svg
    width="31"
    height="26"
    viewBox="0 0 31 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66675 21.3488H22.7208V9.30246H18.7054C17.5965 9.30246 16.6976 8.40358 16.6976 7.29474V3.2793H8.66675V21.3488Z"
      fill="#EFF3F6"
    />
    <path
      d="M19.0696 12.6116C19.4556 12.2256 19.4556 11.5998 19.0696 11.2138C18.6836 10.8278 18.0578 10.8278 17.6718 11.2138L19.0696 12.6116ZM12.3179 16.5677C11.9319 16.9537 11.9319 17.5795 12.3179 17.9655C12.7039 18.3515 13.3297 18.3515 13.7157 17.9655L12.3179 16.5677ZM13.7157 11.2138C13.3297 10.8278 12.7039 10.8278 12.3179 11.2138C11.9319 11.5998 11.9319 12.2256 12.3179 12.6116L13.7157 11.2138ZM17.6718 17.9655C18.0578 18.3515 18.6836 18.3515 19.0696 17.9655C19.4556 17.5795 19.4556 16.9537 19.0696 16.5677L17.6718 17.9655ZM8.19824 22.0852H7.20983C7.20983 22.6311 7.65235 23.0736 8.19824 23.0736V22.0852ZM23.1892 22.0852V23.0736C23.7351 23.0736 24.1776 22.6311 24.1776 22.0852H23.1892ZM8.19824 2.81104V1.82262C7.65235 1.82262 7.20983 2.26515 7.20983 2.81104H8.19824ZM17.8353 2.81104L18.5342 2.11212C18.3489 1.92675 18.0975 1.82262 17.8353 1.82262V2.81104ZM23.1892 8.16496H24.1776C24.1776 7.90282 24.0735 7.65141 23.8881 7.46604L23.1892 8.16496ZM17.6718 11.2138L14.9948 13.8907L16.3927 15.2886L19.0696 12.6116L17.6718 11.2138ZM14.9948 13.8907L12.3179 16.5677L13.7157 17.9655L16.3927 15.2886L14.9948 13.8907ZM12.3179 12.6116L14.9948 15.2886L16.3927 13.8907L13.7157 11.2138L12.3179 12.6116ZM14.9948 15.2886L17.6718 17.9655L19.0696 16.5677L16.3927 13.8907L14.9948 15.2886ZM8.19824 23.0736H23.1892V21.0967H8.19824V23.0736ZM7.20983 2.81104V22.0852H9.18666V2.81104H7.20983ZM16.7645 1.82262H8.19824V3.79945H16.7645V1.82262ZM24.1776 22.0852V9.23574H22.2008V22.0852H24.1776ZM16.7645 3.79945H17.8353V1.82262H16.7645V3.79945ZM24.1776 9.23574V8.16496H22.2008V9.23574H24.1776ZM23.8881 7.46604L18.5342 2.11212L17.1364 3.50995L22.4903 8.86388L23.8881 7.46604ZM15.7761 2.81104V7.09417H17.7529V2.81104H15.7761ZM15.7761 7.09417C15.7761 7.95103 16.1946 8.72516 16.7348 9.26542C17.2751 9.80569 18.0492 10.2242 18.9061 10.2242V8.24733C18.6922 8.24733 18.3955 8.13041 18.1327 7.86759C17.8699 7.60476 17.7529 7.3081 17.7529 7.09417H15.7761ZM18.9061 10.2242H23.1892V8.24733H18.9061V10.2242Z"
      fill="#102E40"
    />
  </svg>
</template>
