<template>
  <div>
    <div class="row mb-2">
      <div class="col-12 ta-last-left">
        <span class="title">{{ selectedLang.registrationInterests }}</span>
      </div>
      <div class="col-12">
        <hr class="rounded" />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <DadosRelativosAtividades
          :declaracaoUnicaProp="DadosRelativosAtividades"
        />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <DadosRelativosFiliacao :declaracaoUnicaProp="DadosRelativosFiliacao" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <ApoiosBeneficios :declaracaoUnicaProp="ApoiosBeneficios" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <ServicosPrestados :declaracaoUnicaProp="ServicosPrestados" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <SociedadesSection :declaracaoUnicaProp="SociedadesSection" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <OutrasSituacoes :declaracaoUnicaProp="OutrasSituacoes" />
      </div>
    </div>
  </div>
</template>

<script>
import DadosRelativosAtividades from "./declaracaoUnicaTab4Aux/DadosRelativosAtividades.vue";
import DadosRelativosFiliacao from "./declaracaoUnicaTab4Aux/DadosRelativosFiliacao.vue";
import ApoiosBeneficios from "./declaracaoUnicaTab4Aux/ApoiosBeneficios.vue";
import ServicosPrestados from "./declaracaoUnicaTab4Aux/ServicosPrestados.vue";
import SociedadesSection from "./declaracaoUnicaTab4Aux/SociedadesSection.vue";
import OutrasSituacoes from "./declaracaoUnicaTab4Aux/OutrasSituacoes.vue";

export default {
  components: {
    DadosRelativosAtividades,
    DadosRelativosFiliacao,
    ApoiosBeneficios,
    ServicosPrestados,
    SociedadesSection,
    OutrasSituacoes,
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  data(){
    return {
      validateForm: false
    }
  },
  computed: {
    declaracao() {
      return this.$store.state.declaracao;
    },
    DadosRelativosAtividades: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "2c6d9562-dea5-488b-9afe-42519c636daf"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "2c6d9562-dea5-488b-9afe-42519c636daf",
          newValue
        );
      },
    },
    DadosRelativosFiliacao: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "cd0e811a-d9bb-49ee-b0f0-1dae0680eb22"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "cd0e811a-d9bb-49ee-b0f0-1dae0680eb22",
          newValue
        );
      },
    },
    ApoiosBeneficios: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "62fb0107-a68d-4ba2-822b-bc8adc54369b"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "62fb0107-a68d-4ba2-822b-bc8adc54369b",
          newValue
        );
      },
    },
    ServicosPrestados: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "b1f8c095-fe0c-4bb3-b493-902dc0c31232"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "b1f8c095-fe0c-4bb3-b493-902dc0c31232",
          newValue
        );
      },
    },
    SociedadesSection: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "9b58c917-b14c-4eed-9b3c-40c3d99d45da"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "9b58c917-b14c-4eed-9b3c-40c3d99d45da",
          newValue
        );
      },
    },
    OutrasSituacoes: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          "fd85f91f-2803-41bc-b315-ede3a4a66d4e"
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          "fd85f91f-2803-41bc-b315-ede3a4a66d4e",
          newValue
        );
      },
    },
  },
  mounted() {
    this.emitter.on("ValidateForm", () => {
      this.validateForm = true;

      // const isValid = declaracaoUnicaHelper.isValidTab2Declaration(
      //   this.$store.state.declaracao.json
      // );
      // if (!isValid) {
      //   utils.warningToast(this.selectedLang.invalidDeclaration);
      // } else {
      //   this.emitter.emit("DeclaracaoUnicaTabHeader:UpdateDraft", 4);
      // }
    });
  },
  watch: {
    declaracao: {
      handler(newValue) {
        this.emitter.emit("DeclarationProgress:Update", newValue);
      },
      deep: true,
    },
  },
};
</script>
