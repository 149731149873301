import service from "./service-base";

export default {
    loginHolder: (username, password) => {
        return service.post(`/account/login`, {email: username, password: password, profileId: 1});
    },
    loginCommission: (username, password) => {
        return service.post(`/account/login`, {email: username, password: password, profileId: 2});
    },
    authorize: (model) => {
        return service.post(`/account/authorize`, model);
    },
    authenticate: (model) => {
        return service.post(`/account/authenticate`, model);
    },
    generateToken:(email) => {
        return service.get(`/account/generateresettoken/${email}/${btoa(document.location.origin)}`);
    },
    validateToken:(token) => {
        return service.get(`/account/validateresettoken/${token}`);
    },
    changePassword:(userId, oldPassword, newPassword) => {
        return service.put('/account/setpassword', {
            userId,
            oldPassword,
            newPassword
        });
    },
    resetPassword:(token, newPassword) => {
        return service.post('/account/resetpassword', {
            token,
            newPassword
        });
    },
    setPassword:(userId, oldPassword, newPassword) => {
        return service.put('/account/setpassword', {
            userId,
            oldPassword,
            newPassword
        });
    }
};