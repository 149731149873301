<template>
  <form>
    <div class="row mb-1">
      <div class="col-12">
        <h1 class="">
          {{ selectedLang.filters }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-12 mb-4">
        <CustomInput
          v-model="form.entidade"
          type="Multiselect"
          :label="selectedLang.entity"
          :disabled="false"
          :closeOnSelect="false"
          :options="formFilters.entityList"
          mode="multiple"
        />
      </div>
      <div class="col-lg-4 col-12 mb-4">
        <CustomInput
          v-model="form.cargo"
          type="Multiselect"
          :label="selectedLang.role"
          :disabled="false"
          :closeOnSelect="false"
          :options="formFilters.roleList"
          mode="multiple"
        />
      </div>
      <div class="col-lg-4 col-12 mb-4">
        <CustomInput
          v-model="form.natureza"
          type="Multiselect"
          :label="selectedLang.nature"
          :disabled="false"
          :closeOnSelect="false"
          :maxlength="10"
          :options="formFilters.natureList"
          mode="multiple"
        />
      </div>
      <div class="col-lg-6 col-12 mb-4">
        <CustomInput
          v-model="form.anoEntrega"
          :label="selectedLang.deliveryYear"
          :options="formFilters.yearList"
          type="Multiselect"
          :disabled="false"
          :closeOnSelect="false"
          :maxlength="10"
          mode="multiple"
        />
      </div>
      <div class="col-lg-6 col-12 mb-4">
        <CustomInput
          v-model="form.estado"
          :label="selectedLang.state"
          :options="formFilters.stateList"
          type="Multiselect"
          :disabled="false"
          :closeOnSelect="false"
          mode="multiple"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4 d-f jc-right mt-auto">
        <button
          type="button"
          @click="clearForm"
          class="btn btnS btnSecundary mr-1"
        >
          {{ selectedLang.clear }}
        </button>
        <button type="button" @click="submitForm" class="btn btnS btnPrimary">
          {{ selectedLang.searchTxt }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import declarationService from "@/services/declaration.service";
import authentication from "@/helpers/authentication";
import { utils } from "@/helpers";

export default {
  component: {},
  data() {
    return {
      loading: false,
      form: {
        entidade: null,
        cargo: null,
        id: null,
        natureza: null,
        anoEntrega: null,
        estado: null,
        tipoPedido: null,
      },
      formFilters: {
        entityList: [],
        natureList: [],
        roleList: [],
        stateList: [],
        yearList: [],
      },
    };
  },
  methods: {
    clearForm() {
      this.form = {
        entidade: null,
        cargo: null,
        id: null,
        natureza: null,
        anoEntrega: null,
        estado: null,
        tipoPedido: null,
      };

      this.submitForm();
    },
    formToModel() {
      return {
        Nature: this.form.natureza,
        Year: this.form.anoEntrega,
        State: this.form.estado,
        RequestType: this.form.tipoPedido,
        EntityId: this.form.entidade,
        RoleId: this.form.cargo,
        //RoleId: null,
      };
    },

    submitForm() {
      let model = this.formToModel();
      this.emitter.emit("search", model);
    },
    multipleLabel(value) {
      var txt =
        value.length > 1
          ? this.selectedLang.optionsSelectedTxt
          : this.selectedLang.optionSelectedTxt;
      return `${value.length} ${txt}`;
    },
    async getdeclarationfilters(model) {
      this.loading = true;

      const response = await declarationService.getdeclarationfilters(model);

      utils.validateResponse(response, null, () => {
        this.formFilters = response.data;

        this.formFilters.stateList.forEach((element) => {
          element.label =
            this.selectedLang.declarationStateTypeEnum[element.value];
        });

        this.formFilters.natureList.forEach((element) => {
          element.label =
            this.selectedLang.declarationNatureTypeEnum[element.value];
        });
      });

      this.loading = false;
    },
  },
  computed: {
    options() {
      return {
        naturezaOptions: [
          {
            value: 0,
            label: this.selectedLang.natureOptions[0],
          },
          {
            value: 1,
            label: this.selectedLang.natureOptions[1],
          },
          {
            value: 2,
            label: this.selectedLang.natureOptions[2],
          },
          {
            value: 3,
            label: this.selectedLang.natureOptions[3],
          },
        ],
        estadoOptions: [
          {
            value: 0,
            label: this.selectedLang.stateOptions[0],
          },
          {
            value: 1,
            label: this.selectedLang.stateOptions[1],
          },
          {
            value: 2,
            label: this.selectedLang.stateOptions[2],
          },
          {
            value: 3,
            label: this.selectedLang.stateOptions[3],
          },
          {
            value: 4,
            label: this.selectedLang.stateOptions[4],
          },
          {
            value: 5,
            label: this.selectedLang.stateOptions[5],
          },
          {
            value: 6,
            label: this.selectedLang.stateOptions[6],
          },
        ],
        tipoPedidoOptions: [
          {
            value: 0,
            label: this.selectedLang.orderTypeOptions[0],
          },
          {
            value: 1,
            label: this.selectedLang.orderTypeOptions[1],
          },
          {
            value: 2,
            label: this.selectedLang.orderTypeOptions[2],
          },
        ],
      };
    },
  },
  mounted() {
    let auth = authentication.get();
    let model = {
      userFOId: auth.id,
    };

    this.getdeclarationfilters(model);
  },
};
</script>
