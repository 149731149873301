<template>
  <template v-if="visible">
    <CustomInput
      :key="CustomInputKey"
      v-model="declaracaoUnicaInput"
      :type="props.type"
      :label="props.label"
      :placeholder="props.placeholder"
      :errorMsg="props.errorMsg"
      :disabled="localDisable"
      :required="props.required"
      :maxlength="props.maxlength"
      :options="props.options"
      :searchable="props.searchable"
      :mode="props.mode"
      :maxval="props.maxval"
      :showErrorMsg="showErrorMessage"
      :maxDate="maxDate"
      :loading="loading"
      :showOptions="showOptions"
      :groupName="groupName"
      :parseIntProp="parseIntProp"
      :showOtherOption="showOtherOption"
      :otherOptionValue="otherOptionValue"
      :showTooltip="true"
      @select="select"
      :tooltipInfo="tooltipInfo"
      :tooltipInfoSize="tooltipInfoSize"
      :minDate="minDate"
      @input="input"
      @change="change"
      @update="update"
    />
  </template>
  <template v-else-if="!visible && item?.reason != null">
    <div>
      <label class="form-label">{{ props.label }}</label>
      <div class="input-hidden">
        <div class="d-f">
          <span class="">
            <IconPadlock />
          </span>
          <div class="text" style="top: 2px; position: relative">
            {{ reason }}
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import {
  defineProps,
  computed,
  defineEmits,
  onMounted,
  ref,
  getCurrentInstance,
} from "vue";
import declaracaoUnicaHelper from "@/helpers/declaracaoUnicaHelper";
//import { useStore } from "vuex";
//import { utils } from "@/helpers";

const { appContext } = getCurrentInstance();
const selectedLang = appContext.config.globalProperties.selectedLang;
const emit = defineEmits(["update:modelValue", "select", "update"]);
//<p> {{ props.inputKey }}</p>

var props = defineProps({
  inputKey: String,
  modelValue: [Boolean, String, Array, Number],
  validateForm: Boolean,
  loading: Boolean,
  type: String,
  label: String,
  placeholder: String,
  errorMsg: String,
  disabled: { Boolean, default: false },
  required: Boolean,
  maxlength: Number,
  options: Array,
  searchable: Boolean,
  mode: String,
  maxval: Number,
  customValidation: Function,
  declaracaoUnicaProp: [Object, Array],
  maxDate: Date,
  showOptions: { Boolean, default: true },
  groupName: String,
  parseIntProp: { Boolean, default: false },
  showOtherOption: { Boolean, default: false },
  otherOptionValue: { Object, default: { value: -1, label: "Outro" } },
  tooltipInfo: {
    String,
    default: null,
  },
  tooltipInfoSize: {
    String,
    default: "medium",
  },
  minDate: [Date, String],
});

//const store = useStore();

var initialValue = ref(props.modelValue);
var visible = ref(true);
var reason = ref(selectedLang.blockedData);
var CustomInputKey = ref(0);
var item = ref(null);

const declaracaoUnicaInput = computed({
  get() {
    var value = declaracaoUnicaHelper.getFieldByKey(
      props.declaracaoUnicaProp,
      props.inputKey
    );

    if (value != null && typeof value === "object") {
      value = value.value;
    }

    if (!value && initialValue.value) {
      declaracaoUnicaHelper.setFieldValueByKey(
        props.declaracaoUnicaProp,
        props.inputKey,
        initialValue.value
      );

      value = initialValue.value;
    }

    return value;
  },
  set(newValue) {
    declaracaoUnicaHelper.setFieldValueByKey(
      props.declaracaoUnicaProp,
      props.inputKey,
      newValue
    );

    localValue.value = newValue;
  },
});

const localDisable = computed({
  get() {
    var result = props.disabled || !declaracaoUnicaHelper.canEditDeclaration();
    return result;
  },
});

const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const isValid = computed(() => {
  if (props.customValidation != null) {
    return props.customValidation(declaracaoUnicaInput.value);
  }

  if (declaracaoUnicaInput.value === 0) {
    return true;
  }
  return declaracaoUnicaInput.value != null && !!declaracaoUnicaInput.value;
});

const showErrorMessage = computed(() => {
  if (!props.required) return false;
  return !isValid.value && props.validateForm;
});

function isVisible() {
  item.value = declaracaoUnicaHelper.getFieldByKey(
    props.declaracaoUnicaProp,
    props.inputKey
  );

  if (item.value == null) {
    return false;
  } else {
    if (item.value.reason) {
      reason.value =
        selectedLang.decrarationRestrictReasonEnum[item.value.reason];
    }

    return item.value.isVisible;
  }
}

onMounted(() => {
  visible.value = isVisible();
});

function select(event) {
  emit("select", event);
}
function input(event) {
  emit("input", event);
}
function change(event) {
  emit("change", event);
}
function update(event) {
  emit("update", event);
}
</script>
<style scoped>
.input-hidden {
  background: #f0f2f3;
  box-sizing: border-box;
  padding: 10px 0px 10px 10px;
  gap: 10px;
  min-height: 45px;
  border-radius: 10px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-color: var(--border);
  border: solid 1px;
}
</style>
