<template>
 <input
    :class="{ invalid: showErrorMsg }"
    type="password"
    class="form-control"
    :placeholder="localPlaceholder"
    v-model="localModelValue"
    :disabled="disabled"
    :maxlength="maxlength"
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
    showErrorMsg: {
      Boolean,
      default: false,
    },
    modelValue: String,
    disabled: {
      Boolean,
      default: false,
    },
    required: {
      Boolean,
      default: false,
    },
    maxlength: {
      Number,
      default: 20,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    localPlaceholder() {
      return this.placeholder ?? this.selectedLang.add;
    },
  },
};
</script>
