<template>
  <div class="p-4">
    <LoginForm :profile-id="profileId" />
    <div class="row mb-4">
      <div class="col-12">
        <div class="loginDivider">{{ selectedLang.orTxt }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4">
        <button type="button" class="btn-primary govBTN" v-on:click="govAuth">
          <svg
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.9836 28.9938H15.9713V29H15.7739C7.57742 29 0.928955 22.5089 0.928955 14.5C0.928955 6.49723 7.57742 0 15.7739 0H15.7862H15.7924H15.9836V5.68277H15.9713H15.7924C10.803 5.68894 6.76949 9.62553 6.76949 14.4944C6.76949 19.3689 10.8091 23.3049 15.7986 23.3049H15.9836V28.9938Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.2795 17.1118C17.0834 17.1924 16.9485 17.2432 16.6665 17.2432C15.1646 17.2432 15.0481 15.8889 15.0481 15.8889V13.5927L15.0849 13.33L15.1217 13.3243H18.2175V15.5862C18.2175 15.5868 18.1255 16.7748 17.2795 17.1118Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M26.9309 17.1118C26.7347 17.1924 26.5999 17.2432 26.3117 17.2432C24.8159 17.2432 24.6995 15.8889 24.6995 15.8889V13.5927L24.7362 13.33L24.773 13.3243H27.8689V15.5862C27.8689 15.5868 27.7769 16.7748 26.9309 17.1118Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.9683 21.879C21.8212 21.922 21.7415 21.946 21.5576 21.946C20.0618 21.946 19.9453 20.5022 19.9453 20.5022V18.027L19.976 18.2967L20.1108 18.2844H23.1147V20.7222C23.1147 20.7234 23.0044 21.6087 21.9683 21.879Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.1706 17.1118C21.9745 17.1924 21.8335 17.2432 21.5576 17.2432C20.0618 17.2432 19.9453 15.8889 19.9453 15.8889V13.5927L19.976 13.33L20.1108 13.3243H23.1147V15.5862C23.1147 15.5868 23.0166 16.7805 22.1706 17.1118Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.9683 11.6963C21.8212 11.7326 21.7415 11.7567 21.5576 11.7567C20.0618 11.7567 19.9453 10.3355 19.9453 10.3355V7.90435L19.976 7.85597L20.1108 7.83783H23.1147V10.2448C23.1147 10.2448 23.0044 11.4235 21.9683 11.6963Z"
              fill="white"
            />
          </svg>
          <span class="m-2">{{ selectedLang.govAuth }}</span>
        </button>
      </div>
    </div>
    <div class="row ta-left">
      <div class="col-12 d-none">
        {{ selectedLang.serviceTerms }}
        <router-link :to="{ name: 'Login' }">{{
          selectedLang.serviceTermsHref
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import LoginForm from "./LoginForm.vue";
import { ref, getCurrentInstance, defineProps } from "vue";
import accountService from "@/services/account.service";

var selectedLang =
  getCurrentInstance().appContext.config.globalProperties.selectedLang;

var loading = ref(false);
const props = defineProps(["profileId"]);
const profileId = ref(props.profileId);

async function govAuth() {
  loading.value = true;

  let model = {
    Username: "",
    AuthType: 4,
    RedirectUrl: process.env.VUE_APP_AUTHGOVREDIRECT,
  };

  var response = await accountService.authorize(model);
  window.open(response, "_self");
  loading.value = false;
}
</script>
