<template>
  <div>
    <div
      class="TabItem btnAlike"
      @click="selectTabBtn"
      :class="{ active: isActive }"
    >
      <p>
        {{ tabTittle }}
      </p>
    </div>
    <div v-if="isActive">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: this.initialIsActive,
    };
  },
  props: {
    tabId: String,
    tabTittle: String,
    group: String,
    initialIsActive: {
      Boolean,
      default: false,
    },
  },
  methods: {
    selectTabBtn() {
      const emitter = {
        tabId: this.tabId,
        group: this.group,
      };
      this.emitter.emit("selectTabMenuItem", emitter);
    },
    selectTab(emitterItem) {
      if (emitterItem.group == this.group) {
        if (this.tabId == emitterItem.tabId) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
      }
    },
  },
  mounted() {
    this.emitter.on("selectTabMenuItem", (emitterItem) => {
      this.selectTab(emitterItem);
    });
  },
};
</script>
