<template>
  <div class="notifications" v-if="isVisible">
    <div class="toolbar">
      <div class="icon-settings btnAlike" @click="settings">
        <svg
          width="424"
          height="1141"
          viewBox="0 0 424 1141"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="424" height="1141" fill="white" />
          <g clip-path="url(#clip0_4_5)">
            <rect
              x="350"
              y="14"
              width="48"
              height="48"
              rx="9"
              fill="white"
              stroke="#007AC4"
              stroke-width="2"
            />
          </g>
          <path
            d="M364.9 33.7599L365.78 32.2357C366.3 31.335 367.466 31.0152 368.376 31.5402C369.943 32.4452 371.224 31.7067 371.221 29.892C371.221 28.8526 372.074 27.9944 373.122 27.999L375.115 28.0067C376.035 27.9946 376.778 28.7471 376.79 29.6663L376.79 29.8858C376.785 31.6955 378.066 32.4355 379.639 31.5308L379.83 31.4213C380.632 30.9721 381.655 31.2396 382.104 32.0417L383.108 33.764C383.636 34.6694 383.319 35.8376 382.419 36.357C380.846 37.2617 380.846 38.7402 382.414 39.6452C383.315 40.1652 383.635 41.3313 383.11 42.2406L382.229 43.7648C381.709 44.6655 380.543 44.9853 379.634 44.4603C378.066 43.5553 376.786 44.2938 376.789 46.1084C376.784 47.1565 375.936 48.0061 374.887 48.0015L372.894 47.9938C371.975 48.0059 371.232 47.2534 371.22 46.3342L371.219 46.1147C371.225 44.305 369.943 43.565 368.37 44.4697L368.18 44.5792C367.378 45.0284 366.355 44.7609 365.905 43.9588L364.902 42.2365C364.374 41.3311 364.691 40.1629 365.591 39.6434C367.164 38.7388 367.163 37.2603 365.596 36.3553C364.686 35.8303 364.38 34.6605 364.9 33.7599Z"
            stroke="#007AC4"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M377.25 38C377.25 36.2051 375.795 34.75 374 34.75C372.205 34.75 370.75 36.2051 370.75 38C370.75 39.7949 372.205 41.25 374 41.25C375.795 41.25 377.25 39.7949 377.25 38Z"
            stroke="#007AC4"
            stroke-width="1.5"
          />
          <defs>
            <clipPath id="clip0_4_5">
              <rect
                width="50"
                height="50"
                fill="white"
                transform="translate(349 13)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="content" :style="notFoundStyle">
      <template v-if="!items.length && !loading">
        <svg
          width="90"
          height="100"
          viewBox="0 0 90 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86 75.5556C86 75.5556 75.75 50 75.75 34.6667C75.75 17.9636 61.7485 4 45 4C28.2515 4 14.25 17.9635 14.25 34.6667C14.25 55.1111 4 75.5556 4 75.5556H86Z"
            fill="#CAEBFF"
          />
          <path
            d="M29.625 75.5556V80.6667C29.625 82.6803 30.0227 84.6742 30.7954 86.5345C31.568 88.3948 32.7005 90.0851 34.1282 91.509C35.5559 92.9328 37.2509 94.0622 39.1162 94.8328C40.9816 95.6034 42.9809 96 45 96C47.0191 96 49.0184 95.6034 50.8838 94.8328C52.7491 94.0622 54.4441 92.9328 55.8718 91.509C57.2995 90.0851 58.432 88.3948 59.2046 86.5345C59.9773 84.6742 60.375 82.6803 60.375 80.6667V75.5556M75.75 34.6667C75.75 50 86 75.5556 86 75.5556H4C4 75.5556 14.25 55.1111 14.25 34.6667C14.25 17.9635 28.2515 4 45 4C61.7485 4 75.75 17.9636 75.75 34.6667Z"
            stroke="#007AC4"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>
          <h1 class="tal-center" style="margin-top: 29px">
            {{ selectedLang.notificationsNotFound }}
          </h1>
        </span>
      </template>
      <template v-else>
        <div class="notifications-items">
          <table class="table table-bordered">
            <tbody>
              <tr
                class="notification"
                @click="viewNotification(item)"
                v-for="(item, index) in items"
                :key="index"
              >
                <td style="width: 95%">
                  <div class="d-f">
                    <span class="notification-title">{{
                      item.messageTittle
                    }}</span>
                    <!-- <span class="notification-date">
                      {{ item.from.name }}
                    </span> -->
                    <span class="notification-date ml-auto">
                      {{ $filters.formatDateTime(item.created) }}
                    </span>
                  </div>
                </td>
                <td class="tal-center va-m">
                  <template v-if="item.state == 1">
                    <tooltip size="medium" :text="selectedLang.unread">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                          stroke="#102E40"
                          stroke-width="2"
                        />
                        <path
                          d="M11 6V12M11 15.5V16"
                          stroke="#102E40"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </tooltip>
                  </template>
                  <template v-if="item.state == 2">
                    <tooltip size="medium" :text="selectedLang.read">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 22 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 11.08V12C20.9988 14.1564 20.3005 16.2547 19.0093 17.9818C17.7182 19.709 15.9033 20.9725 13.8354 21.5839C11.7674 22.1953 9.55726 22.1219 7.53447 21.3746C5.51168 20.6273 3.78465 19.2461 2.61096 17.4371C1.43727 15.628 0.879791 13.4881 1.02168 11.3363C1.16356 9.18457 1.99721 7.13633 3.39828 5.49707C4.79935 3.85782 6.69279 2.71538 8.79619 2.24015C10.8996 1.76491 13.1003 1.98234 15.07 2.86"
                          stroke="#102E40"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 4L11 14.01L8 11.01"
                          stroke="#102E40"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </tooltip>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
  <CustomModal
    size="lg"
    :title="selectedLang.notificationSettings"
    modalId="NotificationSettings"
  >
    <div class="row mt-5 mb-3">
      <div class="col-12" style="text-align: left">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="width: 80%">{{ selectedLang.actions }}</th>
              <th style="width: 80%">{{ selectedLang.email }}</th>
              <th style="width: 80%">{{ selectedLang.system }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ selectedLang.preferredCommunicationChannel }}</td>
              <td class="tal-center">
                <input
                  :disabled="loading"
                  class="form-check-input"
                  type="checkbox"
                  v-model="configuration.emailNotification"
                  :value="true"
                />
              </td>
              <td class="tal-center">
                <input
                  :disabled="loading"
                  class="form-check-input"
                  type="checkbox"
                  v-model="configuration.systemNotification"
                  :value="true"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-4 d-f jc-right mt-auto">
        <button
          type="button"
          @click="save"
          class="btn btnS btnPrimary mr-1"
          :disabled="loading"
        >
          {{ selectedLang.save }}
        </button>
        <button
          type="button"
          @click="cancelSettings"
          class="btn btnS btnSecundary"
        >
          {{ selectedLang.close }}
        </button>
      </div>
    </div>
  </CustomModal>
  <CustomModal
    size="xl"
    :title="selectedLang.notification"
    modalId="Notification"
  >
    <div class="row mb-5 mt-5">
      <div class="col-12">
        <div class="comment p-3">
          <div class="d-f">
            <svg
              class="mr-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 18C18 14.6865 15.3135 12 12 12C8.6865 12 6 14.6865 6 18M18 2H22V6M6 2H2V6M18 22H22V18M6 22H2V18"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div>Entidade para a Transparência</div>
            <div class="ml-auto">
              {{ $filters.formatDateTime(notification.created) }}
            </div>
          </div>
          <hr class="rounded" />
          <h1 class="mb-3">{{ notification.messageTittle }}</h1>
          <div class="descriptionHtml" v-html="notification.messageBody"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-4 d-f jc-right mt-auto">
        <button
          :disabled="notification.state == 2"
          :class="notification.state == 2 ? 'd-none' : ''"
          type="button"
          @click="markAsRead()"
          class="btn btnS btnPrimary mr-1"
        >
          {{ selectedLang.markAsRead }}
        </button>

        <button
          type="button"
          @click="removeNotification"
          class="btn btnS btnDanger mr-1"
        >
          {{ selectedLang.delete }}
        </button>
        <button
          type="button"
          @click="closeNotification"
          class="btn btnS btnSecundary"
        >
          {{ selectedLang.close }}
        </button>
      </div>
    </div>
  </CustomModal>
</template>
<script setup>
import {
  ref,
  onMounted,
  defineExpose,
  getCurrentInstance,
  onBeforeUnmount,
} from "vue";
import { notificationService, userService } from "@/services";
import { utils, authentication } from "@/helpers";
import tooltip from "@/components/tooltip.vue";

var items = ref([]);
var notFoundStyle = ref(null);
var isVisible = ref(false);
var loading = ref(true);
var sortBy = ref("created desc");
var notification = ref({
  messageTittle: "",
  messageBody: "",
});
var configuration = ref({
  emailNotification: false,
  systemNotification: false,
});

const { appContext } = getCurrentInstance();
const selectedLang = appContext.config.globalProperties.selectedLang;
const emitter = appContext.config.globalProperties.emitter;

async function removeNotification() {
  const response = await notificationService.delete(notification.value.id);
  if (response.code == 0) {
    await load();
    emitter.emit("Notification:Counter");
    emitter.emit("closeCustomModal", "Notification");
    utils.successToast(selectedLang.notificationDeleteSuccess);
  }
}

async function markAsRead() {
  if (notification.value.state == 1) {
    const response = await notificationService.markAsRead(
      notification.value.id
    );

    if (response.code == 0) {
      emitter.emit("closeCustomModal", "Notification");
      emitter.emit("Notification:Counter");
      utils.successToast(selectedLang.notificationReadSuccess);
      await load();
    } else if (response.code == -160) {
      utils.errorToast(selectedLang.notificationNotFound);
    } else {
      utils.errorToast(selectedLang[response.description]);
    }
  }
}

async function viewNotification(item) {
  notification.value = item;

  emitter.emit("openCustomModal", "Notification");
}

async function settings() {
  const auth = authentication.get();
  const response = await userService.getConfiguration(auth.id);

  if (response.code == 0) {
    configuration.value.emailNotification = response.data.emailNotification;
    configuration.value.systemNotification = response.data.systemNotification;
    emitter.emit("openCustomModal", "NotificationSettings");
  } else {
    utils.errorToast(selectedLang[response.description]);
  }
}

async function save() {
  if (
    !configuration.value.emailNotification &&
    !configuration.value.systemNotification
  ) {
    utils.warningToast(selectedLang.requiredPreferredCommunicationChannel);
    return;
  }

  const response = await userService.updateConfiguration(
    configuration.value.emailNotification,
    configuration.value.systemNotification
  );

  if (response.code == 0) {
    utils.successToast(selectedLang.savedConfigurations);
    emitter.emit("closeCustomModal", "NotificationSettings");
  } else {
    utils.errorToast(selectedLang[response.description]);
  }
}

async function cancelSettings() {
  emitter.emit("closeCustomModal", "NotificationSettings");
}

async function closeNotification() {
  emitter.emit("closeCustomModal", "Notification");
}

async function load() {
  loading.value = true;

  const response = await notificationService.search(1, sortBy.value, null);

  if (response.code == 0) {
    items.value = response.data.items;
  }

  loading.value = false;

  if (!items.value.length) notFoundStyle.value = { "padding-top": "192px" };
}

const open = async () => {
  isVisible.value = true;
  await load();
};

const close = async () => {
  isVisible.value = false;
};

defineExpose({
  open,
  close,
});

onMounted(async () => {
  //await open();
  emitter.on("OpenClose-Notification", () => {
    if (!isVisible.value) open();
    else close();
  });
  emitter.on("Close-Notification", () => {
    close();
  });
});

onBeforeUnmount(() => {
  emitter.off("OpenClose-Notification");
  emitter.off("Close-Notification");
});
</script>
<style scoped>
.unread {
  color: #fff;
  background: blue;
}
.read {
  color: #fff;
  background: #eee;
}

.notification {
  cursor: pointer;
}

.notification-title {
  display: block;
  font-weight: bold;
  color: #000;
}
.notification-date {
  position: relative;
  display: block;
  text-align: left;
  font-size: 90%;
}

.descriptionHtml {
  text-align: left;
}
</style>
