<template>
  <div class="co">
    <span class="text">
      <strong>{{ selectedLang.legalNotice }}</strong>
      {{ selectedLang.declarationLegalNotice }}
    </span>
  </div>
</template>

<script setup></script>

<style scoped>
.co {
  text-align: justify;
  border: dashed;
  /* margin: 43px; */
  width: 90%;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 16px !important;
}
.text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 144%;
  letter-spacing: 0.01em;
  color: var(--branco);
}
</style>
