<template>
  <tr ref="declarationTableRow" :class="{ 'emphasize-row': emphasize }">
    <slot />
  </tr>
</template>

<script setup>
import { defineProps, onMounted, ref, getCurrentInstance } from "vue";

var props = defineProps({
  rowGUID: {
    type: [String],
    required: true,
  },
});

const { appContext } = getCurrentInstance();
const emitter = appContext.config.globalProperties.emitter;
var declarationTableRow = ref(null);
var emphasize = ref(false);

onMounted(() => {
  emitter.on("jumpToField", (fieldId) => {
    jumpToField(fieldId);
  });
});

function jumpToField(fieldId) {
  if (props.rowGUID == fieldId && declarationTableRow.value != null) {
    //declarationTableRow.value.$el.scrollIntoView({ behavior: "smooth" });
    emphasize.value = true;
    // setTimeout(() => {
    //   emphasize.value = false;
    // }, 5000);
  }
}
</script>

<style lang="scss" scope>
.emphasize-row > td {
  color: #c82b11 !important;
  border: 1px solid #c82b11 !important;
  //color: white !important;
  //border-color: white !important;
  //background: #c82b11 !important;
}
</style>
