<template>
  <div :key="tab2Key">
    <div class="row mb-1">
      <div class="col-12 ta-last-left">
        <span class="title">{{ selectedLang.personalData }}</span>
      </div>
      <div class="col-12">
        <hr class="rounded" />
      </div>
    </div>
    <div v-if="showPersonalData" class="row">
      <div class="col-12 col-lg-8 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="c0a2f813-fb84-4923-b7a1-55cef0c5fc1b"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.completeName"
          v-model="form.name"
          :required="true"
          :disabled="true"
          :maxlength="150"
        />
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="54854c64-5845-40bb-a536-8c03da55420a"
          :validateForm="validateForm"
          type="RadioBtn"
          :label="selectedLang.sexTxt"
          v-model="form.sexo"
          :required="true"
          :disabled="loading"
          :options="formSexOptions"
          :parseIntProp="false"
          groupName="gender"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e"
          :validateForm="validateForm"
          type="Number"
          :label="selectedLang.NIC"
          v-model="form.nic"
          :required="true"
          :disabled="true"
          :maxlength="8"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="d155af2b-21e9-475f-be81-8e42bc5a5641"
          :validateForm="validateForm"
          type="Number"
          :label="selectedLang.NIF"
          v-model="form.nif"
          :required="true"
          :disabled="true"
          :maxlength="9"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="a5bf96eb-8af0-4c05-bb76-a15f57f8490d"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.naturalness"
          v-model="form.naturalidade"
          :required="true"
          :disabled="false"
          :maxlength="40"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :maxDate="new Date()"
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="25fa81a1-70d2-4fca-8292-4717ba2e8cdb"
          :validateForm="validateForm"
          type="DatePicker"
          :label="selectedLang.birthdate"
          v-model="form.dataNascimento"
          :required="true"
          :disabled="true"
          :maxlength="40"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="ed9a8413-b9f0-4d32-89bd-03593c826211"
          :validateForm="validateForm"
          type="Multiselect"
          :label="selectedLang.maritalStatus"
          :required="true"
          :disabled="loading || loadingPersonalData"
          :options="estadoCivilOptions"
          @select="selectEstadoCivil"
          @clear="clearEstadoCivil"
          showOptions
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="showRegimedeBens">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="ed9a8413-b9f0-4d32-89bd-03593c826212"
          :validateForm="validateForm"
          type="Multiselect"
          :label="selectedLang.propertyRegime"
          :required="showRegimedeBens"
          :disabled="loading || loadingPersonalData"
          :options="formRegimedeBensOptions"
          showOptions
        />
      </div>
      <div class="col-12 col-md-6 col-lg-12 mb-4" v-if="showSpouseName">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="ed9a8413-b9f0-4d32-89bd-03593c826213"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.spouseName"
          :required="showSpouseName"
          :disabled="loading || loadingPersonalData"
          :maxlength="150"
        />
      </div>
    </div>
    <DeclarationRestrictData
      :reason="showPersonalDataReason"
      v-if="!showPersonalData"
    />

    <div class="row mb-1 mt-2">
      <div class="col-12 ta-last-left">
        <span class="title">{{ selectedLang.household }}</span>
      </div>
      <div class="col-12">
        <hr class="rounded" />
      </div>
    </div>
    <div v-if="showAdressData" class="row">
      <div class="col-12 col-lg-8 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="37b963d3-b73a-4da7-9a78-cb417f92214a"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.household"
          v-model="form.morada"
          :required="true"
          :disabled="(loading && declaracao.estado != 1) || loadingPersonalData"
          :maxlength="95"
        />
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="38cffadd-0893-47ef-b261-84007c381639"
          :validateForm="validateForm"
          type="Zip"
          :label="selectedLang.postalCode"
          v-model="form.codigoPostal"
          :required="true"
          :disabled="(loading && declaracao.estado != 1) || loadingPersonalData"
        />
      </div>
    </div>
    <div v-if="showAdressData" class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="308ccc8a-452a-4ed7-94e5-5ca17a1667fe"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.location"
          v-model="form.localidade"
          :required="true"
          :placeholder="selectedLang.add"
          :disabled="loading || loadingPersonalData"
          :maxlength="40"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="77902677-0143-437c-ae9b-b3e54642a080"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.parish"
          v-model="form.freguesia"
          :required="true"
          :placeholder="selectedLang.add"
          :disabled="(loading && declaracao.estado != 1) || loadingPersonalData"
          :maxlength="60"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="2b75bfd8-392e-49d7-866f-1353a5e765c3"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.county"
          v-model="form.concelho"
          :required="true"
          :placeholder="selectedLang.add"
          :disabled="loading || loadingPersonalData"
          :maxlength="80"
        />
      </div>
    </div>
    <DeclarationRestrictData
      :reason="showAdressDataReason"
      v-if="!showAdressData"
    />

    <div class="row mb-1 mt-2">
      <div class="col-12 ta-last-left">
        <span class="title">{{ selectedLang.contacts }}</span>
      </div>
      <div class="col-12">
        <hr class="rounded" />
      </div>
    </div>
    <!-- <DeclaracaoUnicaUnseeData v-if="declaracao.estado > 1" /> -->
    <div v-if="showContactsData" class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="d3fd512b-65e8-4550-90d9-018a5ef91c0d"
          :validateForm="validateForm"
          type="Text"
          :label="selectedLang.email"
          v-model="form.email"
          :required="true"
          :disabled="loading || loadingPersonalData"
          :maxlength="100"
          :customValidation="emailIsValid"
          :errorMsg="selectedLang.invalidEmail"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="8b022aca-fdf8-4391-8d49-73abc4e63128"
          :validateForm="validateForm"
          type="Number"
          :label="selectedLang.mobilePhone"
          v-model="form.telemovel"
          :required="false"
          :disabled="loading || loadingPersonalData"
          :maxlength="20"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <DeclaracaoUnicaInput
          :declaracaoUnicaProp="declaracaoUnicaProp"
          inputKey="67d71fd5-100a-42dd-a6a8-44d40ce2ba50"
          type="Number"
          :label="selectedLang.cellPhone"
          v-model="form.telefone"
          :required="false"
          :disabled="loading || loadingPersonalData"
          :maxlength="20"
        />
      </div>
    </div>
    <DeclarationRestrictData
      :reason="showContactsDataReason"
      v-if="!showContactsData"
    />
  </div>
</template>

<script>
import { utils, declaracaoUnicaHelper } from "@/helpers";

export default {
  name: "DeclaracaoUnicaTab2",
  components: {},
  data() {
    return {
      loading: false,
      validateForm: false,
      form: {
        name: null,
        sexo: null,
        nic: null,
        nif: null,
        naturalidade: null,
        estadoCivil: null,
        propertyRegime: null,
        spouseName: null,
        dataNascimento: null,
        morada: null,
        codigoPostal: null,
        localidade: null,
        freguesia: null,
        concelho: null,
        telemovel: null,
        telefone: null,
        email: null,
      },
      tab2Key: 0,
      loadingPersonalData: false,
    };
  },
  props: {
    declaracaoUnicaProp: Object,
  },
  methods: {
    clearEstadoCivil() {
      this.form.maritalStatus = null;
      this.form.spouseName = null;
    },
    selectEstadoCivil(value) {
      if (value == 1) {
        this.form.propertyRegime = this.getFormValue(
          "ed9a8413-b9f0-4d32-89bd-03593c826212"
        );
        this.form.spouseName = this.getFormValue(
          "ed9a8413-b9f0-4d32-89bd-03593c826213"
        );
      } else {
        this.setFormValue("ed9a8413-b9f0-4d32-89bd-03593c826212", null);
        this.setFormValue("ed9a8413-b9f0-4d32-89bd-03593c826213", null);
      }
    },
    emailIsValid(email) {
      return utils.isValidEmail(email);
    },
    load() {
      this.form.estadoCivil = this.$store.state.maritalStatus;
      this.form.propertyRegime = this.getFormValue(
        "ed9a8413-b9f0-4d32-89bd-03593c826212"
      );
      this.form.spouseName = this.getFormValue(
        "ed9a8413-b9f0-4d32-89bd-03593c826213"
      );
    },
    getFormValue(guid) {
      var result = declaracaoUnicaHelper.getFieldByKey(
        this.$store.state.declaracao.json,
        guid
      );
      return result != null ? result.value : null;
    },
    setFormValue(guid, value) {
      declaracaoUnicaHelper.setFieldValueByKey(
        this.$store.state.declaracao.json.value,
        guid,
        value
      );
    },
  },
  computed: {
    estadoCivilOptions() {
      return [
        {
          value: 0,
          label: this.selectedLang.maritalStatusOptions[0],
        },
        {
          value: 1,
          label: this.selectedLang.maritalStatusOptions[1],
        },
        {
          value: 4,
          label: this.selectedLang.maritalStatusOptions[4],
        },
        {
          value: 5,
          label: this.selectedLang.maritalStatusOptions[5],
        },
        {
          value: 2,
          label: this.selectedLang.maritalStatusOptions[2],
        },
        {
          value: 3,
          label: this.selectedLang.maritalStatusOptions[3],
        },
      ];
    },
    formSexOptions() {
      return [
        {
          value: "F",
          label: this.selectedLang.femenine,
        },
        {
          value: "M",
          label: this.selectedLang.masculine,
        },
      ];
    },
    showRegimedeBens() {
      return (
        this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracao.json,
          "ed9a8413-b9f0-4d32-89bd-03593c826211"
        ).value == 1
      );
    },
    showSpouseName() {
      let val = this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "ed9a8413-b9f0-4d32-89bd-03593c826211"
      ).value;
      return val == 1 || val == 4 || val == 5;
    },
    formRegimedeBensOptions() {
      return [
        {
          value: 0,
          label: this.selectedLang.propertyRegimeEnum[0],
        },
        {
          value: 1,
          label: this.selectedLang.propertyRegimeEnum[1],
        },
        {
          value: 2,
          label: this.selectedLang.propertyRegimeEnum[2],
        },
      ];
    },
    declaracao() {
      return this.$store.state.declaracao;
    },
    showPersonalData() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "56786e7c-a8bb-43aa-9250-eaf8fa82002f"
      ).isVisible;
    },
    showPersonalDataReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "56786e7c-a8bb-43aa-9250-eaf8fa82002f"
      ).reason;
    },
    showAdressData() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "6a3c614b-2c9e-42f3-b5af-49cc8b078204"
      ).isVisible;
    },
    showAdressDataReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "6a3c614b-2c9e-42f3-b5af-49cc8b078204"
      ).reason;
    },
    showContactsData() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "b437d8f1-e201-4fc0-a304-e5f78ca1a063"
      ).isVisible;
    },
    showContactsDataReason() {
      return this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracao.json,
        "b437d8f1-e201-4fc0-a304-e5f78ca1a063"
      ).reason;
    },
  },
  mounted() {
    //this.$forceUpdate();
    this.emitter.emit("DeclaracaoUnicaProgressBar:Toggle", true);

    this.emitter.on("ValidateForm", () => {
      this.validateForm = true;
    });
    this.load();
  },
  watch: {
    declaracao: {
      handler(newValue) {
        this.emitter.emit("DeclarationProgress:Update", newValue);
      },
      deep: true,
    },
  },
};
</script>
