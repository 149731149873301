import security from "@/helpers/security";

export default {
  get(key) {
    const encrypted = localStorage.getItem(key);
    if(!encrypted)
    return null;
    const json = security.decryptMessage(encrypted);
    return JSON.parse(json);
  },
  set(value) {
    const json = JSON.stringify(value);
    const encrypted = security.encryptMessage(json);
    const key = security.md5(json);
    localStorage.setItem(key, encrypted);
    return key;
  },
  setKey(key, value) {
    const json = JSON.stringify(value);
    const encrypted = security.encryptMessage(json);
    localStorage.setItem(key, encrypted);
  },
  remove(key) {
    localStorage.removeItem(key);
  },
};
