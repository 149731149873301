<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 10L10 14"
      :stroke="props.color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M14 14L10 10"
      :stroke="props.color"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12Z"
      :stroke="props.color"
      stroke-width="1.5"
    />
  </svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#102E40",
  },
});
</script>
