import service from "./service-base";

export default {
  search: (pageNumber, sortBy, model) => {
    model.pageNumber = pageNumber;
    model.sortBy = sortBy; 
    return service.post(
      `/commission`,model
    );
  },
  getAllEntities: (searchModel) => {
    return service.post(`/commission/getallentities`, searchModel);
  },
  getAllRoles: (searchModel) => {
    return service.post(`/commission/getallroles`, searchModel);
  },
  getAllHolders: (searchModel) => {
    return service.post(`/commission/getallholders`, searchModel);
  },
  getallboards: (searchModel) => {
    return service.post(`/commission/getallboards`, searchModel);
  },  
  requirements: (pageNumber, sortBy, searchModel) => {
    searchModel.pageNumber = pageNumber;
    searchModel.sortBy = sortBy;
    return service.post(
      `/commission/requirements`,
      searchModel
    );
  },
  createRequirements: (model) => {
    return service.post(`/commission/requirements/create`, model);
  },
};