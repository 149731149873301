<template>
    <svg
              width="31"
              height="26"
              viewBox="0 0 31 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6.79285"
                y="3.98198"
                width="18.036"
                height="17.0991"
                rx="4.61261"
                fill="#EFF3F6"
              />
              <path
                d="M20.8371 3.23293H10.7845C8.425 3.23293 6.51221 5.14571 6.51221 7.50526V17.5578C6.51221 19.9174 8.425 21.8301 10.7845 21.8301H20.8371C23.1966 21.8301 25.1094 19.9174 25.1094 17.5578V7.50526C25.1094 5.14571 23.1966 3.23293 20.8371 3.23293Z"
                stroke="#102E40"
                stroke-width="1.85586"
              />
              <path
                d="M10.7748 16.1173L11.9057 17.3236L13.7905 15.3131"
                stroke="#102E40"
                stroke-width="1.85586"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.7748 8.07523L11.9057 9.28154L13.7905 7.27103"
                stroke="#102E40"
                stroke-width="1.85586"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <line
                x1="18"
                y1="8"
                x2="22"
                y2="8"
                stroke="#102E40"
                stroke-width="2"
                stroke-linecap="round"
              />
              <line
                x1="18"
                y1="16"
                x2="22"
                y2="16"
                stroke="#102E40"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
</template>