<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 12.8335C20.3082 11.3317 21 10 21 10C21 10 17.3636 3 11 3C10.6588 3 10.3254 3.02013 10 3.05822C9.65778 3.09828 9.3244 3.15822 9 3.23552M11 7C11.3506 7 11.6872 7.06015 12 7.17071C12.8524 7.47199 13.528 8.14759 13.8293 9C13.9398 9.31278 14 9.64936 14 10M2 1L20 19M11 13C10.6494 13 10.3128 12.9398 9.99999 12.8293C9.14758 12.528 8.47198 11.8524 8.1707 11C8.11386 10.8392 8.07034 10.6721 8.04147 10.5M3.14701 7C2.83877 7.34451 2.56234 7.68241 2.31864 8C1.45286 9.12824 1 10 1 10C1 10 4.63636 17 11 17C11.3412 17 11.6746 16.9799 12 16.9418"
      :stroke="props.color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#102E40",
  },
});
</script>
