<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5304 9.53033C17.8233 9.23744 17.8233 8.76256 17.5304 8.46967C17.2375 8.17678 16.7626 8.17678 16.4697 8.46967L17.5304 9.53033ZM10 16L9.46971 16.5304C9.76261 16.8232 10.2375 16.8232 10.5304 16.5303L10 16ZM7.53033 12.4697C7.23743 12.1768 6.76256 12.1768 6.46967 12.4697C6.17678 12.7626 6.17678 13.2374 6.46967 13.5303L7.53033 12.4697ZM16.4697 8.46967L9.46971 15.4697L10.5304 16.5303L17.5304 9.53033L16.4697 8.46967ZM6.46967 13.5303L9.46971 16.5304L10.5304 15.4697L7.53033 12.4697L6.46967 13.5303ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z"
      :fill="props.color"
    />
  </svg>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#102E40",
  },
});
</script>
