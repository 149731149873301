<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.25 6.5C10.25 4.42893 8.57107 2.75 6.5 2.75C4.42893 2.75 2.75 4.42893 2.75 6.5C2.75 8.57107 4.42893 10.25 6.5 10.25C8.57107 10.25 10.25 8.57107 10.25 6.5Z"
      stroke="#1B1B1B"
      stroke-width="1.5"
    />
    <path
      d="M21.25 6.5C21.25 4.42893 19.5711 2.75 17.5 2.75C15.4289 2.75 13.75 4.42893 13.75 6.5C13.75 8.57107 15.4289 10.25 17.5 10.25C19.5711 10.25 21.25 8.57107 21.25 6.5Z"
      stroke="#1B1B1B"
      stroke-width="1.5"
    />
    <path
      d="M21.25 17.5C21.25 15.4289 19.5711 13.75 17.5 13.75C15.4289 13.75 13.75 15.4289 13.75 17.5C13.75 19.5711 15.4289 21.25 17.5 21.25C19.5711 21.25 21.25 19.5711 21.25 17.5Z"
      stroke="#1B1B1B"
      stroke-width="1.5"
    />
    <path
      d="M10.25 17.5C10.25 15.4289 8.57107 13.75 6.5 13.75C4.42893 13.75 2.75 15.4289 2.75 17.5C2.75 19.5711 4.42893 21.25 6.5 21.25C8.57107 21.25 10.25 19.5711 10.25 17.5Z"
      stroke="#1B1B1B"
      stroke-width="1.5"
    />
  </svg>
</template>
