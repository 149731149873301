<template>
  <div>
    <DeclaracaoUnicaBlockedData
      v-if="!items.isVisible"
      :reason="items.reason"
    />
    <TableSort
      :showEmptyrow="false"
      :totalItems="1"
      v-if="items.isVisible"
      defaultSort=""
      :checkBox="false"
      :loading="loading"
      :tableModel="tableModel"
      :actions="canEditDeclaration"
      :searchFunc="searchFunc"
      :sortFunc="sortFunc"
      :showPagination="false"
      :showTotal="false"
      :sortHeader="false"
      :key="tableRef"
    >
      <tr
        v-if="
          !canEditDeclaration &&
          declaracaoUnicaHelper.isNothingDeclare(items.value)
        "
      >
        <td colspan="11" class="p-3 jc-center tal-center">
          <h1>
            {{ selectedLang.nothingDeclare }}
          </h1>
        </td>
      </tr>
      <DeclarationTableRow
        v-for="(item, index) in items.value"
        :key="index"
        :rowGUID="item.key"
        v-else
      >
        <template v-slot="slotProps">
          <td>
            <declaration-column-table
              :emphasize="slotProps.emphasize"
              :item="item.value[1]"
            />
          </td>
          <td>
            <declaration-column-table
              :emphasize="slotProps.emphasize"
              :item="item.value[0]"
              type="currency"
            />
          </td>
          <td class="d-f" v-if="canEditDeclaration">
            <span @click="openDeleteDetail(item)" class="btnAlike">
              <IconTrash style="width: 26px; height: 26px" />
            </span>
            <span class="ml-1 mr-1">
              <IconVerticalSeparator />
            </span>
            <span @click="editItem(item)" class="btnAlike">
              <IconEdit />
            </span>
          </td>
          <td v-else>
            <span @click="detailItem(item)" class="btnAlike">
              <IconEye :color="slotProps.emphasize ? '#db9102' : '#102E40'" />
            </span>
          </td>
        </template>
      </DeclarationTableRow>
      <tr v-if="canEditDeclaration">
        <td colspan="4" class="p-0 pt-1">
          <span @click="newItem" class="d-f jc-center btnAlike">
            <svg
              style="margin: 9px 0px"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2241 7.57143V6.42857C12.2241 6.27381 12.1658 6.13988 12.0491 6.02678C11.9324 5.91369 11.7942 5.85714 11.6345 5.85714H9.27579V3.57143C9.27579 3.41667 9.21744 3.28274 9.10074 3.16964C8.98403 3.05655 8.84583 3 8.68613 3H7.50679C7.34709 3 7.20889 3.05655 7.09218 3.16964C6.97548 3.28274 6.91713 3.41667 6.91713 3.57143V5.85714H4.55847C4.39877 5.85714 4.26056 5.91369 4.14386 6.02678C4.02715 6.13988 3.9688 6.27381 3.9688 6.42857V7.57143C3.9688 7.72619 4.02715 7.86012 4.14386 7.97322C4.26056 8.08631 4.39877 8.14286 4.55847 8.14286H6.91713V10.4286C6.91713 10.5833 6.97548 10.7173 7.09218 10.8304C7.20889 10.9435 7.34709 11 7.50679 11H8.68613C8.84583 11 8.98403 10.9435 9.10074 10.8304C9.21744 10.7173 9.27579 10.5833 9.27579 10.4286V8.14286H11.6345C11.7942 8.14286 11.9324 8.08631 12.0491 7.97322C12.1658 7.86012 12.2241 7.72619 12.2241 7.57143ZM14.3511 3.49089C14.9969 4.56033 15.3199 5.73004 15.3199 7C15.3199 8.26996 14.9969 9.44119 14.3511 10.5137C13.7052 11.5862 12.829 12.4353 11.7223 13.0612C10.6156 13.6871 9.40695 14 8.09645 14C6.78596 14 5.57735 13.6871 4.47064 13.0612C3.36393 12.4353 2.48765 11.5862 1.84181 10.5137C1.19597 9.44119 0.873047 8.26996 0.873047 7C0.873047 5.73004 1.19597 4.55881 1.84181 3.48633C2.48765 2.41385 3.36393 1.56467 4.47064 0.938802C5.57735 0.312934 6.78596 0 8.09645 0C9.40695 0 10.6156 0.312934 11.7223 0.938802C12.829 1.56467 13.7052 2.41536 14.3511 3.49089Z"
                fill="#007AC4"
              />
            </svg>
            <p class="declarationRow">
              {{ selectedLang.adding }}
            </p>
          </span>
        </td>
      </tr>
    </TableSort>

    <CustomModal modalId="TrabalhoDependenteEdit" size="xl" :title="modalTitle">
      <div class="row mt-5">
        <div class="col-12 col-md-6 mb-4">
          <DeclaracaoUnicaInput
            :declaracaoUnicaProp="itemToEdit.value"
            :inputKey="`${guid}-col4`"
            type="RadioBtn"
            :label="selectedLang.incomeEarnedAbroad"
            :required="true"
            :options="formExercicioFuncoesOptions"
            groupName="remuneration"
            @input="incomeEarnedAbroadSelect"
            :showErrorMsg="isItNotFromPortugalShowError"
          />
        </div>
        <div class="col-6 mb-4" v-if="isNotFromPortugal">
          <DeclaracaoUnicaInput
            :declaracaoUnicaProp="itemToEdit.value"
            :inputKey="`${guid}-col5`"
            type="Text"
            :label="selectedLang.countryISOCode"
            :required="true"
            :maxlength="40"
            :showErrorMsg="countryISOCodeShowError"
          />
        </div>
        <div class="col-6 mb-4" v-if="isItNotFromPortugalIsValid">
          <DeclaracaoUnicaInput
            :declaracaoUnicaProp="itemToEdit.value"
            :inputKey="`${guid}-col2`"
            type="Text"
            :label="selectedLang.designationPayingEntity"
            :required="true"
            :maxlength="60"
            :showErrorMsg="entidadeShowError"
          />
        </div>
        <div class="col-6 mb-4" v-if="isFromPortugal">
          <DeclaracaoUnicaInput
            :declaracaoUnicaProp="itemToEdit.value"
            :inputKey="`${guid}-col3`"
            type="Number"
            :label="selectedLang.NIFNIPC"
            :required="true"
            :maxlength="9"
            :showErrorMsg="NIFNIPCShowError"
            :errorMsg="NIFNIPCErrorMsg"
          />
        </div>
        <div class="col-6 mb-4" v-if="isNotFromPortugal">
          <DeclaracaoUnicaInput
            :declaracaoUnicaProp="itemToEdit.value"
            :inputKey="`${guid}-col7`"
            type="Text"
            :label="selectedLang.taxIdentification"
            :required="true"
            :maxlength="40"
            :showErrorMsg="taxIdentificationShowError"
          />
        </div>
        <div class="col-6 mb-4" v-if="isItNotFromPortugalIsValid">
          <DeclaracaoUnicaInput
            :declaracaoUnicaProp="itemToEdit.value"
            :inputKey="`${guid}-col1`"
            type="Monetery"
            :label="
              isNotFromPortugal
                ? selectedLang.monetaryValueEuro
                : selectedLang.monetaryValue
            "
            :required="true"
            :showErrorMsg="trabalhoShowError"
            tooltipInfoSize="large"
            :tooltipInfo="
              isNotFromPortugal ? selectedLang.monetaryValueInfo : null
            "
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-f jc-right" v-if="canEditDeclaration">
          <button
            v-if="!newItemFlag"
            type="button"
            @click="openDeleteDetail(itemToEdit)"
            class="btn btnIcon btnSecundary mr-1"
          >
            <svg
              width="30"
              height="28"
              viewBox="0 0 30 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.25 6.97664C22.0875 6.59164 17.9 6.39331 13.725 6.39331C11.25 6.39331 8.775 6.50998 6.3 6.74331L3.75 6.97664"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.625 5.79834L10.9 4.27C11.1 3.16167 11.25 2.33334 13.3625 2.33334H16.6375C18.75 2.33334 18.9125 3.20834 19.1 4.28167L19.375 5.79834"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.5625 10.6633L22.75 22.4117C22.6125 24.2433 22.5 25.6667 19.0125 25.6667H10.9875C7.5 25.6667 7.3875 24.2433 7.25 22.4117L6.4375 10.6633"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.9121 19.25H17.0746"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.875 14.5833H18.125"
                stroke="#007AC4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            type="button"
            @click="saveItem"
            class="btn btnS btnPrimary mr-1"
          >
            {{ selectedLang.save }}
          </button>
          <button type="button" @click="cancel" class="btn btnS btnSecundary">
            {{ selectedLang.cancel }}
          </button>
        </div>
        <div v-else class="col-12 d-f jc-right">
          <button type="button" @click="cancel" class="btn btnS btnSecundary">
            {{ selectedLang.close }}
          </button>
        </div>
      </div>
    </CustomModal>
    <CustomModal
      modalId="TrabalhoDependenteDelete"
      size="md"
      :isCenter="true"
      :title="selectedLang.workDependentModalDelete"
    >
      <p class="p-3">{{ selectedLang.deleteQuestion }}</p>
      <div class="d-f pc-center">
        <button
          type="button"
          @click="deleteItem"
          class="btn btnS btnPrimary mr-1"
        >
          {{ selectedLang.eliminate }}
        </button>
        <button
          type="button"
          @click="cancel"
          class="btn btnS btnSecundary mr-1"
        >
          {{ selectedLang.cancel }}
        </button>
      </div>
    </CustomModal>
  </div>
</template>

<script>
import IconEdit from "@/components/icons/edit.vue";
import IconTrash from "@/components/icons/trash.vue";
import IconVerticalSeparator from "@/components/icons/vertical-separator.vue";
import { utils } from "@/helpers";
export default {
  components: {
    IconEdit,
    IconTrash,
    IconVerticalSeparator,
  },
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {
      validateForm: false,
      loading: false,
      tableModelAux: {
        total: 200,
        pages: 7,
      },
      modalDetailAux: {
        visible: false,
        size: "xl",
      },
      modalDeleteAux: {
        visible: false,
        size: "lg",
      },
      idToDelete: null,
      itemToEdit: {},
      itemToEditValidate: {
        trabalho: false,
        entidade: false,
      },
      newItemFlag: false,
      guid: "da9c415a-e950-4ce9-ab58-5ce1c1b5af53",
      tableRef: 0,
      isFromPortugalFlag: null,
    };
  },
  mounted() {},
  methods: {
    incomeEarnedAbroadSelect() {
      this.declaracaoUnicaHelper.clearCols(this.itemToEdit, [0, 1, 2, 4, 5, 6]);
    },
    searchFunc() {},
    sortFunc() {},
    openModal(modalId) {
      this.validateForm = false;
      this.emitter.emit("openCustomModal", modalId);
    },
    closeModal(modalId) {
      this.emitter.emit("closeCustomModal", modalId);
    },
    newItemAux(id) {
      this.trabalhoDependenteEditKey++;
      return this.declaracaoUnicaHelper.NewTableItem(
        this.guid,
        id,
        this.declaracaoUnicaHelper.tableSizeByGuid(this.guid)
      );
    },
    newItem() {
      this.newItemFlag = true;
      var id = this.items.value == null ? 0 : this.items.value.length;
      let item = this.newItemAux(id);

      this.itemToEdit = item;
      this.openModal("TrabalhoDependenteEdit");
    },
    editItem(item) {
      let valueToEdit = JSON.stringify(item);
      this.itemToEdit = JSON.parse(valueToEdit);
      utils.checkAllIfGUIDExistsInArray(
        this.itemToEdit.value,
        this.guid,
        this.declaracaoUnicaHelper.intToArray(
          this.declaracaoUnicaHelper.tableSizeByGuid(this.guid)
        )
      );
      this.openModal("TrabalhoDependenteEdit");
    },
    detailItem(item) {
      let valueToEdit = JSON.stringify(item);
      this.itemToEdit = JSON.parse(valueToEdit);
      this.openModal("TrabalhoDependenteEdit");
    },
    saveItem() {
      this.validateForm = true;
      if (this.formIsValid) {
        if (this.items.value == null) {
          this.items.value = [];
        }
        var id = parseInt(this.itemToEdit.key.split("_")[1]);

        this.items.value[id] = this.itemToEdit;

        this.cancel();
      }
    },
    openDeleteDetail(item) {
      console.log("this.items.isDeleted", this.items.isDeleted);

      this.idToDelete = item.key;

      this.openModal("TrabalhoDependenteDelete");
    },
    deleteItem() {
      utils.removeOfArrayByKey(this.items.value, this.idToDelete);
      var idReset = 0;
      this.items.value.forEach((element) => {
        element.key = `${this.guid}_${idReset}`;
        idReset++;
      });

      this.items.isDeleted = true;
      this.cancel();
    },
    cancel() {
      this.closeModal("TrabalhoDependenteEdit");
      this.closeModal("TrabalhoDependenteDelete");

      this.idToDelete = null;
      //this.itemToEdit = this.newItemAux(0);
      this.newItemFlag = false;
      this.validateForm = false;

      this.tableRef++;
    },
  },
  computed: {
    modalTitle() {
      if (this.declaracao.estado != 1) {
        return this.selectedLang.workDependentModalDetail;
      } else if (this.newItemFlag) {
        return this.selectedLang.workDependentModalAdd;
      }
      return this.selectedLang.workDependentModalEdit;
    },
    tableModel() {
      return {
        headers: [
          {
            title: this.selectedLang.payingEntity,
            sort: "payingEntity",
          },
          {
            title: this.selectedLang.monetaryValue,
            sort: "monetaryValue",
          },
        ],
        total: this.tableModelAux.total,
        pages: this.tableModelAux.pages,
      };
    },

    trabalhoIsValid() {
      //utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 0);
      return this.itemToEdit.value[0].value != null;
    },
    trabalhoShowError() {
      return this.validateForm && !this.trabalhoIsValid;
    },

    entidadeIsValid() {
      //utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 1);
      return this.itemToEdit.value[1].value != null;
    },
    entidadeShowError() {
      return this.validateForm && !this.entidadeIsValid;
    },
    isItNotFromPortugalIsValid() {
      //utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 1);
      return this.itemToEdit.value[3].value != null;
    },
    isItNotFromPortugalShowError() {
      return this.validateForm && !this.isItNotFromPortugalIsValid;
    },
    countryISOCodeIsValid() {
      //utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 1);
      return this.isFromPortugal || this.itemToEdit.value[4].value != null;
    },
    countryISOCodeShowError() {
      return this.validateForm && !this.countryISOCodeIsValid;
    },
    taxIdentificationIsValid() {
      //utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 1);
      return this.isFromPortugal || this.itemToEdit.value[6].value != null;
    },
    taxIdentificationShowError() {
      return this.validateForm && !this.taxIdentificationIsValid;
    },
    NIFNIPCIsValid() {
      //utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 2);
      return (
        !this.isFromPortugal ||
        (this.itemToEdit.value[2].value != null &&
          utils.checkNif(this.itemToEdit.value[2].value))
      );
    },
    NIFNIPCShowError() {
      return this.validateForm && !this.NIFNIPCIsValid;
    },
    NIFNIPCErrorMsg() {
      //utils.checkIfGUIDExistsInArray(this.itemToEdit.value, this.guid, 2);
      return this.itemToEdit.value[2].value != null
        ? this.selectedLang.invalidNIF
        : null;
    },

    formIsValid() {
      return (
        this.trabalhoIsValid &&
        this.entidadeIsValid &&
        this.NIFNIPCIsValid &&
        this.countryISOCodeIsValid &&
        this.taxIdentificationIsValid &&
        this.isItNotFromPortugalIsValid
      );
    },

    items: {
      get() {
        return this.declaracaoUnicaHelper.getFieldByKey(
          this.declaracaoUnicaProp,
          this.guid
        );
      },
      set(newValue) {
        this.declaracaoUnicaHelper.setFieldValueByKey(
          this.declaracaoUnicaProp,
          this.guid,
          newValue
        );
      },
    },
    formExercicioFuncoesOptions() {
      return [
        {
          value: 1,
          label: this.selectedLang.yes,
        },
        {
          value: 0,
          label: this.selectedLang.no,
        },
      ];
    },
    isFromPortugal() {
      return this.itemToEdit.value[3].value == 0;
    },
    isNotFromPortugal() {
      return this.itemToEdit.value[3].value == 1;
    },
  },
};
</script>

<style></style>
